<div class="modal-body">
  <ng-template [ngIf]="!showConfirmation" [ngIfElse]="confirmation">
    <div class="container">
      <div class="row info">
        <div class="col-lg-12">
          {{ 'M_DOCUMENT_MODIFICATION_REQUEST' | translate }}
        </div>
      </div>
      <div class="row">
        <div class="container">
          <form id="editForm" #editForm [formGroup]="form" (ngSubmit)="submit()">
            <app-textarea
              [formGroup]="form"
              [isSubmitted]="isSubmitted"
              [options]="inputs.message"></app-textarea>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #confirmation>
    <div class="container text-center">
      <p>
        <i class="icon icon-assistance-fill icon-64"></i>
      </p>
      <p>
        <strong>{{ 'M_YOUR_REQUEST_SENT' | translate }}!</strong>
      </p>
      <p>
        {{ 'M_WE_WILL_CONTACT_YOU_SHORTLY_2' | translate }},
        <br />
        {{ 'M_TO_UPDATE_ABOUT_REQUEST' | translate }}.
        <br />
        {{ 'C_THANKS' | translate }}.
      </p>
    </div>
  </ng-template>
</div>
