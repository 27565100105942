import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { C_MINIMUM_AMOUNT_TO_SENT } from '@app/shared/constants/common.constants';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SkipErrorInterceptorHeaders } from '../interceptors/skip-error.interceptor';

const STATUS_PASS = [409, 429, 504];
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  static showErrorMessage = true;

  constructor(
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const displayErrorMessage = request.headers.has(
      SkipErrorInterceptorHeaders.SKIP_ERROR_INTERCEPTOR
    );

    return next.handle(request).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const { error, status } = httpErrorResponse;
        const message = 'Corp cities not found.';

        if (STATUS_PASS.includes(status)) {
          return throwError(() => httpErrorResponse);
        }

        if (error && error.Code === C_MINIMUM_AMOUNT_TO_SENT) {
          this.toastrService.error(this.translateService.instant('C_MINIMUM_AMOUNT_TO_SENT'));

          return throwError(() => error);
        }
        HttpErrorInterceptor.showErrorMessage &&
          !displayErrorMessage &&
          error &&
          error.Message &&
          status >= 400 &&
          status !== 401 &&
          status !== 403 &&
          status < 605 &&
          status !== 600 &&
          status !== 9999 &&
          message !== error.Message &&
          this.toastrService.error(error.Message);

        return throwError(() => error);
      })
    );
  }
}
