<ng-container
  *ngIf="additionalDocumentTypes && inputs.ResidentType.options.length > 0; else loading">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <i class="icon icon-hause-address icon-24 mb-2">
      <span class="path1"></span>
      <span class="path2"></span>
      <span class="path3"></span>
    </i>
    <app-radio
      class="resident-type"
      fullWidth="true"
      [formGroup]="form"
      [isSubmitted]="isSubmitted"
      [options]="inputs.ResidentType"></app-radio>

    <ng-container *ngIf="residentTypeSelected">
      <app-breadcrumb
        *ngIf="residentTypeSelected === hostedOptionID"
        class="breadcrumb-small"
        [options]="optionsBreadcrumb"
        [activeAutoChangeStep]="false"></app-breadcrumb>
      <app-selector
        *ngIf="residentTypeSelected !== hostedOptionID"
        (changeEvent)="onTypeChange($event)"
        [fullWidth]="true"
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.Type"></app-selector>
      <br />
      <ng-container [ngSwitch]="residentTypeSelected" *ngIf="residentTypeSelected > 0">
        <div *ngSwitchCase="hostedOptionID" class="container-info">
          <i class="icon icon-info icon-20 me-2"></i>
          <div *ngIf="active[0]" class="info">
            {{ 'M_PAC_ENROLLMENT_HOSTED_STEP1_DOCS_PROOF' | translate }}
          </div>
          <div *ngIf="active[1]" class="info__adjust">
            {{ 'M_PAC_ENROLLMENT_HOSTED_STEP2_DOCS_PROOF' | translate }}
          </div>
          <div *ngIf="active[2]" class="info">
            <div>&bull; {{ 'M_PAC_ENROLLMENT_HOSTED_STEP3_DOCS_PROOF' | translate }}</div>
            <div>&bull; {{ 'M_PAC_ENROLLMENT_TENANT_STEP_DOCS_PROOF' | translate }}</div>
            <div>&bull; {{ 'M_PAC_ENROLLMENT_OWNER_STEP_DOCS_PROOF' | translate }}</div>
          </div>
        </div>
        <div *ngSwitchDefault class="container-info">
          <i class="icon icon-info icon-20 me-2"></i>
          <div
            class="info"
            [class.info__adjust]="
              inputs.ResidentType.options[1]['IdAcceptanceCode'] === residentTypeSelected
            ">
            {{
              (inputs.ResidentType.options[1]['IdAcceptanceCode'] === residentTypeSelected
                ? 'M_PAC_ENROLLMENT_TENANT_STEP_DOCS_PROOF'
                : 'M_PAC_ENROLLMENT_OWNER_STEP_DOCS_PROOF'
              ) | translate
            }}
          </div>
        </div>
      </ng-container>

      <br />

      <app-image-uploader
        [fullWidth]="true"
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [accept]="inputs.file1.accept"
        [options]="inputs.file1"></app-image-uploader>
    </ng-container>
  </form>
</ng-container>
<ng-template #loading>
  <app-skeleton-loader
    [selector]="'text-center'"
    [isList]="true"
    [listParams]="[
      { w: '20px', h: '20px' },
      { w: '25%', h: '16px', mb: '12px' }
    ]"></app-skeleton-loader>
  <app-skeleton-loader
    [selector]="'text-center'"
    [isHorizontal]="true"
    [border]="null"
    [listParams]="[
      { w: '20px', h: '20px', type: 'circle' },
      { w: '50px', h: '16px', mr: '8%' },
      { w: '20px', h: '20px', type: 'circle' },
      { w: '50px', h: '16px', mr: '8%' },
      { w: '20px', h: '20px', type: 'circle' },
      { w: '50px', h: '16px' }
    ]"></app-skeleton-loader>
  <app-skeleton-loader
    [isList]="true"
    [listParams]="[
      { w: '10%', h: '20px' },
      { w: '100%', h: '40px', mb: '12px' },
      { w: '18%', h: '20px' },
      { w: '100%', h: '200px', br: '8px' }
    ]"></app-skeleton-loader>
</ng-template>
