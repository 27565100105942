import { Component, OnDestroy, OnInit } from '@angular/core';
import { GoogleAuthenticationService } from '@app/core/services/google-authentication.service';
import { TranslationsService } from '@app/core/services/translations.service';
import { SmsConfirmationType } from '@app/shared/enums/sms-confirmation-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { SmsConfirmationModalComponent } from './sms-confirmation-modal/sms-confirmation-modal.component';

@Component({
  selector: 'app-account-modal-security',
  templateUrl: './account-modal-security.component.html',
  styleUrls: ['./account-modal-security.component.scss'],
})
export class AccountModalSecurityComponent implements OnInit, OnDestroy {
  get guidePath() {
    if (this.language === 'uk') {
      return `assets/guides/gauth/gauth_en.pdf`;
    }
    return `assets/guides/gauth/gauth_${this.language}.pdf`;
  }

  loading = false;
  pending = false;
  isActive$: Observable<boolean>;
  private language: string;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly translationService: TranslationsService,
    private readonly googleAuthenticationService: GoogleAuthenticationService,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit() {
    this.loading = true;
    this.language = this.translationService.currentLanguage$.getValue();
    this.getIsActive();
  }

  requestActivation() {
    this.pending = true;
    this.googleAuthenticationService
      .requestActivation('')
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.openSmsConfirmationModal('activation')),
        finalize(() => (this.pending = false))
      )
      .subscribe();
  }

  requestDeactivation() {
    this.pending = true;
    this.googleAuthenticationService
      .requestDeactivation()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.openSmsConfirmationModal('deactivation')),
        finalize(() => (this.pending = false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getIsActive() {
    this.loading = true;
    this.isActive$ = this.googleAuthenticationService
      .isActive()
      .pipe(finalize(() => (this.loading = false)));
  }

  private openSmsConfirmationModal(type: SmsConfirmationType): void {
    const modal = this.modalService.open(SmsConfirmationModalComponent);
    modal.componentInstance.type = type;
    modal.result.finally(() => this.getIsActive());
  }
}
