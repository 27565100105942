import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private enabled: boolean;
  private apiKey: string | null;
  private libraries: string | null;
  private mapsLoaded: boolean;

  constructor() {
    const { googleMaps } = environment.services;
    this.enabled = googleMaps.enabled;
    this.apiKey = googleMaps.apiKey;
    this.libraries = googleMaps.libraries;
    this.mapsLoaded = false;
  }

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.enabled && !this.mapsLoaded) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=${this.libraries}&callback=Function.prototype`;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Error loading Google Maps script.'));
        document.head.appendChild(script);
        this.mapsLoaded = true;
      } else {
        console.log('Google Maps is disabled in this environment.');
        resolve();
      }
    });
  }
}
