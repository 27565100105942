import { Component } from '@angular/core';
import { fadeIn, fadeOut } from '@app/shared/animations/fade.animation';

@Component({
  selector: 'app-account-modal-menu',
  templateUrl: './account-modal-menu.component.html',
  styleUrls: ['./account-modal-menu.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class AccountModalMenuComponent {}
