<div class="form-group row address" [formGroup]="formGroup">
  <label *ngIf="!options?.unlabelled" class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div class="col-lg" [class.col-lg-12]="fullWidth">
    <div class="address-container">
      <input
        #mapSearchField
        [ngStyle]="{
          'letter-spacing': inputValue && separateLetter ? '5px' : '0px'
        }"
        class="form-control {{ options.inputClass }}"
        [ngClass]="{
          'is-invalid': isInvalid || invalid,
          disabled: disabled
        }"
        [type]="options.type"
        [formControlName]="options.formControlNameValue"
        [placeholder]="placeholder | translate"
        [appTrimInput]="true" />
      <div *ngIf="options.append" class="input-group-append">
        <span class="input-group-text"><i [class]="options.append"></i></span>
      </div>
    </div>
    <ng-container *ngIf="!options.hideErrors && isRequiredError">
      <span class="msg-error">
        {{ requiredErrorText || 'VAL_MSG_REQUIRED' | translate }}
      </span>
    </ng-container>
    <ng-container *ngIf="options?.helper">
      <small class="form-text text-muted">
        {{ options.helper | translate }}
      </small>
    </ng-container>
  </div>
</div>
