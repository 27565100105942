export const APP_CONFIG = {
  CONTEXT: 'htr',
} as const;

export const FORMATS = {
  DATE_HOUR_FORMAT: 'DD MMMM yyyy HH:mm',
  DATE_HOUR_NEW_FORMAT: 'DD MMM yyyy h:mm A',
  LARGE_DATE_AND_TIME_FORMAT: 'DD MMMM yyyy HH:mm',
  LARGE_DATE_FORMAT: 'dd MMMM yyyy',
  MONTH_YEAR_DATE_FORMAT: 'MM/yyyy',
  NUMBER_FORMAT: '1.2-2',
} as const;

export const CONSTANTS = {
  DAYS_IN_MONTH: 30,
} as const;

export const C_MINIMUM_AMOUNT_TO_SENT = 9452;
export const MAX_FILE_SIZE = 5000000;

export const STATUS_TRANSLATE = {
  Cancelled: 'TOPUP_TRANSACTION_CANCELLED',
  Pending: 'TOPUP_TRANSACTION_PENDING',
  Awaiting: 'TOPUP_TRANSACTION_AWAITING',
  Failed: 'TOPUP_TRANSACTION_FAILED',
  Success: 'TOPUP_TRANSACTION_SUCCESS',
  Warning: 'TOPUP_TRANSACTION_WARNING',
} as const;

export const GENDER = {
  1: 'C_F_MALE',
  2: 'C_F_FEMALE',
} as const;
