import { Component, Input } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { SessionService } from '@app/core/services/session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() showSidebar: boolean;

  readonly aboutUsUrl = AppConfig.aboutUsUrl;
  readonly blogUrl = AppConfig.blogUrl;

  constructor(
    private readonly sessionService: SessionService,
    public readonly translate: TranslateService
  ) {}

  logOut() {
    this.sessionService.logout();
  }
}
