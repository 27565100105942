import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslationsService } from '@app/core/services/translations.service';
import { fadeIn, fadeOut } from '@app/shared/animations/fade.animation';
import { BaseComponent } from '@app/shared/components/base/base.component';

type ButtonTypes = 'button' | 'submit';
type ButtonColors =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'light-success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'link'
  | 'primary-inverted'
  | 'blue-dark'
  | 'secondary-on-hover'
  | 'light-primary-no-border';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class ButtonComponent extends BaseComponent implements OnInit, AfterViewInit {
  @HostBinding('class') hostClass;
  @ViewChild('button', { static: true }) button: ElementRef;
  @Input() color: ButtonColors = 'primary';
  @Input() type: ButtonTypes = 'button';
  @Input() fullWidth: boolean;
  @Input() disabled: boolean;
  @Input() pending: boolean;
  @Input() disableAnimation = false;

  get colorClass() {
    return `btn-${this.color}`;
  }

  constructor(private readonly translationsService: TranslationsService) {
    super();
  }

  ngOnInit() {
    if (this.fullWidth) {
      this.hostClass = 'full-width';
    }
    this.observeLanguage();
  }

  ngAfterViewInit(): void {
    if (!this.fullWidth && this.button.nativeElement.offsetWidth) {
      this.button.nativeElement.style.width = `${this.button.nativeElement.offsetWidth + 10}px`;
    }
  }

  private observeLanguage() {
    this.subscription.add(
      this.translationsService.currentLanguage$.subscribe(() => this.setConstWidth())
    );
  }

  private setConstWidth() {
    if (!this.fullWidth) {
      this.button.nativeElement.style.width = 'auto';
    }
  }
}
