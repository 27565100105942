import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  private readonly DEFAULT_DOMAIN = window.location.hostname;
  private readonly DEFAULT_CROSS_ORIGIN_SETTING = 'Strict';

  private readonly domain: string;
  private readonly useSecureCookies: boolean;
  private readonly crossOriginSetting: 'Strict' | 'Lax' | 'None';

  constructor(
    private readonly cookieService: CookieService,
    private readonly deviceDetectorService: DeviceDetectorService
  ) {
    const browser = this.deviceDetectorService.getDeviceInfo().browser;
    this.domain = browser === 'IE' ? undefined : this.DEFAULT_DOMAIN;
    this.useSecureCookies = browser === 'IE' ? false : environment.useSecureCookies;
    this.crossOriginSetting = browser === 'IE' ? undefined : this.DEFAULT_CROSS_ORIGIN_SETTING;
  }

  public getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  public setCookie(name: string, value: string, expiration?: Date): void {
    this.cookieService.set(
      name,
      value,
      expiration,
      '/',
      this.domain,
      this.useSecureCookies,
      this.crossOriginSetting
    );
  }

  public deleteCookie(name: string): void {
    // TODO verify since this issue was solve in v3
    // fixes https://github.com/stevermeister/ngx-cookie-service/issues/81
    // This need to be use only when useSecureCookies is true and only work in pre and localhost
    this.cookieService.set(
      name,
      '',
      new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
      '/',
      this.domain,
      this.useSecureCookies,
      this.crossOriginSetting
    );

    this.cookieService.delete(name);
  }

  public isCookieSet(name: string): boolean {
    return this.cookieService.check(name);
  }
}
