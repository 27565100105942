import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from '@app/core/services/account.service';
import { CustomerDocument } from '@app/interfaces/customer.interface';
import { IMessage } from '@app/interfaces/edit-document-request.interface';

@Component({
  selector: 'app-edit-document-modal',
  templateUrl: './edit-document-modal.component.html',
})
export class EditDocumentModalComponent implements OnDestroy {
  @Input() document: CustomerDocument;
  showConfirmation: boolean;
  pending: boolean;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly accountService: AccountService,
    private readonly activeModal: NgbActiveModal
  ) {}

  save({ Message }: IMessage) {
    this.pending = true;
    this.accountService
      .editDocument({ DocumentId: this.document.Id, Message })
      .pipe(
        tap(() => (this.showConfirmation = true)),
        finalize(() => (this.pending = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  close() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
