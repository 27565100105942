import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslationsService } from '../translations.service';
@Injectable({
  providedIn: 'root',
})
export class CustomDatePickerI18n extends NgbDatepickerI18n {
  constructor(private readonly translationsService: TranslationsService) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return this.translationsService.calendarNames.weekdays[weekday - 1];
  }

  getWeekdayShortName(weekday: number): string {
    return this.translationsService.calendarNames.weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return this.getMonthFullName(month);
  }
  getMonthFullName(month: number): string {
    return this.translationsService.calendarNames.months[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}/${date.month}/${date.year}`;
  }
}
