<img
  *ngIf="!isPDF"
  class="img-responsive img-preview"
  [ngClass]="{ 'img-resize': resize }"
  [src]="(image | async) || placeholder"
  alt="" />
<div *ngIf="isPDF" class="pdf-preview">
  <pdf-viewer
    [src]="image | async"
    [original-size]="false"
    [show-all]="false"
    [zoom]="0.7"
    [autoresize]="false"
    style="width: 100%; height: 50vh"
    (after-load-complete)="afterLoad()"></pdf-viewer>
</div>

<div *ngIf="isPDF && pending">
  <app-infinite-scroll-loader></app-infinite-scroll-loader>
</div>
