import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

/* eslint-disable */
declare let Froged: Function;

@Injectable({
  providedIn: 'root',
})
export class FrogedService {
  private enabled: boolean;
  private id: string | null;

  private frogedReadySubject = new BehaviorSubject<boolean>(false);
  public frogedReady$ = this.frogedReadySubject.asObservable();

  constructor() {
    const { froged } = environment.services;
    this.enabled = froged.enabled;
    this.id = froged.id;
    this.checkFrogedReady();
  }

  init(): void {
    if (this.enabled) {
      if (typeof window['Froged'] === 'function') {
        return;
      }

      window['frogedSettings'] = { appId: this.id };

      (function () {
        const a = document;
        const b = window;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        if (typeof window['Froged'] !== 'function') {
          window['Froged'] = function (a, b, c) {
            window['Froged'].c(a, b, c);
          };
          window['Froged'].q = [];
          window['Froged'].c = function (a, b, c) {
            window['Froged'].q.push([a, b, c]);
          };
          window['fg_data'] = { hooks: {} };

          const loadScript = function () {
            const script = a.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = `https://${self.id}.sdk.froged.com`; // Usa la variable externa `self`
            const firstScript = a.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(script, firstScript);
          };

          if (a.readyState === 'complete') {
            loadScript();
          } else {
            b.addEventListener('load', loadScript, false);
          }
        }
      }).call(this);
    } else {
      console.log('Froged is disabled in this environment.');
    }
  }

  emitEvent(eventName: string, params) {
    if (this.enabled) {
      Froged('track', eventName, params);
    }
  }

  logout() {
    if (this.enabled) {
      Froged('logout');
    }
  }
  set(params) {
    if (this.enabled) {
      Froged('set', params);
      this.frogedReadySubject.next(!!Froged('auth'));
    }
  }

  private checkFrogedReady() {
    if (this.enabled) {
      const interval = setInterval(() => {
        if (typeof Froged === 'function' && Froged('auth')) {
          this.frogedReadySubject.next(true);
          clearInterval(interval);
        }
      }, 300);
    }
  }
}
