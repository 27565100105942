import { Renderer2, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ScriptService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public loadJsScript(renderer: Renderer2, src: string, id = 'script'): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.id = id;
    renderer.appendChild(this.document.body, script);
    return script;
  }
}
