import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Entry } from '@app/interfaces/imt-topups-common.interfaces';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollLoaderComponent } from '../../loaders/infinite-scroll-loader/infinite-scroll-loader.component';
import { BaseInputComponent } from '../base-input.component';
import { ErrorsComponent } from '../errors/errors.component';
import { LocalizedCurrencyPipe } from '@app/pipes/localized-currency.pipe';

@Component({
  selector: 'app-amount-entry-picker',
  templateUrl: './amount-entry-picker.component.html',
  styleUrls: ['./amount-entry-picker.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    ErrorsComponent,
    NgbTooltip,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollLoaderComponent,
    LocalizedCurrencyPipe,
  ],
})
export class AmountEntryPickerComponent extends BaseInputComponent {
  @Input() entries = new Array<Entry>();

  constructor() {
    super();
    this.formControlType = 'amountPicker';
  }

  selectEntry(entry: Entry) {
    this.control.patchValue(entry);
  }
}
