<div class="modal-header">
  <div class="modal-header__icon"><i class="far fa-address-card"></i></div>
  <div class="modal-header__title">{{ 'C_ACCOUNT' | translate }}</div>
</div>
<div class="modal-body">
  <div class="account-modal">
    <div class="row">
      <div class="col-md-12 col-lg-3">
        <app-account-modal-user></app-account-modal-user>
      </div>
      <div class="col-md-12 col-lg-9">
        <app-account-modal-menu></app-account-modal-menu>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-end p-1">
  <app-button color="secondary" (click)="close()">
    <i class="fas fa-times-circle"></i>
    {{ 'C_A_CLOSE' | translate }}
  </app-button>
</div>
