import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, NgSelectModule, ReactiveFormsModule, TranslateModule],
})
export class SelectorComponent extends BaseInputComponent implements OnInit, OnChanges {
  @ViewChild('selectInput', { static: true }) selectInput: NgSelectComponent;
  @Input() searchable = false;
  @Input() selected: number;
  @Input() hasTranslationCode = false;
  @Input() invalid = false;
  @Input() dropdownPosition: 'bottom' | 'top' | 'auto' = 'auto';
  @Output() changed: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() focused: EventEmitter<any> = new EventEmitter<any>();

  isFocused = false;
  isOpen = false;
  indexList = 1;
  pressKey = false;
  mouseMoveEvent: Event;
  isMouseMove = true;
  isObject = false;
  showMagnifyingGlass = true;

  itemsObject: Array<{ label: string; id: string }> = [];
  elementValueRef;
  isClickOnOption = false;

  constructor(
    private renderer: Renderer2,
    public translateService: TranslateService
  ) {
    super();
    this.formControlType = 'select';
  }

  ngOnInit() {
    this.observeValue();
    this.keyArrowsListener();
    if (this.value && this.hasTranslationCode) {
      this.control.patchValue(this.value);
    }
    this.observeLanguage();
  }

  ngOnChanges() {
    this.itemsObject = this.options.options.map((item) => {
      if (typeof item === 'string') {
        return { label: this.translateService.instant(item), id: item };
      } else {
        this.isObject = true;
        return item;
      }
    });
    this.sortArray();
  }

  handleChange(e) {
    this.changed.emit(e);
  }

  handleOpen() {
    if (!this.value) {
      this.indexList = 1;
    } else {
      this.indexList = this.itemsObject.findIndex((item) => item.id === this.value) + 1;
    }
    this.isOpen = true;
  }

  handleClose() {
    this.isOpen = false;
  }

  handleBlur() {
    if (!this.selected) {
      this.showMagnifyingGlass = true;
      const pl: HTMLElement = document.querySelector('.ng-placeholder');
      if (pl) {
        if (this.placeholder) {
          pl.innerText = this.translateService.instant(this.placeholder);
        }
      }
    }
  }

  observeValue() {
    this.subscription.add(
      this.control.valueChanges.pipe(distinctUntilChanged()).subscribe({
        next: () => {
          if (this.value) {
            this.indexList = this.itemsObject.findIndex((item) => item.id === this.value) + 1;
          } else {
            this.indexList = 1;
          }
        },
      })
    );
  }

  onOptionSelect(index?: number) {
    this.indexList = index + 1;
    this.isClickOnOption = true;
    this.showMagnifyingGlass = true;
  }

  onSearch(e) {
    this.showMagnifyingGlass = false;
    this.isClickOnOption = false;
    this.control.patchValue(null);
    if (e.term === '') {
      this.selectInput.handleClearClick();
    }
  }

  defaultPlaceholder(): string {
    const translate = this.translateService.instant('C_A_SELECT');

    return !this.searchable ? translate + '...' : translate;
  }

  private keyArrowsListener() {
    const containerRef = this.selectInput.element.getElementsByClassName('ng-select-container')[0];
    this.renderer.listen(containerRef, 'keydown.arrowdown', (t) => {
      this.pressKey = true;
      if (this.indexList >= this.itemsObject.length) {
        this.stopPropagation(t);
      }
      if (this.indexList < this.itemsObject.length) {
        this.indexList++;
        if (!this.selectInput.isOpen) {
          this.indexList--;
        }
      }
    });
    this.renderer.listen(containerRef, 'keydown.arrowup', (t) => {
      this.pressKey = true;
      if (this.indexList <= 1) {
        this.stopPropagation(t);
      }
      this.indexList--;
      if (this.indexList === 0) {
        this.indexList = 1;
      }
    });
  }

  private stopPropagation(e: Event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
  }

  onMouseover(index: number) {
    this.indexList = index + 1;
    this.pressKey = false;
  }

  onMouseMove(e: Event) {
    this.mouseMoveEvent = e;
    this.pressKey = false;
  }

  private observeLanguage() {
    this.subscription.add(
      this.translateService.onLangChange.subscribe(() => {
        this.sortArray();
      })
    );
  }

  private sortArray() {
    this.options.options.forEach((element) => {
      if (element.Code || element.code) {
        element['translation'] = element.Code
          ? this.translateService.instant(element.Code)
          : this.translateService.instant(element.code);
      }
    });
    const elements = this.options.options.map((item) => {
      if (typeof item === 'string') {
        return { label: this.translateService.instant(item), id: item };
      } else {
        return item;
      }
    });
    this.itemsObject = this.isObject
      ? elements.sort((a, b) =>
          String(a.translation)
            .toLowerCase()
            .trim()
            .localeCompare(String(b.translation).toLowerCase().trim())
        )
      : elements.sort((a, b) =>
          String(a.label).toLowerCase().trim().localeCompare(String(b.label).toLowerCase().trim())
        );
  }
}
