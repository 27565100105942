<div class="form-group row" [formGroup]="formGroup">
  <div *ngIf="loading" class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
    <app-skeleton-loader
      [isList]="true"
      [listParams]="[
        { w: '25%', h: '20px' },
        { w: '100%', h: '40px' }
      ]"></app-skeleton-loader>
  </div>
  <ng-container *ngIf="!loading">
    <label
      *ngIf="!options?.unlabelled"
      class="col-lg-4 col-form-label"
      [class.col-lg-12]="fullWidth">
      <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
      {{ options.label | translate | withConfigKeys }}
    </label>
    <div class="col-lg" [class.col-lg-12]="fullWidth">
      <div class="input-group">
        <input
          class="form-control {{ options.inputClass }}"
          [class.is-invalid]="isInvalid || invalid"
          [type]="options.type"
          [formControlName]="options.formControlNameValue"
          [class.disabled]="disabled"
          [placeholder]="placeholder | translate"
          (keypress)="accepNumberOnly($event)"
          [maxlength]="maxlength"
          [appTrimInput]="trim" />
        <div *ngIf="options.append" class="input-group-text">
          <span><i [class]="options.append"></i></span>
        </div>
      </div>
      <ng-container *ngIf="options?.helper">
        <small class="form-text text-muted">
          {{ options.helper | translate }}
        </small>
      </ng-container>
      <app-errors
        *ngIf="!options.hideErrors"
        [dataTestId]="options.formControlNameValue"
        [isInvalid]="isInvalid || invalid"
        [formGroup]="formGroup"
        [formControlNameValue]="options.formControlNameValue"
        [requiredErrorText]="requiredErrorText"></app-errors>
    </div>
  </ng-container>
</div>
