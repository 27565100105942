import { Pagination } from '@app/interfaces/pagination.interface';

export class PaginatedResult<TData> implements Pagination {
  Data: TData[];
  PagesCount: number;
  CurrentPage: number;
  TotalCount: number;

  constructor(Data: TData[], PagesCount: number, CurrentPage: number, TotalCount: number) {
    this.Data = Data;
    this.PagesCount = PagesCount;
    this.CurrentPage = CurrentPage || 1;
    this.TotalCount = TotalCount;
  }

  static empty<T>(): PaginatedResult<T> {
    return new PaginatedResult(new Array<T>(), 0, 0, 0);
  }

  static create<T>(
    data: T[],
    totalRowsCount: number,
    startRow: number,
    rowsCount: number
  ): PaginatedResult<T> {
    return new PaginatedResult(
      data,
      Math.ceil(totalRowsCount / rowsCount) || 1,
      Math.ceil(startRow / rowsCount) + 1 || 1,
      totalRowsCount
    );
  }
}
