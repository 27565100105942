import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@app/core/services/account.service';
import { SessionService } from '@app/core/services/session.service';
import { TranslationsService } from '@app/core/services/translations.service';
import { fadeIn, fadeOut } from '@app/shared/animations/fade.animation';
import { Account } from '@app/interfaces/account/account.interface';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { AVAILABLE_LANGUAGES } from '@app/shared/constants/available-languages.constants';
import { SCREEN_SIZES } from '@app/shared/constants/screen-sizes.constants';
import { IAvailableLanguage } from '@app/interfaces/available-language.interface';
import { AccountModalComponent } from '@app/shared/modals/account-modal/account-modal.component';
import { ContactUsModalComponent } from '@app/shared/modals/contact-us-modal/contact-us-modal.component';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '@app/app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @ViewChild('languageDropdown', { static: true }) languageDropdown: NgbDropdown;

  @Input() showReturnToHomeButton = false;
  @Input() title = 'P_HOME';
  @Input() route = '';
  @Input() showBackButtonInSmall = false;
  @Input() showChatButton = false;

  @Output() readonly OnChatWidget = new EventEmitter();

  currentLanguage: IAvailableLanguage;
  url: string;
  isTooltipVisible: boolean;
  isMobile: boolean;
  account: Account;
  initials: string;
  nameFormat: string;
  backButtonLabel = 'P_HOME';
  isContact = false;
  callbackGoTo: any;
  contactEnabled = AppConfig.contactPageEnabled ?? true;

  readonly availableLanguages = AVAILABLE_LANGUAGES;

  constructor(
    readonly translationsService: TranslationsService,
    readonly translateService: TranslateService,
    readonly router: Router,
    readonly sessionService: SessionService,
    readonly modalService: NgbModal,
    readonly accountService: AccountService
  ) {
    super();
  }

  ngOnInit() {
    this.observeLanguage();
    this.observeAccount();
    this.onResize();
  }

  @HostListener('window:resize')
  onResize() {
    const screenWidth = window.innerWidth;
    this.isTooltipVisible = screenWidth > SCREEN_SIZES.XS && screenWidth < SCREEN_SIZES.MD;
    this.isMobile = screenWidth < SCREEN_SIZES.MD;
    if (screenWidth > 766 && this.showBackButtonInSmall && this.route.includes('contact')) {
      this.router.navigateByUrl('contact');
    }
  }

  setLanguage(language: IAvailableLanguage) {
    this.translationsService.setLanguage(language.code);
  }

  getTooltip(label: string) {
    return this.isTooltipVisible ? this.translateService.instant(label) : '';
  }

  openAccountModal() {
    this.modalService.open(AccountModalComponent, { size: 'xl' });
  }

  openContactUsModal() {
    this.modalService.open(ContactUsModalComponent);
  }

  toggleLanguageDropdown() {
    // Library is broken - it works as it should:
    if (this.languageDropdown) {
      this.languageDropdown.isOpen() ? this.languageDropdown.open() : this.languageDropdown.close();
    }
  }

  goTo(route: string) {
    if (this.callbackGoTo) {
      this.callbackGoTo();

      return;
    }

    this.router.navigateByUrl(route);
  }

  logout() {
    this.languageDropdown.close();
    setTimeout(() => {
      this.sessionService.logout();
    });
  }

  getFullNameFormat() {
    const name = this.account !== null && this.account?.Name ? this.account.Name.toLowerCase() : '';
    const initialLetter =
      this.account !== null && this.account?.LastName ? this.account.LastName.charAt(0) : '';

    this.nameFormat = `${name} ${initialLetter}.`;
  }

  overrideGoToButton(options: any) {
    this.title = options ? options.title : 'P_HOME';
    this.callbackGoTo = options ? options.callback : null;
  }

  observeLanguage() {
    this.subscription.add(
      this.translationsService.currentLanguage$.subscribe((code) => {
        this.currentLanguage = this.availableLanguages.find((x) => x.code === code);
      })
    );
  }

  private getInitials() {
    if (this.accountService.account) {
      const initialName = this.account?.Name ? this.account.Name.charAt(0) : '';
      const initialLastName = this.account?.LastName ? this.account.LastName.charAt(0) : '';
      this.initials = `${initialName}${initialLastName}`;
    }
  }

  private observeAccount() {
    this.subscription.add(
      this.accountService.account$.subscribe((res) => {
        this.account = res;
        this.getFullNameFormat();
        this.getInitials();
      })
    );
  }
}
