import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IFormInput } from '@app/interfaces/forms.interface';
import { Subscription } from 'rxjs';

const REQUIRED_STR = 'required';

type FormControlTypes =
  | 'input'
  | 'email'
  | 'select'
  | 'radio'
  | 'datePicker'
  | 'checkbox'
  | 'countryPicker'
  | 'file'
  | 'textarea'
  | 'cityPicker'
  | 'amountInput'
  | 'dropdown'
  | 'phoneInput'
  | 'searchablePicker'
  | 'operatorPicker'
  | 'operatorListPicker'
  | 'amountPicker'
  | 'paymentMethodPicker'
  | 'beneficiaryPicker';
@Component({
  selector: 'app-base-input',
  template: '',
})
export class BaseInputComponent implements OnChanges, OnDestroy {
  @Output() readonly focusEvent = new EventEmitter<void>();
  @Output() readonly blurEvent = new EventEmitter<void>();
  @Input() formGroup: UntypedFormGroup;
  @Input() options: IFormInput;
  @Input() showRequiredSymbol = false;
  @Input() requiredErrorText: string;
  @Input() isSubmitted = false;
  @Input() fullWidth: boolean;
  @Input() disabled: boolean;
  @Input() pending: boolean;
  @Input() loading: boolean;

  formControlType: FormControlTypes;

  // Select, radio, checkbox:
  availableOptions: any[];
  optionsLabels: string[];
  optionsValues: any[];

  subscription = new Subscription();

  ngOnChanges(_changes: SimpleChanges) {
    this.validateModel();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get control(): AbstractControl {
    return this.formGroup ? this.formGroup.controls[this.options.formControlNameValue] : null;
  }

  get isRequired() {
    if (!this.control.validator) {
      return false;
    }
    return this.control.hasValidator(Validators.required);
  }

  get isRequiredError() {
    return !!this.control && this.control.hasError(REQUIRED_STR) && this.isInvalid;
  }

  get isInvalid(): boolean {
    if (this.isSubmitted || (this.options.validateOnTouched && this.isTouched)) {
      return this.control.invalid;
    }

    return false;
  }

  get isDisabled(): boolean {
    return this.control.disabled;
  }

  get isTouched(): boolean {
    return this.control.touched;
  }

  get placeholder(): string {
    return this.options.placeholder ? this.options.placeholder : '';
  }

  get value(): any {
    return this.control.value;
  }

  private validateModel() {
    if (!this.formControlType) {
      throw new Error('Input: Missing form control type');
    }
    switch (this.formControlType) {
      case 'input':
      case 'email':
        {
          const isInputValidConditions = [Boolean(this.options?.type)];
          if (!isInputValidConditions.every((x) => x)) {
            throw new Error('Input: Missing type');
          }
        }
        break;
      case 'radio':
      case 'select':
        {
          const isArrayExists = this.options && this.options.options;
          if (!isArrayExists) {
            throw new Error('Input: Missing options for selectable input');
          }
          const isArrayOfStrings =
            Array.isArray(this.options.options) &&
            this.options.options.every((item) => typeof item === 'string');
          const isArrayToSelectValidConditions = [
            Boolean(this.options.options.length),
            Boolean(this.options.optionLabel),
            Boolean(this.options.optionValue),
          ];
          if (!isArrayOfStrings && !isArrayToSelectValidConditions.every((x) => x)) {
            throw new Error('Input: Invalid selectable array model');
          }
          this.parseOptions(isArrayOfStrings);
        }
        break;
      case 'checkbox':
      case 'countryPicker':
      case 'datePicker':
      case 'file':
        break;
      default:
        break;
    }
  }

  private parseOptions(isArrayOfStrings: boolean) {
    if (isArrayOfStrings) {
      this.availableOptions = this.options.options;
      this.optionsLabels = this.options.options;
      this.optionsValues = this.options.options;

      return;
    }
    this.availableOptions = this.options.options;
    this.optionsLabels = this.options.options.map((x) => x[this.options.optionLabel]);
    this.optionsValues = this.options.options.map((x) => x[this.options.optionValue]);
  }
}
