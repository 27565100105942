import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private enabled: boolean;
  private id: string | null;

  constructor() {
    const { googleTagManager } = environment.services;
    this.enabled = googleTagManager.enabled;
    this.id = googleTagManager.id;
  }

  init(): void {
    if (this.enabled) {
      const gtmId = this.id;

      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0],
          j: any = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmId);
    } else {
      console.log('Google Tag Manager is disabled in this environment.');
    }
  }

  addNoScriptTagManager(): void {
    if (this.enabled) {
      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.title = '';
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.id}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.setAttribute('style', 'display: none; visibility: hidden');
      noscript.appendChild(iframe);
      document.head.appendChild(noscript);
    }
  }
}
