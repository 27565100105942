import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalRef: NgbModalRef;

  constructor(private readonly modal: NgbModal) {}

  public open(component, options?: NgbModalOptions): NgbModalRef {
    options ? (options.centered = true) : (options = { centered: true });
    this.modalRef = this.modal.open(component, options);

    return this.modalRef;
  }

  public close(data?): void {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.close(data);
  }
}
