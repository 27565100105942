import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NationalNumberService } from '@app/core/services/national-number.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Beneficiary } from '../classes/beneficiary.class';
import { onlyLettersNoSymbolsRegex, onlyLettersRegex } from '../constants/regex.constants';

export class CommonValidators {
  static isValidName(): ValidatorFn {
    const checkHyphensAndApostrophes = (val: string): boolean => {
      const value = ` ${val} `;
      // Since only letters is passed before, now, we are sure that only blank spaces are allowed
      /* eslint-disable */
      const regular_expr1 = / -/;
      const regular_expr2 = /- /;
      const regular_expr3 = /\' /;
      const regular_expr4 = / \'/;
      const regular_expr5 = /--/;
      const regular_expr6 = /\'\'/;
      const regular_expr7 = /\./;

      const match1 = regular_expr1.exec(value);

      if (match1 != null) {
        return false;
      }
      const match2 = regular_expr2.exec(value);
      if (match2 != null) {
        return false;
      }
      const match3 = regular_expr3.exec(value);
      if (match3 != null) {
        return false;
      }
      const match4 = regular_expr4.exec(value);
      if (match4 != null) {
        return false;
      }
      const match5 = regular_expr5.exec(value);
      if (match5 != null) {
        return false;
      }
      const match6 = regular_expr6.exec(value);
      if (match6 != null) {
        return false;
      }
      const match7 = regular_expr7.exec(value);
      if (match7 != null) {
        return false;
      }

      return true;
    };

    const checkDoubleSpace = (val: string): boolean => {
      const value = `${val}`;
      const regular_expr1 = /^[\s]+$/g;
      const regular_expr2 = /^[\s][\w]+/g;
      const regular_expr3 = /[\w]+[\s]+$/g;
      const regular_expr4 = /[\s]{2}[\w]+/g;
      const match1 = regular_expr1.exec(value);
      if (match1 != null) {
        return false;
      }
      const match2 = regular_expr2.exec(value);
      if (match2 != null) {
        return false;
      }
      const match3 = regular_expr3.exec(value);
      if (match3 != null) {
        return false;
      }
      const match4 = regular_expr4.exec(value);
      if (match4 != null) {
        return false;
      }
      return true;
    };

    const checkNoInvalidChars = (val: string): boolean => {
      const value = val.replaceAll(/[-' ]/g, '').split('');
      const match1 = value.find((v) => !v.match(onlyLettersNoSymbolsRegex));
      if (match1) {
        return false;
      }

      return true;
    };

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (typeof control.value !== 'string') {
        return null;
      }
      if (control.value) {
        if (!checkHyphensAndApostrophes(control.value)) {
          return { specialCharacters: true };
        }

        if (!checkNoInvalidChars(control.value)) {
          return { specialCharacters: true };
        }

        if (!checkDoubleSpace(control.value)) {
          return { doubleWhiteSpace: true };
        }

        const regex = onlyLettersRegex;
        if (!regex.test(control.value)) {
          return { repeteadCharacters: true };
        }
      }

      return null;
    };
  }

  static isAlphanumeric(isStrict?: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        const regex = isStrict ? /^[a-zA-Z0-9]*$/ : /^[a-zA-Z0-9 _]*$/;
        if (!regex.test(control.value)) {
          return { alphaNumeric: true };
        }
      }

      return null;
    };
  }

  /**
   *  Check if the value is alphanumeric with comma
   * important: this validator not include accented characters
   * @returns
   */
  static isAlphanumericWithComma(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        const regex = /^[a-zA-Z0-9 _,]*$/;
        if (!regex.test(control.value)) {
          return { alphaNumeric: true };
        }
      }

      return null;
    };
  }

  static isNumbersOnly(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        const regex = /^[0-9_]*$/;
        if (!regex.test(control.value)) {
          return { numbersOnly: true };
        }
      }

      return null;
    };
  }

  static isFloat(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        // eslint-disable-next-line no-useless-escape
        const regex = /^([1-9]\d*)(\.\d+)?(\,\d+)?$/;
        if (!regex.test(control.value)) {
          return { floatOnly: true };
        }
      }

      return null;
    };
  }

  static isLettersOnly(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        const regex = /^[a-zA-Z_]*$/;
        if (!regex.test(control.value)) {
          return { numbersOnly: true };
        }
      }

      return null;
    };
  }

  static validEmailPattern(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (!regex.test(control.value)) {
          return { invalidPatternEmail: true };
        }
      }

      return null;
    };
  }

  static isValidTelephone(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const telephone = control.value;
      if (!telephone || !telephone.number || !telephone.e164Number || !telephone.internationalNumber) {
        return { 'invalidTelephone': true };
      }
      return null;
    };
  }
  

  static checkUniqueAccountNumber(accounts: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && isNaN(control.value) && accounts.includes(control.value)) {
        return { existIban: true };
      }

      return null;
    };
  }

  static checkOnEditBnfIban(
    beneficiaries?: Beneficiary[],
    editBeneficiary?: Beneficiary,
    currentAccountIbn?: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const beneficiary = beneficiaries
        ? beneficiaries.find((data) => data.AccountNumber === control.value)
        : null;
      const isSameBeneficiary =
        editBeneficiary && beneficiary ? beneficiary.Id === editBeneficiary.Id : null;
      const validation = control.value !== undefined && isNaN(control.value);
      if (validation && currentAccountIbn === control.value) {
        return { isCurrentAccountIban: true };
      } else if (validation && beneficiary && beneficiary.Id && !isSameBeneficiary) {
        return { existIban: true };
      } else {
        return null;
      }
    };
  }

  static checkSendEmail(required: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (required && !control.value) {
        return { sendEmail: true };
      }

      return null;
    };
  }

  static isMiniumValid(minimum: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.value) {
        return null;
      }
      const valNum = String(control.value).replace('.', '').replace(',', '.');
      if (!String(control.value).includes('.') || !String(control.value).includes(',')) {
        if (Number(control.value) < minimum) {
          return { minium: false };
        }
      }
      if (String(control.value).includes('.') || String(control.value).includes(',')) {
        if (Number(valNum) < minimum) {
          return { minium: false };
        }
      }
      if (String(control.value).trim().startsWith('0,')) {
        if (Number(valNum) < minimum || Number(valNum) === 0 || Number(valNum) === 0.0) {
          return { minium: true };
        }
      }

      return null;
    };
  }

  static nationalNumberValidator(
    nationalNumberService: NationalNumberService,
    partnerSystem: string
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (partnerSystem === 'BEL') {
        return nationalNumberService.check(control.value).pipe(
          map((response) => (response['Data'] ? null : response)),
          catchError(() => of({ invalidNationalNumber: true }))
        );
      }
      return of(null);
    };
  }

  static validPostalCode(partnerSystem: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        const regex = this.getRegex(partnerSystem);
        if (!regex.test(control.value)) {
          return { pattern: true };
        }
      }
      return null;
    };
  }

  static getRegex(partnerSystem: string): RegExp {
    let regex = /^[A-Z0-9]*$/;
    switch (partnerSystem) {
      case 'BE':
        regex = /^\d{4}$/g;
        break;
      case 'ES':
      case 'FR':
      case 'IT':
        regex = /^\d{5}$/g;
        break;
      case 'NL':
        regex = /^\d{4}\s?[A-Z]{2}$/g;
        break;
      case 'GB':
        regex = /^([A-Z]{3}\s\d[A-Z]{2})|([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2})$/g;
        break;
      default:
        break;
    }
    return regex;
  }
}
