import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DictionariesService } from '@app/core/services/dictionaries.service';
import { SmileAccountService } from '@app/core/services/smile-account.service';
import { IdDocComplianceType } from '@app/interfaces/id-doc-response.interface';
import { BreadcrumbService } from '@app/services/breadcrumb.service';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { CatalogNumber } from '@app/shared/enums/smile-account.enum';
import { Breadcrumb } from '@app/interfaces/breadcrumb.interface';
import { ICreateDocumentRequest } from '@app/interfaces/create-document-request.interface';
import { IFormInputs } from '@app/interfaces/forms.interface';
import { Detail } from '@app/interfaces/smile-account/additional-information.interface';

@Component({
  selector: 'app-add-additional-document-form',
  templateUrl: './add-additional-document-form.component.html',
  styleUrls: ['./add-additional-document-form.component.scss'],
})
export class AddAdditionalDocumentFormComponent extends BaseComponent implements OnInit, OnChanges {
  @Output() readonly formSubmitted = new EventEmitter<ICreateDocumentRequest>();
  @Input() readonly docType: string;
  @Input() readonly onNextStep: number;
  @Output() readonly formIsValid = new EventEmitter<boolean>();
  @Output() readonly hostedCurrentStep = new EventEmitter<boolean>();
  isSubmitted: boolean;
  form: UntypedFormGroup;
  inputs: IFormInputs;
  additionalDocumentTypes: IdDocComplianceType[];
  documentTypeInfo: string;

  optionsBreadcrumb: Breadcrumb[];
  disabled: Array<boolean>;
  completedStep: Array<boolean>;
  active: Array<boolean>;
  currentStep: number;
  residentTypeSelected = 0;
  hostedOptionID = 0;

  hostedObj = {
    HandWritten: null,
    IdentDocument: null,
    ProofAddress: null,
  };

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dictionariesService: DictionariesService,
    private readonly breadcrumService: BreadcrumbService,
    private readonly smileAccountService: SmileAccountService
  ) {
    super();
  }
  ngOnChanges(): void {
    this.next();
  }

  ngOnInit(): void {
    this.getAdditionalDocumentType();
    this.resetBreadcrumbOptions();
  }

  submit(): void {
    if (this.form.valid && this.residentTypeSelected !== this.hostedOptionID) {
      this.formSubmitted.emit({
        ...this.form.value,
        propertyStatusDetail: this.getPropertyStatusDetail(),
      });
    } else {
      this.formSubmitted.emit({
        ...this.form.value,
        files: this.hostedObj,
        propertyStatusDetail: this.getPropertyStatusDetail(),
      });
    }
    this.isSubmitted = true;
  }

  unlockButton(): void {
    this.subscription.add(
      this.form.valueChanges.subscribe((res) => {
        this.setHostedObjectData(res);
        if (this.form.valid) {
          this.formIsValid.emit(false);
        } else {
          this.formIsValid.emit(true);
        }
      })
    );
  }

  setHostedObjectData(values): void {
    if (values.ResidentType === this.hostedOptionID) {
      this.hostedObj.HandWritten =
        this.active[0] && !this.hostedObj.HandWritten ? values.file1 : this.hostedObj.HandWritten;
      this.hostedObj.IdentDocument =
        this.active[1] && !this.hostedObj.IdentDocument
          ? values.file1
          : this.hostedObj.IdentDocument;
      this.hostedObj.ProofAddress =
        this.active[2] && !this.hostedObj.ProofAddress ? values.file1 : this.hostedObj.ProofAddress;
    }
  }

  onTypeChange(docTypeCode: string) {
    this.documentTypeInfo = this.additionalDocumentTypes.find(
      (docType) => docType.Code === docTypeCode
    ).Text;
  }

  next(): void {
    switch (this.currentStep) {
      case 1:
        this.changeBreadcrumb(2, 1, 1);
        this.initBreadcrumbOptions();
        this.setCurrentStep(2);
        break;
      case 2:
        this.changeBreadcrumb(3, 2, 2);
        this.initBreadcrumbOptions();
        break;
      default:
        break;
    }
    this.form?.controls?.file1.reset();
    this.form?.controls?.file1.markAsTouched();
    this.form?.controls?.file1.updateValueAndValidity();
  }

  back(): void {
    switch (this.currentStep) {
      case 1:
        this.setActiveIndex(0);
        break;
      case 2:
        this.setCurrentStep(1);
        this.setActiveIndex(1);
        break;
      default:
        break;
    }
  }

  private setAdditionalDocumentTypes(types: IdDocComplianceType[]) {
    this.additionalDocumentTypes = this.docType
      ? types.filter((type) => type.Code === this.docType)
      : types;
  }

  private getAdditionalDocumentType() {
    this.subscription.add(
      this.dictionariesService.fetchAdditionalDocumentTypes().subscribe((types) => {
        this.setAdditionalDocumentTypes(types);
        this.initInputProps();
        this.setPropertyStatusOptions();
        this.initForm();
        this.documentTypeInfo = this.additionalDocumentTypes[0].Text;
        this.unlockButton();
      })
    );
  }

  private initInputProps(): void {
    this.inputs = {
      ResidentType: {
        formControlNameValue: 'ResidentType',
        label: 'M_PAC_ENROLLMENT_SMILE_ACCOUNT_DOCS_RESIDENT_TYPE',
        optionLabel: 'label',
        optionValue: 'IdAcceptanceCode',
        validateOnTouched: true,
        options: [],
      },
      Type: {
        formControlNameValue: 'Type',
        label: 'C_F_TYPE',
        options: this.additionalDocumentTypes,
        optionLabel: 'Description',
        optionValue: 'Code',
        validateOnTouched: true,
      },
      file1: {
        formControlNameValue: 'file1',
        label: 'C_F_DOC_IMAGE',
        type: 'file',
        helper: 'C_A_SELECT_3',
        accept: 'image/*, application/pdf',
        validateOnTouched: true,
      },
    };
  }

  private setPropertyStatusOptions(): void {
    const IdAcceptanceCode = this.inputs.ResidentType.optionValue;
    this.subscription.add(
      this.smileAccountService
        .getAdditionalInfoCatalogMapped(CatalogNumber.PROPERTY_STATUS)
        .subscribe({
          next: (options) => {
            this.inputs.ResidentType.options = options;
            this.hostedOptionID =
              this.inputs.ResidentType.options.length > 0
                ? this.inputs.ResidentType.options[0][IdAcceptanceCode]
                : 0;
          },
          error: (error) => console.log(error),
        })
    );
  }

  private initBreadcrumbOptions(): void {
    this.optionsBreadcrumb = [
      {
        infoDisplayProperty: '',
        stepInfoText: 'C_T_UPLOADED',
        title: 'M_PAC_ENROLLMENT_HOSTED_STEP1',
        disabled: this.disabled[0],
        completedStep: this.completedStep[0],
        active: this.active[0],
      },
      {
        infoDisplayProperty: '',
        stepInfoText: 'C_T_UPLOADED',
        title: 'M_PAC_ENROLLMENT_HOSTED_STEP2',
        disabled: this.disabled[1],
        completedStep: this.completedStep[1],
        active: this.active[1],
      },
      {
        infoDisplayProperty: '',
        stepInfoText: 'C_T_UPLOADED',
        title: 'M_PAC_ENROLLMENT_HOSTED_STEP3',
        disabled: this.disabled[2],
        completedStep: this.completedStep[2],
        active: this.active[2],
      },
    ];
  }

  private changeBreadcrumb(
    disabledEnd: number,
    completedStepEnd: number,
    activeIndex: number
  ): void {
    for (let i = 0; i < disabledEnd; i++) {
      this.disabled[i] = false;
    }
    for (let i = 0; i < completedStepEnd; i++) {
      this.completedStep[i] = true;
    }
    for (let i = 0; i < this.active.length; i++) {
      this.active[i] = i === activeIndex;
    }
  }

  private setCurrentStep(currentStep: number): void {
    this.currentStep = currentStep;
    this.breadcrumService.currentStep = this.currentStep;
  }

  private setActiveIndex(activeIndex: number): void {
    for (let i = 0; i < this.active.length; i++) {
      this.active[i] = i === activeIndex;
    }
    this.initBreadcrumbOptions();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      ResidentType: ['', [Validators.required]],
      Type: [this.additionalDocumentTypes[0].Code, [Validators.required]],
      file1: [null, [Validators.required]],
    });
    this.form.controls.ResidentType.valueChanges.subscribe((res) => {
      this.residentTypeSelected = res;
      this.hostedCurrentStep.emit(res === this.hostedOptionID);
      this.form?.controls?.file1.reset(null, { emitEvent: false });
      if (res === this.hostedOptionID) {
        this.resetBreadcrumbOptions();
      }
    });
  }

  private getPropertyStatusDetail(): Detail {
    const option = this.inputs.ResidentType.options.find(
      (item) => item.IdAcceptanceCode === this.residentTypeSelected
    );
    return Object.assign({}, option);
  }

  private resetBreadcrumbOptions(): void {
    this.disabled = new Array(3).fill(true);
    this.completedStep = new Array(3).fill(false);
    this.active = new Array(3).fill(false);
    this.changeBreadcrumb(1, 0, 0);
    this.setCurrentStep(1);
    this.initBreadcrumbOptions();
  }
}
