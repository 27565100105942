import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-input-mt',
  templateUrl: './search-input-mt.component.html',
  styleUrls: ['./search-input-mt.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class SearchInputMtComponent implements OnInit {
  @Input() control: FormControl;
  @Input() placeholder: string;
  @Input() ActiveSearchButton = false;
  @Input() activeDisable = false;
  @Input() isMsClientSearch = false;
  @Input() maxLength: number;
  @Output() readonly clickSearchBtn = new EventEmitter<string>();
  isDisabled = false;
  searchValue: string;

  constructor(readonly translateService: TranslateService) {}

  ngOnInit() {
    if (this.activeDisable) {
      this.isDisabled = true;
    }
    const input = document.getElementById('inputSearch');

    input.addEventListener('keyup', function (event) {
      // Number 13 is the "Enter" key on the keyboard
      if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault();
        const btnElemnt = document.getElementById('buttonSearch');
        btnElemnt?.click();
      }
    });
    if (this.ActiveSearchButton) {
      this.obserSerach();
    }
  }

  obserSerach() {
    this.control.valueChanges.subscribe((res: string) => {
      this.searchValue = res;
      if (this.activeDisable) {
        this.isDisabled = res.length >= 3 ? false : true;
      }
      if (this.isMsClientSearch && this.searchValue == '1234') {
        this.control.patchValue('');
      }
      //comment "isDisabled" for now because there's no clean button and the user needs to clean the input and search again to get all results.
      //this.isDisabled =  res.length > 0 ? true : false;
    });
  }

  emitClickBtn() {
    this.clickSearchBtn.emit(this.searchValue);
  }

  onKeyPress(event: any): boolean {
    if (this.isMsClientSearch) {
      const regex = new RegExp(/^[a-zA-Z0-9+ñÑ\s]{1,50}$/).test(event.key);
      if (regex) {
        return true;
      } else {
        return false;
      }
    }
  }
}
