import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { BaseInputComponent } from '../base-input.component';
import { CountryService } from '@app/core/services/country.service';
import { CitiesService } from '@app/services/cities.service';
import { TrimDirective } from '@app/shared/directives/trim.directive';
import { CatalogCountry } from '@app/interfaces/country-information.interface';
import { City } from '@app/interfaces/address.interface';
import { environment } from '@env/environment';

@Component({
  selector: 'app-address-search-input',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, TrimDirective],
  templateUrl: './address-search-input.component.html',
  styleUrls: ['./address-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchInputComponent extends BaseInputComponent implements OnChanges {
  @ViewChild('mapSearchField') set search(s: ElementRef) {
    this.searchField = s;
    this.initSearch();
  }

  @Input() separateLetter: boolean;
  @Input() invalid: boolean;
  @Input() iso2Country: string = null;

  /**
   * City, country and postal code events
   */
  @Output() citySelected: EventEmitter<Pick<CatalogCountry, 'Id' | 'Name'>> = new EventEmitter();
  @Output() setPlace: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();
  @Output() countrySelected: EventEmitter<CatalogCountry> = new EventEmitter();
  @Output() postalCodeSelected: EventEmitter<google.maps.GeocoderAddressComponent> =
    new EventEmitter();
  @Output() streetNumberSelected: EventEmitter<google.maps.GeocoderAddressComponent> =
    new EventEmitter();

  public countries: CatalogCountry[] = [];
  private _city: Subject<google.maps.GeocoderAddressComponent> = new Subject();

  public inputValue: string;
  public searchField: ElementRef;
  private city: string;
  public autocomplete: google.maps.places.Autocomplete = null;

  public mapOptions: google.maps.places.AutocompleteOptions = {
    types: ['address'],
    componentRestrictions: { country: [] },
  };

  constructor(
    private citiesService: CitiesService,
    private countryService: CountryService
  ) {
    super();
    this.formControlType = 'input';

    this.countryService.fetchCountries().subscribe((res) => {
      this.countries = res;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iso2Country && this.searchField) {
      this.updateCountry(changes.iso2Country.currentValue);
    }
  }

  private findCitySelected(cities: City[]) {
    const citySelected = cities.find((city) => city.Name === this.city);
    this.citySelected.emit(citySelected ?? null);
  }

  private initSearch() {
    if (environment.services.googleMaps.enabled) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.searchField.nativeElement,
        this.options
      );
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();
        let addressRoute; // get street from place
        // get city from place
        if (place?.address_components) {
          const city = place.address_components.find((component) => {
            return component.types.includes('locality');
          });
          const country = place.address_components.find((component) => {
            return component.types.includes('country');
          });

          //get postal code from place
          const postalCode = place.address_components.find((component) => {
            return component.types.includes('postal_code');
          });

          //get street number from place
          const streetNumber = place.address_components.find((component) => {
            return component.types.includes('street_number');
          });

          addressRoute = place.address_components.find((component) => {
            return component.types.includes('route');
          });

          this.city = city?.short_name;
          const countrySelected = this.countries.find((c) => c.Iso2 === country.short_name);
          if (countrySelected?.Id) {
            this.countrySelected.emit({
              Id: countrySelected.Id,
              Name: countrySelected.Name,
              Iso2: countrySelected.Iso2,
              Iso3: countrySelected.Iso3,
              PhonePrefix: countrySelected.PhonePrefix,
            });

            this.citiesService.getCityByCountryId(countrySelected.Id).subscribe((res) => {
              if (res?.Data) {
                this.findCitySelected(res.Data);
              }
            });
          }
          this._city.next(city);
          this.postalCodeSelected.emit(postalCode);
          this.streetNumberSelected.emit(streetNumber);
        }

        this.setPlace.emit(place);
        this.formGroup.controls[this.options.formControlNameValue].setValue(
          addressRoute?.short_name ?? place.name
        );
      });
    }
  }

  updateCountry(Iso2: string) {
    if (Iso2) {
      this.mapOptions.componentRestrictions.country = Iso2;
      this.autocomplete?.setOptions(this.mapOptions);
    }
  }
}
