import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FlagIconDirective } from '@app/shared/directives/flag-icon.directive';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import { NativeElementInjectDirective } from './directives/native-element-inject.directive';
import { FocusDirective } from './directives/focus.directive';
import { HighlightDirective } from './directives/highlight.directive';
import { PhoneFilterPipe } from '@app/pipes/phone-filter.pipe';
import { PhoneInputTwoComponent } from './phone-input-two/phone-input-two.component';

@NgModule({
  declarations: [
    PhoneNumberInputComponent,
    PhoneInputTwoComponent,
    NativeElementInjectDirective,
    FocusDirective,
    HighlightDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BsDropdownModule.forRoot(),
    FlagIconDirective,
    PhoneFilterPipe,
  ],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: true } }],
  exports: [
    PhoneNumberInputComponent,
    PhoneInputTwoComponent,
    NativeElementInjectDirective,
    FocusDirective,
    HighlightDirective,
  ],
})
export class MtPhoneInputsModule {}
