import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorResponse } from '@app/interfaces/api-error-response.interface';
import { ApiResponse } from '@app/interfaces/api-response.interface';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthenticationService {
  private readonly GOOGLE_AUTH_URL = `${environment.apiBasePath}/online/account/gauth`;
  private readonly GOOGLE_AUTH_ACTIVATION_URL = `${this.GOOGLE_AUTH_URL}/activation`;
  private readonly GOOGLE_AUTH_DEACTIVATION_URL = `${this.GOOGLE_AUTH_URL}/deactivation`;

  constructor(private readonly httpClient: HttpClient) {}

  isActive(): Observable<boolean> {
    return this.httpClient
      .get<ApiResponse<boolean>>(`${this.GOOGLE_AUTH_URL}/enabled`)
      .pipe(map((res) => res.Data));
  }

  activate(): Observable<boolean> {
    return this.httpClient
      .post<ApiResponse<boolean>>(`${environment.apiBasePath}/online//account/gauth/activate`, {})
      .pipe(map((res) => res.Data));
  }

  requestActivation(captchaTokenResult: string): Observable<ApiErrorResponse> {
    return this.httpClient
      .post<ApiResponse<ApiErrorResponse>>(`${this.GOOGLE_AUTH_ACTIVATION_URL}/request`, {
        CaptchaTokenResult: captchaTokenResult,
      })
      .pipe(map((res) => res.Data));
  }

  confirmActivation(confirmationCode: string): Observable<string | void> {
    return this.httpClient
      .get<ApiResponse<string>>(`${this.GOOGLE_AUTH_ACTIVATION_URL}/confirm/${confirmationCode}`)
      .pipe(map((res) => res.Data));
  }

  requestDeactivation(): Observable<ApiErrorResponse> {
    return this.httpClient
      .get<ApiResponse<ApiErrorResponse>>(`${this.GOOGLE_AUTH_DEACTIVATION_URL}/request`)
      .pipe(map((res) => res.Data));
  }

  confirmDeactivation(code: string): Observable<string | void> {
    return this.httpClient
      .post<ApiResponse<string>>(`${this.GOOGLE_AUTH_DEACTIVATION_URL}/confirm/${code}`, {})
      .pipe(map((res) => res.Data));
  }
}
