<div [class]="selector">
  <div *ngIf="isHorizontal">
    <ul>
      <li *ngFor="let item of listHorizontal">
        <ngx-skeleton-loader
          *ngFor="let item of listParams"
          [appearance]="item.type ?? 'line'"
          [theme]="{
            width: item.w,
            height: item.h,
            'margin-right': item.mr,
            'border-radius': item.br ?? border,
            'margin-bottom': item.mb ?? '0px'
          }"></ngx-skeleton-loader>
      </li>
    </ul>
  </div>
  <ul *ngIf="!isHorizontal">
    <li *ngFor="let item of listParams">
      <ngx-skeleton-loader
        [count]="count"
        [appearance]="isList ? item.type ?? 'line' : type"
        [theme]="{
          width: item.w,
          height: item.h,
          'border-radius': item.br ?? border,
          'margin-bottom': item.mb ?? '0px'
        }"></ngx-skeleton-loader>
    </li>
  </ul>
</div>
