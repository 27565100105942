import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

interface DataCard {
  name: string;
  data: string;
}

@Component({
  selector: 'app-mixed-card',
  templateUrl: './mixed-card.component.html',
  styleUrls: ['./mixed-card.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class MixedCardComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() action: string;
  @Input() icon: string;
  @Input() active: boolean;
  @Output() Click = new EventEmitter<DataCard>();
  @Output() Action = new EventEmitter<DataCard>();
  isEditable: boolean;

  handleClick(_e: MouseEvent): void {
    this.isEditable = !this.isEditable;
    this.Click.emit({
      name: this.title,
      data: this.subTitle,
    });
  }
  handleAction(_e: MouseEvent): void {
    this.isEditable = !this.isEditable;
    this.Action.emit({
      name: this.title,
      data: this.subTitle,
    });
  }
}
