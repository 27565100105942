/* eslint-disable no-useless-escape */
export const onlyLettersRegex =
  /(?=^[a-zA-Z\'\-ñÑäÄàÀáÁåÅäÄâÂāĀąĄăĂãÃćĆčČċĊďĎÐđÊêëËèÈéÉěĚēĒęĘėĖĔğĞģĢġĠÎîïÏíÍīĪįĮİı^ķĶľĽĹļĻņŅöÖòÒóÓôÔõÕőŐřŘŕŔśŚšŠşŞṣṢșȘťŤţŢȚŨÛûüÜŭŬúÚůŮűŰūŪųŲýÝÿŸźŹžŽżŻßłŁæøÆØðþÐÞĦçÇħ\:\(\)\.\s]+$)(?!.*(.)\1{2,})/i;
export const extractStringRegex = /\/{1}([a-zA-Z\-]{1,20})\//; // To extract string between two "/"
export const emailRegex = /^(?![-.])[a-zA-Z0-9._-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
export const onlyLettersNoSymbolsRegex =
  /[a-zA-Z\ñÑńŃäÄàÀáÁåÅäÄâÂāĀąĄăĂãÃćĆčČċĊďĎÐđÊêëËèÈéÉěĚēĒęĘėĖĔğĞģĢġĠÎîïÏíÍīĪįĮİıķĶľĽĹļĻņŅöÖòÒóÓôÔõÕőŐřŘŕŔśŚšŠşŞṣṢșȘťŤţŢȚŨÛûüÜŭŬúÚůŮűŰūŪųŲýÝÿŸźŹžŽżŻßłŁæøÆØðþÐÞĦçÇħ]/i;
export const onlyCharsRegex = /[.,:;_'"!~`><@*\&%#?^${}()|[\]\\//\s]/gim;
export const allowedCharsNumbers = /[0-9\+\-\(\)\ ]/;
export const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
export const allowedOtherKeys = [
  'ArrowLeft',
  'ArrowUp',
  'ArrowRight',
  'ArrowDown',
  'Home',
  'End',
  'Insert',
  'Delete',
  'Backspace',
];
