import { Injectable } from '@angular/core';
import { TranslationsService } from './translations.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentLanguageService {
  constructor(private readonly translationService: TranslationsService) {}
  currentLanguage(): string {
    return this.translationService.currentLanguage$.getValue();
  }
}
