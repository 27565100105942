import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { extractStringRegex } from '@app/shared/constants/regex.constants';
import { environment } from '@env/environment';
import { COUNTRIES } from '@app/shared/pipes/urls-pipes/urls.conts';

@Component({
  selector: 'app-home-wrapper',
  templateUrl: './home-wrapper.component.html',
  styleUrls: ['./home-wrapper.component.scss'],
})
export class HomeWrapperComponent {
  goToLoginPage: string;
  display = false;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    const isWpEnabled = environment.wpEnabled;
    this.goToLoginPage = `${environment.imtWordpressBaseUrl}${this.extractCountryFromUrl()}/`;
    if (isWpEnabled && location.origin.includes(environment.imtWordpressBaseUrlNoProtocol)) {
      window.location.href = this.goToLoginPage;
    } else {
      this.display = true;
    }
  }

  getUrl(): string {
    return this.document.location.href;
  }

  private extractCountryFromUrl(): string {
    const regex = extractStringRegex;
    const matches = regex.exec(this.getUrl());
    let country = matches ? matches[1] : '';
    if (!COUNTRIES.includes(country)) {
      country = 'belgium';
    }

    return country;
  }
}
