<div class="sidebar" [class.sidebar__active]="showSidebar">
  <div class="sidebar__container">
    <div class="sidebar__container__link">
      <a [href]="aboutUsUrl" target="_blank">
        {{ 'P_ABOUT_US' | translate }}
      </a>
    </div>

    <div class="sidebar__container__link">
      <a [href]="blogUrl" target="_blank">
        {{ 'Blog' | translate }}
      </a>
    </div>

    <div class="sidebar__container__link">
      <a [href]="translate.currentLang | termsPipe" target="_blank">
        {{ 'M_PAC_ENROLLMENT_TERMS_AND_CONDITIONS_TITLE' | translate }}
      </a>
    </div>

    <div class="sidebar__container__link">
      <a [href]="translate.currentLang | privacyPolicyPipe" target="_blank">
        {{ 'C_F_PRIVACY_POLICY' | translate }}
      </a>
    </div>

    <div class="sidebar__container__link">
      <a [href]="translate.currentLang | cookiesPolicyPipe" target="_blank">
        {{ 'C_F_COOKIES_POLICY' | translate }}
      </a>
    </div>

    <div class="sidebar__container__link" (click)="logOut()">
      <a class="sidebar__container__link__highlight">
        {{ 'C_A_LOGOUT' | translate }}
      </a>
      <i class="icon icon-log_out-door icon-20"></i>
    </div>
  </div>
</div>
