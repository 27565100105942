<div class="container">
  <ng-container *ngIf="!loading">
    <div class="alert alert-warning" role="alert" *ngIf="form.invalid">
      <div class="alert__icon"><i class="fas fa-exclamation-triangle"></i></div>
      <div class="alert__text">{{ 'HT_PROFILE_MSG_VALIDATION2' | translate }}</div>
    </div>
    <div class="alert alert-primary" role="alert" *ngIf="!editable">
      <div class="alert__icon"><i class="fas fa-exclamation-triangle"></i></div>
      <div class="alert__text">
        {{ 'MSG_ACCOUNT_NOT_EDITABLE' | translate }}: {{ contactPhone$ | async }}
      </div>
    </div>
    <form [formGroup]="form" [class.disabled]="pending" (ngSubmit)="submit()" @fadeIn>
      <app-input
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.Name"
        [trim]="true"></app-input>
      <app-input
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.LastName"
        [trim]="true"></app-input>
      <app-date-picker
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.BirthDate"></app-date-picker>
      <app-input
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.Email"></app-input>
      <app-input
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.Telephone"></app-input>
      <app-radio
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.Gender"></app-radio>
      <app-select
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.Occupation"></app-select>
      <app-button [type]="'submit'" [pending]="pending" [fullWidth]="true">
        {{ 'C_A_SAVE' | translate }}
      </app-button>
    </form>
  </ng-container>
  <app-infinite-scroll-loader [show]="loading"></app-infinite-scroll-loader>
</div>
