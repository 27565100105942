<div class="form-group row amount-picker" [formGroup]="formGroup">
  <label *ngIf="!options?.unlabelled" class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div class="col-lg amount-picker__control" [class.col-lg-12]="fullWidth">
    <app-infinite-scroll-loader [show]="loading"></app-infinite-scroll-loader>
    <div *ngIf="!loading && entries">
      <div
        class="amount-picker__control__entry p-2"
        [ngClass]="{ selected: value && value.ProductCode === entry.ProductCode }"
        (click)="selectEntry(entry)"
        containerClass="custom"
        placement="top"
        [ngbTooltip]="entry?.ProductDisplayName"
        *ngFor="let entry of entries">
        <span class="amount-picker__control__entry__local">
          {{
            entry.ProductDisplayName.length > 10
              ? (entry.ProductDisplayName | slice: 0 : 10) + '...'
              : entry.ProductDisplayName
          }}
        </span>
        <br />
        <span class="amount-picker__control__entry__total" *ngIf="entry.Price">
          {{ 'C_F_TOTAL' | translate }}:
          {{ entry.Price.Value | localizedCurrency: entry.Price.CurrencyISOCode }}
        </span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="options?.helper">
    <small class="form-text text-muted">
      {{ options.helper | translate }}
    </small>
  </ng-container>
  <app-errors
    *ngIf="!options.hideErrors"
    [isInvalid]="isInvalid"
    [formGroup]="formGroup"
    [formControlNameValue]="options.formControlNameValue"></app-errors>
</div>
