import { ResponseMapper } from '@app/core/mappers/base/response.mapper';
import { PaymentWithTokenizationResponse } from '@app/core/models/dto/response';
import { PaymentWithTokenization } from '@app/core/models';

export class PaymentWithTokenizationResponseMapper extends ResponseMapper<
  PaymentWithTokenizationResponse,
  PaymentWithTokenization
> {
  fromResponse(param: PaymentWithTokenizationResponse): PaymentWithTokenization {
    return {
      code: param.Code,
      redirectHref: param.RedirectHref,
      message: param.Message,
      result: param.Result,
    };
  }
}
