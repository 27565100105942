<div class="container" *ngIf="documents$ | async as docs; else loading">
  <div *ngIf="!docs.length; else documentsList" class="alert alert-box alert-warning">
    <i class="icon icon-info icon-20 me-2"></i>
    {{ 'HT_PROFILE_MSG_VALIDATION3' | translate }}
  </div>
  <ng-template #documentsList>
    <div class="row">
      <ul class="event-list col-md-12">
        <li *ngFor="let doc of docs">
          <app-preview-additional-document-image
            class="image-container"
            [documentId]="doc.Id"></app-preview-additional-document-image>
          <div class="info">
            <h2 class="title">{{ doc.DocumentType || '' }}</h2>
            <p class="desc">{{ 'C_T_UPLOADED' | translate }}: {{ doc.creationDate || '' }}</p>
          </div>
        </li>
      </ul>
      <button class="btn btn-primary btn-mt-blue btn-full-width" (click)="openAddDocumentModal()">
        {{ 'C_A_ADD_IMAGE' | translate }}
      </button>
    </div>
  </ng-template>
</div>
<ng-template #loading>
  <app-infinite-scroll-loader></app-infinite-scroll-loader>
</ng-template>
