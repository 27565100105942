import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CountryService } from '@app/core/services/country.service';
import { TranslationsService } from '@app/core/services/translations.service';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';
import { CatalogCountry } from '@app/interfaces/country-information.interface';

@Component({
  selector: 'app-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.scss'],
})
export class CountryPickerComponent
  extends BaseInputComponent
  implements OnInit, OnChanges, OnDestroy
{
  get currentFlag() {
    return this.formGroup.controls[this.options.formControlNameValue].value &&
      this.formGroup.controls[this.options.formControlNameValue].value.Iso2
      ? this.formGroup.controls[this.options.formControlNameValue].value.Iso2.toLowerCase()
      : null;
  }
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @Input() customCountries: CatalogCountry[];
  countries: CatalogCountry[];

  @Input()
  showCaret = false;

  countriesSubscription = new Subscription();
  languageSubscription = new Subscription();

  constructor(
    private readonly translationsService: TranslationsService,
    private readonly countryService: CountryService
  ) {
    super();
    this.formControlType = 'countryPicker';
  }

  search = (text$: Observable<string>): Observable<CatalogCountry[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === ''
          ? this.countries
          : this.countries.filter((v) => v.Name.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );
  };

  formatter(country) {
    return country.Name;
  }

  ngOnInit() {
    this.parse();
  }

  ngOnChanges() {
    this.load();
  }

  ngOnDestroy() {
    this.countriesSubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
  }

  private load() {
    this.countriesSubscription = this.customCountries
      ? this.translationsService
          .translateCountries(this.customCountries)
          .subscribe((res) => (this.countries = res))
      : this.countryService.currentTranslatedCountries().subscribe((res) => (this.countries = res));
    this.languageSubscription = this.translationsService.currentLanguage$
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        if (this.value && this.value.Id) {
          const existingCountry = this.countries.find((x) => x.Id === this.value.Id);
          this.instance.writeValue(existingCountry);
        }
      });
  }

  private parse() {
    const control = this.formGroup.controls[this.options.formControlNameValue];
    const assignValue = (value) => {
      if (value && typeof value === 'number') {
        const existingCountry = this.countries.find((x) => x.Id === value);
        control.patchValue(existingCountry);
      }
    };

    assignValue(control.value);
    this.subscription.add(
      control.valueChanges.subscribe((value) => {
        assignValue(value);
      })
    );
  }
}
