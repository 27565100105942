import { HttpHeaders } from '@angular/common/http';

export class SkipErrorInterceptorHeaders extends HttpHeaders {
  static SKIP_ERROR_INTERCEPTOR = 'SKIP_ERROR_INTERCEPTOR';
  constructor() {
    super({ [SkipErrorInterceptorHeaders.SKIP_ERROR_INTERCEPTOR]: '' });
  }
}

export class SkipErrorInterceptorOptions {
  headers = new SkipErrorInterceptorHeaders();
}
