import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  Permission,
  SessionDataConsts,
  StorageKey,
  UserPermissionGraph,
  UserSession,
} from '@app/interfaces/user-session.interface';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private readonly BASE_PATH = environment.agentApiBasePath;

  constructor(private readonly httpClient: HttpClient) {}

  public isUserAuthorized(permissionName: Permission): Observable<boolean> {
    return this.getPermissions().pipe(
      map((permissions: UserPermissionGraph[]) => {
        const permission = this.findPermission(permissions, permissionName);
        return permission.IsPositivePermission;
      })
    );
  }

  private findPermission(
    permissions: UserPermissionGraph[],
    code: Permission
  ): UserPermissionGraph {
    for (const permission of permissions) {
      if (permission.Code === code) {
        return permission;
      }

      const foundPermission = this.findPermission(permission.Children, code);
      if (foundPermission) {
        return foundPermission;
      }
    }
    return null;
  }

  private getPermissions(): Observable<UserPermissionGraph[]> {
    const permissions = localStorage.getItem(
      StorageKey.PERMISSIONS
    ) as unknown as UserPermissionGraph[];
    if (permissions) {
      return of(permissions);
    } else {
      return this.fetchAndSavePermissions();
    }
  }

  public fetchAndSavePermissions(): Observable<UserPermissionGraph[]> {
    const url = `${this.BASE_PATH}/security/session/${String(SessionDataConsts.userPermissionGraph)}`;
    return this.httpClient.get<UserSession>(url).pipe(
      map((session: UserSession) => [session.SessionData.UserPermissionGraph]),
      tap((permissions: UserPermissionGraph[]) => {
        localStorage.setItem(StorageKey.PERMISSIONS, JSON.stringify(permissions));
      })
    );
  }

  public clearPermissions(): void {
    localStorage.removeItem(StorageKey.PERMISSIONS);
  }
}
