import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '@app/shared/enums/language-enum';
import { COUNTRIES, LANGUAGES } from '../shared/pipes/urls-pipes/urls.conts';
import { TermsUrlRules } from './terms_conditions.urls';

@Pipe({
  name: 'termsPipe',
  standalone: true,
})
export class TermsAndConditionsPipe implements PipeTransform {
  transform(lang: string): string {
    const URL = window.location.href;
    const country = COUNTRIES.find((v) => URL.toLowerCase().includes(v)) || 'belgium';
    const language = LANGUAGES.includes(lang as Language) ? lang : 'en';
    return TermsUrlRules[country][language];
  }
}
