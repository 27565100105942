/**
 * Results of execution of compliance controls.
 */
export enum ComplianceControlPaymentAccountResult {
  NotSet = 0,
  Success = 1,
  RejectedFoundDuplicatesOrSimiliarCustomers = 2,
  RejectedBadRequest = 3,
  OperationPending = 4,
  /**
   * Rejected because of blacklist of other AML controls.
   */
  RejectedByAML = 5,
  UnexpectedError = 6,
  /**
   * Blocked by compliance.
   */
  BlockedByCompliance = 7,
}
