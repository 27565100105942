<div class="wrapper">
  <app-header
    [title]="title"
    [route]="route"
    [showBackButtonInSmall]="showBackButtonInSmall"
    [showReturnToHomeButton]="showReturnToHomeButton"
    [currentUrl]="route"
    [showChatButton]="fsChatEnabled && fsChatSubject$ | async"
    (OnChatWidget)="onChatWidget()"></app-header>
  <div class="wrapper__content">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
  <app-sidebar
    class="sidebar"
    [class.d-none]="!showNavbarMobileVersion"
    [showSidebar]="showSidebar"></app-sidebar>
  <app-sidebar-menu
    class="sidebar"
    [class.d-none]="!showNavbarMobileVersion"
    [options]="options"
    [showSidebarMenu]="showSidebarMenu"
    (clickOptionEvent)="eventListener($event)"></app-sidebar-menu>
  <app-mobile-navbar
    class="mobile-navbar"
    [class.d-none]="!showNavbarMobileVersion"
    (clickOptionEvent)="eventListener($event)"></app-mobile-navbar>
</div>
