import { GetOperatorRequest } from '@app/interfaces/international-top-up/get-operator-request.interface';
import { ChangeData } from 'ngx-intl-tel-input-gg';
import { Beneficiary } from '../classes/beneficiary.class';
import { getCountryByIso2 } from '../utils/countries.utils';

export function isGetOperatorRequest(object: any): object is GetOperatorRequest {
  return 'MSISDN' in object && 'country_isocode' in object;
}

export function mapToGetOperatorRequest(source: ChangeData | Beneficiary): GetOperatorRequest {
  return isBeneficiary(source) ? mapFromBeneficiary(source) : mapFromChangeData(source);
}

function isBeneficiary(object: any): object is Beneficiary {
  return 'Telephone' in object && 'CountryPhoneIso2' in object;
}

function mapFromBeneficiary({ Telephone, CountryPhoneIso2 }: Beneficiary): GetOperatorRequest {
  const country = getCountryByIso2(CountryPhoneIso2);

  return {
    MSISDN: Telephone,
    country_isocode: country ? country.alpha3.toUpperCase() : '',
  };
}

function mapFromChangeData({ e164Number, countryCode }: ChangeData): GetOperatorRequest {
  const country = getCountryByIso2(countryCode);

  return {
    MSISDN: e164Number,
    country_isocode: country ? country.alpha3.toUpperCase() : '',
  };
}
