<div class="modal-body preview-document-modal">
  <div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 left-pane">
          <app-preview-document-image
            class="image"
            [side]="'front'"
            resize="true"
            [documentId]="document.Id"></app-preview-document-image>
          <app-preview-document-image
            class="image"
            [side]="'back'"
            resize="true"
            [documentId]="document.Id"></app-preview-document-image>
        </div>
        <div class="col-lg-4 right-pane">
          <div class="row justify-content-end">
            <button type="button" class="close" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-add-identity-document-form
                readonly="true"
                [value]="document"></app-add-identity-document-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
