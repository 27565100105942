<div class="modal-header">
  <div class="modal-header__title">{{ title | translate }}</div>
  <button
    type="button"
    class="closeModal"
    (click)="dismiss()"
    data-dismiss="modal"
    aria-label="Close">
    <span aria-hidden="true">
      <i class="icon icon-close-blue"></i>
    </span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="container">
        <app-add-additional-document-form
          [docType]="docType"
          [onNextStep]="onNextStep"
          (formSubmitted)="save($event)"
          (hostedCurrentStep)="changeButtons($event)"
          (formIsValid)="unlockButton($event)"
          #form></app-add-additional-document-form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-button-big
    class="close"
    [callToAction]="'C_A_CANCEL' | translate"
    [color]="'secondary'"
    (Click)="dismiss()"></app-button-big>
  <app-button-big
    *ngIf="!isNextStep"
    class="save"
    [callToAction]="'C_A_SAVE' | translate"
    [disabled]="flag"
    [color]="'primary'"
    [loading]="loading"
    (Click)="form.submit()"></app-button-big>
  <app-button-big
    *ngIf="isNextStep"
    class="save"
    [callToAction]="'M_PAC_ENROLLMENT_SMILE_ACCOUNT_NEXT_STEP' | translate"
    [disabled]="flag"
    [color]="'primary'"
    [loading]="loading"
    (Click)="nextStep()"></app-button-big>
</div>
