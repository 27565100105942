import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginRequest, LoginResponse } from '@app/interfaces/login-response.interface';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  SessionDataConsts,
  UserSession,
} from '@app/interfaces/user-session.interface';
import { ApiErrorResponse } from '@app/interfaces/api-error-response.interface';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private readonly AUTHENTICATION_URL = `${environment.apiBasePath}/auth`;
  private readonly SESSION_URL = `${environment.apiBasePath}/security/session`;
  private readonly BASE_PATH = environment.agentApiBasePath;

  constructor(private readonly httpClient: HttpClient) {}

  public login(loginRequest: LoginRequest): Observable<LoginResponse> {
    if (loginRequest === null || loginRequest === undefined) {
      throw new Error('Required parameter loginRequest was null or undefined when calling login.');
    }

    const _request = { ...loginRequest, UserName: loginRequest.UserName.toLowerCase() };
    return this.httpClient.post<LoginResponse>(`${this.AUTHENTICATION_URL}/login`, _request);
  }

  // TODO move to user session service
  public getSessionData(): Observable<UserSession> {
    /* eslint-disable no-bitwise */
    const slice =
      SessionDataConsts.userHeader |
      SessionDataConsts.userBasicData |
      SessionDataConsts.userBehavior |
      SessionDataConsts.userSecurity |
      SessionDataConsts.agentBasicData |
      SessionDataConsts.agentAddress |
      SessionDataConsts.partnerSystemHeader |
      SessionDataConsts.partnerSystemAddress |
      SessionDataConsts.agentHeader;
    /* eslint-enable no-bitwise */

    return this.httpClient.get<UserSession>(
      `${this.SESSION_URL}/${encodeURIComponent(String(slice))}`
    );
  }

  public changePassword(request: ChangePasswordRequest): Observable<ChangePasswordResponse> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling changePassword.'
      );
    }

    return this.httpClient.post<ChangePasswordResponse>(
      `${this.AUTHENTICATION_URL}/changePassword`,
      request
    );
  }

  public logout(): Observable<ApiErrorResponse> {
    return this.httpClient.post<ApiErrorResponse>(`${this.AUTHENTICATION_URL}/logout`, {});
  }

  public checkUserInHours() {
    return this.httpClient.post(`${this.BASE_PATH}/ValidateAgent/`, '');
  }

  forgotPassword(request: ForgotPasswordRequest): Observable<ForgotPasswordResponse> {
    return this.httpClient.post<ForgotPasswordResponse>(
      `${this.AUTHENTICATION_URL}/forgotpassword`,
      request
    );
  }
}
