import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { AccountService } from '@app/core/services/account.service';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { ICreateDocumentRequest } from '@app/interfaces/create-document-request.interface';

@Component({
  selector: 'app-add-identity-document-modal',
  templateUrl: './add-identity-document-modal.component.html',
  styleUrls: [
    './add-identity-document-modal.component.scss',
    '../account-modal-identity-documents.component.scss',
  ],
})
export class AddIdentityDocumentModalComponent extends BaseComponent {
  pending = true;
  loading = false;
  flag = true;
  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly accountService: AccountService
  ) {
    super();
  }

  save(data: ICreateDocumentRequest) {
    this.pending = true;
    this.loading = true;

    data.ExpiryDate = moment(data.ExpiryDate).toDate();
    data.DeliveryDate = moment(data.DeliveryDate).toDate();
    this.accountService.createDocument(data).subscribe(() => {
      this.pending = false;
      this.loading = false;
      this.close();
    });
  }
  UnlockButton(formIsValid) {
    this.flag = formIsValid;
  }

  close() {
    this.activeModal.close();
  }
}
