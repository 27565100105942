import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { fadeIn, fadeOut } from '@app/shared/animations/fade.animation';
import { BaseComponent } from '@app/shared/components/base/base.component';

export interface ItemParams {
  w: string;
  h: string;
  br?: string;
  mr?: string;
  mb?: string;
  type?: string;
}

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class SkeletonLoaderComponent extends BaseComponent implements OnChanges {
  @Input() selector = '';
  @Input() count = 1;
  @Input() width = '100%';
  @Input() height = '40px';
  @Input() border = '4px';
  @Input() type = 'line';
  @Input() isList = false;
  @Input() isHorizontal = false;
  @Input() listParams: ItemParams[] = [
    {
      w: this.width,
      h: this.height,
    },
  ];

  listHorizontal = new Array(this.count).fill(1);
  constructor(private readonly cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.listParams = this.getListParams(changes['listParams']?.currentValue);
    this.listHorizontal = new Array(this.count).fill(1);
    this.cdRef.detectChanges();
  }

  private getListParams(listParams: ItemParams[]): ItemParams[] {
    return !this.isList && !this.isHorizontal
      ? [{ ...listParams, w: this.width, h: this.height }]
      : this.listParams;
  }
}
