import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SpinnerComponent } from '@app/shared/loaders/spinner/spinner.component';

@Component({
  selector: 'app-button-big',
  templateUrl: './button-big.component.html',
  styleUrls: ['./button-big.component.scss'],
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
})
export class ButtonBigComponent {
  @Input() color: 'primary' | 'secondary' | 'tertiary' | 'cancellation' | 'primary2' = 'primary';
  @Input() callToAction = '';
  @Input() disabled = false;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() loading = false;
  @Input() icon?: string;
  @Output() Click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  handleClick(e: MouseEvent): void {
    this.Click.emit(e);
  }
}
