import { Component, Input } from '@angular/core';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent extends BaseInputComponent {
  @Input() invalid = false;
  constructor() {
    super();
    this.formControlType = 'radio';
  }
}
