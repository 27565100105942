import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-timeout-modal',
  template: `
    <div class="modal-body px-0">
      <div class="container">
        <div class="row mb-3">
          <div class="col-md-12">
            <div class="d-flex justify-content-end">
              <button type="button" (click)="onDismiss()">
                <i class="icon icon-close-blue"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 text-center">
            <i class="icon icon-alert icon-80">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
              <span class="path5"></span>
            </i>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 text-center modal-warning">
            <p>{{ 'MSG_SESSION_TIMEOUT' | translate: { countdown } }}</p>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./timeout-modal.component.scss'],
})
export class TimeoutModalComponent implements OnInit, OnDestroy {
  @Input() countdown: number;

  private unsubscribe$ = new Subject<void>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    interval(1000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          this.activeModal.dismiss();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onContinue() {
    this.activeModal.close();
  }

  onDismiss() {
    this.activeModal.dismiss();
  }
}
