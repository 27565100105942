<div class="modal-header">
  <div class="modal-header__title">
    {{ 'M_PAC_ENROLLMENT_SMILE_ACCOUNT_DOCS_ADD_TO_ACCOUNT' | translate }}
  </div>
  <button
    type="button"
    class="closeModal"
    (click)="close()"
    data-dismiss="modal"
    aria-label="Close">
    <span aria-hidden="true">
      <i class="icon icon-close-blue"></i>
    </span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="container">
        <app-add-identity-document-form
          (formSubmitted)="save($event)"
          (formIsValid)="UnlockButton($event)"
          #form></app-add-identity-document-form>
      </div>
    </div>
  </div>
</div>

<div class="container modal-footer">
  <app-button-big
    class="close"
    [callToAction]="'C_A_CANCEL' | translate"
    [color]="'secondary'"
    (Click)="close()"></app-button-big>
  <app-button-big
    class="save"
    [callToAction]="'C_A_SAVE' | translate"
    [disabled]="flag"
    [color]="'primary'"
    [loading]="loading"
    (Click)="form.submit()"></app-button-big>
</div>
