import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/interfaces/api-response.interface';
import { CatalogCountry } from '@app/interfaces/country-information.interface';
import { ImtRecipientsList } from '@app/interfaces/imt-recipients-list.interface';
import {
  CountryPromotion,
  ImtOperationList,
  ImtOperationListRequest,
  Operator,
} from '@app/interfaces/imt-topups-common.interfaces';
import { CalculateTopUpRequest } from '@app/interfaces/international-top-up/calculate-top-up-request.interface';
import { TopUpCatalogResponse } from '@app/interfaces/international-top-up/countries-catalog-response.interface';
import { GetOperatorByCodeRequest } from '@app/interfaces/international-top-up/get-operator-by-code-request.interface';
import { GetOperatorRequest } from '@app/interfaces/international-top-up/get-operator-request.interface';
import { LastestRecipient } from '@app/interfaces/international-top-up/lastest-recipient-request.inteface';
import { TopUpOperation } from '@app/interfaces/international-top-up/top-up-operation.interface';
import { TopUpTransaction } from '@app/interfaces/international-top-up/top-up-transaction.interface';
import { PaymentResponse } from '@app/interfaces/payment-response.interface';
import { TransferRequest } from '@app/interfaces/smile-account/transfer-request.interface';
import { TransferResponse } from '@app/interfaces/smile-account/transfer-response.interface';
import { Beneficiary } from '@app/shared/classes/beneficiary.class';
import { PaginatedRequest } from '@app/shared/classes/paginated-request.class';
import { PaginatedResult } from '@app/shared/classes/paginated-result.class';
import { CacheCatalogCountry } from '@app/shared/decorators/cache.decorator';
import {
  isGetOperatorRequest,
  mapToGetOperatorRequest,
} from '@app/shared/mappers/get-operator-request.mapper';
import { mapFromRow } from '@app/shared/mappers/top-up-operation.mapper';
import { environment } from '@env/environment';
import moment from 'moment';
import { ChangeData } from 'ngx-intl-tel-input-gg';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PaymentWithTokenizationResponseMapper } from '@app/core/mappers/payment-tokenization.mapper';
import { PaymentWithTokenization } from '@app/core/models/PaymentWithTokenization';
import { DataResponse, PaymentWithTokenizationResponse } from '@app/core/models/dto/response';

@Injectable({
  providedIn: 'root',
})
export class InternationalTopUpService {
  private readonly IMT_RESOURCES_URL = `${environment.imt_resources_url}`;
  private readonly OPERATIONS_URL = `${environment.apiBasePath}/online/product/IMT`;
  private readonly CATALOG_URL = `${this.OPERATIONS_URL}/catalog`;
  private readonly COUNTRIES_URL = `${this.CATALOG_URL}/country`;
  private readonly OPERATOR_URL = `${this.CATALOG_URL}/product/MSISDN/`;
  private readonly OPERATOR_BY_CODE_URL = `${this.CATALOG_URL}/product/operator`;
  private readonly OPERATORS_URL = `${this.CATALOG_URL}/operator/country`;
  private readonly TOP_UP_REQUEST_URL = `${environment.apiBasePath}/online/product/IMT/sale/request`;
  private readonly PROMOTIONS_URL = `${this.CATALOG_URL}/promotions`;
  private readonly BENEFICIARY_URL = `${environment.apiBasePath}/product/IMT/latestbeneficiary`;
  private readonly TRANSACTION_URL = `${environment.apiBasePath}/product/IMT`;
  private readonly PAYMENT_REQUEST = `${environment.apiBasePath}/online/transfer/payment/productRequest`;

  constructor(private readonly httpClient: HttpClient) {}

  @CacheCatalogCountry()
  getCountriesCatalog(): Observable<CatalogCountry[]> {
    return this.httpClient
      .get<TopUpCatalogResponse>(this.COUNTRIES_URL)
      .pipe(map((res) => res.TopUpCatalogData.Countries));
  }

  getOperator(
    request: GetOperatorRequest | ChangeData | Beneficiary
  ): Observable<TopUpCatalogResponse> {
    const params = isGetOperatorRequest(request) ? request : mapToGetOperatorRequest(request);

    return this.httpClient.get<TopUpCatalogResponse>(this.OPERATOR_URL, { params: { ...params } });
  }

  getAmountEntries({
    CountryCode,
    OperatorCode,
  }: GetOperatorByCodeRequest): Observable<TopUpCatalogResponse> {
    return this.httpClient.get<TopUpCatalogResponse>(
      `${this.OPERATOR_BY_CODE_URL}/${OperatorCode}/${CountryCode}`
    );
  }

  getOperators(countryIso3Code: string): Observable<Operator[]> {
    return this.httpClient
      .get<TopUpCatalogResponse>(`${this.OPERATORS_URL}/${countryIso3Code}`)
      .pipe(map((res) => res.TopUpCatalogData.CountryOperators));
  }

  getOperatorLogoUrl(operatorCode: string): string {
    return `${this.IMT_RESOURCES_URL}${operatorCode}-3.png`;
  }

  sendTopUpRequest(request: CalculateTopUpRequest): Observable<PaymentResponse> {
    return this.httpClient.post<PaymentResponse>(this.TOP_UP_REQUEST_URL, request);
  }

  getPaymentTopUpWithTokenization(
    requestId: string,
    tokenCode: string
  ): Observable<PaymentWithTokenization> {
    const mapper = new PaymentWithTokenizationResponseMapper();
    return this.httpClient
      .get<
        DataResponse<PaymentWithTokenizationResponse>
      >(`${this.OPERATIONS_URL}/${requestId}/tokenizations/${tokenCode}`)
      .pipe(map(({ Data }) => mapper.fromResponse(Data)));
  }

  requestTransfer(request: TransferRequest): Observable<ApiResponse<TransferResponse>> {
    return this.httpClient.post<ApiResponse<TransferResponse>>(`${this.PAYMENT_REQUEST}`, request);
  }

  getTopUpOperations(
    params: Partial<ImtOperationListRequest>,
    page = 1
  ): Observable<PaginatedResult<TopUpOperation>> {
    const request = new PaginatedRequest(params, page);

    return this.httpClient
      .post<ApiResponse<ImtOperationList>>(`${this.OPERATIONS_URL}`, request)
      .pipe(
        map(({ Data }) =>
          PaginatedResult.create(
            Data.rows.map(mapFromRow),
            Data.TotalCount,
            request.StartRow,
            request.RowQty
          )
        )
      );
  }

  getPromotions(iso3CountryCode: string): Observable<CountryPromotion[]> {
    return this.httpClient
      .get<TopUpCatalogResponse>(`${this.PROMOTIONS_URL}/${iso3CountryCode}`)
      .pipe(
        map(({ TopUpCatalogData }) => TopUpCatalogData.CountryPromotions),
        catchError(() => of([]))
      );
  }

  getLastestRecipients(): Observable<LastestRecipient[]> {
    const request = {
      DateFrom: '2000-08-08',
      DateTo: moment().toDate(),
      startRow: 1,
      rowQty: 3,
      RequestStatusCode: 'S',
    };

    return this.httpClient
      .post<ApiResponse<ImtRecipientsList>>(`${this.BENEFICIARY_URL}`, request)
      .pipe(map(({ Data }) => Data.Data));
  }

  getTransactionDetail(TransactionId: number): Observable<TopUpTransaction> {
    return this.httpClient
      .get<ApiResponse<TopUpTransaction>>(`${this.TRANSACTION_URL}/${TransactionId}`)
      .pipe(map(({ Data }) => Data));
  }
}
