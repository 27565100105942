import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionBasedService } from '@app/core/services/session-based.service';
import { ApiErrorResponse } from '@app/interfaces/api-error-response.interface';
import { ApiResponse } from '@app/interfaces/api-response.interface';
import {
  PaymentFormData,
  ReactivationPaymentAccount,
} from '@app/interfaces/payment-form-data.interface';
import {
  AdditionaInfoCatalogueItem,
  AdditionalInfoCatalogIFormInput,
  Balance,
  SmileCard,
  CardLimit,
  CardMovement,
  InitialDeposit,
  MaintenanceInfo,
  ReactivationInformation,
  SmileAccount,
  WithdrawLimitInfoDto,
} from '@app/interfaces/smile-account.interface';
import { SmileAccountEnrolmentAcceptanceDataWrapperDto } from '@app/interfaces/smile-account/additional-information.interface';
import { CalculateDepositRequest } from '@app/interfaces/smile-account/calculate-deposit-request.interface';
import { CardOperationRequest } from '@app/interfaces/smile-account/card-operation-request.interface';
import { CloseAccountRequest } from '@app/interfaces/smile-account/close-account-request.interface';
import { DepositResponse } from '@app/interfaces/smile-account/deposit-response.interface';
import {
  EnrollmentRequest,
  ReactivationRequest,
} from '@app/interfaces/smile-account/enrollment-request.interface';
import { PendingTransaction } from '@app/interfaces/smile-account/pending-transaction.interface';
import { SendDepositRequest } from '@app/interfaces/smile-account/send-deposit-request.interface';
import { Update3DSCodeRequest } from '@app/interfaces/smile-account/update-3ds-code-request.interface';
import { ComplianceControlPaymentAccountResult } from '@app/shared/enums/compliance-control-payment-account-result.enum';
import { CatalogNumber } from '@app/shared/enums/smile-account.enum';
import { DateRange } from '@app/shared/utils/date.utils';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import {
  SkipErrorInterceptorHeaders,
  SkipErrorInterceptorOptions,
} from '../interceptors/skip-error.interceptor';
import { PaymentWithTokenizationResponseMapper } from '@app/core/mappers/payment-tokenization.mapper';
import { PaymentWithTokenization } from '@app/core/models';
import { DataResponse, PaymentWithTokenizationResponse } from '@app/core/models/dto/response';
import { encodeToBase64Url } from '@app/shared/utils/strings.utils';
import { CustomHttpParamEncoder } from '@app/shared/classes/http-encoder.class';

@Injectable({
  providedIn: 'root',
})
export class SmileAccountService {
  private readonly PRODUCT_URL = '/product/PAC';
  private readonly ACCOUNT_URL = `${environment.apiBasePath}${this.PRODUCT_URL}/account`;
  private readonly ACCOUNT_ACCOUNT_REOPEN = `${environment.apiBasePath}${this.PRODUCT_URL}/account/reopen`;
  private readonly BALANCE_URL = `${environment.apiBasePath}${this.PRODUCT_URL}/account/balance`;
  private readonly CARD_DETAILS_URL = `${environment.apiBasePath}${this.PRODUCT_URL}/card`;
  private readonly CARD_LIMITS_POSTFIX = '/limits';
  private readonly CARD_MOVEMENTS = `${environment.apiBasePath}${this.PRODUCT_URL}/account/movement`;
  private readonly CARD_LAST_MOVEMENTS = `${environment.apiBasePath}${this.PRODUCT_URL}/pendingTransactions`;
  private readonly CARD_STATEMENT = `${environment.apiBasePath}${this.PRODUCT_URL}/account/statement`;
  private readonly ACCOUNT_DETAIL_URL = `${environment.apiBasePath}${this.PRODUCT_URL}/account/details`;
  private readonly DEPOSIT_URL = `${environment.apiBasePath}${this.PRODUCT_URL}/deposit`;
  private readonly CALCULATE_DEPOSIT_URL = `${this.DEPOSIT_URL}/calculate`;
  private readonly REQUEST_VERIFICATION_CODE_URL = `${environment.apiBasePath}${this.PRODUCT_URL}/enroll/sca/sms`;
  private readonly ENROLLMENT_URL = `${environment.apiBasePath}${this.PRODUCT_URL}/enroll`;
  private readonly CLOSE_ACCOUNT_URL = `${environment.apiBasePath}${this.PRODUCT_URL}/close`;
  private readonly MAINTENANCE_INFO = `${environment.apiBasePath}${this.PRODUCT_URL}/MaintenanceInfo`;
  private readonly MAINTENANCE_CHANGE = `${environment.apiBasePath}${this.PRODUCT_URL}/changeSmileMaintenance`;
  private readonly MAINTENANCE_PRICE = `${environment.apiBasePath}${this.PRODUCT_URL}/maintenance/price`;
  private readonly ENROLL_PRICE = `${environment.apiBasePath}${this.PRODUCT_URL}/enroll/price`;
  private readonly CONTACT_URL = `${environment.apiBasePath}${this.PRODUCT_URL}/contact/phone`;
  private readonly INITIAL_DEPOSITS_URL = `${environment.apiBasePath}/online${this.PRODUCT_URL}/enroll/initialDeposits`;
  private readonly PLANS_URL = `${environment.apiBasePath}/product/pac/maintenance/price`;
  private readonly WITHDRAWAL_INFO_URL = `${environment.apiBasePath}/product/pac/withdraw/infoLimits`;
  private readonly REACTIVATION_URL = `${environment.apiBasePath}/product/pac/reactivation`;
  private readonly REACTIVATION_INFO_URL = `${environment.apiBasePath}/product/pac/reactivationInformation`;
  private readonly CUSTOMER_SMILE_ACCOUNT_CATALOG_URL = `${environment.apiBasePath}/agent/customer/smileaccountenrolmentcatalogue`;
  private readonly CUSTOMER_SMILE_ACCOUNT_ENROLMENT_INFO_URL = `${environment.apiBasePath}/agent/customer/smileaccountenrolmentinformation`;
  private readonly MICROCREDIT_URL = `https://reddocredit.com/moneytrans290`;

  account: SmileAccount;
  balance: Balance;
  movements: BehaviorSubject<CardMovement[]> = new BehaviorSubject([]);
  maintenancePrice: number;
  smileContactNumber: string;
  maintenanceInfo: MaintenanceInfo;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly sessionBasedService: SessionBasedService
  ) {
    this.observeClear();
  }

  get currentAccount(): Observable<SmileAccount> {
    return this.account ? of(this.account) : this.fetchAccount();
  }

  get currentBalance(): Observable<Balance> {
    return this.balance ? of(this.balance) : this.fetchBalance();
  }

  get currentMaintenancePrice(): Observable<number> {
    return this.maintenancePrice ? of(this.maintenancePrice) : this.fetchMaintenancePrice();
  }

  get currentMaintenanceInfo(): Observable<MaintenanceInfo> {
    return this.maintenanceInfo ? of(this.maintenanceInfo) : this.fetchMantenanceInfo();
  }

  get currentContactNumber(): Observable<string> {
    return this.smileContactNumber ? of(this.smileContactNumber) : this.fetchContactNumber();
  }

  fetchContactNumber(): Observable<string> {
    return this.httpClient
      .get<ApiResponse<string>>(`${this.CONTACT_URL}`)
      .pipe(map((res) => res.Data));
  }

  fetchOpeningfee(plan: number): Observable<number> {
    return this.httpClient
      .get<ApiResponse<number>>(`${this.ENROLL_PRICE}/${plan}`)
      .pipe(map((res) => res.Data));
  }

  fetchMaintenancePrice(): Observable<number> {
    return this.httpClient
      .get<ApiResponse<number>>(`${this.MAINTENANCE_PRICE}`)
      .pipe(map((res) => res.Data));
  }

  fetchMantenanceInfo(): Observable<MaintenanceInfo> {
    return this.httpClient
      .get<ApiResponse<MaintenanceInfo>>(`${this.MAINTENANCE_INFO}`)
      .pipe(map((res) => res.Data));
  }

  fetchBalance(): Observable<Balance> {
    return this.httpClient.get<ApiResponse<Balance>>(this.BALANCE_URL).pipe(map((res) => res.Data));
  }

  updateAccountReopen(): Observable<ApiResponse<void>> {
    return this.httpClient.put<ApiResponse<void>>(`${this.ACCOUNT_ACCOUNT_REOPEN}`, {});
  }

  fetchCardLimits(externalCardId: string): Observable<CardLimit[]> {
    return this.httpClient
      .get<
        ApiResponse<CardLimit[]>
      >(`${this.CARD_DETAILS_URL}/${externalCardId}/${this.CARD_LIMITS_POSTFIX}`)
      .pipe(map((res) => res.Data));
  }

  fetchCard(externalCardId: string): Observable<SmileCard> {
    return this.httpClient
      .get<ApiResponse<SmileCard>>(`${this.CARD_DETAILS_URL}/${externalCardId}`)
      .pipe(map((res) => res.Data));
  }

  freezeCard(request: CardOperationRequest): Observable<number> {
    return this.performCardOperation(request, 'FREEZE_DEBIT_CARD');
  }

  unfreezeCard(request: CardOperationRequest): Observable<number> {
    return this.performCardOperation(request, 'UNFREEZE_DEBIT_CARD');
  }

  blockCard(request: CardOperationRequest): Observable<number> {
    return this.performCardOperation(request, 'BLOCK_DEBIT_CARD');
  }

  replaceCard(request: CardOperationRequest): Observable<number> {
    return this.performCardOperation(request, 'REPLACE_DEBIT_CARD');
  }
  requestVerificationCode(): Observable<void> {
    return this.httpClient.get<void>(this.REQUEST_VERIFICATION_CODE_URL);
  }

  update3DSCode(request: Update3DSCodeRequest): Observable<Record<string, never>> {
    const { MtBankCardExternalId, CustomerId, ...rest } = request;

    return this.httpClient.post<Record<string, never>>(
      `${this.CARD_DETAILS_URL}/${MtBankCardExternalId}/secure`,
      { ...rest, IdCustomer: CustomerId }
    );
  }

  calculateCardReplacementCost(
    customerMtBankAccountId: number,
    replaceCardReason: string
  ): Observable<number> {
    return this.httpClient
      .get<
        ApiResponse<number>
      >(`${this.ACCOUNT_URL}/${customerMtBankAccountId}/card/REPLACE_DEBIT_CARD/calculate?ReplaceCause=${replaceCardReason}`)
      .pipe(map((res) => res.Data));
  }

  performCardOperation(
    request: CardOperationRequest,
    operation:
      | 'FREEZE_DEBIT_CARD'
      | 'UNFREEZE_DEBIT_CARD'
      | 'BLOCK_DEBIT_CARD'
      | 'REPLACE_DEBIT_CARD'
  ): Observable<number> {
    const { CustomerMtBankAccountId, ...body } = request;

    return this.httpClient
      .post<ApiResponse<number>>(
        `${this.ACCOUNT_URL}/${CustomerMtBankAccountId}/card/${operation}`,
        {
          ...body,
          IdCustomer: body.CustomerId,
        },
        new SkipErrorInterceptorOptions()
      )
      .pipe(map((res) => res.Data));
  }

  fetchMovements(datesQuery: DateRange): Observable<CardMovement[]> {
    const DatesQueryObj = { ...datesQuery };

    return this.httpClient
      .get<ApiResponse<CardMovement[]>>(this.CARD_MOVEMENTS, {
        params: new HttpParams({
          fromObject: DatesQueryObj,
        }),
      })
      .pipe(
        map((res) => res.Data),
        tap((movements) => this.movements.next(movements))
      );
  }

  getLastestMovements(): Observable<PendingTransaction[]> {
    return this.httpClient
      .get<ApiResponse<PendingTransaction[]>>(`${this.CARD_LAST_MOVEMENTS}`)
      .pipe(map(({ Data }) => Data));
  }

  fetchStatement(request: DateRange): Observable<string> {
    return this.httpClient
      .get<ApiResponse<string>>(`${this.CARD_STATEMENT}`, {
        params: new HttpParams({
          fromObject: { ...request },
        }),
      })
      .pipe(map((res) => res.Data));
  }

  fetchAccountDetail(): Observable<string> {
    return this.httpClient
      .get<ApiResponse<string>>(`${this.ACCOUNT_DETAIL_URL}`, {})
      .pipe(map((res) => res.Data));
  }

  fetchAccount(): Observable<SmileAccount> {
    return this.httpClient.get<ApiResponse<SmileAccount>>(`${this.ACCOUNT_URL}`).pipe(
      map((res) => res.Data),
      tap((res) => (this.account = res))
    );
  }

  fetchInitialDeposits(): Observable<InitialDeposit[]> {
    return this.httpClient
      .get<ApiResponse<InitialDeposit[]>>(`${this.INITIAL_DEPOSITS_URL}`)
      .pipe(map((res) => res.Data));
  }

  fetchSmileAccountEnrollmentInformation(): Observable<SmileAccountEnrolmentAcceptanceDataWrapperDto> {
    return this.httpClient
      .get<
        ApiResponse<SmileAccountEnrolmentAcceptanceDataWrapperDto>
      >(`${this.CUSTOMER_SMILE_ACCOUNT_ENROLMENT_INFO_URL}`)
      .pipe(map((res) => res.Data));
  }

  calculateDeposit(request: CalculateDepositRequest): Observable<number> {
    return this.httpClient
      .post<ApiResponse<number>>(`${this.CALCULATE_DEPOSIT_URL}`, request)
      .pipe(map((res) => res.Data));
  }

  closeAccount(request: CloseAccountRequest): Observable<ApiErrorResponse> {
    return this.httpClient
      .post<ApiResponse<ApiErrorResponse>>(`${this.CLOSE_ACCOUNT_URL}`, {
        IdCustomerMTBankAccount: request.CustomerMtBankAccountId,
        SCACode: request.SCACode,
      })
      .pipe(map((res) => res.Data));
  }

  sendDepositRequest(request: SendDepositRequest): Observable<DepositResponse> {
    return this.httpClient
      .post<ApiResponse<DepositResponse>>(`${this.DEPOSIT_URL}`, request)
      .pipe(
        mergeMap((response: ApiResponse<DepositResponse>) => this.processDepositResponse(response))
      );
  }

  sendAdditionalInformationRequest(
    request: SmileAccountEnrolmentAcceptanceDataWrapperDto
  ): Observable<ApiResponse<boolean>> {
    return this.httpClient.post<ApiResponse<boolean>>(
      `${this.CUSTOMER_SMILE_ACCOUNT_ENROLMENT_INFO_URL}`,
      request
    );
  }

  sendEnrollmentRequest(request: EnrollmentRequest): Observable<ApiResponse<PaymentFormData>> {
    return this.httpClient.post<ApiResponse<PaymentFormData>>(`${this.ENROLLMENT_URL}`, request, {
      headers: new SkipErrorInterceptorHeaders(),
    });
  }

  sendReactivationRequest(request: ReactivationRequest): Observable<ReactivationPaymentAccount> {
    return this.httpClient
      .post<ApiResponse<ReactivationPaymentAccount>>(`${this.REACTIVATION_URL}`, request, {
        headers: new SkipErrorInterceptorHeaders(),
      })
      .pipe(map((res) => res.Data));
  }

  fetchPaymentGateway(
    IdRequest: number,
    paymentId: number,
    pending?: boolean,
    os?: string,
    pwa?: string,
    sessionToken?: string,
    bank?: number,
    iban?: string
  ): Observable<any> {
    const isPending = pending ? 'true' : 'false';
    const connector = bank ?? '';
    const obgwAcc = iban ? encodeToBase64Url(iban) : '';

    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('ispending', isPending)
      .set('os', os)
      .set('pwa', pwa)
      .set('connector', connector)
      .set('obgwAcc', obgwAcc);

    if (sessionToken) {
      params['se_ct'] = sessionToken;
      params['se_cc'] = '3';
      window.location.assign(
        `/api${this.PRODUCT_URL}/${IdRequest}/${paymentId}?ispending=${isPending}&os=${os}&pwa=${pwa}&se_ct=${sessionToken}&se_cc=3&connector=${connector}&obgwAcc=${obgwAcc}`
      );

      return new Observable<any>((observer) =>
        observer.next({ Data: { openInExternalWindow: true } })
      ).pipe(take(1));
    }

    return this.httpClient.get(
      `${environment.apiBasePath}${this.PRODUCT_URL}/${IdRequest}/${paymentId}`,
      { params }
    );
  }

  getPaymentWithTokenization(
    IdPartnerProductRequest: number,
    tokenCode: string
  ): Observable<PaymentWithTokenization> {
    const mapper = new PaymentWithTokenizationResponseMapper();

    return this.httpClient
      .get<
        DataResponse<PaymentWithTokenizationResponse>
      >(`${environment.apiBasePath}${this.PRODUCT_URL}/${IdPartnerProductRequest}/tokenizations/${tokenCode}`)
      .pipe(map(({ Data }) => mapper.fromResponse(Data)));
  }

  getPlans(): Observable<any> {
    return this.httpClient.get<ApiResponse<any>>(`${this.PLANS_URL}`).pipe(map((res) => res.Data));
  }

  getSmileAccountTransferType(type: string): string {
    if (type.includes('|')) {
      const index = type.indexOf('|');

      return type.slice(0, index - 1);
    } else {
      return type;
    }
  }

  changeMaintenanceFee(): Observable<ApiResponse<ApiErrorResponse>> {
    return this.httpClient.post<ApiResponse<ApiErrorResponse>>(`${this.MAINTENANCE_CHANGE}`, {});
  }

  getWithdrawInfoLimits(): Observable<WithdrawLimitInfoDto> {
    return this.httpClient
      .get<ApiResponse<WithdrawLimitInfoDto>>(`${this.WITHDRAWAL_INFO_URL}`, {
        headers: new SkipErrorInterceptorHeaders(),
      })
      .pipe(map((res) => res.Data));
  }

  getReactivationInformation(): Observable<ReactivationInformation> {
    return this.httpClient
      .get<ApiResponse<ReactivationInformation>>(`${this.REACTIVATION_INFO_URL}`)
      .pipe(map((res) => res.Data));
  }

  getSmileAccountCatalog(catalogNumber: number): Observable<AdditionaInfoCatalogueItem[]> {
    return this.httpClient
      .get<
        ApiResponse<AdditionaInfoCatalogueItem[]>
      >(`${this.CUSTOMER_SMILE_ACCOUNT_CATALOG_URL}/${catalogNumber}`)
      .pipe(map((res) => res.Data));
  }

  getAdditionalInfoCatalogMapped(
    catalogNumber: number
  ): Observable<AdditionalInfoCatalogIFormInput[]> {
    return this.getSmileAccountCatalog(catalogNumber).pipe(
      map((response) => this.catalogMapperData(response, catalogNumber)),
      catchError(() => of([]))
    );
  }

  getMicrocreditUrl(): string {
    return this.MICROCREDIT_URL;
  }

  private catalogMapperData(
    result: AdditionaInfoCatalogueItem[],
    catalogNumber: number
  ): AdditionalInfoCatalogIFormInput[] {
    return result
      .filter((item) => item.IsActive)
      .map((item) =>
        catalogNumber === CatalogNumber.SMILE_ACCOUNT_USE
          ? {
              formControlNameValue: String(item.Id),
              label: item.Label,
              IdAcceptanceCode: item.Id,
              IdAcceptanceSection: item.Section.Id,
              code: item.Code,
            }
          : {
              IdAcceptanceCode: item.Id,
              label: item.Label,
              IdAcceptanceSection: item.Section.Id,
              code: item.Code,
            }
      );
  }

  private observeClear() {
    this.sessionBasedService.clearData$.subscribe(() => {
      this.account = null;
    });
  }

  private processDepositResponse({
    Data,
  }: ApiResponse<DepositResponse>): Observable<DepositResponse> {
    if (Data.Result === ComplianceControlPaymentAccountResult.RejectedByAML) {
      return throwError(() => Data.TranslatedMessage);
    }

    return of(Data);
  }
}
