<nav class="header container">
  <div class="header__content" id="top">
    <div class="d-flex w-100 align-items-center">
      <ng-container *ngIf="showReturnToHomeButton">
        <div
          (click)="goTo(route)"
          class="header__back-button"
          [class.d-md-flex]="!showBackButtonInSmall"
          [class.d-none]="!showBackButtonInSmall">
          <i class="icon icon-chevron-bg-left-default icon-20 me-2">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
          <span data-testid="Back" class="header__back-button__text">
            {{ title | translate }}
          </span>
        </div>
      </ng-container>

      <div class="header__logo">
        <div
          *ngIf="!showReturnToHomeButton"
          class="header__logo__full"
          [class.skeleton]="!account"
          [routerLink]="['/']"
          alt="logo"></div>
        <div
          *ngIf="showReturnToHomeButton"
          class="header__logo__circle"
          [class.skeleton]="!account"
          [routerLink]="['/']"
          alt="logo"></div>
      </div>
      <div class="header__menu">
        <ng-container *ngIf="showChatButton">
          <div class="header__chat">
            <button class="btn d-flex" (click)="OnChatWidget.emit()">
              <i class="icon icon-chat icon-28">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </button>
          </div>
        </ng-container>
        <div class="header__menu__user" ngbDropdown #menuDropdown="ngbDropdown" *ngIf="!account">
          <div class="d-flex align-items-center" @fadeIn @fadeOut ngbDropdownToggle>
            <div>
              <ngx-skeleton-loader
                [theme]="{ 'height.px': 30, 'width.px': 30 }"
                appearance="circle"></ngx-skeleton-loader>
            </div>
            <div class="header__user__info">
              <ngx-skeleton-loader
                class="d-flex flex-column"
                count="2"
                [theme]="{
                  width: '90px',
                  height: '14px',
                  'margin-bottom': '5px'
                }"></ngx-skeleton-loader>
            </div>
          </div>
          <div ngbDropdownMenu></div>
        </div>
        <div
          class="header__menu__user"
          data-testid="menu_user"
          ngbDropdown
          #menuDropdown="ngbDropdown"
          *ngIf="account">
          <div class="d-flex align-items-center" @fadeIn @fadeOut ngbDropdownToggle>
            <div>
              <span class="header__initials">{{ initials }}</span>
            </div>
            <div class="header__user__info">
              <span class="header__user__value">{{ nameFormat }}</span>
              <br />
              <span class="header__user__code">{{ account.Code }}</span>
            </div>
          </div>
          <div ngbDropdownMenu *ngIf="account">
            <div class="header__menu__code">
              <span class="header__menu__code__label">{{ 'M_CODE' | translate }}</span>
              <span class="header__menu__code__number">{{ account.Code }}</span>
            </div>
            <div id="links">
              <a
                ngbDropdownItem
                class="header__menu__item"
                [routerLink]="['/profile-account']"
                data-testid="My_profileMenu">
                <div class="header__menu__item__icon">
                  <span class="icon icon-profile-outline-default icon-20"></span>
                  <span class="icon icon-profile-outline-active icon-20">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </span>
                </div>
                <div class="header__menu__item__label">{{ 'C_MY_PROFILE' | translate }}</div>
              </a>
              <a
                *ngIf="contactEnabled"
                ngbDropdownItem
                class="header__menu__item"
                [routerLink]="['/contact']"
                data-testid="Help_Menu">
                <div class="header__menu__item__icon">
                  <span class="icon icon-message-dots-help-default icon-20"></span>
                  <span class="icon icon-message-dots-help-active icon-20">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </span>
                </div>
                <div class="header__menu__item__label">{{ 'P_HELP' | translate }}</div>
              </a>
              <a
                ngbDropdownItem
                class="header__menu__item"
                (click)="logout()"
                data-testid="Logout_Menu">
                <div class="header__menu__item__icon">
                  <span class="icon icon-log_out-default icon-20"></span>
                  <span class="icon icon-log_out-active icon-20"></span>
                </div>
                <div class="header__menu__item__label">{{ 'C_A_LOGOUT' | translate }}</div>
              </a>
            </div>
          </div>
        </div>
        <ng-container *ngIf="account">
          <div class="header__menu__code-small d-md-none">
            <span>{{ account.Code }}</span>
          </div>
        </ng-container>
        <div
          ngbDropdown
          #languageDropdown="ngbDropdown"
          (click)="toggleLanguageDropdown()"
          class="header__menu__language"
          data-testid="Menu_languages">
          <div ngbDropdownToggle>
            <span class="header__menu__language__code">
              {{ currentLanguage?.code | uppercase }}
            </span>
          </div>
          <div ngbDropdownMenu>
            <ng-container *ngFor="let language of availableLanguages; index as i">
              <button
                (click)="setLanguage(language)"
                [attr.data-testid]="'languages' + i"
                ngbDropdownItem>
                {{ language.desc }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
