import { City } from '@app/interfaces/address.interface';
import { CatalogCountry } from '@app/interfaces/country-information.interface';

export interface UserBasicData {
  DefaultLanguage: number;
  FirstName: string;
  LastName: string;
  Email?: string;
  Phone?: string;
  Login?: string;
}

export interface UserBehavioralSettings {
  IsAgentEmployee: boolean;
  IsCustomerLocal: boolean;
}

export interface AgentBasicData {
  DisplayName: string;
  TelephonePrincipal?: any;
  PoSDeploymentMethod: POSDeploymentMethod | null;
}

export interface AgentAddress {
  Address: string;
  AddressLetter?: any;
  AddressNumber: number;
  City: City;
  Country: CatalogCountry;
  PostalCode: string;
}

export interface PartnerSystemAddress {
  IdCountry: number;
}

export interface SessionData {
  PartnerSystemAddress?: PartnerSystemAddress;
  UserBasicData?: UserBasicData;
  UserBehavioralSettings?: UserBehavioralSettings;
  AgentBasicData?: AgentBasicData;
  AgentAddress?: AgentAddress;
  UserPermissionGraph?: UserPermissionGraph;
  AgentHeader?: AgentHeaderData;
  PartnerSystemHaveCardReader?: boolean;
  AriadNextActive?: boolean;
  PartnerSystemId?: number;
  PartnerSystemDisplayName?: string;
  PartnerSystemCodeExternal?: string;
}

export interface UserPermissionGraph {
  Children?: UserPermissionGraph[];
  Code: string;
  IsPositivePermission: boolean;
}

export interface UserSession {
  SessionData: SessionData;
}

export interface AgentHeaderData {
  Id: number;
  Code: string;
}

export enum POSDeploymentMethod {
  ACTIVEX = 'ACTIVEX',
  AJAX = 'AJAX',
}

export enum Permission {
  // see https://bitbucket.org/mtdevguys/mt-partner-apis/src/master/src/API/agent/AgentsPermissions.cs for more
  EDIT_CUSTOMER = 'customers00017',
  CREATE_CUSTOMER = 'customers00026',
}

export const enum StorageKey {
  PERMISSIONS = 'permissions',
}

export interface ChangePasswordRequest {
  Username: string;
  OldPassword: string;
  NewPassword: string;
}
export interface ChangePasswordResponse {
  Code: number;
}

export interface ForgotPasswordRequest {
  Email?: string;
  Language?: string;
  Login: string;
  Telephone?: string;
  MethodSendPassword: string;
}
export interface ForgotPasswordResponse {
  Message: string;
  Success: boolean;
}
export enum MethodSendPasswordEnum {
  NONE,
  EMAIL,
  SMS,
}

export class SessionDataConsts {
  public static userHeader = 1 << 0;
  public static userBasicData = 1 << 1;
  public static userBehavior = 1 << 2;
  public static userPermissionGraph = 1 << 3;
  public static userSecurity = 1 << 4;

  public static partnerSystemHeader = 1 << 10;
  public static partnerSystemBasicData = 1 << 11;
  public static partnerSystemAddress = 1 << 12;
  public static partnerSystemBehavior = 1 << 13;
  public static partnerSystemCultureSettings = 1 << 14;
  public static partnerSystemCustomizations = 1 << 15;
  public static partnerSystemEntities = 1 << 16;
  public static partnerSystemFinancialSettings = 1 << 17;
  public static partnerSystemSecurityInfo = 1 << 18;

  public static agentHeader = 1 << 20;
  public static agentBasicData = 1 << 21;
  public static agentAddress = 1 << 22;
  public static agentEntities = 1 << 23;
  public static agentBehavior = 1 << 24;
}
