import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Breadcrumb } from '@app/interfaces/breadcrumb.interface';
import { BreadcrumbService } from '@app/services/breadcrumb.service';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input() options: Breadcrumb[] = [];
  @Input() pageRoute: string;
  @Input() activeAutoChangeStep = false;
  keys: string[] = [];
  breadcrumbsubscription: Subscription;

  constructor(
    private readonly router: Router,
    private breadCrumbService: BreadcrumbService
  ) {
    this.routeListener();
  }

  ngOnInit() {
    this.observeBreadcrumbData();
  }

  ngOnDestroy() {
    this.breadcrumbsubscription.unsubscribe();
  }

  getSteap(step: number) {
    this.breadCrumbService.currentStep = step;
  }

  getCurrentTitleValue(prevStep: number) {
    const data = this.breadCrumbService.stepsData['step' + this.breadCrumbService.currentStep];
    const option = this.options[prevStep];
    option.completedStep = true;

    if (data) {
      if (typeof option.infoDisplayProperty === 'object') {
        let concatValue = '';
        for (let index = 0; index < option.infoDisplayProperty.length; index++) {
          if (data[option.infoDisplayProperty[index]]) {
            concatValue = concatValue.concat(data[option.infoDisplayProperty[index]] + '<br/>');
            option.stepInfoText = concatValue;
          }
        }
      } else if (typeof option.infoDisplayProperty === 'string') {
        option.stepInfoText = data[option.infoDisplayProperty];
      }
    }
  }

  private routeListener() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (this.options) {
          this.options.forEach((option, i) => {
            option.active = false;
            if (this.router.url.includes(option.router)) {
              if (i > 0 && i === this.breadCrumbService.currentStep) {
                this.getCurrentTitleValue(i - 1);
              }

              this.getSteap(i + 1);
              option.disabled = false;
              option.active = true;
            }
          });
        }
      });
  }

  private autoCompletedstep() {
    if (this.keys.length !== Object.getOwnPropertyNames(this.breadCrumbService.stepsData).length) {
      this.keys = Object.getOwnPropertyNames(this.breadCrumbService.stepsData);
      for (let i = 0; i < this.keys.length; i++) {
        if (i === this.keys.length - 1) {
          this.router.navigate([this.pageRoute, this.options[i + 1].router]);
        }
      }
    }
  }

  private observeBreadcrumbData() {
    this.breadcrumbsubscription = this.breadCrumbService.currentbreadcrumbData
      .pipe(filter(() => this.activeAutoChangeStep))
      .subscribe({
        next: () => {
          this.autoCompletedstep();
        },
      });
  }
}
