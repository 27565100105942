<button
  class="btn__size {{ color }}"
  [ngClass]="{
    btn_size__spinner: loading
  }"
  [disabled]="disabled"
  (click)="handleClick($event)"
  [type]="type">
  <ng-container *ngIf="loading">
    <span [ngClass]="{ btn__spinner: loading }">
      <app-spinner [color]="'button-color'" [size]="'button-size'"></app-spinner>
    </span>
  </ng-container>
  <span class="btn__text">
    <i *ngIf="icon" class="icon {{ icon }} icon-20">
      <span class="path1"></span>
      <span class="path2"></span>
      <span class="path3"></span>
    </i>
    {{ callToAction }}
  </span>
</button>
