import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SessionBasedService } from '@app/core/services/session-based.service';
import { TranslationsService } from '@app/core/services/translations.service';
import { ApiResponse } from '@app/interfaces/api-response.interface';
import { CatalogCountry } from '@app/interfaces/country-information.interface';
import { extractStringRegex } from '@app/shared/constants/regex.constants';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';

export type ISO3PartnerCode =
  | 'BEL'
  | 'DEU'
  | 'ESP'
  | 'FRA'
  | 'GBR'
  | 'ITA'
  | 'NLD'
  | 'COD'
  | 'PRT'
  | 'unknownCountry';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private readonly COUNTRIES_URL = `${environment.apiBasePath}/online/corp/country`;
  private readonly SOURCE_COUNTRY_URL = `${environment.apiBasePath}/online/transfers/htr/catalog/sourceCountries`;
  private readonly SOURCE_COUNTRIES_URL = `${environment.apiBasePath}/online/public/calculator/sourceCountries`;
  private readonly _sourceCountry = new BehaviorSubject<CatalogCountry>(null);
  private readonly _countries = new BehaviorSubject<CatalogCountry[]>([]);

  readonly sourceCountry$ = this._sourceCountry.asObservable();
  readonly sourceCountry = this._sourceCountry.getValue();
  private readonly countries$ = this._countries.asObservable();
  readonly countries = this._countries.getValue();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly httpClient: HttpClient,
    private readonly sessionBasedService: SessionBasedService,
    private readonly translationsService: TranslationsService
  ) {
    this.sessionBasedService.clearData$.subscribe(() => {
      this._countries.next([]);
      this._sourceCountry.next(null);
    });
  }

  /**
   *
   * @returns HOT OBSERVABLE
   */
  currentTranslatedCountries(): Observable<CatalogCountry[]> {
    return this.fetchCountries().pipe(
      mergeMap((countries) => this.translationsService.translateCountries(countries))
    );
  }

  getTranslatedCountry(Iso3: string): Observable<CatalogCountry> {
    return this.currentTranslatedCountries().pipe(
      map((countries) => countries.find((c) => c.Iso3 === Iso3))
    );
  }

  fetchCountries(): Observable<CatalogCountry[]> {
    if (this._countries.getValue().length) {
      return this.countries$.pipe(take(1));
    }
    return this.httpClient.get<ApiResponse<CatalogCountry[]>>(`${this.COUNTRIES_URL}`).pipe(
      map((res) =>
        [...res.Data].sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()))
      ),
      tap((value) => this._countries.next(value))
    );
  }

  fetchSourceCountry(IdCustomer?: number): Observable<CatalogCountry> {
    if (this._sourceCountry.getValue()) {
      return this.sourceCountry$.pipe(take(1));
    }
    if (IdCustomer) {
      return this.httpClient
        .get<ApiResponse<CatalogCountry[]>>(`${this.SOURCE_COUNTRY_URL}/${IdCustomer}`)
        .pipe(
          map(({ Data }) => Data[0]),
          tap((value) => this._sourceCountry.next(value))
        );
    } else {
      return this.httpClient.get<ApiResponse<CatalogCountry[]>>(`${this.SOURCE_COUNTRY_URL}`).pipe(
        map(({ Data }) => Data[0]),
        tap((value) => this._sourceCountry.next(value))
      );
    }
  }

  getSourceContries(): Observable<CatalogCountry[]> {
    return this.httpClient
      .get<ApiResponse<CatalogCountry[]>>(`${this.SOURCE_COUNTRIES_URL}`)
      .pipe(map((res) => res.Data));
  }

  extractCountryFromUrl(): ISO3PartnerCode {
    const regex = extractStringRegex;
    const matches = regex.exec(this.document.location.pathname);
    const country = matches ? matches[1] : '';

    switch (country) {
      case 'spain':
        return 'ESP';
      case 'united-kingdom':
        return 'GBR';
      case 'congo-rdc':
        return 'COD';
      case 'belgium':
        return 'BEL';
      case 'germany':
        return 'DEU';
      case 'italy':
        return 'ITA';
      case 'netherlands':
        return 'NLD';
      case 'france':
        return 'FRA';
      case 'portugal':
        return 'PRT';
      default:
        return 'unknownCountry';
    }
  }
}
