export const environment = {
  production: true,
  version: '5.27.2-21.01',
  apiBasePath: '/api',
  agentApiBasePath: '/api/agent',
  imt_resources_url: 'https://operator-logo.transferto.com/logo-',
  legal_age: 18,
  apiTokenExpirationTimeInHours: 2,
  idle: {
    seconds: 5 * 60,
    timeout: 5 * 60,
  },
  outlookMailUrl: 'https://outlook.live.com',
  gmailWebUrl: 'https://mail.google.com',
  wpEnabled: true,
  wpSpecificURL: false,
  imtWordpressBaseUrlNoProtocol: 'moneytrans.eu',
  imtWordpressBaseUrl: 'https://www.moneytrans.eu/',
  wpSiteCookieKey: 'mt-site',
  wpSiteLanguageCookie: 'wp-wpml_current_language',
  imtPwaUrlProtocol: 'imoneytrans://login',
  countryActiveLimitDateExpiry: ['FRA', 'NLD'],
  documentTypeActiveLimitDateExpiry: ['LRPT'],
  PartnerDisabledNewSmiles: [],
  skeletonTime: 200,
  useSecureCookies: true,
  safetyTimeMinutes: 60,
  services: {
    facebookPixel: {
      enabled: true,
      id: '844079885753751',
    },
    freshChat: {
      enabled: false,
      url: 'https://moneytrans.freshchat.com',
      tokens: {
        ES: 'ac81337e-a036-4830-82cf-0872801ce192',
        FR: 'ac81337e-a036-4830-82cf-0872801ce192',
        BE: 'ac81337e-a036-4830-82cf-0872801ce192',
        IT: 'ac81337e-a036-4830-82cf-0872801ce192',
        UK: 'ac81337e-a036-4830-82cf-0872801ce192',
        CD: 'ac81337e-a036-4830-82cf-0872801ce192',
        default: 'ac81337e-a036-4830-82cf-0872801ce192',
      },
      widgetUuid: {
        ES: '1b1100de-ad26-4ea1-8b36-87e427ff2c03',
        FR: 'c70635e3-e4a3-4284-8a53-7059000f50f4',
        BE: 'a8c2e908-3ce7-4c0f-af8d-3c18114bb216',
        IT: 'd6045801-c06f-4518-ba3f-457293d77d27',
        UK: '93a6e6a9-c945-425b-a96c-cf7cd623d112',
        CD: 'b9a01d58-59b0-4249-bfb0-9f96ddbebabf',
        default: '',
      },
    },
    froged: {
      enabled: true,
      id: 'bg5rlz',
    },
    hotjar: {
      enabled: true,
      hjid: 1723379,
      hjsv: 6,
    },
    googleAnalytics: {
      enabled: true,
    },
    googleMaps: {
      enabled: true,
      apiKey: 'AIzaSyA4anJcBlyrJ5tAR8dKxbUwgWzsBrKjKZc',
      libraries: 'places',
    },
    googleTagManager: {
      enabled: true,
      id: 'GTM-T89XTZ9',
    },
    recaptcha: {
      apiKey: '6Ld3ZHQpAAAAAL3a2wVb_KD-gqX5lCUb9b-7rvyY',
    },
  },
};
