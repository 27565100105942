import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/core/services/account.service';
import { FORMATS } from '@app/shared/constants/common.constants';
import { ComplianceDoc } from '@app/interfaces/compliance-doc.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddAdditionalDocumentModalComponent } from './add-additional-document-modal/add-additional-document-modal.component';
@Component({
  selector: 'app-account-modal-additional-documents',
  templateUrl: './account-modal-additional-documents.component.html',
  styleUrls: ['./account-modal-additional-documents.component.scss'],
})
export class AccountModalAdditionalDocumentsComponent implements OnInit {
  documents$: Observable<ComplianceDoc[]>;
  constructor(
    private readonly accountService: AccountService,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit() {
    this.fetchDocuments();
  }

  openAddDocumentModal(): void {
    this.modalService
      .open(AddAdditionalDocumentModalComponent, { size: 'lg' })
      .result.then(() => this.fetchDocuments());
  }

  private fetchDocuments() {
    this.documents$ = this.accountService.fetchAdditionalDocuments().pipe(
      map((documents) =>
        documents.map((doc) => ({
          ...doc,
          creationDate: moment(doc.CreationDate).format(FORMATS.LARGE_DATE_AND_TIME_FORMAT),
        }))
      )
    );
  }
}
