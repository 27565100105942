import { Component } from '@angular/core';
import { BaseInputComponent } from '../base-input.component';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent extends BaseInputComponent {
  constructor() {
    super();
    this.formControlType = 'textarea';
  }
}
