<div class="form-group row" [formGroup]="formGroup">
  <div *ngIf="loading" class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
    <app-skeleton-loader
      [isHorizontal]="true"
      [border]="null"
      [listParams]="[
        { w: '20px', h: '20px', type: 'circle' },
        { w: '50px', h: '16px', mr: '6%' },
        { w: '20px', h: '20px', type: 'circle' },
        { w: '50px', h: '16px' }
      ]"></app-skeleton-loader>
  </div>
  <ng-container *ngIf="!loading">
    <label class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
      <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
      {{ options.label | translate }}
    </label>
    <div class="col-lg-8 p-0 d-flex align-items-center" [class.col-lg-12]="fullWidth">
      <ng-container *ngFor="let option of availableOptions; let index = index">
        <div class="form-check form-check-inline">
          <label
            [class.label-disabled]="control.disabled"
            class="form-check-label l-radio"
            [for]="option + index">
            <input
              [ngClass]="{ 'is-invalid': isInvalid || invalid }"
              type="radio"
              class="form-check-input"
              [formControlName]="options.formControlNameValue"
              [name]="options.formControlNameValue"
              [id]="option + index"
              [value]="optionsValues[index]" />
            <span>{{ optionsLabels[index] | translate }}</span>
          </label>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <app-errors
  *ngIf="!options.hideErrors"
  class="mt-2"
  [isInvalid]="isInvalid"
  [formGroup]="formGroup"
  [formControlNameValue]="options.formControlNameValue"></app-errors>
  <ng-container *ngIf="options?.helper">
    <small class="form-text text-muted mt-1">
      {{ options.helper | translate }}
    </small>
  </ng-container>
</div>
