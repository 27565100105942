<div class="form-group row" [formGroup]="formGroup">
  <label class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div class="col-lg-8" [class.col-lg-12]="fullWidth">
    <div class="app-select-container">
      <select
        class="app-select form-control"
        (change)="onChange($event.target.value)"
        [class.is-invalid]="isInvalid"
        [class.disabled]="disabled"
        [formControlName]="options.formControlNameValue">
        <option [value]="null" hidden>{{ placeholder | translate }}</option>
        <ng-container *ngIf="availableOptions.length === ONE_ELEMENT; else multipleOptions">
          <option selected [value]="optionsValues[ONE_ELEMENT]">
            {{ optionsLabels[ONE_ELEMENT] | translate }}
          </option>
        </ng-container>
        <ng-template #multipleOptions>
          <ng-container *ngFor="let option of availableOptions; let index = index">
            <option [value]="optionsValues[index]">{{ optionsLabels[index] | translate }}</option>
          </ng-container>
        </ng-template>
      </select>
      <i class="select-icon"></i>
    </div>
    <ng-container *ngIf="options?.helper">
      <small class="form-text text-muted">
        {{ options.helper | translate }}
      </small>
    </ng-container>
    <app-errors
      [isInvalid]="isInvalid"
      [formGroup]="formGroup"
      [formControlNameValue]="options.formControlNameValue"></app-errors>
  </div>
</div>
