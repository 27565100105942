<div class="modal-header">
  <div class="modal-header__icon"><i class="fas fa-comments"></i></div>
  <div class="modal-header__title modal-header__title--wrapped">
    {{ 'C_F_DOC_NUMBER' | translate }}:
  </div>
</div>
<app-edit-document-modal-body
  [showConfirmation]="showConfirmation"
  (formSubmitted)="save($event)"
  #form></app-edit-document-modal-body>
<app-edit-document-modal-footer
  [showConfirmation]="showConfirmation"
  [pending]="pending"
  (saved)="form.submit()"
  (closed)="close()"></app-edit-document-modal-footer>
