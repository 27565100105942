import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FlagIconDirective } from '@app/shared/directives/flag-icon.directive';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CountryPickerMtComponent } from './country-picker-mt.component';

@NgModule({
  declarations: [CountryPickerMtComponent],
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule, TranslateModule, FlagIconDirective],
  exports: [CountryPickerMtComponent],
  providers: [NgSelectConfig],
})
export class CountryPickerMtModule {}
