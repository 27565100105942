import { Injectable } from '@angular/core';

import { FacebookPixelService } from './facebook-pixel.service';
import { GoogleMapsService } from './google-maps.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { HotjarService } from './hotjar.service';
import { FrogedService } from './froged.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicScriptLoaderService {
  constructor(
    private facebookPixel: FacebookPixelService,
    private froged: FrogedService,
    private hotjar: HotjarService,
    private googleMaps: GoogleMapsService,
    private googleTagManager: GoogleTagManagerService
  ) {}

  init() {
    this.googleTagManager.init(); // Load hotjar, froged and google analytics
    this.googleTagManager.addNoScriptTagManager();
    this.facebookPixel.init();
    this.facebookPixel.addNoScriptPixel();
    this.froged.init();
    this.hotjar.init();
    this.googleMaps.init().catch((error) => {
      console.error('Google maps error: ', error);
    });
  }
}
