import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class DeleteButtonComponent {
  @Input() color = 'delete';
  @Input() disabled = false;
  @Input() type = 'button';
  @Input() label: string;
  @Output() Click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  handleClick(e: MouseEvent): void {
    this.Click.emit(e);
  }
}
