<div class="image-uploader">
  <div class="form-group row" [formGroup]="formGroup">
    <label
      *ngIf="!options?.unlabelled"
      class="col-lg-4 col-form-label"
      [class.col-lg-12]="fullWidth">
      {{ options.label | translate }}
    </label>
    <div class="col-lg">
      <div class="input-group">
        <div class="file-container" [class.is-invalid]="isInvalid">
          <div class="file-container__info" *ngIf="!image">
            <i class="icon icon-upload-blue icon-20"></i>
            <span class="d-block pre-wrap">{{ options.helper | translate }}</span>
          </div>
          <div *ngIf="!image" class="file-container__support">
            {{ 'C_A_SUPORT_FORMAT' | translate }}
            <span class="file-container__support__types">jpg, png, jpeg & pdf</span>
          </div>
          <div class="file-container__upload" *ngIf="image">
            <i class="icon icon-upload-withe icon-20"></i>
          </div>
          <img alt="" *ngIf="image && !isPDF" class="img-responsive preview" [src]="image" />
          <div *ngIf="image && isPDF" class="pdf-preview">
            <pdf-viewer
              [src]="image"
              [original-size]="false"
              [show-all]="false"
              [zoom]="0.6"
              [autoresize]="true"></pdf-viewer>
          </div>
          <input
            class="custom-file-input opacity-0"
            [disabled]="disabled"
            [accept]="options.accept || '*.*'"
            [id]="options.formControlNameValue"
            type="file"
            [class.is-invalid]="isInvalid"
            [placeholder]="placeholder | translate"
            (change)="onFileChange($event)" />
          <label
            class="custom-file-label font-small"
            [for]="options.formControlNameValue"
            [attr.data-browse]="(options.helper | translate) || ''"></label>
        </div>
      </div>
      <app-errors
        [isInvalid]="isInvalid"
        [formGroup]="formGroup"
        [formControlNameValue]="options.formControlNameValue"></app-errors>
    </div>
    <ng-content></ng-content>
  </div>
</div>
