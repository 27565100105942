<form *ngIf="documentTypes; else spinner" [formGroup]="form" (ngSubmit)="submit()">
  <app-input
    [fullWidth]="true"
    [formGroup]="form"
    [isSubmitted]="isSubmitted"
    [options]="inputs.DocumentNumber"></app-input>
  <app-selector
    [fullWidth]="true"
    [formGroup]="form"
    [isSubmitted]="isSubmitted"
    [options]="inputs.Type"></app-selector>

  <div class="container-pickers">
    <div class="pickers">
      <label class="form__label">{{ 'C_F_VALIDITY_DATE' | translate }}</label>
      <app-date-picker
        [showRequiredSymbol]="false"
        [fullWidth]="true"
        [formGroup]="form"
        [wrapped]="true"
        [maxDate]="currentDate"
        [options]="inputs.ExpiryDate"></app-date-picker>
    </div>
    <div class="pickers">
      <label class="form__label">{{ 'C_F_DELIVERY_DATE' | translate }}</label>
      <app-date-picker
        [showRequiredSymbol]="false"
        [fullWidth]="true"
        [formGroup]="form"
        [wrapped]="true"
        [minDate]="currentDate"
        [options]="inputs.DeliveryDate"></app-date-picker>
    </div>
  </div>
  <app-country-picker-mt
    [fullWidth]="true"
    [formGroup]="form"
    [customCountries]="currentTranslateCountries"
    [currentTranslatedCountries]="currentTranslateCountries"
    [options]="inputs.IdDeliveryCountry"
    data-testid="OnlineDelivery_country"></app-country-picker-mt>
  <label class="form__label">{{ 'M_PAC_ENROLLMENT_SMILE_ACCOUNT_DOCS_IMAGES' | translate }}</label>
  <div class="contain-images">
    <app-image-uploader
      [fullWidth]="true"
      *ngIf="!readonly"
      [formGroup]="form"
      [isSubmitted]="isSubmitted"
      [options]="inputs.file1"></app-image-uploader>
    <app-image-uploader
      [fullWidth]="true"
      *ngIf="!readonly"
      [formGroup]="form"
      [isSubmitted]="isSubmitted"
      [options]="inputs.file2"></app-image-uploader>
  </div>
</form>
<ng-template #spinner>
  <app-infinite-scroll-loader></app-infinite-scroll-loader>
</ng-template>
