<div class="form-group country-picker row" [formGroup]="formGroup">
  <label class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div class="col-lg-8" [class.col-lg-12]="fullWidth">
    <ng-template #rt let-r="result" let-t="term">
      <i appFlagIcon [ISOCode]="r.Iso2"></i>
      <ngb-highlight [result]="r.Name" [term]="t"></ngb-highlight>
    </ng-template>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        [class.is-invalid]="isInvalid"
        [class.has-value]="currentFlag"
        [class.disabled]="disabled"
        [formControlName]="options.formControlNameValue"
        [ngbTypeahead]="search"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        [editable]="false"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)"
        autocomplete="chrome-off"
        appAlphabetOnly
        #instance="ngbTypeahead" />
      <a *ngIf="showCaret" class="input-inline-button">
        <span class="fa fa-angle-down"></span>
      </a>
    </div>
    <ng-container *ngIf="currentFlag">
      <i class="flag-icon" appFlagIcon [ISOCode]="currentFlag"></i>
    </ng-container>
    <ng-container *ngIf="options?.helper">
      <small class="form-text text-muted">
        {{ options.helper | translate }}
      </small>
    </ng-container>
    <app-errors
      [isInvalid]="isInvalid"
      [formGroup]="formGroup"
      [formControlNameValue]="options.formControlNameValue"></app-errors>
  </div>
</div>
