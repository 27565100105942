<div class="form-group row" [formGroup]="formGroup">
  <label *ngIf="!options?.unlabelled" class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div class="col-lg" [class.col-lg-12]="fullWidth">
    <textarea
      class="form-control textarea"
      [placeholder]="options.placeholder || '' | translate"
      [class.is-invalid]="isInvalid"
      [formControlName]="options.formControlNameValue"
      [maxlength]="options.maxLength"
      [rows]="options.rowsCount || 1"></textarea>
    <ng-container *ngIf="options?.helper">
      <small class="form-text text-muted">
        {{ options.helper | translate }}
      </small>
    </ng-container>
    <app-errors
      *ngIf="!options.hideErrors"
      [isInvalid]="isInvalid"
      [formGroup]="formGroup"
      [formControlNameValue]="options.formControlNameValue"></app-errors>
  </div>
</div>
