/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from './alert-modal.component';
import { AlertModalType } from './alert-modal.model';

@Injectable({ providedIn: 'root' })
export class AlertModalService {
  constructor(private readonly modalService: NgbModal) {}

  open(
    messages: string[],
    title: string,
    type: AlertModalType,
    buttonMessage?: string,
    onClose?: () => void,
    warnings?: string[],
    onDismiss?: () => void,
    selector?: string
  ): void {
    const modal = this.modalService.open(AlertModalComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: 'alert-modal-width',
    });
    (modal.componentInstance as AlertModalComponent).title = title;
    (modal.componentInstance as AlertModalComponent).buttonMessage = buttonMessage;
    (modal.componentInstance as AlertModalComponent).messages = messages;
    (modal.componentInstance as AlertModalComponent).warnings = warnings;
    (modal.componentInstance as AlertModalComponent).type = type;
    (modal.componentInstance as AlertModalComponent).selector = selector;
    modal.result.then(onClose).catch(onDismiss);
  }

  success(
    messages: string[],
    title = '',
    buttonMessage?: string,
    onClose?: () => void,
    warnings?: string[],
    onDismiss?: () => void,
    selector?: string
  ): void {
    this.open(messages, title, 'success', buttonMessage, onClose, warnings, onDismiss, selector);
  }

  warning(
    messages: string[],
    title = '',
    buttonMessage?: string,
    onClose?: () => void,
    warnings?: string[],
    onDismiss?: () => void,
    selector?: string
  ): void {
    this.open(messages, title, 'warning', buttonMessage, onClose, warnings, onDismiss, selector);
  }

  danger(
    messages: string[],
    title = '',
    buttonMessage?: string,
    onClose?: () => void,
    warnings?: string[],
    onDismiss?: () => void,
    selector?: string
  ): void {
    this.open(messages, title, 'danger', buttonMessage, onClose, warnings, onDismiss, selector);
  }

  delete(
    messages: string[],
    title = '',
    buttonMessage?: string,
    onClose?: () => void,
    warnings?: string[],
    onDismiss?: () => void,
    selector?: string
  ): void {
    this.open(messages, title, 'delete', buttonMessage, onClose, warnings, onDismiss, selector);
  }

  dismissAll(): void {
    this.modalService.dismissAll();
  }

  defaultServerError(errorMessage?: string): void {
    this.danger(
      ['C_F_ERROR_FACE', errorMessage],
      '',
      'C_A_CLOSE',
      () => {},
      [],
      () => {},
      'server-error-modal'
    );
  }
}
