import { Component, EventEmitter, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfig } from '@app/app.config';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

interface Sidebars {
  sidebar: boolean;
  sidebarMenu: boolean;
}

interface MenuOption {
  label: string;
  icon: string;
  route?: string;
  active: boolean;
}

const homeMenuOption: MenuOption = {
  label: 'P_HOME',
  icon: 'icon-home',
  active: true,
  route: '/',
};

const helpMenuOption: MenuOption = {
  label: 'P_HELP',
  icon: 'icon-help',
  active: false,
  route: 'contact',
};

const profileMenuOption: MenuOption = {
  label: 'P_PROFILE',
  icon: 'icon-profile',
  active: false,
};

const moreMenuOption: MenuOption = {
  label: 'P_MORE',
  icon: 'icon-more',
  active: false,
};

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
})
export class MobileNavbarComponent {
  @Output() readonly clickOptionEvent = new EventEmitter<Sidebars>();

  setSidebar: Sidebars = {
    sidebarMenu: false,
    sidebar: false,
  };

  menuOptions: MenuOption[] = AppConfig.contactPageEnabled
    ? [homeMenuOption, helpMenuOption, profileMenuOption, moreMenuOption]
    : [homeMenuOption, profileMenuOption, moreMenuOption];
  routeEvents$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    distinctUntilChanged(),
    map((route: NavigationEnd) => route.url),
    tap((route) => {
      if (route.includes('profile-account')) {
        this.currentActiveIcon(this.menuOptions[2]);
      }
      if (route.includes('contact')) {
        this.currentActiveIcon(this.menuOptions[1]);
      }
      if (route === '/') {
        this.currentActiveIcon(this.menuOptions[0]);
      }
    })
  );

  constructor(private readonly router: Router) {}

  currentActiveIcon(item: MenuOption) {
    this.menuOptions.forEach((option) => {
      option.active = false;
    });

    if (['icon-more', 'icon-profile'].includes(item.icon)) {
      item.active = this.checkActiveMenuOption(item.icon);
    } else {
      this.setSidebar.sidebar = false;
      this.setSidebar.sidebarMenu = false;
      item.active = true;
    }
  }

  checkActiveMenuOption(icon: string): boolean {
    if (icon === 'icon-more') {
      this.setSidebar.sidebar = !this.setSidebar.sidebar;
      this.setSidebar.sidebarMenu = false;
      return this.setSidebar.sidebar;
    }
    if (icon === 'icon-profile') {
      this.setSidebar.sidebarMenu = !this.setSidebar.sidebarMenu;
      this.setSidebar.sidebar = false;
      return this.setSidebar.sidebarMenu;
    }
  }

  goToPage(item: MenuOption) {
    this.currentActiveIcon(item);
    this.clickOptionEvent.emit(this.setSidebar);
    if (item.route) {
      this.router.navigateByUrl(item.route);
    }
    if (this.setSidebar.sidebar || this.setSidebar.sidebarMenu) {
      return;
    }

    if (this.router.url === '/') {
      this.currentActiveIcon(this.menuOptions[0]);
    }
    if (this.router.url === '/contact') {
      this.currentActiveIcon(this.menuOptions[1]);
    }
  }
}
