import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localePy from '@angular/common/locales/es-PY';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from '@app/core/interceptors/auth.interceptor';
import { HttpErrorInterceptor } from '@app/core/interceptors/http-error.interceptor';
import { LanguageInterceptor } from '@app/core/interceptors/language.interceptor';
import { SecurityInterceptor } from '@app/core/interceptors/security.interceptor';
import { SessionInterceptor } from '@app/core/interceptors/session.interceptor';
import { AccountResolve } from '@app/core/resolvers/account.resolve';
import { CountryResolve } from '@app/core/resolvers/country.resolve';
import { CustomDatePickerI18n } from '@app/core/services/datepicker/datepicker.i18n';
import { CustomDateParserFormatter } from '@app/core/services/datepicker/datepicker.parser';
import { SessionService } from '@app/core/services/session.service';
import { SessionBaseService } from '@app/services/session-base.service';
import {
  NgbDateAdapter,
  NgbDateNativeUTCAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
} from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CurrentLanguageService } from './core/services/current-lang.service';
import { TranslationsService } from './core/services/translations.service';
import { PwaLoginComponent } from './pwa/pwa-login.component';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localePy, 'es');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, PwaLoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 8000,
      closeButton: true,
      tapToDismiss: false,
    }),
    SharedModule,
    NgIdleModule.forRoot(),
  ],
  providers: [
    AccountResolve,
    CountryResolve,
    { provide: BsDropdownConfig, useValue: { autoClose: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
    { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter },
    { provide: SessionBaseService, useClass: SessionService },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatePickerI18n },
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [],
      multi: true,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (translationService: TranslationsService) =>
        new CurrentLanguageService(translationService).currentLanguage(),
      deps: [TranslationsService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
