<div class="container">
  <ng-container *ngIf="!loading">
    <div class="alert alert-warning" role="alert" *ngIf="form.invalid">
      <div class="alert__icon"><i class="fas fa-exclamation-triangle"></i></div>
      <div class="alert__text">{{ 'HT_PROFILE_MSG_VALIDATION2' | translate }}</div>
    </div>
    <div class="alert alert-primary" role="alert" *ngIf="!editable">
      <div class="alert__icon"><i class="fas fa-exclamation-triangle"></i></div>
      <div class="alert__text">
        {{ 'MSG_ACCOUNT_NOT_EDITABLE' | translate }}: {{ contactPhone$ | async }}
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <app-country-picker
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.Country"></app-country-picker>
      <app-country-picker
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.Nationality"></app-country-picker>
      <app-address-search-input
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.Street"
        (postalCodeSelected)="onPostalCodeSelected($event)"
        (countrySelected)="onCountrySelected($event)"
        (streetNumberSelected)="onStreetNumberSelected($event)"
        (citySelected)="onCitySelected($event)"></app-address-search-input>
      <app-input
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.StreetNumber"
        [trim]="true"></app-input>
      <app-input
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.StreetLetter"
        [trim]="true"></app-input>
      <app-city-picker
        [formGroup]="form"
        [countryId]="selectedResidenceCountryId"
        [isSubmitted]="isSubmitted"
        [options]="inputs.City"
        #cityPicker></app-city-picker>
      <app-input
        [formGroup]="form"
        [isSubmitted]="isSubmitted"
        [options]="inputs.PostalCode"
        [trim]="true"></app-input>
      <app-button [type]="'submit'" [pending]="pending" [fullWidth]="true">
        {{ 'C_A_SAVE' | translate }}
      </app-button>
    </form>
  </ng-container>
  <app-infinite-scroll-loader [show]="loading"></app-infinite-scroll-loader>
</div>
