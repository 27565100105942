import { environment } from '@env/environment';
import { UrlRules } from '@app/shared/pipes/urls-pipes/urls.conts';

const { imtWordpressBaseUrl } = environment;
enum PrivacyPolicyByLanguage {
  FR = 'protection-des-donnees-privee',
  ES = 'politicas-privacidad',
  EN = 'privacy-policy',
  IT = 'politica-sulla-riservatezza',
  NL = 'privacybeleid',
}

/**
 * ? Belgium & France & Spain*:
 * * ES EN FR
 *
 * ? Congo RDC & United Kingdom:
 * * ES EN FR
 *
 * ? Italy:
 * * ES EN FR IT
 *
 * ? Netherlands:
 * * ES EN FR NL
 *
 *  */

export const PrivacyPolicyUrlRules: UrlRules = {
  belgium: {
    fr: `${imtWordpressBaseUrl}belgium/${PrivacyPolicyByLanguage.FR}/`,
    es: `${imtWordpressBaseUrl}belgium/es/${PrivacyPolicyByLanguage.ES}/`,
    en: `${imtWordpressBaseUrl}belgium/en/${PrivacyPolicyByLanguage.EN}/`,
    // ! Missing URL default EN
    uk: `${imtWordpressBaseUrl}belgium/en/${PrivacyPolicyByLanguage.EN}/`,
    it: `${imtWordpressBaseUrl}belgium/en/${PrivacyPolicyByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}belgium/en/${PrivacyPolicyByLanguage.EN}/`,
  },
  'congo-rdc': {
    fr: `${imtWordpressBaseUrl}congo-rdc/${PrivacyPolicyByLanguage.FR}/`,
    es: `${imtWordpressBaseUrl}congo-rdc/es/${PrivacyPolicyByLanguage.ES}/`,
    en: `${imtWordpressBaseUrl}congo-rdc/en/${PrivacyPolicyByLanguage.EN}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}congo-rdc/en/${PrivacyPolicyByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}congo-rdc/en/${PrivacyPolicyByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}congo-rdc/en/${PrivacyPolicyByLanguage.EN}/`,
  },
  spain: {
    es: `${imtWordpressBaseUrl}spain/${PrivacyPolicyByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}spain/fr/${PrivacyPolicyByLanguage.FR}/`,
    en: `${imtWordpressBaseUrl}spain/en/${PrivacyPolicyByLanguage.EN}/`,
    // ! Missing URL default EN
    uk: `${imtWordpressBaseUrl}spain/en/${PrivacyPolicyByLanguage.EN}/`,
    it: `${imtWordpressBaseUrl}spain/en/${PrivacyPolicyByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}spain/en/${PrivacyPolicyByLanguage.EN}/`,
  },
  italy: {
    it: `${imtWordpressBaseUrl}italy/${PrivacyPolicyByLanguage.IT}/`,
    fr: `${imtWordpressBaseUrl}italy/fr/${PrivacyPolicyByLanguage.FR}/`,
    es: `${imtWordpressBaseUrl}italy/es/${PrivacyPolicyByLanguage.ES}/`,
    en: `${imtWordpressBaseUrl}italy/en/${PrivacyPolicyByLanguage.EN}/`,
    // ! Missing URL default EN
    nl: `${imtWordpressBaseUrl}italy/en/${PrivacyPolicyByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}italy/en/${PrivacyPolicyByLanguage.EN}/`,
  },
  netherlands: {
    nl: `${imtWordpressBaseUrl}netherlands/${PrivacyPolicyByLanguage.NL}/`,
    es: `${imtWordpressBaseUrl}netherlands/es/${PrivacyPolicyByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}netherlands/fr/${PrivacyPolicyByLanguage.FR}/`,
    en: `${imtWordpressBaseUrl}netherlands/en/${PrivacyPolicyByLanguage.EN}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}netherlands/en/${PrivacyPolicyByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}netherlands/en/${PrivacyPolicyByLanguage.EN}/`,
  },
  'united-kingdom': {
    en: `${imtWordpressBaseUrl}united-kingdom/${PrivacyPolicyByLanguage.EN}/`,
    es: `${imtWordpressBaseUrl}united-kingdom/es/${PrivacyPolicyByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}united-kingdom/fr/${PrivacyPolicyByLanguage.FR}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}united-kingdom/${PrivacyPolicyByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}united-kingdom/${PrivacyPolicyByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}united-kingdom/${PrivacyPolicyByLanguage.EN}/`,
  },
  france: {
    fr: `${imtWordpressBaseUrl}france/${PrivacyPolicyByLanguage.FR}/`,
    en: `${imtWordpressBaseUrl}france/en/${PrivacyPolicyByLanguage.EN}/`,
    es: `${imtWordpressBaseUrl}france/es/${PrivacyPolicyByLanguage.ES}/`,
    // ! Missing URL default EN
    uk: `${imtWordpressBaseUrl}france/en/${PrivacyPolicyByLanguage.EN}/`,
    it: `${imtWordpressBaseUrl}france/en/${PrivacyPolicyByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}france/en/${PrivacyPolicyByLanguage.EN}/`,
  },
  portugal: {
    en: `${imtWordpressBaseUrl}portugal/${PrivacyPolicyByLanguage.EN}/`,
    es: `${imtWordpressBaseUrl}portugal/es/${PrivacyPolicyByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}portugal/fr/${PrivacyPolicyByLanguage.FR}/`,
    // ! Missing URL default EN
    uk: `${imtWordpressBaseUrl}portugal/${PrivacyPolicyByLanguage.EN}/`,
    it: `${imtWordpressBaseUrl}portugal/${PrivacyPolicyByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}portugal/${PrivacyPolicyByLanguage.EN}/`,
  },
};
