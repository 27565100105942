import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IMessage } from '@app/interfaces/edit-document-request.interface';
import { IFormInputs } from '@app/interfaces/forms.interface';

@Component({
  selector: 'app-edit-document-modal-body',
  templateUrl: './edit-document-modal-body.component.html',
  styleUrls: ['../account-modal-identity-documents.component.scss'],
})
export class EditDocumentModalBodyComponent implements OnInit {
  @Output() readonly formSubmitted = new EventEmitter<IMessage>();
  @Input() showConfirmation = false;
  isSubmitted = false;
  pending = false;
  form: UntypedFormGroup;
  inputs: IFormInputs = {
    message: {
      formControlNameValue: 'message',
      label: '',
      type: 'textarea',
      rowsCount: 5,
      unlabelled: true,
    },
  };

  ngOnInit() {
    this.initForm();
  }

  submit() {
    this.isSubmitted = true;
    if (this.form.valid) {
      this.formSubmitted.emit(this.form.value);
    }
  }

  private initForm() {
    this.form = new UntypedFormGroup({ message: new UntypedFormControl('', Validators.required) });
  }
}
