<div class="modal-footer h-auto p-2">
  <ng-template [ngIf]="!showConfirmation" [ngIfElse]="confirmationFooter">
    <app-button (click)="save()" [pending]="pending">
      <i class="fas fa-plus-circle"></i>
      {{ 'C_A_SEND_MODIFICATION_REQUEST' | translate }}
    </app-button>
    <app-button color="secondary" (click)="close()">
      <i class="fas fa-times-circle"></i>
      {{ 'C_A_CLOSE' | translate }}
    </app-button>
  </ng-template>
  <ng-template #confirmationFooter>
    <app-button color="success" [type]="'submit'" (click)="close()">
      <i class="fas fa-check"></i>
      {{ 'C_OK' | translate }}!
    </app-button>
  </ng-template>
</div>
