<div (mousemove)="onMouseMove($event)" id="select">
  <div class="form-group row" [formGroup]="formGroup">
    <label
      *ngIf="!options?.unlabelled"
      class="col-lg-4 col-form-label"
      [class.col-lg-12]="fullWidth">
      <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
      {{ options.label | translate }}
    </label>
    <div class="col-lg-8" [class.col-lg-12]="fullWidth">
      <div class="select-container">
        <ng-container
          *ngIf="
            searchable &&
            ((!value && showMagnifyingGlass && !isClickOnOption) || isOpen || isFocused)
          ">
          <div>
            <div class="search-icn">
              <i class="fas fa-search"></i>
            </div>
          </div>
        </ng-container>
        <ng-select
          #selectInput
          [class.is-invalid]="invalid || isInvalid"
          [class.focus]="isOpen || isFocused"
          [class.text-disabled]="control.disabled || disabled"
          [class.padding]="
            searchable &&
            ((!value && showMagnifyingGlass && !isClickOnOption) || isOpen || isFocused)
          "
          [class.w-100]="fullWidth"
          [class.disable-pointer]="pressKey"
          [formControlName]="options.formControlNameValue"
          [items]="itemsObject"
          [bindLabel]="options.optionLabel ? options.optionLabel : 'label'"
          [bindValue]="options.optionValue ? options.optionValue : 'id'"
          [placeholder]="placeholder ? translateService.instant(placeholder) : defaultPlaceholder()"
          [notFoundText]="'RESULTS_NOT_FOUND' | translate"
          [searchable]="searchable"
          [readonly]="disabled"
          [clearable]="false"
          [editableSearchTerm]="true"
          [dropdownPosition]="dropdownPosition"
          (change)="handleChange($event)"
          (open)="handleOpen()"
          (blur)="handleBlur()"
          (close)="handleClose()"
          (search)="onSearch($event)">
          <ng-container *ngIf="isObject && hasTranslationCode">
            <ng-template *ngIf="itemsObject" ng-label-tmp let-item="item">
              <span *ngIf="item.Code || item.code">
                {{ (item.Code ? item.Code : item.code) | translate }}
              </span>
            </ng-template>
          </ng-container>
          <ng-template *ngIf="itemsObject" ng-option-tmp let-item="item" let-index="index">
            <div
              class="form-group__option"
              (mouseover)="onMouseover(index)"
              (click)="onOptionSelect(index)">
              <span>
                {{ item[options.optionLabel ? options.optionLabel : 'label'] | translate }}
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <ng-container *ngIf="!options.hideErrors && isRequiredError">
        <span class="msg-error">
          {{ requiredErrorText || 'VAL_MSG_REQUIRED' | translate }}
        </span>
      </ng-container>
    </div>
  </div>
</div>
