import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CityListResponse, CityResponse } from '@app/interfaces/city-response.interface';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class CitiesService {
  private readonly CITIES_URL = `${environment.apiBasePath}/corp/city/`;
  private readonly CITIES_BY_COUNTRY_URL = `${environment.apiBasePath}/corp/city/country/`;

  constructor(private readonly httpClient: HttpClient) {}

  public getCity(cityId: string): Observable<CityResponse> {
    return this.httpClient.get<CityResponse>(`${this.CITIES_URL}${cityId}`);
  }

  public getCityBySearchTerm(countryId: number, searchTerm: string): Observable<CityListResponse> {
    if (!countryId) {
      throw new Error('Required parameter countryId was null or undefined when calling services.');
    }

    if (!searchTerm) {
      throw new Error('Required parameter name was null or undefined when calling services.');
    }

    return this.httpClient.get<CityListResponse>(
      `${this.CITIES_BY_COUNTRY_URL}${countryId}?searchTerm=${searchTerm}`
    );
  }

  public getCityByCountryId(countryId: number): Observable<CityListResponse> {
    if (!countryId) {
      throw new Error('Required parameter countryId was null or undefined when calling services.');
    }

    return this.httpClient.get<CityListResponse>(`${this.CITIES_BY_COUNTRY_URL}${countryId}`);
  }
}
