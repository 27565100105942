<form [formGroup]="formGroup" class="form-group city-picker row">
  <label class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div class="col-lg-8" [class.col-lg-12]="fullWidth">
    <ng-select
      #select
      [class.focus]="isFocused"
      [class.is-invalid]="isInvalid"
      [formControlName]="options.formControlNameValue"
      [items]="cities$ | async"
      [placeholder]="isFocused ? '' : getPlaceholder()"
      [notFoundText]="noFoundCode || 'RESULTS_NOT_FOUND' | translate"
      [loadingText]="loadingText || 'C_MSG_LOADING' | translate"
      [clearable]="false"
      [searchable]="true"
      [editableSearchTerm]="true"
      [minTermLength]="3"
      [virtualScroll]="true"
      [loading]="loading"
      [typeahead]="cityInput$"
      bindLabel="Name"
      (blur)="onBlur()"
      (focus)="onFocus()">
      <ng-template ng-loadingspinner-tmp>
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </ng-template>
      <ng-template ng-typetosearch-tmp></ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div class="form-group__option">
          <span>{{ item.Name }}</span>
        </div>
      </ng-template>
    </ng-select>
    <ng-container *ngIf="!options.hideErrors && isRequiredError">
      <span class="msg-error">
        {{ requiredErrorText || 'VAL_MSG_REQUIRED' | translate }}
      </span>
    </ng-container>
  </div>
</form>
