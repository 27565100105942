<img
  *ngIf="!isPDF"
  alt=""
  class="img-responsive img-preview"
  [ngClass]="{ 'img-resize': resize }"
  [src]="image || placeholder" />
<div *ngIf="isPDF" class="pdf-preview">
  <pdf-viewer
    [src]="image"
    [original-size]="false"
    [show-all]="false"
    [zoom]="0.4"
    [autoresize]="false"></pdf-viewer>
</div>
