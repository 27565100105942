<nav class="header" *ngIf="showNavbarLegacy" aria-label="Site menu legacy">
  <div class="header__content">
    <div class="header__logo">
      <div [routerLink]="['/home']" class="header__logo__image" alt="logo"></div>
    </div>
    <div class="header__menu">
      <a
        *ngFor="let link of links"
        [href]="link.href"
        class="header__menu__item fixed-menu"
        placement="bottom">
        <div class="header__menu__item__label">{{ link.label | translate }}</div>
      </a>
      <div ngbDropdown #menuDropdown="ngbDropdown" class="header__menu__item mobile-menu">
        <div ngbDropdownToggle><span class="fas fa-bars"></span></div>
        <div ngbDropdownMenu>
          <a
            *ngFor="let link of links"
            [href]="link.href"
            class="header__menu__item"
            placement="bottom">
            <div class="header__menu__item__label">{{ link.label | translate }}</div>
          </a>
        </div>
      </div>
      <div
        ngbDropdown
        #languageDropdown="ngbDropdown"
        (click)="toggleLanguageDropdown()"
        class="header__menu__item language-dropdown">
        <div ngbDropdownToggle><span appFlagIcon [ISOCode]="currentLanguage.country"></span></div>
        <div ngbDropdownMenu>
          <ng-container *ngFor="let language of availableLanguages">
            <button (click)="setLanguage(language)" ngbDropdownItem>
              <i appFlagIcon [ISOCode]="language.country"></i>
              {{ language.desc }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</nav>

<nav aria-label="Site new menu" class="new__header container" *ngIf="showNavbarNew && !register">
  <div class="new__header__content">
    <div class="new__header__logo">
      <div [routerLink]="['/']" class="new__header__logo__image" alt="logo"></div>
    </div>

    <div class="new__header__menu">
      <a
        *ngFor="let link of newLinks"
        [href]="link.href"
        class="new__header__menu__item fixed-menu"
        placement="bottom">
        <div class="new__header__menu__item__label__dark">{{ link.label | translate }}</div>
      </a>

      <div
        ngbDropdown
        #languageDropdown="ngbDropdown"
        placement="bottom-end"
        (click)="toggleLanguageDropdown()"
        class="new__header__menu__item language-dropdown">
        <div ngbDropdownToggle>
          <span class="new__header__menu__language__code">
            {{ currentLanguage?.code | uppercase }}
          </span>
        </div>
        <div ngbDropdownMenu>
          <ng-container *ngFor="let language of availableLanguages">
            <button (click)="setLanguage(language)" ngbDropdownItem>
              <i [className]="language?.icon"></i>
              {{ language.desc }}
            </button>
          </ng-container>
        </div>
      </div>

      <a
        (click)="redirectToLoginPage()"
        class="new__header__menu__item fixed-menu"
        placement="bottom">
        <div class="new__header__menu__item__label__blue">{{ 'C_A_LOGIN' | translate }}</div>
      </a>

      <div
        ngbDropdown
        #menuDropdown="ngbDropdown"
        class="new__header__menu__item mobile-menu"
        placement="bottom-end"
        (openChange)="handleMenuIsOpen($event)">
        <div ngbDropdownToggle>
          <span class="fas fa-bars" *ngIf="showBars"></span>
          <span class="fas fa-times" *ngIf="!showBars"></span>
        </div>
        <div ngbDropdownMenu>
          <a
            *ngFor="let link of newLinks"
            [href]="link.href"
            class="new__header__menu__item"
            placement="bottom">
            <div class="new__header__menu__item__label">{{ link.label | translate }}</div>
          </a>

          <div
            ngbDropdown
            #languageDropdown="ngbDropdown"
            (click)="toggleLanguageDropdown()"
            class="new__header__menu__item language-dropdown d-mobile"
            placement="bottom-end">
            <div ngbDropdownToggle class="d-flex justify-content-between w-100">
              <span class="new__header__menu__language__code col-sm-10">
                {{ currentLanguage?.code | uppercase }}
              </span>
              <span class="new__header__menu__language__after col-sm-2"></span>
            </div>
            <div ngbDropdownMenu>
              <ng-container *ngFor="let language of availableLanguages">
                <button (click)="setLanguage(language); handleMenuIsOpen(false)" ngbDropdownItem>
                  <i [className]="language?.icon"></i>
                  {{ language.desc }}
                </button>
              </ng-container>
            </div>
          </div>

          <a (click)="redirectToLoginPage()" class="new__header__menu__item" placement="bottom">
            <div class="new__header__menu__item__label__blue">{{ 'C_A_LOGIN' | translate }}</div>
          </a>
          <a (click)="redirectToSingUpPage()" class="new__header__menu__item" placement="bottom">
            <div class="new__header__menu__item__label">{{ 'C_A_REGISTER' | translate }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>

<nav
  aria-label="Site register menu"
  class="new__header container"
  *ngIf="showNavbarNew && register">
  <div class="new__header__content">
    <div class="new__header__logo">
      <div [routerLink]="['/']" class="new__header__logo__image" aria-label="logo"></div>
    </div>

    <div class="new__header__menu">
      <a
        *ngFor="let link of newLinks"
        [href]="link.href"
        class="new__header__menu__item"
        placement="bottom">
        <div class="new__header__menu__item__label__dark">{{ link.label | translate }}</div>
      </a>

      <div
        ngbDropdown
        #languageDropdown="ngbDropdown"
        (click)="toggleLanguageDropdown()"
        class="new__header__menu__item">
        <div ngbDropdownToggle>
          <span class="new__header__menu__language__code">
            {{ currentLanguage?.code | uppercase }}
          </span>
        </div>
        <div ngbDropdownMenu>
          <ng-container *ngFor="let language of availableLanguages">
            <button (click)="setLanguage(language)" ngbDropdownItem>
              <i [className]="language?.icon"></i>
              {{ language.desc }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</nav>
