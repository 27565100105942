import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { catchError } from 'rxjs/operators';
import { UserSession } from '@app/interfaces/user-session.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionResolve implements Resolve<Observable<UserSession>> {
  constructor(private readonly sessionService: SessionService) {}

  resolve(): Observable<UserSession> {
    return this.sessionService.currentSession.pipe(catchError(() => EMPTY));
  }
}
