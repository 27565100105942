<nav class="header">
  <div class="header__content">
    <div class="header__logo"></div>
    <div class="header__menu">
      <a
        *ngFor="let link of links"
        [href]="link.href"
        class="header__menu__item fixed-menu"
        placement="bottom">
        <div class="header__menu__item__label">{{ link.label | translate | withConfigKeys }}</div>
      </a>
      <div ngbDropdown #menuDropdown="ngbDropdown" class="header__menu__item mobile-menu">
        <div ngbDropdownToggle><span class="fas fa-bars"></span></div>
        <div ngbDropdownMenu>
          <a
            *ngFor="let link of links"
            [href]="link.href"
            class="header__menu__item"
            placement="bottom">
            <div class="header__menu__item__label">
              {{ link.label | translate | withConfigKeys }}
            </div>
          </a>
        </div>
      </div>
      <div
        ngbDropdown
        #languageDropdown="ngbDropdown"
        (click)="languageDropdown.open()"
        class="header__menu__item language-dropdown">
        <div ngbDropdownToggle><span appFlagIcon [ISOCode]="currentLanguage.country"></span></div>
        <div ngbDropdownMenu>
          <ng-container *ngFor="let language of availableLanguages">
            <button (click)="setLanguage(language)" ngbDropdownItem>
              <span appFlagIcon [ISOCode]="language.country"></span>
              {{ language?.desc }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</nav>
