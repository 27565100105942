import { Component, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavbarService } from '@app/core/services/navbar.service';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { PublicHeaderComponent } from './public-header/public-header.component';

@Component({
  selector: 'app-public-wrapper',
  templateUrl: './public-wrapper.component.html',
  styleUrls: ['./public-wrapper.component.scss'],
})
export class PublicWrapperComponent extends BaseComponent {
  @ViewChild(PublicHeaderComponent, { static: true }) header: PublicHeaderComponent;
  route = 'home';
  showNavbarLegacy = true;
  showNavbarNew = true;

  constructor(
    private readonly renderer: Renderer2,
    private readonly router: Router,
    readonly navService: NavbarService
  ) {
    super();
    const loginSklElment = this.renderer.selectRootElement('#login-skl-loader');
    this.renderer.addClass(loginSklElment, 'd-none');
    this.routeListener();
  }

  onActivate(componentRef) {
    if (componentRef.setWrapperComponent) {
      componentRef.setWrapperComponent(this.header);
    }
  }

  setOtherConfigNavbar() {
    if (this.route.includes('/register/verify/mail')) {
      this.navService.changeNavbar(true);
    }
  }

  private routeListener() {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          distinctUntilChanged()
        )
        .subscribe((route: NavigationEnd) => {
          this.route = route.url;
          this.showNavbarLegacy = false;
          this.showNavbarNew = true;
          this.setOtherConfigNavbar();
        })
    );
  }
}
