import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import {
  ClientLookupAction,
  ClientLookupMethod,
  Customer,
  CustomerDocumentResponse,
  CustomerListResponse,
  PostalCodeAddressRequest,
  PostalCodeAddressResponse,
  ProofOfAddressImageResponse,
  ProofOfAddressResponse,
} from '@app/interfaces/customer.interface';
import { ApiResponse } from '@app/interfaces/api-response.interface';
import { SaveCustomerResponse } from '@app/interfaces/save-customer-response.interface';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private readonly CUSTOMER_ID_DOCS_URL = `${environment.agentApiBasePath}/customer/iddoc/`;
  private readonly CUSTOMER_LOCAL_URL = `${environment.agentApiBasePath}/customer/local`;
  private readonly CUSTOMER_PROOFS_URL = `${environment.agentApiBasePath}/customer/proof`;
  private readonly CUSTOMER_DETAILS_URL = `${environment.agentApiBasePath}/customer/local/`;
  private readonly ID_DOCUMENTS_URL = `${environment.apiBasePath}/account/iddoc/`;
  private readonly PROOF_OF_ADDRESS_URL = `${environment.apiBasePath}/customer/proof/img/`;
  private readonly IS_PROOF_VALID_URL = `${environment.apiBasePath}/customer/proof/isvalid`;
  private readonly POSTAL_CODE_ADDRESS_URL = `${environment.apiBasePath}/customer/postalcode/address`;
  private readonly ID_ADDITIONAL_DOCUMENTS_URL = `${environment.apiBasePath}/account/compliancedoc/`;

  constructor(private readonly httpClient: HttpClient) {}

  public searchCustomers(
    method: ClientLookupMethod,
    criteria: string,
    action?: ClientLookupAction
  ): Observable<Customer[]> {
    // TODO replace those checks with strictNullChecking in TypeScript compiler settings
    if (!method) {
      throw new Error(
        'Required parameter method was null or undefined when calling getCustomerLocalByCriteria.'
      );
    }

    if (!criteria) {
      throw new Error(
        'Required parameter criteria was null or undefined when calling getCustomerLocalByCriteria.'
      );
    }

    let searchAction = '';
    if (action) {
      searchAction = `&SearchActionContext=${action.toString()}`;
    }

    return this.httpClient
      .get<CustomerListResponse>(
        `${this.CUSTOMER_LOCAL_URL}?${method.toString()}=${encodeURIComponent(criteria)}${searchAction}`
      )
      .pipe(
        map((response: CustomerListResponse) =>
          response.Data.map((customer) => {
            if (customer.Documents && customer.Documents.length > 0) {
              customer.SelectedDocument = customer.Documents[0];
            } else {
              customer.SelectedDocument = null;
            }
            return customer;
          })
        )
      );
  }

  public getCustomerProofOfAddress(customerId: number): Observable<ProofOfAddressResponse> {
    // TODO replace this check with strictNullChecking in TypeScript compiler settings
    if (!customerId) {
      throw new Error(
        'Required parameter customerId was null or undefined when calling getCustomerProofOfAddress.'
      );
    }

    return this.httpClient.get<ProofOfAddressResponse>(`${this.CUSTOMER_PROOFS_URL}/${customerId}`);
  }

  public getCustomerIdDocs(customerId: number): Observable<CustomerDocumentResponse> {
    // TODO replace this check with strictNullChecking in TypeScript compiler settings
    if (!customerId) {
      throw new Error(
        'Required parameter customerId was null or undefined when calling getCustomerIdDocs.'
      );
    }

    return this.httpClient.get<CustomerDocumentResponse>(
      `${this.CUSTOMER_ID_DOCS_URL}${customerId}`
    );
  }

  public getCustomerDetails(customerLocalId: number): Observable<Customer> {
    // TODO replace this check with strictNullChecking in TypeScript compiler settings
    if (!customerLocalId) {
      throw new Error(
        'Required parameter customerLocalId was null or undefined when calling getCustomerDetails.'
      );
    }

    return this.httpClient
      .get<ApiResponse<Customer>>(`${this.CUSTOMER_DETAILS_URL}${customerLocalId}`)
      .pipe(map((response) => response.Data));
  }

  public updateCustomersIsPrestigeFlag(
    customerLocalId: number,
    isPrestige: boolean | null
  ): Observable<SaveCustomerResponse> {
    // TODO replace those checks with strictNullChecking in TypeScript compiler settings
    if (customerLocalId === null || customerLocalId === undefined) {
      throw new Error(
        'customerLocalId parameter is required in CustomersService.updateCustomersIsPrestigeFlag'
      );
    }
    if (isPrestige === undefined) {
      throw new Error(
        'isPrestige parameter is required in CustomersService.updateCustomersIsPrestigeFlag'
      );
    }

    return this.httpClient
      .patch<ApiResponse<SaveCustomerResponse>>(`${this.CUSTOMER_DETAILS_URL}${customerLocalId}`, {
        IsPrestige: isPrestige,
      })
      .pipe(map((response) => response.Data));
  }

  public getCustomerIdDocFrontImage(documentId: number): Observable<string> {
    if (!documentId) {
      throw new Error('Required parameter criteria was null or undefined when calling services.');
    }

    return this.httpClient.get<string>(
      `${this.ID_DOCUMENTS_URL}${documentId}/picturefront/base64`,
      { responseType: 'text' as 'json' }
    );
  }

  public getCustomerIdAdditionalDocImage(additionalDocumentId: number): Observable<string> {
    if (!additionalDocumentId) {
      throw new Error('Required parameter criteria was null or undefined when calling services.');
    }

    return this.httpClient.get<string>(
      `${this.ID_ADDITIONAL_DOCUMENTS_URL}${additionalDocumentId}/picture/base64`,
      { responseType: 'text' as 'json' }
    );
  }

  public getPostalCodeAddress(
    request: PostalCodeAddressRequest
  ): Observable<PostalCodeAddressResponse> {
    if (!request) {
      throw new Error('Required parameter criteria was null or undefined when calling services.');
    }

    return this.httpClient.post<PostalCodeAddressResponse>(this.POSTAL_CODE_ADDRESS_URL, request);
  }

  public getCustomerIdDocBackImage(documentId: number): Observable<string> {
    if (!documentId) {
      throw new Error('Required parameter criteria was null or undefined when calling services.');
    }

    return this.httpClient.get<string>(`${this.ID_DOCUMENTS_URL}${documentId}/pictureback/base64`, {
      responseType: 'text' as 'json',
    });
  }

  public getProofOfAddressImage(proofId: number): Observable<ProofOfAddressImageResponse> {
    if (!proofId) {
      throw new Error('Required parameter criteria was null or undefined when calling services.');
    }

    return this.httpClient.get<ProofOfAddressImageResponse>(
      `${this.PROOF_OF_ADDRESS_URL}${proofId}`
    );
  }

  public isCustomersProofOfAddressValid(customerId: number): Observable<boolean> {
    if (!customerId) {
      throw new Error('Required parameter criteria was null or undefined when calling services.');
    }

    return this.httpClient
      .get<ApiResponse<boolean>>(`${this.IS_PROOF_VALID_URL}/${customerId}`)
      .pipe(
        map((response) => response.Data),
        catchError(() => of(false))
      );
  }
}
