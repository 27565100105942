import { Row } from '@app/interfaces/imt-topups-common.interfaces';
import { TopUpOperation } from '@app/interfaces/international-top-up/top-up-operation.interface';

export function mapFromRow(row: Row): TopUpOperation {
  return {
    id: row.columnValues[0],
    reference: row.columnValues[1],
    date: row.columnValues[2],
    beneficiaryMSISDN: row.columnValues[5],
    beneficiaryCountryIso2: row.columnValues[6],
    countryName: row.columnValues[7],
    operatorCode: row.columnValues[9],
    operatorName: row.columnValues[10],
    receivedValue: row.columnValues[11],
    receivedValueCurrency: row.columnValues[12],
    paidValueCurrency: row.columnValues[13],
    paidValue: row.columnValues[14],
    fees: row.columnValues[15],
    firstName: row.columnValues[18],
    lastName: row.columnValues[19],
    rate: row.columnValues[21],
    status: row.columnValues[22],
    paymentMethodCode: row.columnValues[23],
  };
}
