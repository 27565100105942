import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '@app/shared/enums/language-enum';
import { COUNTRIES, LANGUAGES } from '@app/shared/pipes/urls-pipes/urls.conts';
import { PrivacyPolicyUrlRules } from './privacy_policy.urls';

@Pipe({
  name: 'privacyPolicyPipe',
  standalone: true,
})
export class PrivacyPolicyPipe implements PipeTransform {
  transform(lang: string): string {
    const URL = window.location.href;
    const country = COUNTRIES.find((v) => URL.toLowerCase().includes(v)) || 'belgium';
    const language = LANGUAGES.includes(lang as Language) ? lang : 'en';
    return PrivacyPolicyUrlRules[country][language];
  }
}
