<div class="modal-body px-0" [class]="selector">
  <div class="container">
    <div class="row mb-3">
      <div class="col-md-12">
        <div class="d-flex justify-content-end">
          <button type="button" (click)="dismiss()">
            <i class="icon icon-close-blue"></i>
          </button>
        </div>
      </div>
    </div>
    <hr *ngIf="title" class="mt-0 mb-3" />
    <div class="row mb-3">
      <div class="col-12 text-center">
        <i *ngIf="type === 'success'" class="icon icon-success icon-80">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
          <span class="path4"></span>
          <span class="path5"></span>
        </i>
        <i *ngIf="type === 'warning'" class="icon icon-alert icon-80">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
          <span class="path4"></span>
          <span class="path5"></span>
        </i>
        <i *ngIf="type === 'danger'" class="icon icon-error icon-80">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
          <span class="path4"></span>
          <span class="path5"></span>
        </i>

        <i *ngIf="type === 'delete'" class="icon icon-user-delete icon-80">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
        </i>
      </div>
    </div>
    <div *ngIf="title" class="row mb-3">
      <div class="col-12 text-center">
        <h3>{{ title | translate }}</h3>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 text-center modal-message" data-testid="modal_msg">
        <p *ngFor="let message of messages; index as i" [attr.data-testid]="'msg_' + i">
          {{ message | translate | withConfigKeys }}
          <br />
        </p>
      </div>
      <div class="col-12 text-center modal-warning">
        <p *ngFor="let warning of warnings">
          {{ warning | translate }}
          <br />
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center button-row">
        <app-button-big class="modal-btn"
          (Click)="close()"
          [callToAction]="(buttonMessage === '' ? 'C_A_CLOSE' : buttonMessage) | translate"
          [color]="'primary'"></app-button-big>
      </div>
    </div>
  </div>
</div>
