import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { GoogleAuthenticationService } from '@app/core/services/google-authentication.service';
import { SmsConfirmationType } from '@app/shared/enums/sms-confirmation-type.enum';
import { IFormInput } from '@app/interfaces/forms.interface';
import { AlertModalService } from '@app/shared/modals/alert-modal/alert-modal.service';
import { SessionService } from '@app/core/services/session.service';

type Status = 'submitted' | 'pending';

@Component({
  selector: 'app-sms-confirmation-modal',
  templateUrl: './sms-confirmation-modal.component.html',
  styleUrls: ['./sms-confirmation-modal.component.scss'],
})
export class SmsConfirmationModalComponent implements OnInit, OnDestroy {
  @Input() type: SmsConfirmationType;
  status: Status;
  qrCode: string;
  code: string;
  form: UntypedFormGroup;
  input: IFormInput = {
    formControlNameValue: 'code',
    unlabelled: true,
    label: '',
    placeholder: 'MSG_ENTER_CODE_2',
    type: 'password',
  };
  disableButton = true;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly alertModalService: AlertModalService,
    private readonly googleAuthenticationService: GoogleAuthenticationService,
    private readonly sessionService: SessionService,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService
  ) {}

  close() {
    this.activeModal.close();
  }

  get submitted(): boolean {
    return this.status === 'submitted';
  }

  get pending(): boolean {
    return this.status === 'pending';
  }

  get active(): boolean {
    return this.type === 'activation';
  }

  get submitButtonLabel(): string {
    return this.active ? 'C_A_ACTIVATE' : 'C_A_DEACTIVATE';
  }

  get title(): string {
    return this.active
      ? this.translateService.instant('MSG_ACTIVATE_GA_TITLE')
      : this.translateService.instant('MSG_DEACTIVATE_GA_TITLE');
  }

  submit() {
    this.status = 'submitted';
    const { valid, value } = this.form;
    if (valid) {
      this.sendConfirmationCode(value.code);
    }
  }

  activate() {
    this.status = 'pending';
    this.googleAuthenticationService
      .activate()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.close();
          this.clearStatus();
        }),
        catchError(() => {
          this.clearStatus();

          return EMPTY;
        })
      )
      .subscribe();
  }

  closeModalAfterminute() {
    setTimeout(() => {
      this.close();
    }, 60000);
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      code: new UntypedFormControl(null, [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  copyCode() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastrService.info(this.translateService.instant('M_KEY_COPIED'));
  }

  private sendConfirmationCode(code: string) {
    this.status = 'pending';
    this.active ? this.confirmActivation(code) : this.confirmDeactivation(code);
  }

  private confirmActivation(code: string) {
    this.googleAuthenticationService
      .confirmActivation(code)
      .pipe(
        takeUntil(this.destroy$),
        tap((qrCode) => {
          this.qrCode = qrCode || undefined;
          this.code = this.extractSecretKey(this.qrCode);
          this.clearStatus();
          this.closeModalAfterminute();
        }),
        catchError(() => {
          this.clearStatus();

          return EMPTY;
        })
      )
      .subscribe();
  }

  private confirmDeactivation(code: string) {
    this.googleAuthenticationService
      .confirmDeactivation(code)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.close();
          this.deactivationSuccessAndLogout();
        }),
        catchError(() => {
          this.clearStatus();

          return EMPTY;
        })
      )
      .subscribe();
  }

  private clearStatus() {
    this.status = undefined;
  }

  private extractSecretKey(encodedUri: string): string {
    const uriDec = decodeURIComponent(encodedUri);
    const regex = new RegExp('[?&]' + 'secret' + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(uriDec);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  private deactivationSuccessAndLogout() {
    this.alertModalService.success(
      ['PPC_M_SUCCESS_GOOGLE_AUTH_DEACTIVATION', 'PPC_M_SUCCESS_PASSWORD_SECURITY'],
      '',
      'C_A_LOGOUT',
      () => this.logout(),
      [],
      () => this.logout()
    );
  }

  private logout(): void {
    this.sessionService.setIsSessionTimeOut(true);
    this.sessionService.logout(false);
  }
}
