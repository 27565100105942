import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFormInput } from '@app/interfaces/forms.interface';
import { MAX_FILE_SIZE } from '@app/shared/constants/common.constants';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseInputComponent } from '../base-input.component';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent extends BaseInputComponent {
  @Input() options: IFormInput;
  @Input() isSubmitted: boolean;
  @Input() formGroup: UntypedFormGroup;
  isPDF = false;
  disabled = false;

  get image(): string {
    return this.formGroup.get(`${this.options.formControlNameValue}`).value || null;
  }

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService
  ) {
    super();
    this.formControlType = 'file';
  }

  loadFileBase64(file: string) {
    this.formGroup.patchValue({
      [`${this.options.formControlNameValue}`]: file,
    });
  }

  setDisable(disable: boolean) {
    this.disabled = disable;
  }

  onFileChange(event) {
    const reader = new FileReader();
    const validTypes = this.options.accept ? this.options.accept.split(',') : [];

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      const isValidType =
        validTypes.length === 0 || validTypes.some((type) => file.type.match(type.trim()));

      if (isValidType) {
        if (file.size <= MAX_FILE_SIZE) {
          this.isPDF = file.type === 'application/pdf';
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.formGroup.patchValue({
              [`${this.options.formControlNameValue}`]: reader.result,
            });

            this.cd.markForCheck();
          };
        } else {
          event.target.value = null;
          this.toastrService.error(this.translateService.instant('C_A_ADD_IMAGE_MAX_SIZE_ADVISE'));
        }
      } else {
        event.target.value = null;
        this.toastrService.error(this.translateService.instant('C_A_INVALID_FILE_TYPE'));
      }
    }
  }
}
