import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/interfaces/api-response.interface';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private readonly CONTACT_PHONE_URL = `${environment.apiBasePath}/online/common/contact/phone`;
  private contactPhone: string;

  constructor(private readonly httpClient: HttpClient) {}

  get currentContactPhone(): Observable<string> {
    return this.contactPhone ? of(this.contactPhone) : this.fetchContactPhone();
  }

  fetchContactPhone(): Observable<string> {
    return this.httpClient.get<ApiResponse<string>>(`${this.CONTACT_PHONE_URL}`).pipe(
      map((res) => res.Data),
      tap((res) => (this.contactPhone = res))
    );
  }

  fileExists(url: string): Observable<boolean> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  getWhatsappContac(iso3: string): string {
    switch (iso3) {
      case 'ESP':
        return '+34951015501';
      case 'GBR':
        return '+447916810777';
      case 'COD':
        return '+243846504669';
      case 'BEL':
        return '+32471263854';
      case 'ITA':
        return '+393386593433';
      case 'NLD':
        return '+31614734963';
      case 'FRA':
        return '+33789299039';
      default:
        return this.contactPhone;
    }
  }
}
