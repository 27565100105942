import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import {
  IdDocComplianceType,
  IdDocComplianceTypeResponse,
  IdDocType,
} from '@app/interfaces/id-doc-response.interface';
import { AccountsService } from '@app/services/accounts.service';
import { ApiResponse } from '@app/interfaces/api-response.interface';
import { BankExthand } from '@app/interfaces/exthand-banks.interface';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  private readonly EXTHAND_BANKS = `${environment.apiBasePath}/online/transfer/payment/exthand/banks`;
  private readonly ADDITIONAL_DOCUMENT_TYPES_URL = `${environment.apiBasePath}/account/compliancedoc/types`;
  private readonly _exthandBanks = new BehaviorSubject<BankExthand[]>([]);

  private docTypes: IdDocType[];
  private additionalDocTypes: IdDocComplianceType[];

  readonly exthandBanks$ = this._exthandBanks.asObservable();

  constructor(
    private readonly accountsService: AccountsService,
    private readonly httpClient: HttpClient
  ) {}

  getExthandBanks(): Observable<BankExthand[]> {
    if (this._exthandBanks.getValue().length) {
      return this.exthandBanks$.pipe(take(1));
    }

    return this.httpClient.get<ApiResponse<BankExthand[]>>(this.EXTHAND_BANKS).pipe(
      catchError(() =>
        of({
          Data: [
            {
              Connector: 33,
              Fullname: 'VDK',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 968,
              Fullname: 'Argenta',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 5,
              Fullname: 'AXA Bank',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 1209,
              Fullname: 'Banx',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 1672,
              Fullname: 'BBVA',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 14,
              Fullname: 'Belfius',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 1067,
              Fullname: 'BeoBank',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 2,
              Fullname: 'BNP PARIBAS FORTIS',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 1195,
              Fullname: 'BNP PARIBAS FORTIS Business',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 7,
              Fullname: 'BPost Bank',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 1372,
              Fullname: 'Bunq',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 30,
              Fullname: 'CBC',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 123,
              Fullname: 'CBP Quilvest S.A.',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 28,
              Fullname: 'Crelan',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 6,
              Fullname: 'EuropaBank',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 10,
              Fullname: 'Fintro',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 11,
              Fullname: 'Hello Bank',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 1,
              Fullname: 'ING',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 15,
              Fullname: 'KBC',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 31,
              Fullname: 'KBC Brussels',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 3,
              Fullname: 'KeyTrade Bank',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 1350,
              Fullname: 'N26',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 8,
              Fullname: 'Nagelmackers',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 1309,
              Fullname: 'Revolut',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 12,
              Fullname: 'Triodos (French)',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 124,
              Fullname: 'Triodos (Nederlands)',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
            {
              Connector: 4,
              Fullname: 'Van Breda',
              CountryIso: 'BE',
              Status: 0,
              Group: '',
            },
          ],
        })
      ),
      map(({ Data }) => Data),
      tap((exthandBanks) => this._exthandBanks.next(exthandBanks))
    );
  }

  fetchAdditionalDocumentTypes(): Observable<IdDocComplianceType[]> {
    return this.httpClient
      .get<IdDocComplianceTypeResponse>(`${this.ADDITIONAL_DOCUMENT_TYPES_URL}`)
      .pipe(
        map((response: IdDocComplianceTypeResponse) => response.Data),
        tap((data) => (this.additionalDocTypes = data))
      );
  }

  fetchDocumentTypes(productType = ''): Observable<IdDocType[]> {
    const observable = productType
      ? this.accountsService.getIdDocTypesByProduct(productType)
      : this.accountsService.getGetIdDocTypes();

    return observable.pipe(
      map((response) => response.Data),
      tap((data) => (this.docTypes = data))
    );
  }
}
