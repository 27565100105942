import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

/* eslint-disable */
declare let fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  private enabled: boolean;
  private id: string | null;

  constructor() {
    const { facebookPixel } = environment.services;
    this.enabled = facebookPixel.enabled;
    this.id = facebookPixel.id;
  }

  init(): void {
    if (this.enabled) {
      const pixelId = this.id;

      (function (f: any, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function (...args: any[]) {
          n.callMethod ? n.callMethod(...args) : n.queue.push(args);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', pixelId);
      fbq('track', 'PageView');
    } else {
      console.log('Facebook Pixel is disabled in this environment.');
    }
  }

  addNoScriptPixel(): void {
    if (this.enabled) {
      const noscript = document.createElement('noscript');
      const img = document.createElement('img');
      img.alt = '';
      img.height = 1;
      img.width = 1;
      img.src = `https://www.facebook.com/tr?id=${this.id}&ev=PageView&noscript=1`;
      noscript.appendChild(img);
      document.head.appendChild(noscript);
    }
  }

  trackCustomEvent(event): void {
    if (this.enabled) {
      fbq('trackCustom', event);
    }
  }
}
