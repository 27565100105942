import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/core/services/account.service';
import { fadeIn, fadeOut } from '@app/shared/animations/fade.animation';
import { Account } from '@app/interfaces/account/account.interface';
import { BaseComponent } from '@app/shared/components/base/base.component';

@Component({
  selector: 'app-account-modal-user',
  templateUrl: './account-modal-user.component.html',
  styleUrls: ['./account-modal-user.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class AccountModalUserComponent extends BaseComponent implements OnInit {
  account: Account;

  constructor(private readonly accountService: AccountService) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.observeAccount();
  }

  get address() {
    const { Street, StreetNumber, PostalCode, City } = this.account.Address;
    const addressParts = [Street, StreetNumber, PostalCode, City];

    return addressParts.every((x) => !!x)
      ? `${Street} ${StreetNumber}, ${PostalCode}, ${City.Name}`
      : '-';
  }

  private observeAccount() {
    this.subscription.add(
      this.accountService.fetchAccount().subscribe((res) => {
        this.account = res;
        this.loading = false;
      })
    );
  }
}
