<nav ngbNav #nav="ngbNav" class="nav-tabs">
  <ng-container ngbNavItem>
    <a ngbNavLink>{{ 'C_PERSONAL_DATA' | translate }}</a>
    <ng-template ngbNavContent>
      <div class="nav-tabs__item" @fadeIn @fadeOut>
        <app-account-modal-personal-data></app-account-modal-personal-data>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink>{{ 'C_F_CONTACT_DATA' | translate }}</a>
    <ng-template ngbNavContent>
      <div class="nav-tabs__item" @fadeIn @fadeOut>
        <app-account-modal-additional-data></app-account-modal-additional-data>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink>{{ 'C_F_IDENTITY_DOCUMENTS' | translate }}</a>
    <ng-template ngbNavContent>
      <div class="nav-tabs__item" @fadeIn @fadeOut>
        <app-account-modal-identity-documents></app-account-modal-identity-documents>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink>{{ 'C_A_COMPLIANCE_FORM' | translate }}</a>
    <ng-template ngbNavContent>
      <div class="nav-tabs__item" @fadeIn @fadeOut>
        <app-account-modal-additional-documents></app-account-modal-additional-documents>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink>{{ 'C_SECURITY' | translate }}</a>
    <ng-template ngbNavContent>
      <div class="nav-tabs__item" @fadeIn @fadeOut>
        <app-account-modal-security></app-account-modal-security>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink>{{ 'C_F_PASSWORD' | translate }}</a>
    <ng-template ngbNavContent>
      <div class="nav-tabs__item" @fadeIn @fadeOut>
        <app-account-modal-password></app-account-modal-password>
      </div>
    </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav"></div>
