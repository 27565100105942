import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpErrorInterceptor } from '@app/core/interceptors/http-error.interceptor';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CitiesService } from '@app/services/cities.service';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';
import { City } from '@app/interfaces/address.interface';
import { CatalogCountry } from '@app/interfaces/country-information.interface';

@Component({
  selector: 'app-city-picker',
  templateUrl: './city-picker.component.html',
})
export class CityPickerComponent extends BaseInputComponent implements OnInit {
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @Input() countryId: number;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  cities: City[] = [];

  searchFailed: boolean;
  loading: boolean;

  constructor(private readonly citiesService: CitiesService) {
    super();
    this.formControlType = 'cityPicker';
    this.cities = [];
  }

  search = (text$: Observable<string>): Observable<CatalogCountry[]> => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((res) => res.length >= 3),
      tap(() => (this.loading = true)),
      switchMap((term) =>
        this.citiesService.getCityBySearchTerm(this.countryId, term).pipe(
          map((res) => res.Data),
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;

            return of([]);
          })
        )
      ),
      tap(() => (this.loading = false))
    );
  };

  formatter(city) {
    return city.Name;
  }

  ngOnInit() {
    this.parse();
    HttpErrorInterceptor.showErrorMessage = false;
  }

  private parse() {
    const control = this.formGroup.controls[this.options.formControlNameValue];
    const assignValue = (value) => {
      if (value && typeof value === 'number') {
        const existingCity = this.cities.find((x) => x.Id === value);
        control.patchValue(existingCity);
      }
    };

    assignValue(control.value);
    control.valueChanges.subscribe((value) => {
      assignValue(value);
    });
  }
}
