import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomersService } from '@app/services/customers.service';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { PROFILE_IMAGE_PLACEHOLDER } from '@app/shared/constants/profile-images.constants';
import { getFileExtFromBase64 } from '@app/shared/utils/file.utils';

@Component({
  selector: 'app-preview-additional-document-image',
  templateUrl: './preview-additional-document-image.component.html',
  styleUrls: ['./preview-additional-document-image.component.scss'],
})
export class PreviewAdditionalDocumentImageComponent extends BaseComponent implements OnInit {
  @Input() documentId: number;
  @Input() resize = false;
  @Output() readonly source = new EventEmitter<string>();
  isPDF = false;
  image: Observable<string>;
  readonly placeholder = PROFILE_IMAGE_PLACEHOLDER;

  constructor(private readonly customerService: CustomersService) {
    super();
  }

  ngOnInit() {
    this.image = this.customerService.getCustomerIdAdditionalDocImage(this.documentId);
    this.subscription.add(
      this.image.subscribe((res) => {
        const b64WithoutPrefix = res.split(',')[1];
        const fileExt = getFileExtFromBase64(b64WithoutPrefix);
        this.isPDF = fileExt === 'pdf';
        this.pending = true;
        this.source.emit(res);
      })
    );
  }

  afterLoad() {
    this.pending = false;
  }
}
