import { InternationalTopUpService } from '@app/core/services/international-top-up.service';
import { Observable, race, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CatalogCountry } from '@app/interfaces/country-information.interface';

export function CacheCatalogCountry(ttl = 50000) {
  let lastCallArguments: CatalogCountry[] = [];

  return (
    target: InternationalTopUpService,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    const originalFunction = descriptor.value;

    target[`${propertyKey}_cached`] = new ReplaySubject(1, ttl);

    descriptor.value = function (...args) {
      let argsNotChanged = true;

      for (let i = 0; i < lastCallArguments.length; i++) {
        argsNotChanged = argsNotChanged && lastCallArguments[i] === args[i];
      }

      if (!argsNotChanged) {
        this[`${propertyKey}_cached`] = new ReplaySubject(1, ttl);
      }

      lastCallArguments = args;

      const req: Observable<CatalogCountry[]> = originalFunction.apply(this, args).pipe(
        tap((response) => {
          this[`${propertyKey}_cached`].next(response);
        })
      );

      return race(this[`${propertyKey}_cached`], req);
    };

    return descriptor;
  };
}
