export const COUNTRIES = [
  'belgium',
  'france',
  'spain',
  'italy',
  'netherlands',
  'united-kingdom',
  'congo-rdc',
  'portugal',
];
export const LANGUAGES = ['fr', 'en', 'es', 'it', 'nl', 'uk'] as const;
export type Countries = (typeof COUNTRIES)[number];
export type Languages = (typeof LANGUAGES)[number];

export type UrlRules = {
  [keyC in Countries]: {
    [keyL in Languages]: string;
  };
};

/**
 * TODOS los paises tienen ES FR EN
 *
 * Italia tiene + IT
 *
 * Paises bajos tiene + NL
 *
 * Belgica España y Francia tienen + uk
 */

/**
 * Terminos y condiciones - no UK
 *
 * Cookies - si UK España Belgica Francia
 *
 * Privacidad - no UK
 */
