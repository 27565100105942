import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CitiesService } from '@app/services/cities.service';
import { City } from '@app/interfaces/address.interface';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';
import {
  BehaviorSubject,
  catchError,
  concat,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  of,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-city-picker-mt',
  templateUrl: './city-picker-mt.component.html',
  styleUrls: ['./city-picker-mt.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule, TranslateModule],
})
export class CityPickerMtComponent extends BaseInputComponent implements OnInit, OnChanges {
  @ViewChild('select', { static: true }) select: NgSelectComponent;

  @Input() countryId: number;
  @Input() noFoundCode: string;
  @Input() loadingText: string;
  cityInput$ = new BehaviorSubject<string>(null);
  isFocused = false;
  selectedCity: City;
  cities: City[] = [];

  cities$ = concat(
    of([]),
    this.cityInput$.pipe(
      distinctUntilChanged(),
      filter((term) => !!term),
      tap(() => (this.loading = true)),
      debounceTime(1000),
      switchMap((term: string) =>
        this.citiesService.getCityBySearchTerm(this.countryId, term.trim()).pipe(
          map((res) => res.Data),
          catchError(() => of([])),
          finalize(() => (this.loading = false))
        )
      )
    )
  );

  constructor(
    private readonly translateService: TranslateService,
    private readonly citiesService: CitiesService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    super();
    this.formControlType = 'searchablePicker';
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        takeWhile(() => !this.control.dirty),
        map((city) => city?.Name)
      )
      .subscribe({
        next: (value) => {
          this.cityInput$.next(value);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { loading } = changes;

    if (loading?.currentValue) {
      this.isFocused = false;
      this.select.detectChanges();
      this.cdRef.detectChanges();
    }
  }

  onBlur(): void {
    this.onFocus();
  }

  onFocus(): void {
    this.isFocused = !this.isFocused;
  }

  getPlaceholder(): string {
    return this.value || this.loading
      ? ''
      : this.translateService.instant(
          this.placeholder ? this.placeholder : 'HT_M_SELECT_CITY_CHARACTERES'
        );
  }
}
