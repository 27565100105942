import { Component, Input } from '@angular/core';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  standalone: true,
  imports: [NgbModalModule, TranslateModule],
})
export class ModalHeaderComponent {
  @Input() title: string;
  @Input() registerGoogleAnalitycs = 'false';

  constructor(
    private readonly ngbModalService: NgbModal,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {}

  close() {
    if (this.registerGoogleAnalitycs === 'signup-form-cc') {
      this.googleAnalyticsService.eventEmitter('', 'signup-form-cc', 'click-close');
    }
    this.ngbModalService.dismissAll();
  }
}
