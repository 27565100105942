import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const storageKey = 'currencyHidden';

@Injectable({
  providedIn: 'root',
})
export class CurrencyVisibilityService {
  private currencyHiddenSubject = new BehaviorSubject<boolean>(this.getValue());
  currencyHidden$ = this.currencyHiddenSubject.asObservable();

  private getValue(): boolean {
    return localStorage.getItem(storageKey) === 'true';
  }
  toggle(): void {
    const newValue = !this.currencyHiddenSubject.value;
    localStorage.setItem(storageKey, newValue.toString());
    this.currencyHiddenSubject.next(newValue);
  }
}
