import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionBasedService {
  clearData$: Subject<void> = new Subject<void>();

  clearData() {
    this.clearData$.next();
  }
}
