import { Component, Input } from '@angular/core';
import { AccountService } from '@app/core/services/account.service';
import { CreateAdditionalDocumentRequest } from '@app/interfaces/create-document-request.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-add-additional-document-modal',
  templateUrl: './add-additional-document-modal.component.html',
  styleUrls: [
    './add-additional-document-modal.component.scss',
    '../account-modal-additional-documents.component.scss',
  ],
})
export class AddAdditionalDocumentModalComponent {
  @Input() docType: string;
  @Input() title = 'C_A_ADD_IMAGE';
  pending = true;
  loading = false;
  flag = true;
  isNextStep = false;
  onNextStep = 1;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly accountService: AccountService
  ) {}

  async save(data): Promise<void> {
    this.pending = true;
    this.loading = true;
    let result = false;
    const hasMoreThanOneFile = Object.keys(data.files ?? []).length > 1;
    if (hasMoreThanOneFile) {
      const results = [];
      for (const key in data.files) {
        const file = data.files[key];
        const res = await this.createAdditionalDocument({
          Type: data.Type,
          file1: file,
        });
        results.push(res);
      }
      result = results.every(Boolean);
    } else {
      result = await this.createAdditionalDocument(data);
    }
    if (result) {
      this.pending = false;
      this.loading = false;
      this.close(data);
    }
  }

  createAdditionalDocument(data: CreateAdditionalDocumentRequest): Promise<boolean> {
    return firstValueFrom(this.accountService.createAdditionalDocument(data).pipe(map((v) => !!v)));
  }

  unlockButton(formIsValid) {
    this.flag = formIsValid;
  }

  close(data) {
    this.activeModal.close(data);
  }

  dismiss() {
    this.activeModal.dismiss();
  }
  changeButtons(isNext: boolean) {
    this.isNextStep = isNext;
  }
  nextStep() {
    this.onNextStep++;
    if (this.onNextStep === 3) {
      this.onNextStep = 1;
      this.isNextStep = false;
    }
  }
}
