import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-arrow-button',
  templateUrl: './arrow-button.component.html',
  styleUrls: ['./arrow-button.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ArrowButtonComponent {
  @Input() color: 'primary' | 'warning' = 'primary';
  @Input() callToAction = '';
  @Input() disabled = false;
  @Input() type: 'button' | 'submit' = 'button';
  @Output() Click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  handleClick(e: MouseEvent): void {
    this.Click.emit(e);
  }
}
