import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CountryService } from '@app/core/services/country.service';
import { CatalogCountry } from '@app/interfaces/country-information.interface';

@Injectable({
  providedIn: 'root',
})
export class CountryResolve implements Resolve<Observable<CatalogCountry[]>> {
  constructor(private readonly countryService: CountryService) {}

  resolve(): Observable<CatalogCountry[]> {
    return this.countryService.fetchCountries().pipe(catchError(() => EMPTY));
  }
}
