import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerDocument } from '@app/interfaces/customer.interface';

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss'],
})
export class PreviewDocumentModalComponent {
  @Input() document: CustomerDocument;

  constructor(private readonly activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.close();
  }
}
