import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { SessionService } from '@app/core/services/session.service';

@Component({
  selector: 'app-pwa-login',
  template: ``,
})
export class PwaLoginComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionService: SessionService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    localStorage.setItem('pwa-session', 'true');

    const routeParams = this.route.snapshot.paramMap;
    const queryParams = this.route.snapshot.queryParams;
    this.sessionService.startNewPWASession(
      routeParams.get('apisectoken'),
      routeParams.get('lang'),
      queryParams.os ? queryParams.os : null
    );

    this.router.navigate(['/']);
  }
}
