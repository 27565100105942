<div class="container">
  <app-infinite-scroll-loader
    *ngIf="(isActive$ | async) === undefined || loading; else content"></app-infinite-scroll-loader>
  <ng-template #content>
    <div class="row font-slim">
      <div class="col-md-12">
        <h4>Google Authenticator</h4>
        <hr />
        <div>
          <div *ngIf="isActive$ | async; else disabled">
            <p>{{ 'MSG_GOOGLE_AUTH_ENABLED' | translate }}</p>
            <p>{{ 'MSG_GOOGLE_AUTH_ENABLED_INFO_1' | translate }}</p>
            <div class="text-center">
              <app-button
                color="primary"
                [pending]="pending"
                [type]="'button'"
                (click)="requestDeactivation()">
                {{ 'C_A_DEACTIVATE' | translate }}
              </app-button>
            </div>
          </div>
          <ng-template #disabled>
            <div>
              <p>{{ 'MSG_GOOGLE_AUTH_DISABLED' | translate }}</p>
              <div class="text-center mt-3 mb-3">
                <app-button
                  color="primary"
                  [pending]="pending"
                  [type]="'button'"
                  (click)="requestActivation()">
                  {{ 'C_A_ACTIVATE' | translate }}
                </app-button>
              </div>
              <p>{{ 'MSG_GOOGLE_AUTH_DISABLED_INFO_1' | translate }}</p>
              <p>{{ 'MSG_GOOGLE_AUTH_DISABLED_INFO_2' | translate }}</p>
            </div>
          </ng-template>
          <div class="row mt-3">
            <div class="col-md-12 text-center">
              <p>{{ 'MSG_NEED_HELP' | translate }}</p>
              <a class="btn-link" [href]="guidePath" target="_self">
                <i class="icon icon-file-pdf-download icon-98 m-2"></i>
                <br />
                <span>{{ 'C_A_DOWNLOAD_GAUTH_GUIDE' | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
