import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, merge } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-date-inputs',
  templateUrl: './date-inputs.component.html',
  styleUrls: ['./date-inputs.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
})
export class DateInputsComponent extends BaseInputComponent implements OnInit {
  @Input() maxDate: string;
  @Input() minDate: string;
  @Input() isExpiryDate: boolean;
  @Input() isIssuingDate: boolean;

  dayObs$: Observable<string>;
  monthObs$: Observable<string>;
  yearObs$: Observable<string>;

  inputsConfig = [
    {
      name: 'day',
      translateCode: 'C_A_DAY',
      placeholder: 'C_F_DATE_FORMAT_DAY',
      todalDigits: 2,
    },
    {
      name: 'month',
      translateCode: 'C_A_MONTH',
      placeholder: 'C_F_DATE_FORMAT_MONT',
      todalDigits: 2,
    },
    {
      name: 'year',
      translateCode: 'C_A_YEAR',
      placeholder: 'C_F_DATE_FORMAT_YEAR',
      todalDigits: 4,
    },
  ];
  onlyNumbers = new RegExp('^[0-9]*$');
  dateFormGroup: FormGroup;
  currentIndexInputFocus: number;
  isInvalidValue: boolean;
  _requiredError: boolean;
  isValueError: boolean;
  dateFormat: Date;

  get isInvalidBirthdate(): boolean {
    return this.control && this.control.errors && this.control.errors.underLegalAge;
  }

  get isInvalidDate(): boolean {
    return (
      this.control &&
      this.control.errors &&
      (this.control.errors.invalid || this.control.errors.minDate)
    );
  }

  constructor(public readonly translateService: TranslateService) {
    super();
    this.formControlType = 'input';
  }

  ngOnInit(): void {
    this.initform();
    this.observeDay();
    this.observeMonth();
    this.observeYear();
    this.observeControl();
  }

  private patchValue() {
    if (this.control && this.control.value) {
      const selectedDate = new Date(this.control.value);
      this.dateFormGroup.patchValue({
        day: selectedDate.getDate(),
        month: selectedDate.getMonth() + 1,
        year: selectedDate.getFullYear(),
      });
    }
  }

  keyPressNumbers(event) {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFocusOut(controlName: string) {
    this.currentIndexInputFocus = null;
    this.applyFormatting(controlName);
    this.checkValidValue();
    this.control.markAsTouched();
  }

  onFocus(index: number) {
    this.currentIndexInputFocus = index;
  }

  private checkValidValue() {
    const conditions = [
      this.dateFormGroup,
      this.dateFormGroup.invalid,
      this.dateFormGroup.get('day').value,
      this.dateFormGroup.get('month').value,
      this.dateFormGroup.get('year').value,
    ];

    this.isInvalidValue = conditions.every((x) => !!x);

    if (this.dateFormGroup.valid) {
      const month = Number(this.dateFormGroup.get('month').value) - 1;
      const isValidDate = this.isValidDate(
        this.dateFormGroup.get('year').value,
        month,
        this.dateFormGroup.get('day').value
      );
      if (!isValidDate) {
        this.control.setErrors({ invalid: true });
      } else {
        this.dateFormGroup.setErrors(null);
        this.control.patchValue(this.dateFormat);
      }
    } else {
      this._requiredError = this.hasValue();
    }
  }

  private isValidDate(year: number, month: number, day: number) {
    this.dateFormat = new Date(year, month, day);
    const monthIndex = Number(this.dateFormGroup.get('month').value) - 1;
    const currentDate = new Date();
    const conditions = [
      currentDate.getFullYear() === this.dateFormat.getFullYear(),
      currentDate.getMonth() + 1 === this.dateFormat.getMonth() + 1,
      currentDate.getDate() === this.dateFormat.getDate(),
    ];
    if (this.isExpiryDate) {
      const isCurrentDate = conditions.every((x) => !!x);
      if (isCurrentDate) {
        return true;
      }

      if (this.dateFormat < currentDate) {
        return false;
      }
    }

    if (this.isIssuingDate) {
      if (this.dateFormat > currentDate) {
        return false;
      }

      const isCurrentDate = conditions.every((x) => !!x);
      if (isCurrentDate) {
        return true;
      }
    }

    if (
      this.dateFormat.getFullYear() == year &&
      this.dateFormat.getMonth() == monthIndex &&
      this.dateFormat.getDate() == day
    ) {
      return true;
    }

    return false;
  }

  private applyFormatting(controlName: string) {
    const value = Number(this.dateFormGroup.get(controlName).value);
    if (controlName !== 'year' && value >= 1 && value <= 9) {
      this.dateFormGroup.get(controlName).patchValue(`0${value}`);
    }
  }

  private observeDay() {
    this.subscription.add(
      this.dayObs$
        .pipe(
          map(Number),
          filter((value) => !!value),
          tap((res) => this.greaterThanError(res, 31)),
          debounceTime(200)
        )
        .subscribe((value) => {
          if (value > 31) {
            this.dateFormGroup.controls.day.patchValue(null);
          }
        })
    );
  }

  private observeMonth() {
    this.subscription.add(
      this.monthObs$
        .pipe(
          map(Number),
          filter((value) => !!value),
          tap((res) => this.greaterThanError(res, 12)),
          debounceTime(200)
        )
        .subscribe((value) => {
          if (value > 12) {
            this.dateFormGroup.controls.month.patchValue(null);
          }
        })
    );
  }

  private observeYear() {
    this.subscription.add(
      this.yearObs$
        .pipe(
          map(Number),
          tap(() => {
            this.isValueError = false;
            this.isInvalidValue = false;
          }),
          filter((value) => !!value),
          tap((res) => this.greaterThanError(4, res.toString().length)),
          debounceTime(200)
        )
        .subscribe()
    );
  }

  private observeControl() {
    this.subscription.add(
      this.control.valueChanges.subscribe(() => {
        this.patchValue();
        this.applyFormatting('day');
        this.applyFormatting('month');
      })
    );

    this.subscription.add(
      merge(this.dayObs$, this.monthObs$, this.yearObs$).subscribe(() => {
        this._requiredError = false;
        if (this.haveValues()) return;
        this.setError();
      })
    );
  }

  private initform() {
    this.dateFormGroup = new FormGroup({
      day: new FormControl('', [Validators.required, Validators.maxLength(2)]),
      month: new FormControl('', [Validators.required, Validators.maxLength(2)]),
      year: new FormControl('', [Validators.required, Validators.minLength(4)]),
    });
    this.dayObs$ = this.dateFormGroup.controls.day.valueChanges;
    this.monthObs$ = this.dateFormGroup.controls.month.valueChanges;
    this.yearObs$ = this.dateFormGroup.controls.year.valueChanges;
  }

  private setError() {
    if (this.isValueError) return;
    this.control.setErrors(null);
    if (this.hasValue()) {
      this._requiredError = true;
    }
    if (this.noHaveValues()) {
      this._requiredError = false;
      this.control.patchValue(null);
    }
  }

  private greaterThanError(value: number, reference: number): void {
    this.isValueError = false;
    this.isInvalidValue = false;
    if (value > reference) {
      this.isValueError = true;
      this.isInvalidValue = true;
      this._requiredError = false;
    }
  }

  private hasValue(): boolean {
    return !!(
      this.dateFormGroup.controls.day.value ||
      this.dateFormGroup.controls.month.value ||
      this.dateFormGroup.controls.year.value
    );
  }
  private haveValues(): boolean {
    return !!(
      this.dateFormGroup.controls.day.value &&
      this.dateFormGroup.controls.month.value &&
      this.dateFormGroup.controls.year.value
    );
  }
  private noHaveValues(): boolean {
    return (
      !this.dateFormGroup.controls.day.value &&
      !this.dateFormGroup.controls.month.value &&
      !this.dateFormGroup.controls.year.value
    );
  }
}
