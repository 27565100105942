import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { Account } from '@app/interfaces/account/account.interface';
import { AppConfig } from '@app/app.config';
import { CatalogCountry } from '@app/interfaces/country-information.interface';
import { environment } from '@env/environment';
import { ScriptService } from '@app/core/services/script.service';

/* eslint-disable */
declare let fcWidget: any;

@Injectable({
  providedIn: 'root',
})
export class FreshChatService {
  private enabled: boolean;
  private url: string | null;
  private tokens: Object;
  private widgetUuid: Object;
  private isProduction: boolean;

  private renderer: Renderer2;
  private scriptElementFC: HTMLScriptElement;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    private readonly scriptService: ScriptService
  ) {
    const { freshChat } = environment.services;
    this.enabled = freshChat.enabled;
    this.url = freshChat.url;
    this.tokens = freshChat.tokens;
    this.widgetUuid = freshChat.widgetUuid;
    this.isProduction = environment.production;
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  async init(country: CatalogCountry, account: Account): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.enabled) {
        const src = `${this.url}/js/widget.js?t=${Date.now()}`;

        this.scriptElementFC = this.scriptService.loadJsScript(this.renderer, src, 'fc-script');
        this.scriptElementFC.onload = async () => {
          await this.initFreshChat(country, account);
          resolve();
        };
        this.scriptElementFC.onerror = (err) => {
          console.log('FreshChat script error', err);
          reject();
        };
      } else {
        console.log('FreshChat is disabled in this environment.');
        resolve();
      }
    });
  }

  private initFreshChat(country: CatalogCountry, account: Account): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const { Id, Iso2 } = country;
        const { Name, LastName, Email, Telephone, Code } = account;
        const hideChatButton = window.innerWidth < 766;

        await fcWidget.init({
          token: this.tokens[`${this.isProduction ? Iso2 : ''}`] ?? this.tokens['default'],
          host: this.url,
          widgetUuid: this.widgetUuid[`${Iso2}`] ?? this.widgetUuid['default'],
          externalId: `${Id}-${Code}`,
          firstName: `${Name}`,
          lastName: `${LastName}`,
          email: `${Email}`,
          phone: `${Telephone}`,
          config: {
            headerProperty: {
              appName: AppConfig.appName,
              hideChatButton,
            },
          },
        });

        await this.update();
        resolve();
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  }
  async update() {
    if (this.enabled && window['fcWidget']) {
      await fcWidget.setConfig({
        headerProperty: {
          hideChatButton: window.innerWidth < 766,
        },
      });
    }
  }

  open() {
    if (this.enabled && window['fcWidget']) {
      fcWidget?.isInitialized && fcWidget.open();
    }
  }
}
