import { environment } from '@env/environment';
import { UrlRules } from '../shared/pipes/urls-pipes/urls.conts';

const { imtWordpressBaseUrl } = environment;
enum TermsURLByLanguage {
  FR = 'conditions-generales-d-utilisation',
  ES = 'condiciones-y-terminos-de-uso',
  EN = 'legal-notes-and-terms-of-use',
  IT = 'note-legali-e-condizioni-di-uso',
  NL = 'algemene-voorwaarden',
}

/**
 * ? Belgium & France & Spain*:
 * * ES EN FR
 *
 * ? Congo RDC & United Kingdom:
 * * ES EN FR
 *
 * ? Italy:
 * * ES EN FR IT
 *
 * ? Netherlands:
 * * ES EN FR NL
 *
 *  */

export const TermsUrlRules: UrlRules = {
  belgium: {
    fr: `${imtWordpressBaseUrl}belgium/${TermsURLByLanguage.FR}/`,
    es: `${imtWordpressBaseUrl}belgium/es/${TermsURLByLanguage.ES}/`,
    en: `${imtWordpressBaseUrl}belgium/en/${TermsURLByLanguage.EN}/`,
    // ! Missing URL default EN
    uk: `${imtWordpressBaseUrl}belgium/en/${TermsURLByLanguage.EN}/`,
    it: `${imtWordpressBaseUrl}belgium/en/${TermsURLByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}belgium/en/${TermsURLByLanguage.EN}/`,
  },
  'congo-rdc': {
    fr: `${imtWordpressBaseUrl}congo-rdc/${TermsURLByLanguage.FR}/`,
    es: `${imtWordpressBaseUrl}congo-rdc/es/${TermsURLByLanguage.ES}/`,
    en: `${imtWordpressBaseUrl}congo-rdc/en/${TermsURLByLanguage.EN}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}congo-rdc/en/${TermsURLByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}congo-rdc/en/${TermsURLByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}congo-rdc/en/${TermsURLByLanguage.EN}/`,
  },
  spain: {
    es: `${imtWordpressBaseUrl}spain/${TermsURLByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}spain/fr/${TermsURLByLanguage.FR}/`,
    en: `${imtWordpressBaseUrl}spain/en/${TermsURLByLanguage.EN}/`,
    // ! Missing URL default EN
    uk: `${imtWordpressBaseUrl}spain/en/${TermsURLByLanguage.EN}/`,
    it: `${imtWordpressBaseUrl}spain/en/${TermsURLByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}spain/en/${TermsURLByLanguage.EN}/`,
  },
  italy: {
    it: `${imtWordpressBaseUrl}italy/${TermsURLByLanguage.IT}/`,
    fr: `${imtWordpressBaseUrl}italy/fr/${TermsURLByLanguage.FR}/`,
    es: `${imtWordpressBaseUrl}italy/es/${TermsURLByLanguage.ES}/`,
    en: `${imtWordpressBaseUrl}italy/en/${TermsURLByLanguage.EN}/`,
    // ! Missing URL default EN
    nl: `${imtWordpressBaseUrl}italy/en/${TermsURLByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}italy/en/${TermsURLByLanguage.EN}/`,
  },
  netherlands: {
    nl: `${imtWordpressBaseUrl}netherlands/${TermsURLByLanguage.NL}/`,
    es: `${imtWordpressBaseUrl}netherlands/es/${TermsURLByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}netherlands/fr/${TermsURLByLanguage.FR}/`,
    en: `${imtWordpressBaseUrl}netherlands/en/${TermsURLByLanguage.EN}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}netherlands/en/${TermsURLByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}netherlands/en/${TermsURLByLanguage.EN}/`,
  },
  'united-kingdom': {
    en: `${imtWordpressBaseUrl}united-kingdom/${TermsURLByLanguage.EN}/`,
    es: `${imtWordpressBaseUrl}united-kingdom/es/${TermsURLByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}united-kingdom/fr/${TermsURLByLanguage.FR}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}united-kingdom/${TermsURLByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}united-kingdom/${TermsURLByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}united-kingdom/${TermsURLByLanguage.EN}/`,
  },
  france: {
    fr: `${imtWordpressBaseUrl}france/${TermsURLByLanguage.FR}/`,
    en: `${imtWordpressBaseUrl}france/en/${TermsURLByLanguage.EN}/`,
    es: `${imtWordpressBaseUrl}france/es/${TermsURLByLanguage.ES}/`,
    // ! Missing URL default EN
    uk: `${imtWordpressBaseUrl}france/en/${TermsURLByLanguage.EN}/`,
    it: `${imtWordpressBaseUrl}france/en/${TermsURLByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}france/en/${TermsURLByLanguage.EN}/`,
  },
  portugal: {
    en: `${imtWordpressBaseUrl}portugal/${TermsURLByLanguage.EN}/`,
    es: `${imtWordpressBaseUrl}portugal/es/${TermsURLByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}portugal/fr/${TermsURLByLanguage.FR}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}portugal/${TermsURLByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}portugal/${TermsURLByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}portugal/${TermsURLByLanguage.EN}/`,
  },
};
