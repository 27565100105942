import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { fadeIn, fadeOut } from '@app/shared/animations/fade.animation';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { ERRORS_CATALOG, FormStatus } from './errors.config';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  animations: [fadeIn, fadeOut],
  styleUrls: ['./errors.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class ErrorsComponent implements OnInit, OnChanges {
  @Input() formControlNameValue: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() isInvalid: boolean;
  @Input() requiredErrorText: string;
  @Input() dataTestId: string;

  isInvalid$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get errors() {
    const errors = this.formGroup.controls[this.formControlNameValue].errors;
    if (errors?.ngbDate) {
      return errors?.ngbDate;
    }
    return this.formGroup && this.formGroup.controls[this.formControlNameValue] && errors
      ? errors
      : null;
  }

  vm$: Observable<ValidationErrors>;

  ngOnInit(): void {
    this.vm$ = merge(
      this.isInvalid$,
      this.formGroup.controls[this.formControlNameValue].statusChanges
    ).pipe(
      map((status) => {
        const isErrorIncluded = this.errors
          ? Object.keys(this.errors).filter((key) => ERRORS_CATALOG.includes(key))
          : [];

        return (this.isInvalid || status === FormStatus.INVALID) && isErrorIncluded.length > 0
          ? this.errors
          : null;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isInvalid$.next(changes.isInvalid?.currentValue);
  }
}
