import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { EventPurchaseEcommerce } from '@app/shared/classes/event-purchase.class';

/* eslint-disable */
declare let gtag: Function;
declare const dataLayer: any[];

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private enabled: boolean;
  constructor() {
    const { googleAnalytics } = environment.services;
    this.enabled = googleAnalytics.enabled;
  }

  eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string = null,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (this.enabled) {
      try {
        if (gtag) {
          gtag('event', eventName, {
            event_category: eventCategory,
            event_action: eventAction,
            event_label: eventLabel,
            event_value: eventValue,
          });
        }
      } catch {
      } finally {
        // This Handle the situation where the User has an AdBlobk and gtag is not loaded
        // Proceed to send data with GTM 4
        dataLayer.push({
          event: 'GA4_Event',
          event_category: eventCategory,
          event_action: eventAction ?? null,
          event_label: eventLabel ?? null,
          event_value: eventValue ?? null,
        });
      }
    }
  }

  eventPurchase(purchase: EventPurchaseEcommerce) {
    if (this.enabled) {
      try {
        if (gtag) {
          gtag('event', 'purchase', {
            transaction_id: purchase.purchase.actionField.id,
            affiliation: purchase.purchase.actionField.affiliation,
            value: Number(purchase.purchase.actionField.revenue),
            currency: purchase.currencyCode,
            tax: null,
            shipping: Number(purchase.purchase.actionField.shipping),
            items: [
              {
                id: purchase.purchase.products[0].id,
                name: purchase.purchase.products[0].name,
                list_name: null,
                brand: purchase.purchase.products[0].brand,
                category: purchase.purchase.products[0].category,
                variant: null,
                list_position: null,
                quantity: 1,
                price: purchase.purchase.products[0].price,
                coupon: purchase.purchase.products[0].coupon,
              },
            ],
          });
        }
      } catch {
      } finally {
        // This Handle the situation where the User has an AdBlobk and gtag is not loaded
        // Proceed to send data with GTM 4
        dataLayer.push({
          event: 'purchase',
          transaction_id: purchase.purchase.actionField.id,
          affiliation: purchase.purchase.actionField.affiliation,
          value: Number(purchase.purchase.actionField.revenue),
          currency: purchase.currencyCode,
          tax: null,
          shipping: Number(purchase.purchase.actionField.shipping),
          items: [
            {
              id: purchase.purchase.products[0].id,
              name: purchase.purchase.products[0].name,
              list_name: null,
              brand: purchase.purchase.products[0].brand,
              category: purchase.purchase.products[0].category,
              variant: null,
              list_position: null,
              quantity: 1,
              price: purchase.purchase.products[0].price,
              coupon: purchase.purchase.products[0].coupon,
            },
          ],
        });
      }
    }
  }

  eventRefund(id: string) {
    if (this.enabled) {
      try {
        if (gtag) {
          gtag('event', 'refund', { transaction_id: id });
        }
      } catch {
      } finally {
        // This Handle the situation where the User has an AdBlobk and gtag is not loaded
        // Proceed to send data with GTM 4
        dataLayer.push({
          event: 'refund',
          transaction_id: id,
          event_category: null,
          event_action: null,
        });
      }
    }
  }
}
