import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '@app/core/services/translations.service';
import { CurrencyVisibilityService } from '@app/services/currency-visibility.service';
import { Subscription } from 'rxjs';

const DefaultSymbolStyle = 'code';

@Pipe({
  name: 'localizedCurrency',
  pure: false,
  standalone: true,
})
export class LocalizedCurrencyPipe implements PipeTransform, OnDestroy {
  private currentLanguage = 'en';
  private languageSubscription: Subscription;
  private currencyHidden = false;
  private currencyVisibilitySubscription: Subscription;

  constructor(
    private languageService: TranslationsService,
    private currencyVisibilityService: CurrencyVisibilityService
  ) {
    this.languageSubscription = this.languageService.currentLanguage$.subscribe((language) => {
      this.currentLanguage = language;
    });
    this.currencyVisibilitySubscription = this.currencyVisibilityService.currencyHidden$.subscribe(
      (hidden) => {
        this.currencyHidden = hidden;
      }
    );
  }

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
    this.currencyVisibilitySubscription.unsubscribe();
  }

  transform(
    value: number | string,
    symbol?: string,
    visibilityMaskEnabled = false,
    signEnabled = false,
    symbolStyle: 'symbol' | 'code' | 'none' = DefaultSymbolStyle
  ): string {
    if ([undefined, null].indexOf(value) > -1) {
      return '';
    }

    if (visibilityMaskEnabled && this.currencyHidden) {
      const symbolParsed = symbolStyle === 'symbol' ? '€' : symbol;
      return `***** ${symbolParsed}`;
    } else {
      const localeMap: { [key: string]: string } = {
        en: 'en-US',
        uk: 'en-GB',
        fr: 'fr-FR',
        it: 'it-IT',
        nl: 'nl-NL',
        es: 'es-ES',
      };
      const locale = localeMap[this.currentLanguage] || 'en-US';

      if (!symbol) {
        symbolStyle = 'none';
      }

      const valueAsNumber = Number(value);
      const formatBySymbolStyle = {
        symbol: new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: symbol || 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(valueAsNumber),
        code: `${new Intl.NumberFormat(locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(valueAsNumber)} ${symbol}`,
        none: new Intl.NumberFormat(locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(valueAsNumber),
      };

      // Format the number in currency style
      let formattedValue = formatBySymbolStyle[symbolStyle];

      // We add the + symbol for positive values
      if (valueAsNumber > 0 && signEnabled) {
        formattedValue = `+${formattedValue}`;
      }

      // We replace all non-separable and narrowing spaces with a normal space.
      formattedValue = formattedValue.replace(/[\u00A0\u202F]/g, ' ');

      return formattedValue;
    }
  }
}
