import { Injectable } from '@angular/core';
/* eslint-disable */

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  showOtheConfig: boolean = false;

  changeNavbar(newConfig: boolean) {
    this.showOtheConfig = newConfig;
  }

  getConfig(): boolean {
    return this.showOtheConfig;
  }
}
