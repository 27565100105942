<div class="modal-header">
  <div class="modal-header__title modal-header__title--wrapped">
    <div class="container">
      <div class="row justify-content-center">
        <div class="modal-header__content col-12 d-flex justify-content-between align-items-center">
          <div>
            {{ title | translate }}
          </div>
          <button class="btn close-btn p-0 d-flex" (click)="close()">
            <i class="icon icon-close-blue"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
