import { Pipe, PipeTransform } from '@angular/core';
import { AppConfig } from '@app/app.config';

@Pipe({
  name: 'withConfigKeys',
  standalone: true,
})
export class WithConfigKeysPipe implements PipeTransform {
  transform(value?: string): string {
    let result = value || '';
    for (const key in AppConfig) {
      if (Object.prototype.hasOwnProperty.call(AppConfig, key)) {
        const regex = new RegExp(`<<${key}>>`, 'g');
        result = result.replace(regex, AppConfig[key]);
      }
    }
    return result;
  }
}
