<div class="sidebar" [class.sidebar__active]="showSidebarMenu">
  <div class="sidebar__container">
    <div class="sidebar__container__link" *ngFor="let item of options">
      <i class="icon {{item.icon}} icon-28" *ngIf="item.icon">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
        <span class="path5"></span>
      </i>
      <div class="label-container">
        <a [routerLink]="item.route" target="_self" (click)="close()">
          {{ item.label | translate }}
        </a>
        <hr>
      </div>
    </div>
  </div>
</div>
