import { Directive, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimInput]',
  standalone: true,
})
export class TrimDirective {
  @Input('appTrimInput') set trimInput(condition: boolean) {
    if (condition) {
      trimValueAccessor(this.ngControl.valueAccessor);
    }
  }
  constructor(private ngControl: NgControl) {}
}

function trimValueAccessor(valueAccessor: ControlValueAccessor) {
  const originalValue = valueAccessor.registerOnChange;
  valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
    return originalValue.call(valueAccessor, (value: unknown) => {
      return fn(typeof value === 'string' ? value.trim() : value);
    });
  };
}
