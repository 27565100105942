import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionBasedService } from '@app/core/services/session-based.service';
import { ApiErrorResponse } from '@app/interfaces/api-error-response.interface';
import { LoginResponse } from '@app/interfaces/login-response.interface';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  UserSession,
} from '@app/interfaces/user-session.interface';
import { AuthenticationService } from '@app/services/authentication.service';
import { AuthorizationService } from '@app/services/authorization.service';
import { CookiesService } from '@app/services/cookies.service';
import { FrogedService } from '@app/services/froged.service';
import { SessionBaseService } from '@app/services/session-base.service';
import { extractStringRegex } from '@app/shared/constants/regex.constants';
import { environment } from '@env/environment';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionService implements SessionBaseService {
  get currentSession(): Observable<UserSession> {
    if (this.session) {
      return of(this.session);
    }

    return this.isSessionActive.pipe(
      map((isSessionActive) => {
        if (!isSessionActive) {
          this.logout();
        }

        return this.session;
      })
    );
  }
  get isUserLoggedIn(): boolean {
    return this.cookiesService.isCookieSet(this.tokenCookieName);
  }
  get loggedByPWA(): boolean {
    return (
      this.cookiesService.getCookie(this.pwaSession) === 'true' ||
      localStorage.getItem(this.pwaSession) === 'true'
    );
  }
  get isSessionActive(): Observable<boolean> {
    return this.authService.getSessionData().pipe(
      map((session: UserSession) => {
        this.session = session;

        return true;
      }),
      catchError(() => {
        this.clearSession();
        return of(false);
      })
    );
  }
  private get isStrictPre() {
    return (
      !environment.wpSpecificURL ||
      (environment.wpSpecificURL &&
        location.origin.includes(environment.imtWordpressBaseUrlNoProtocol))
    );
  }
  readonly tokenCookieName = 'partner-sec-token';
  readonly channelContextCookieName = 'partner-sec-context';
  readonly apiTokenExpirationTimeInHours = environment.apiTokenExpirationTimeInHours;
  readonly sessionTrackerCookieName = 'mt-imoneytrans-sessions';
  readonly pwaSession = 'pwa-session';
  readonly localStorageLanguageName = 'mt-language';
  session: UserSession;
  private readonly LAST_CONNECTIONS_STRING_SEPARATOR = ',';
  private isSessionTimeOut = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly authorizationService: AuthorizationService,
    private readonly cookiesService: CookiesService,
    private readonly router: Router,
    private readonly sessionBasedService: SessionBasedService,
    private readonly frogedService: FrogedService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  setIsSessionTimeOut(flag: boolean) {
    this.isSessionTimeOut = flag;
  }

  getCurrentToken(): string {
    let token = this.cookiesService.getCookie(this.tokenCookieName)
      ? this.cookiesService.getCookie(this.tokenCookieName).replace(/[^0-9a-z-]/gi, '')
      : '';

    if (token === '') {
      token = localStorage.getItem(this.tokenCookieName)
        ? localStorage.getItem(this.tokenCookieName).replace(/[^0-9a-z-]/gi, '')
        : '';
    }

    return token;
  }

  getCurrentContext(): string {
    let context = this.cookiesService.getCookie(this.channelContextCookieName);

    if (context === '') {
      context = localStorage.getItem(this.channelContextCookieName)
        ? localStorage.getItem(this.channelContextCookieName)
        : '';
    }

    return context;
  }

  startNewSession(response: LoginResponse): void {
    this.clearSession();
    this.frogedService.logout();
    this.sessionTrack();
    this.cookiesService.setCookie(this.tokenCookieName, response.APIContext.SecurityToken);
    this.cookiesService.setCookie(
      this.channelContextCookieName,
      String(response.APIContext.ChannelContext)
    );
    this.authorizationService.fetchAndSavePermissions().subscribe();
  }

  startNewPWASession(apisectoken: string, lang?: string, os?: string): void {
    const loginDataFromPwa: LoginResponse = {
      APIContext: {
        SecurityToken: apisectoken,
        ChannelContext: 3,
      },
    };
    localStorage.setItem(this.tokenCookieName, loginDataFromPwa.APIContext.SecurityToken);
    localStorage.setItem(
      this.channelContextCookieName,
      String(loginDataFromPwa.APIContext.ChannelContext)
    );
    this.startNewSession(loginDataFromPwa);
    this.cookiesService.setCookie(this.pwaSession, 'true');
    this.cookiesService.setCookie(this.localStorageLanguageName, lang);
    if (os) {
      this.cookiesService.setCookie('os', os);
    }
  }

  logout(forceLogout = true): void {
    if (this.isUserLoggedIn && forceLogout) {
      this.authService.logout().subscribe();
    }
    if (this.loggedByPWA) {
      this.returnToPwa();
    } else if (this.preIsEnabled()) {
      this.returnToWordPress();
    } else {
      this.router.navigate(['/home']);
    }
    this.clearSession();
    setTimeout(() => {
      localStorage.removeItem(this.tokenCookieName);
      localStorage.removeItem(this.channelContextCookieName);
    }, 2);
  }

  returnToInitializer(): void {
    if (this.loggedByPWA) {
      this.returnToPwa();
    } else if (this.preIsEnabled()) {
      this.returnToWordPress();
    }
  }

  preIsEnabled(): boolean {
    return environment.wpEnabled && this.extractCountryFromUrl();
  }

  getUrl(): string {
    return this.document.location.href;
  }

  changePassword(request: ChangePasswordRequest): Observable<ApiErrorResponse> {
    return this.authService
      .changePassword(request)
      .pipe(map((response) => this.mapChangePasswordResponse(response)));
  }

  getLastSessions(): string[] {
    return this.cookiesService.getCookie(this.sessionTrackerCookieName)
      ? this.cookiesService
          .getCookie(this.sessionTrackerCookieName)
          .split(this.LAST_CONNECTIONS_STRING_SEPARATOR)
      : [];
  }

  /**
   * Update the session cookie timeout
   */
  renewSessionCookie(): void {
    const lastCookieToken = this.cookiesService.getCookie(this.tokenCookieName);
    if (lastCookieToken) {
      const tokenExpirationDate = new Date();
      tokenExpirationDate.setHours(new Date().getHours() + this.apiTokenExpirationTimeInHours);
      this.cookiesService.setCookie(this.tokenCookieName, lastCookieToken, tokenExpirationDate);
    }
  }

  private clearSession(): void {
    this.frogedService.logout();
    this.session = null;
    this.cookiesService.deleteCookie(this.tokenCookieName);
    this.cookiesService.deleteCookie(this.channelContextCookieName);
    this.authorizationService.clearPermissions();
    this.sessionBasedService.clearData();
  }

  private returnToWordPress(): void {
    const isWpEnabled = environment.wpEnabled;
    if (isWpEnabled && this.isStrictPre) {
      let wpSite = this.cookiesService.getCookie(environment.wpSiteCookieKey);
      if (wpSite && this.isSessionTimeOut) {
        wpSite += '/login/';
      }
      window.open(`${environment.imtWordpressBaseUrl}${wpSite}`, '_self');
    } else {
      this.router.navigate(['/home']);
    }
  }

  private returnToPwa(): void {
    window.open(environment.imtPwaUrlProtocol, '_self');
  }

  private extractCountryFromUrl(): boolean {
    const regex = extractStringRegex;
    const matches = regex.exec(this.getUrl());
    const country = matches ? matches[1] : '';
    switch (country) {
      case 'spain':
      case 'united-kingdom':
      case 'congo-rdc':
      case 'belgium':
      case 'germany':
      case 'italy':
      case 'netherlands':
      case 'france':
      case 'portugal':
        return true;
      default:
        return false;
    }
  }

  private mapChangePasswordResponse({
    Code: responseCode,
  }: ChangePasswordResponse): ApiErrorResponse {
    return [
      { Code: 0, Message: 'PPC_M_SUCCESS_PASSWORD' },
      { Code: 1, Message: 'OLD_NEW_PASSWORD_EQUALS' },
      { Code: 2, Message: 'USER_NOT_FOUND' },
      { Code: 3, Message: 'USER_DISABLED' },
      { Code: 4, Message: 'INVALID_PASSWORD' },
      { Code: 5, Message: 'INVALID_PASSWORD_POLICY' },
      { Code: 6, Message: 'UNEXPECTED_ERROR' },
      { Code: 7, Message: 'INVALID_CREDENTIALS' },
      { Code: 8, Message: 'NEW_PASSWORD_IS_SIMILAR_TO_OLD_PASSWORD' },
      { Code: 9, Message: 'NEW_PASSWORD_IS_SIMILAR_TO_USERNAME' },
    ].find(({ Code }) => Code === responseCode);
  }

  private sessionTrack(): void {
    const LAST_CONNECTIONS_TRACKED_COUNT = 5;
    const lastSessions: string[] = this.getLastSessions();
    if (lastSessions.length >= LAST_CONNECTIONS_TRACKED_COUNT) {
      lastSessions.shift();
    }
    lastSessions.push(moment().toString());
    this.cookiesService.setCookie(
      this.sessionTrackerCookieName,
      lastSessions.join(this.LAST_CONNECTIONS_STRING_SEPARATOR)
    );
  }
}
