import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly sessionService: SessionService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const seCt = this.sessionService.getCurrentToken();
    const seCc = this.sessionService.getCurrentContext();

    let { headers } = req;
    if (req.method === 'POST' || req.method === 'PUT') {
      this.shiftDates(req.body);
    }

    if (seCt && seCc) {
      headers = headers.set('Authorization', seCt.toString());
      headers = headers.set('ChannelContext', seCc.toString());
    } else {
      headers = headers.set('ChannelContext', '3');
    }
    headers = headers.set('User-Version', environment.version);

    const request = req.clone({ headers });

    return next.handle(request);
  }

  private shiftDates(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const prop in body) {
      if (body[prop] instanceof Date) {
        const userDate = body[prop];
        body[prop] = new Date(
          Date.UTC(
            userDate.getFullYear(),
            userDate.getMonth(),
            userDate.getDate(),
            userDate.getHours(),
            userDate.getMinutes(),
            userDate.getSeconds()
          )
        );
      } else if (typeof body[prop] === 'object') {
        this.shiftDates(prop);
      }
    }
  }
}
