import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-edit-document-modal-footer',
  templateUrl: './edit-document-modal-footer.component.html',
})
export class EditDocumentModalFooterComponent {
  @Input() showConfirmation = false;
  @Input() pending = false;
  @Output() readonly closed = new EventEmitter<void>();
  @Output() readonly saved = new EventEmitter<void>();

  close() {
    this.closed.emit();
  }

  save() {
    this.saved.emit();
  }
}
