import { Component, EventEmitter, Output } from '@angular/core';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends BaseInputComponent {
  ONE_ELEMENT = 1;
  @Output() readonly changeEvent = new EventEmitter<string>();

  constructor() {
    super();
    this.formControlType = 'select';
  }

  onChange(event: string) {
    this.changeEvent.emit(event);
  }
}
