import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalType } from './alert-modal.model';
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent {
  @Input() type: AlertModalType = 'success';
  @Input() buttonMessage = '';
  @Input() title = '';
  @Input() messages = [];
  @Input() warnings = [];
  @Input() selector = '';

  constructor(private readonly modal: NgbActiveModal) {}

  close() {
    this.modal.close();
  }

  dismiss() {
    this.modal.dismiss();
  }
}
