import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { fadeIn, fadeOut } from '@app/shared/animations/fade.animation';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-infinite-scroll-loader',
  styleUrls: ['./infinite-scroll-loader.component.scss'],
  animations: [fadeIn, fadeOut],
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  template: `
    <div class="loader" *ngIf="show" @fadeIn>
      <div class="loader__content">
        <app-spinner size="sm" [color]="color" [message]="message"></app-spinner>
      </div>
    </div>
  `,
})
export class InfiniteScrollLoaderComponent {
  @Input() color: 'black' | 'white' | 'primary' | 'secondary' = 'primary';
  @Input() show = true;
  @Input() message: string;
}
