import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `
    <div [class.spinner-container]="message">
      <div class="loader {{ color }} {{ size }}"></div>
      <div *ngIf="message" class="loader-message">{{ message }}</div>
    </div>
  `,
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SpinnerComponent {
  @Input() color: 'button-color' | 'primary' | 'secondary' = 'primary';
  @Input() size: 'button-size' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'xs';
  @Input() message: string;
}
