import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { filter, map, take } from 'rxjs/operators';
import { TranslationsService } from '@app/core/services/translations.service';

const DATE_FORMAT_LAN = {
  en: 'DD/MM/YYYY',
  es: 'DD/MM/AAAA',
  fr: 'JJ/MM/AAAA',
  it: 'GG/MM/AAAA',
  nl: 'DD/MM/JJJJ',
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class DatePickerComponent extends BaseInputComponent implements OnInit {
  @ViewChild('datePicker') datePicker: NgbInputDatepicker;

  @Input() minDate?: NgbDate;
  @Input() maxDate?: NgbDate;
  @Input() wrapped? = false;
  @Output() readonly selectedDate = new EventEmitter<Date>();

  placeholderLan$ = this.translationsService.currentLanguage$.pipe(
    map((lan) => (this.placeholder !== '' ? this.placeholder : DATE_FORMAT_LAN[lan]))
  );

  constructor(private readonly translationsService: TranslationsService) {
    super();
    this.formControlType = 'datePicker';
  }

  ngOnInit(): void {
    this.subscription.add(
      this.control.valueChanges
        .pipe(
          take(1),
          filter((value) => typeof value === 'string')
        )
        .subscribe((value) => {
          this.control.patchValue(new Date(value));
        })
    );
  }

  handleFocus() {
    this.datePicker.close();
  }

  handleSelect({ day, month, year }: NgbDate) {
    this.selectedDate.emit(new Date(year, month - 1, day));
  }
}
