import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SessionBaseService } from '@app/services/session-base.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityInterceptor implements HttpInterceptor {
  constructor(private readonly sessionService: SessionBaseService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('Unsecured')) {
      request = request.clone({
        setHeaders: {
          Authorization: this.sessionService.getCurrentToken(),
          ChannelContext: this.sessionService.getCurrentContext(),
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });
    }

    return next.handle(request).pipe(
      tap({
        error: (error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.sessionService.logout();
          }
        },
      })
    );
  }
}
