<app-modal-header [title]="title"></app-modal-header>
<div class="modal-body sms-modal">
  <div class="container p-0" *ngIf="!qrCode; else showQRCode">
    <div class="row text-center">
      <div class="col-12 sms-modal__icon">
        <i class="icon icon-code-desktop-bg icon-80">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
          <span class="path4"></span>
          <span class="path5"></span>
        </i>
      </div>
      <p class="col-md sms-modal__received">{{ 'MSG_ENTER_CODE' | translate }}</p>
    </div>

    <div class="row text-center">
      <form class="col-12" [formGroup]="form" (ngSubmit)="submit()">
        <app-input [formGroup]="form" [isSubmitted]="submitted" [options]="input"></app-input>
        <app-button-big
          [type]="'submit'"
          [callToAction]="submitButtonLabel | translate"
          [disabled]="form.controls.code.invalid"
          [loading]="pending"
          [color]="'primary'"></app-button-big>
      </form>
    </div>
    <div class="row mt-3">
      <p class="col-md help-info" [innerHTML]="'MSG_CODE_SMS_NOT_RECEIVED' | translate | withConfigKeys"></p>
    </div>
  </div>
  <ng-template #showQRCode>
    <div class="container">
      <div class="row text-center">
        <div class="col-md sms-modal__qr">
          <img [src]="qrCode" alt="" />
        </div>
      </div>
      <div class="col-12 sms-modal__code">
        <span>{{ code }}</span>
        <div class="sms-modal__code__copy" (click)="copyCode()">Copy</div>
      </div>
      <div class="row text-center">
        <div class="col-md">
          <app-button-big
            (Click)="activate()"
            [callToAction]="'C_A_ACTIVATE' | translate"
            [loading]="pending"
            [color]="'primary'"></app-button-big>
        </div>
      </div>
      <div class="row mt-2">
        <p class="col-md sms-modal__qr__message">{{ 'MSG_WINDOW_WILL_BE_CLOSED' | translate }}</p>
      </div>
    </div>
  </ng-template>
</div>
