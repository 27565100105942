import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AccountService } from '@app/core/services/account.service';
import { CommonService } from '@app/core/services/common.service';
import { Account } from '@app/interfaces/account/account.interface';
import { IFormInputs } from '@app/interfaces/forms.interface';
import { fadeIn, fadeOut } from '@app/shared/animations/fade.animation';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { CityPickerComponent } from '@app/shared/forms/city-picker/city-picker.component';
import { CommonValidators } from '@app/shared/validators/common.validator';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account-modal-additional-data',
  templateUrl: './account-modal-additional-data.component.html',
  animations: [fadeIn, fadeOut],
})
export class AccountModalAdditionalDataComponent extends BaseComponent implements OnInit {
  account: Account;

  get selectedResidenceCountryId(): number {
    const value = this.form.getRawValue();

    return value.Country && value.Country.Id ? value.Country.Id : null;
  }

  @ViewChild('cityPicker') cityPicker: CityPickerComponent;

  inputs: IFormInputs = {
    Country: {
      formControlNameValue: 'Country',
      label: 'C_F_RESIDENCE_COUNTRY',
    },
    Nationality: {
      formControlNameValue: 'Nationality',
      label: 'C_F_NATIONALITY',
    },
    Street: {
      formControlNameValue: 'Street',
      label: 'C_F_STREET_NAME',
      type: 'text',
    },
    StreetNumber: {
      formControlNameValue: 'StreetNumber',
      label: 'C_F_STREET_NUM',
      type: 'text',
    },
    StreetLetter: {
      formControlNameValue: 'StreetLetter',
      label: 'C_F_STREET_LETTER',
      type: 'text',
    },
    City: {
      formControlNameValue: 'City',
      label: 'C_F_CITY',
      type: 'text',
    },
    PostalCode: {
      formControlNameValue: 'PostalCode',
      label: 'C_F_ZIP',
      type: 'text',
    },
  };

  contactPhone$ = this.commonService.currentContactPhone;
  editable: boolean;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly accountService: AccountService,
    private readonly commonService: CommonService,
    private readonly toastr: ToastrService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.initForm();
    this.accountService.currentEditable().subscribe((res) => {
      this.editable = res;
      this.observeAccount();
      this.loading = false;
    });
  }

  submit() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    this.pending = true;
    const value = this.form.getRawValue();
    const parsedValues: Partial<Account> = {
      BirthDate: this.account.BirthDate,
      Code: this.account.Code,
      Email: this.account.UserMail,
      FiscalCode: this.account.FiscalCode,
      Gender: this.account.Gender,
      IdNationality: value.Nationality.Id,
      LastName: this.account.LastName,
      Name: this.account.Name,
      Occupation: this.account.Occupation,
      OccupationType: this.account.OccupationType,
      Telephone: this.account.Telephone,
      Address: {
        City: value.City,
        CountryId: value.Country.Id,
        PostalCode: value.PostalCode,
        Street: value.Street,
        StreetLetter: value.StreetLetter ? value.StreetLetter : this.account.Address.StreetLetter,
        StreetNumber: value.StreetNumber,
      },
    };

    this.accountService.putAccount(parsedValues).subscribe({
      next: () => this.toastr.success(this.translateService.instant('MSG_ACCOUNT_SAVED')),
      complete: () => (this.pending = false),
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Country: [null, [Validators.required]],
      Nationality: [null, [Validators.required]],
      Street: [null, [Validators.required, CommonValidators.isAlphanumeric()]],
      StreetNumber: [null, [Validators.required, CommonValidators.isAlphanumeric()]],
      StreetLetter: [null, [CommonValidators.isAlphanumeric()]],
      City: [null, [Validators.required]],
      PostalCode: [null, [Validators.required]],
    });
  }

  private observeAccount() {
    this.subscription.add(
      this.accountService.fetchAccount().subscribe((res) => {
        this.account = res;
        this.form.controls.Country.patchValue(res.Address.CountryId);
        this.form.controls.Nationality.patchValue(res.IdNationality);
        this.form.controls.Street.patchValue(res.Address.Street);
        this.form.controls.StreetNumber.patchValue(res.Address.StreetNumber);
        this.form.controls.StreetLetter.patchValue(res.Address.StreetLetter);
        this.form.controls.City.patchValue(res.Address.City);
        this.form.controls.PostalCode.patchValue(res.Address.PostalCode);
        Object.keys(this.form.controls).forEach((key) => {
          const control = this.form.controls[key];
          const value = control.value;
          const validator = control.validator ? control.validator({} as AbstractControl) : null;
          if (value && validator && validator.required) {
            control.disable();
          }
        });
      })
    );
  }

  onPostalCodeSelected(postalCode: google.maps.GeocoderAddressComponent) {
    this.form.controls.PostalCode.setValue(postalCode?.short_name ?? null);
    this.form.updateValueAndValidity();
  }

  onCountrySelected(country) {
    if (country) {
      this.form.controls.Country.patchValue(country, { emitEvent: true });
    }
  }

  onCitySelected(city: { Name: string; Id: number }) {
    this.form.controls.City.patchValue(city);
    if (city) {
      this.cityPicker.focus$.next(city.Name);
    }
  }

  onStreetNumberSelected(streetNumber: google.maps.GeocoderAddressComponent) {
    if (streetNumber?.short_name) {
      this.form.controls.StreetNumber.setValue(streetNumber.short_name);
      this.form.updateValueAndValidity();
    }
  }
}
