import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '@app/core/services/translations.service';
import { AVAILABLE_LANGUAGES } from '@app/shared/constants/available-languages.constants';
import { IAvailableLanguage } from '@app/interfaces/available-language.interface';
import { AppConfig } from '@app/app.config';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
})
export class HomeHeaderComponent implements OnInit {
  currentLanguage: IAvailableLanguage;

  readonly availableLanguages = AVAILABLE_LANGUAGES;
  readonly links: Array<{ label: string; href: string }> = [
    { href: '#/home/public/help', label: 'P_HELP' },
    { href: '#/home', label: 'P_WHY_CHOOSE_IBRAND' },
    { href: '#/home', label: 'P_HOW_IT_WORK' },
    { href: '#/home/public/faq', label: 'P_FAQ' },
    { href: AppConfig.contactUrl, label: 'C_CONTACT_US' },
  ];

  constructor(private readonly translationsService: TranslationsService) {}

  ngOnInit() {
    this.observeLanguage();
  }

  observeLanguage() {
    this.translationsService.currentLanguage$.subscribe((code) => {
      this.currentLanguage = this.availableLanguages.find((x) => x.code === code);
    });
  }

  setLanguage(language: IAvailableLanguage) {
    this.translationsService.setLanguage(language.code);
  }
}
