<button
  class="download download__size"
  [ngClass]="color"
  [disabled]="disabled"
  (click)="handleClick($event)"
  [type]="type">
  <div class="download__container">
    <div class="img-container">
      <i class="download__icon__active icon icon-download-active icon-20"></i>
      <i class="download__icon__normal icon icon-download-default icon-20"></i>
    </div>
    <span class="btn__text" *ngIf="callToAction">
      {{ callToAction }}
    </span>
  </div>
</button>
