import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@app//core/guards/login.guard';
import { AccountResolve } from '@app/core/resolvers/account.resolve';
import { CountryResolve } from '@app/core/resolvers/country.resolve';
import { SessionResolve } from '@app/core/resolvers/session.resolve';
import { PwaLoginComponent } from './pwa/pwa-login.component';
import { WrapperComponent } from './shared/layout/app/wrapper.component';
import { HomeWrapperComponent } from './shared/layout/home/home-wrapper.component';
import { PublicWrapperComponent } from './shared/layout/public/public-wrapper.component';

const routes: Routes = [
  {
    path: 'login/:apisectoken/:lang',
    component: PwaLoginComponent,
  },
  {
    path: 'login/:apisectoken',
    component: PwaLoginComponent,
  },
  {
    path: 'home',
    component: HomeWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./public/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  {
    path: 'home/public',
    component: PublicWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
      },
      {
        path: 'register',
        resolve: { countries: CountryResolve },
        loadComponent: () =>
          import('./register/register.component').then((m) => m.RegisterComponent),
      },
    ],
  },
  {
    path: 'register',
    component: PublicWrapperComponent,
    children: [
      {
        path: 'verify/mail',
        loadComponent: () =>
          import('./register/verify-mail/verify-mail.component').then((p) => p.VerifyMailComponent),
      },
      {
        path: 'verify/address',
        loadComponent: () =>
          import('./register/verify-address/verify-address.component').then(
            (p) => p.VerifyAddressComponent
          ),
      },
      {
        path: '',
        resolve: { countries: CountryResolve },
        loadComponent: () =>
          import('./register/register.component').then((m) => m.RegisterComponent),
      },
      {
        path: ':lang',
        resolve: { countries: CountryResolve },
        loadComponent: () =>
          import('./register/register.component').then((m) => m.RegisterComponent),
      },
      {
        path: ':lang/:countryIso',
        resolve: { countries: CountryResolve },
        loadComponent: () =>
          import('./register/register.component').then((m) => m.RegisterComponent),
      },
    ],
  },
  {
    path: 'account/help',
    redirectTo: 'home/public/help',
  },
  {
    path: 'account',
    component: PublicWrapperComponent,
    children: [
      {
        path: 'forgot',
        loadChildren: () => import('./public/help/help.module').then((m) => m.HelpModule),
      },
    ],
  },
  {
    path: '',
    component: WrapperComponent,
    canActivate: [LoginGuard],
    resolve: { session: SessionResolve, account: AccountResolve, countries: CountryResolve },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./products/products.component').then((p) => p.ProductsComponent),
      },
      {
        path: 'mtr',
        loadChildren: () =>
          import('./money-transfer/money-transfer.module').then((m) => m.MoneyTransferModule),
      },
      {
        path: 'imt',
        loadChildren: () =>
          import('./international-top-up/international-top-up.module').then(
            (m) => m.InternationalTopUpModule
          ),
      },
      {
        path: 'pac',
        loadChildren: () =>
          import('./smile-account/smile-account.module').then((m) => m.SmileAccountModule),
      },
      {
        path: 'enrollment',
        loadChildren: () =>
          import('./enrollment/enrollment.module').then((m) => m.EnrollmentModule),
      },
      {
        path: 'profile-account',
        loadChildren: () =>
          import('./profile-account/profile-account.module').then((m) => m.ProfileAccountModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'payment-process',
        loadChildren: () =>
          import('./payment-process/payment-process.module').then((m) => m.PaymentProcessModule),
      },
      {
        path: 'videos',
        loadComponent: () =>
          import('./videos/pages/videos/videos.component').then((m) => m.VideosComponent),
      },
    ],
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
