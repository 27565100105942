import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryService } from '@app/core/services/country.service';
import { DictionariesService } from '@app/core/services/dictionaries.service';
import { TranslationsService } from '@app/core/services/translations.service';
import { CustomerDocument } from '@app/interfaces/customer.interface';
import { IdDocType } from '@app/interfaces/id-doc-response.interface';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { CatalogCountry } from '@app/interfaces/country-information.interface';
import { ICreateDocumentRequest } from '@app/interfaces/create-document-request.interface';
import { IFormInputs } from '@app/interfaces/forms.interface';
import { DateValidators } from '@app/shared/validators/date.validator';

@Component({
  selector: 'app-add-identity-document-form',
  templateUrl: './add-identity-document-form.component.html',
  styleUrls: ['./add-identity-document-form.component.scss'],
})
export class AddIdentityDocumentFormComponent extends BaseComponent implements OnInit {
  @Input() readonly = false;
  @Input() value: CustomerDocument;
  @Output() readonly formSubmitted = new EventEmitter<ICreateDocumentRequest>();
  @Output() readonly formIsValid = new EventEmitter<boolean>();
  actualFormat = ' DD/MM/AAA';
  isSubmitted: boolean;
  form: UntypedFormGroup;
  inputs: IFormInputs;
  documentTypes: IdDocType[];
  now = new Date();
  currentDate = new NgbDate(this.now.getFullYear(), this.now.getMonth() + 1, this.now.getDate());
  currentTranslateCountries: CatalogCountry[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    readonly translationsService: TranslationsService,
    private readonly dictionariesService: DictionariesService,
    private readonly countryService: CountryService
  ) {
    super();
  }

  ngOnInit() {
    this.subscription.add(
      this.dictionariesService.fetchDocumentTypes().subscribe((types) => {
        this.documentTypes = types;
        this.initInputProps();
        this.initForm();
        this.unlockButton();
      })
    );

    this.countryService.currentTranslatedCountries().subscribe((res) => {
      this.currentTranslateCountries = res;
    });
  }

  unlockButton() {
    this.subscription.add(
      this.form.valueChanges.subscribe(() => {
        this.formIsValid.emit(this.form.invalid);
      })
    );
  }

  submit() {
    if (this.form.valid) {
      const { Type, IdDeliveryCountry } = this.form.value;
      this.formSubmitted.emit({
        ...this.form.value,
        IdDeliveryCountry: IdDeliveryCountry.Id,
        Type: this.documentTypes.find((t) => t.code === Type),
      });
    }

    this.isSubmitted = true;
  }

  private initInputProps() {
    this.inputs = {
      DocumentNumber: {
        formControlNameValue: 'DocumentNumber',
        label: 'C_F_DOC_NUMBER',
        type: 'text',
        validateOnTouched: true,
      },
      Type: {
        formControlNameValue: 'Type',
        label: 'C_F_TYPE',
        options: this.documentTypes,
        optionLabel: 'description',
        optionValue: 'code',
        validateOnTouched: true,
      },
      ExpiryDate: {
        formControlNameValue: 'ExpiryDate',
        label: '',
        validateOnTouched: true,
        type: 'datePicker',
        unlabelled: true,
      },
      DeliveryDate: {
        formControlNameValue: 'DeliveryDate',
        label: '',
        validateOnTouched: true,
        type: 'datePicker',
        unlabelled: true,
      },
      IdDeliveryCountry: {
        formControlNameValue: 'IdDeliveryCountry',
        label: 'C_F_DELIVERY_COUNTRY',
        type: 'text',
        optionLabel: 'Name',
        validateOnTouched: true,
      },
      file1: {
        formControlNameValue: 'file1',
        label: '',
        type: 'file',
        helper: 'C_A_SELECT_1',
        accept: 'image/*, application/pdf',
        validateOnTouched: true,
      },
      file2: {
        formControlNameValue: 'file2',
        label: '',
        type: 'file',
        helper: 'C_A_SELECT_2',
        accept: 'image/*, application/pdf',
        validateOnTouched: true,
      },
    };
  }

  private initForm() {
    const countryId = this.value ? this.value.IdDeliveryCountry : null;
    const expiryDate = this.value ? new Date(this.value.ExpiryDate) : null;
    const deliveryDate = this.value ? new Date(this.value.DeliveryDate) : null;
    this.form = this.formBuilder.group({
      DocumentNumber: [this.value ? this.value.DocumentNumber : null, [Validators.required]],
      Type: [this.value?.Type ? this.value.Type.code : null, [Validators.required]],
      ExpiryDate: [expiryDate, [Validators.required, DateValidators.documentExpired(new Date())]],
      DeliveryDate: [deliveryDate, DateValidators.documentNotIssued(new Date())],
      IdDeliveryCountry: [countryId, [Validators.required]],
      file1: [null, [Validators.required]],
      file2: [null, [Validators.required]],
    });

    if (this.readonly) {
      this.form.disable();
    }
  }
}
