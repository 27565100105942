export const getFileExtFromBase64 = (fileBase64): string => {
  const fileHeader = new Map();
  fileHeader.set('/9j', 'jpeg');
  fileHeader.set('iVB', 'png');
  fileHeader.set('Qk0', 'bmp');
  fileHeader.set('SUk', 'tiff');
  fileHeader.set('JVB', 'pdf');
  fileHeader.set('UEs', 'ofd');

  let res = '';

  fileHeader.forEach((v, k) => {
    if (k === fileBase64.substr(0, 3)) {
      res = v;
    }
  });

  if (res === '') {
    res = 'jpeg';
  }

  return res as string;
};
