<button
  class="delete delete__size"
  [ngClass]="color"
  [disabled]="disabled"
  (click)="handleClick($event)"
  [type]="type">
  <img
    class="delete__icon__normal"
    width="15"
    height="15"
    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMjgwNTUgNC42Mzg1OUgyLjcyMDU1VjEyLjc5ODZDMi43MjA1NSAxNC41NzcgNC4xNjUyMyAxNS45MTg2IDYuMDgwNTUgMTUuOTE4Nkg5LjkyMDU1QzExLjgzNTkgMTUuOTE4NiAxMy4yODA1IDE0LjU3NyAxMy4yODA1IDEyLjc5ODZWNC42Mzg1OUgxNC43MjA1QzE1LjExOCA0LjYzODU5IDE1LjQ0MDUgNC4zMTYwOCAxNS40NDA1IDMuOTE4NTlDMTUuNDQwNSAzLjUyMTExIDE1LjExOCAzLjE5ODU5IDE0LjcyMDUgMy4xOTg1OUgxMS4zNjA1VjEuMjc4NTlDMTEuMzYwNSAwLjg4MTEwNiAxMS4wMzggMC41NTg1OTQgMTAuNjQwNSAwLjU1ODU5NEg1LjM2MDU1QzQuOTYzMDYgMC41NTg1OTQgNC42NDA1NSAwLjg4MTEwNiA0LjY0MDU1IDEuMjc4NTlWMy4xOTg1OUgxLjI4MDU1QzAuODgzMDU5IDMuMTk4NTkgMC41NjA1NDcgMy41MjExMSAwLjU2MDU0NyAzLjkxODU5QzAuNTYwNTQ3IDQuMzE2MDggMC44ODMwNTkgNC42Mzg1OSAxLjI4MDU1IDQuNjM4NTlaTTguNDgwNTUgMTIuMzE4NkM4LjQ4MDU1IDEyLjU4MzkgOC4yNjU4NiAxMi43OTg2IDguMDAwNTUgMTIuNzk4NkM3LjczNTIzIDEyLjc5ODYgNy41MjA1NSAxMi41ODM5IDcuNTIwNTUgMTIuMzE4NlY3LjE1ODU5QzcuNTIwNTUgNi44OTMyNyA3LjczNTIzIDYuNjc4NTkgOC4wMDA1NSA2LjY3ODU5QzguMjY1ODYgNi42Nzg1OSA4LjQ4MDU1IDYuODkzMjggOC40ODA1NSA3LjE1ODU5VjEyLjMxODZaTTEwLjY0MDUgNy4xNTg1OVYxMi4zMTg2QzEwLjY0MDUgMTIuNTgzOSAxMC40MjU5IDEyLjc5ODYgMTAuMTYwNSAxMi43OTg2QzkuODk1MjMgMTIuNzk4NiA5LjY4MDU1IDEyLjU4MzkgOS42ODA1NSAxMi4zMTg2VjcuMTU4NTlDOS42ODA1NSA2Ljg5MzI3IDkuODk1MjMgNi42Nzg1OSAxMC4xNjA1IDYuNjc4NTlDMTAuNDI1OSA2LjY3ODU5IDEwLjY0MDUgNi44OTMyOCAxMC42NDA1IDcuMTU4NTlaTTYuMDgwNTUgMS45OTg1OUg5LjkyMDU1VjMuMTk4NTlINi4wODA1NVYxLjk5ODU5Wk01LjM2MDU1IDcuMTU4NTlDNS4zNjA1NSA2Ljg5MzI3IDUuNTc1MjMgNi42Nzg1OSA1Ljg0MDU1IDYuNjc4NTlDNi4xMDU4NiA2LjY3ODU5IDYuMzIwNTUgNi44OTMyOCA2LjMyMDU1IDcuMTU4NTlWMTIuMzE4NkM2LjMyMDU1IDEyLjU4MzkgNi4xMDU4NiAxMi43OTg2IDUuODQwNTUgMTIuNzk4NkM1LjU3NTIzIDEyLjc5ODYgNS4zNjA1NSAxMi41ODM5IDUuMzYwNTUgMTIuMzE4NlY3LjE1ODU5WiIgZmlsbD0iI0U5NTk2NiIvPgo8L3N2Zz4K" />
  <img
    class="delete__icon__active"
    width="15"
    height="15"
    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMjgwNTUgNC42Mzg1OUgyLjcyMDU1VjEyLjc5ODZDMi43MjA1NSAxNC41NzcgNC4xNjUyMyAxNS45MTg2IDYuMDgwNTUgMTUuOTE4Nkg5LjkyMDU1QzExLjgzNTkgMTUuOTE4NiAxMy4yODA1IDE0LjU3NyAxMy4yODA1IDEyLjc5ODZWNC42Mzg1OUgxNC43MjA1QzE1LjExOCA0LjYzODU5IDE1LjQ0MDUgNC4zMTYwOCAxNS40NDA1IDMuOTE4NTlDMTUuNDQwNSAzLjUyMTExIDE1LjExOCAzLjE5ODU5IDE0LjcyMDUgMy4xOTg1OUgxMS4zNjA1VjEuMjc4NTlDMTEuMzYwNSAwLjg4MTEwNiAxMS4wMzggMC41NTg1OTQgMTAuNjQwNSAwLjU1ODU5NEg1LjM2MDU1QzQuOTYzMDYgMC41NTg1OTQgNC42NDA1NSAwLjg4MTEwNiA0LjY0MDU1IDEuMjc4NTlWMy4xOTg1OUgxLjI4MDU1QzAuODgzMDU5IDMuMTk4NTkgMC41NjA1NDcgMy41MjExMSAwLjU2MDU0NyAzLjkxODU5QzAuNTYwNTQ3IDQuMzE2MDggMC44ODMwNTkgNC42Mzg1OSAxLjI4MDU1IDQuNjM4NTlaTTguNDgwNTUgMTIuMzE4NkM4LjQ4MDU1IDEyLjU4MzkgOC4yNjU4NiAxMi43OTg2IDguMDAwNTUgMTIuNzk4NkM3LjczNTIzIDEyLjc5ODYgNy41MjA1NSAxMi41ODM5IDcuNTIwNTUgMTIuMzE4NlY3LjE1ODU5QzcuNTIwNTUgNi44OTMyNyA3LjczNTIzIDYuNjc4NTkgOC4wMDA1NSA2LjY3ODU5QzguMjY1ODYgNi42Nzg1OSA4LjQ4MDU1IDYuODkzMjggOC40ODA1NSA3LjE1ODU5VjEyLjMxODZaTTEwLjY0MDUgNy4xNTg1OVYxMi4zMTg2QzEwLjY0MDUgMTIuNTgzOSAxMC40MjU5IDEyLjc5ODYgMTAuMTYwNSAxMi43OTg2QzkuODk1MjMgMTIuNzk4NiA5LjY4MDU1IDEyLjU4MzkgOS42ODA1NSAxMi4zMTg2VjcuMTU4NTlDOS42ODA1NSA2Ljg5MzI3IDkuODk1MjMgNi42Nzg1OSAxMC4xNjA1IDYuNjc4NTlDMTAuNDI1OSA2LjY3ODU5IDEwLjY0MDUgNi44OTMyOCAxMC42NDA1IDcuMTU4NTlaTTYuMDgwNTUgMS45OTg1OUg5LjkyMDU1VjMuMTk4NTlINi4wODA1NVYxLjk5ODU5Wk01LjM2MDU1IDcuMTU4NTlDNS4zNjA1NSA2Ljg5MzI3IDUuNTc1MjMgNi42Nzg1OSA1Ljg0MDU1IDYuNjc4NTlDNi4xMDU4NiA2LjY3ODU5IDYuMzIwNTUgNi44OTMyOCA2LjMyMDU1IDcuMTU4NTlWMTIuMzE4NkM2LjMyMDU1IDEyLjU4MzkgNi4xMDU4NiAxMi43OTg2IDUuODQwNTUgMTIuNzk4NkM1LjU3NTIzIDEyLjc5ODYgNS4zNjA1NSAxMi41ODM5IDUuMzYwNTUgMTIuMzE4NlY3LjE1ODU5WiIgZmlsbD0iI0U5NTk2NiIvPgo8L3N2Zz4K" />
  <span class="delete__label">{{ label }}</span>
</button>
