import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslationsService } from '@app/core/services/translations.service';
import { TranslateService } from '@ngx-translate/core';

import { AppConfig } from './app.config';
import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  private readonly APP_HTML_TITLE = 'APP_HTML_TITLE';
  constructor(
    private readonly scriptLoader: DynamicScriptLoaderService,
    private readonly translationsService: TranslationsService,
    private readonly title: Title,
    private readonly translateService: TranslateService
  ) {
    this.scriptLoader.init();

    this.translationsService.initTranslations();
    this.title.setTitle(
      this.translateService
        .instant(this.APP_HTML_TITLE)
        .replace(new RegExp(`<<appName>>`, 'g'), AppConfig.appName)
    );

    // Change language by observable event
    this.translateService.onLangChange.subscribe(() => {
      this.title.setTitle(
        this.translateService
          .instant(this.APP_HTML_TITLE)
          .replace(new RegExp(`<<appName>>`, 'g'), AppConfig.appName)
      );
    });
  }
}
