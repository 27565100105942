import en from './faq_en.json';
import es from './faq_es.json';
import fr from './faq_fr.json';
import it from './faq_it.json';
import nl from './faq_nl.json';
import uk from './faq_uk.json';
import PPCen from './ppc/faq_en.json';
import PPCes from './ppc/faq_es.json';
import PPCfr from './ppc/faq_fr.json';
import PPCit from './ppc/faq_it.json';
import PPCnl from './ppc/faq_nl.json';
import IMTen from './imt/faq_en.json';
import IMTes from './imt/faq_es.json';
import IMTfr from './imt/faq_fr.json';
import IMTit from './imt/faq_it.json';
import IMTnl from './imt/faq_nl.json';
import IMTuk from './imt/faq_uk.json';

export default {
  general: {
    en,
    es,
    fr,
    it,
    nl,
    uk,
  },
  products: {
    IMT: {
      en: IMTen,
      es: IMTes,
      fr: IMTfr,
      it: IMTit,
      nl: IMTnl,
      uk: IMTuk,
    },
  },
  ppc: {
    PPC: {
      en: PPCen,
      es: PPCes,
      fr: PPCfr,
      it: PPCit,
      nl: PPCnl,
    },
  },
};
