import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  constructor() {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);

      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }

    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (
      date?.day?.toString()?.length >= 1 &&
      date?.month?.toString()?.length >= 1 &&
      date?.year?.toString()?.length === 4
    ) {
      const day = date.day > 9 ? date.day : `0${date.day}`;
      const month = date.month > 9 ? date.month : `0${date.month}`;

      return `${day}${this.DELIMITER}${month}${this.DELIMITER}${date.year}`;
    }
    return null;
  }
}
