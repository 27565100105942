import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { InternationalTopUpService } from '@app/core/services/international-top-up.service';
import { IFormInputs } from '@app/interfaces/forms.interface';
import { Entry, Operator } from '@app/interfaces/imt-topups-common.interfaces';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { ModalService } from '@app/services/modal.service';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { AmountEntryPickerComponent } from '../../forms/amount-entry-picker/amount-entry-picker.component';
import { ButtonBigComponent } from '../../forms/button-big/button-big.component';

@Component({
  selector: 'app-topup-amount-modal',
  templateUrl: './topup-amount-modal.component.html',
  styleUrls: ['./topup-amount-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AmountEntryPickerComponent,
    ButtonBigComponent,
    TranslateModule,
    ModalHeaderComponent,
  ],
})
export class TopupAmountModalComponent extends BaseComponent implements OnInit {
  @Input() operator: Operator;
  amountEntries$: Observable<Entry[]> = of([]);
  inputs: IFormInputs = {
    AmountEntry: {
      formControlNameValue: 'AmountEntry',
      label: 'C_F_AMOUNT',
    },
  };

  constructor(
    private readonly internationalTopUpService: InternationalTopUpService,
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.initForm();
    this.fetchEntries();
  }

  goToSendTopUP() {
    this.googleAnalyticsService.eventEmitter('', 'mit-prices-by-country', 'click-send-top-up');
    this.router.navigate(['imt', 'send']);
    this.modalService.close();
  }

  private initForm() {
    this.form = this.fb.group({
      [this.inputs.AmountEntry.formControlNameValue]: [null],
    });
    this.subscription.add(
      this.form.controls.AmountEntry.valueChanges.subscribe(() =>
        this.googleAnalyticsService.eventEmitter('', 'mit-prices-by-country', 'click-amount')
      )
    );
  }

  private fetchEntries() {
    this.amountEntries$ = this.internationalTopUpService
      .getAmountEntries({ OperatorCode: this.operator.Code, CountryCode: 'POL' })
      .pipe(
        finalize(() => (this.loading = false)),
        map(({ TopUpCatalogData }) => TopUpCatalogData.Entries)
      );
  }
}
