import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  private readonly MESSAGE_URL = `${environment.apiBasePath}/online/transfers/contact`;
  constructor(private readonly httpClient: HttpClient) {}

  postMessage(Message: string) {
    return this.httpClient.post(`${this.MESSAGE_URL}`, { Message });
  }
}
