import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AccountService } from '@app/core/services/account.service';
import { Account } from '@app/interfaces/account/account.interface';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountResolve implements Resolve<Observable<Account>> {
  constructor(private readonly accountService: AccountService) {}

  resolve(): Observable<Account> {
    return this.accountService.fetchAccount().pipe(catchError(() => EMPTY));
  }
}
