<ng-container *ngIf="routeEvents$ | async"></ng-container>
<nav class="navbar fixed-bottom navbar-light bg-light menu">
  <ng-container *ngFor="let option of menuOptions">
    <a class="navbar-brand menu__item" (click)="goToPage(option)">
      <div class="menu__item__icon">
        <i class="icon {{ option.icon + (option.active ? '-active' : '-default') }}">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
      </div>
      <span
        class="menu__item__label"
        [ngClass]="{
          menu__item__label__active: option.active
        }">
        {{ option.label | translate }}
      </span>
    </a>
  </ng-container>
</nav>
