import { IAvailableLanguage } from '@app/interfaces/available-language.interface';

export const AVAILABLE_LANGUAGES: IAvailableLanguage[] = [
  {
    code: 'es',
    desc: 'Español',
    country: 'es',
  },
  {
    code: 'en',
    desc: 'English',
    country: 'gb',
  },
  {
    code: 'fr',
    desc: 'Français',
    country: 'fr',
  },
  {
    code: 'nl',
    desc: 'Nederlands',
    country: 'nl',
  },
  {
    code: 'it',
    desc: 'Italiano',
    country: 'it',
  },
  {
    code: 'uk',
    desc: 'Ukrainian',
    country: 'ua',
  },
];
