import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalService } from '@app/shared/modals/alert-modal/alert-modal.service';
import { SessionService } from '../services/session.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(
    private readonly sessionService: SessionService,
    private readonly modalService: NgbModal,
    private readonly alertModalService: AlertModalService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap({
        error: (err) => {
          if (
            err instanceof HttpErrorResponse &&
            [401, 403].includes(err.status) &&
            !this.sessionService.isUserLoggedIn
          ) {
            this.openClosedSessionModal();
          }
        },
      })
    );
  }

  private openClosedSessionModal(): void {
    this.modalService.dismissAll();
    this.alertModalService.danger(
      ['MSG_SESSION_CLOSED'],
      '',
      '',
      () => {
        this.sessionService.setIsSessionTimeOut(true);
        this.sessionService.logout();
      },
      [],
      () => {
        if (!this.modalService.hasOpenModals()) {
          this.sessionService.setIsSessionTimeOut(true);
          this.sessionService.logout();
        }
      },
      'server-error-modal'
    );
  }
}
