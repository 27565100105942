import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  HostListener,
} from '@angular/core';

const LEFT_ARROW_BLUE = 'icon-chevron-left-active';
const LEFT_ARROW_WHITE = 'icon-chevron-left-default';
const RIGHT_ARROW_BLUE = 'icon-chevron-right-active';
const RIGHT_ARROW_WHITE = 'icon-chevron-right-default';

type Side = 'left' | 'right';

@Component({
  selector: 'app-pagination',
  template: `
    <div
      class="row justify-content-center mx-0 pagination"
      [ngClass]="{ 'd-none': conditionToHide }">
      <ng-container *ngIf="pagesCount >= 1">
        <button
          class="btnArrow"
          [ngClass]="{ btnArrowEnabled: selectedPage > 1 }"
          (click)="setPrevPage()"
          (mouseover)="changeWhite('left')"
          (mouseout)="changeBlue('left')">
          <i *ngIf="selectedPage > 1" class="icon {{ arrowLeftColor }} icon-16"></i>
        </button>
        <ng-container *ngFor="let page of pagesList; let i = index">
          <button
            [disabled]="page === '...'"
            (click)="setPage(page, i)"
            [ngClass]="{ selected: page === selectedPage }">
            {{ page }}
          </button>
        </ng-container>

        <button
          class="btnArrow"
          [ngClass]="{ btnArrowEnabled: selectedPage < pagesCount }"
          (click)="setNextPage()"
          (mouseover)="changeWhite('right')"
          (mouseout)="changeBlue('right')">
          <i *ngIf="selectedPage < pagesCount" class="icon {{ arrowRightColor }} icon-16"></i>
        </button>
      </ng-container>
    </div>
  `,
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PaginationComponent<T> implements OnInit, OnChanges {
  @Input() isMT = false;
  @Input() list: T[] = [];
  @Input() pageSize = 10;
  @Input() pagesCount = 0;
  @Input() pageSelectedAuxiliar = 1;
  @Input() doReset = false;
  @Input() conditionToHide = false;
  @Output() readonly paginatedListSubmitted = new EventEmitter<{
    array: T[];
    currentPage: number;
  }>();

  pagesList: Array<number | string>;

  selectedPage = 1;
  listLenght: number;
  isSmallScreen: boolean;

  arrowLeftColor = LEFT_ARROW_BLUE;
  arrowRightColor = RIGHT_ARROW_BLUE;

  listLenghtOld: number;
  selectPageOld: number;
  paginatorAdd = 5;
  totalPaginationsRemaining: number;

  @HostListener('window:resize')
  onResize() {
    this.isSmallScreen = window.innerWidth <= 766;
  }

  ngOnInit() {
    this.onResize();
    if (this.list) {
      this.listLenght = this.list.length;
    }
    if (this.doReset) this.selectedPage = 1;
    this.listLenghtOld = this.listLenght;
    this.selectPageOld = this.selectedPage;
    if (this.isMT) {
      this.totalPaginationsRemaining = this.pagesCount = Math.ceil(this.listLenght / this.pageSize);
    }
    this.updatePaginator();
    this.paginate();
  }

  ngOnChanges() {
    if (this.list) {
      if (this.list.length === this.listLenghtOld) {
        this.selectedPage = this.selectPageOld;
      } else if (this.pageSelectedAuxiliar != this.selectedPage) {
        this.selectedPage = this.pageSelectedAuxiliar;
      }

      this.listLenght = this.list.length;
      if (this.isMT) {
        this.pagesCount = Math.ceil(this.listLenght / this.pageSize);
      }
      this.updatePaginator();
      this.paginate();
    }
  }

  updatePaginator() {
    if (this.pagesCount > 7) {
      this.loadLongPaginator();
    } else {
      this.pagesList = [...Array(this.pagesCount)].map((_v, i) => 1 + i);
    }
  }

  paginate() {
    if (this.list) {
      if (!this.isMT) {
        const arraySlice = this.list.slice(
          (this.selectedPage - 1) * this.pageSize,
          this.selectedPage * this.pageSize
        );
        if (arraySlice.length > 0) {
          this.emitEvent(arraySlice);
        } else if (this.list.length > 0) {
          this.emitEvent(this.list);
        }
      } else {
        this.emitEvent(
          this.list.slice(
            (this.selectedPage - 1) * this.pageSize,
            this.selectedPage * this.pageSize
          )
        );
      }
    }
  }

  setPage(page: string | number, index: number) {
    if (typeof page === 'number') {
      this.selectedPage = page;
      this.selectPageOld = page;
      this.paginate();
      this.handleClickPageNumber(index);
    }
  }

  handleClickPageNumber(index: number) {
    //swith for the different cases when click in paginate number
    if (this.pagesCount > 7) {
      switch (index) {
        case 0:
          this.pagesList[5] = '...';
          break;
        case 4:
          this.next(true);
          break;
        case 6:
          this.onclickAtLastPage();
          break;
        default:
          break;
      }
    }
  }

  setPrevPage() {
    if (this.selectedPage > 1) {
      this.selectedPage -= 1;
      this.selectPageOld -= 1;
      this.paginate();
      if (this.pagesCount > 7) {
        this.prev();
      }
    }
  }

  setNextPage() {
    if (this.selectedPage < this.pagesCount) {
      this.selectedPage += 1;
      this.selectPageOld += 1;
      this.paginate();
      this.next();
    }
  }

  onclickAtLastPage() {
    let test = 6;
    for (let i = 0; i <= 6; i++) {
      this.pagesList[i] = this.pagesCount - test--;
    }
  }

  changeWhite(side: Side) {
    if (this.isSmallScreen) {
      return;
    }
    if (side == 'left') {
      this.arrowLeftColor = LEFT_ARROW_WHITE;
    } else {
      this.arrowRightColor = RIGHT_ARROW_WHITE;
    }
  }

  changeBlue(side: Side) {
    if (side == 'left') {
      this.arrowLeftColor = LEFT_ARROW_BLUE;
    } else {
      this.arrowRightColor = RIGHT_ARROW_BLUE;
    }
  }

  private loadLongPaginator() {
    if (this.selectedPage === 1) {
      this.pagesList = [];
      for (let index = 1; index <= 7; index++) {
        if (index <= 5) {
          this.pagesList.push(index);
        } else if (index == 6) {
          this.pagesList.push('...');
        } else if (index == 7) {
          this.pagesList.push(this.pagesCount);
        }
      }
    }
  }

  private emitEvent(arrayList: T[]) {
    this.paginatedListSubmitted.emit({
      array: arrayList,
      currentPage: this.selectedPage,
    });
  }

  private prev() {
    if (this.pagesCount > 7) {
      this.totalPaginationsRemaining = this.pagesCount - this.selectedPage;
      if (this.totalPaginationsRemaining === 6) {
        //Change penultimate page number to "..."
        this.pagesList[5] = '...';
      } else if (this.totalPaginationsRemaining > 6) {
        //Remove Element at first position and add Element in last potition before '...'
        if (Number(this.pagesList[0]) - 1 > 0) {
          this.pagesList.splice(0, 0, Number(this.pagesList[0]) - 1);
          this.pagesList.splice(5, 1);
        }
      }
    }
  }

  private next(isClickPaginateNum?: boolean) {
    if (this.pagesCount > 7) {
      this.totalPaginationsRemaining = this.pagesCount - this.selectedPage;
      const limitCondition = isClickPaginateNum ? 2 : 5;
      if (
        this.totalPaginationsRemaining === limitCondition ||
        (this.totalPaginationsRemaining < 2 && this.totalPaginationsRemaining >= 2)
      ) {
        //Change "..." valo to penultimate page number
        this.pagesList[5] = this.pagesCount - 1;
      }
      if (
        this.totalPaginationsRemaining > limitCondition ||
        (this.totalPaginationsRemaining < 2 && this.totalPaginationsRemaining >= 2)
      ) {
        //Add Element at first position and remove Element in last potition before '...'
        this.pagesList.splice(5, 0, Number(this.pagesList[4]) + 1);
        this.pagesList.shift();
      }
    }
  }
}
