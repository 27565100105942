<div
  class="invalid-feedback"
  [attr.data-testid]="dataTestId"
  *ngIf="vm$ | async as errors"
  @fadeIn
  @fadeOut>
  <ng-container *ngIf="errors.required">
    <span class="msg-error" *ngIf="requiredErrorText; else noDefaultRequiredText">
      {{ requiredErrorText }}
    </span>
    <ng-template #noDefaultRequiredText>
      <span class="msg-error">
        {{ 'VAL_MSG_REQUIRED' | translate }}
      </span>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="errors.sendEmail">
    <span class="msg-error">
      {{ 'C_F_EMAIL' | translate }}
      {{ 'VAL_MSG_REQUIRED' | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="errors.email">
    <span class="msg-error">{{ 'VAL_MSG_INVALID_EMAIL' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.minlength">
    <span class="msg-error">{{ 'VAL_MSG_SHORT_VALUE' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.maxlength">
    <span class="msg-error">{{ 'VAL_MSG_LONG_VALUE' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.maxDate">
    <span class="msg-error">{{ 'VAL_MSG_MAX_DATE' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.minDate">
    <span class="msg-error">{{ 'VAL_MSG_MIN_DATE' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.underLegalAge">
    <span class="msg-error">{{ 'HT_VAL_GREATER_THAN_18' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.alphaNumeric">
    <span class="msg-error">{{ 'VAL_MSG_ALPHANUMERIC' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.mustMatch">
    <span class="msg-error">{{ 'VAL_MSG_DISTINCT_CODE' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.mustPasswordMatch">
    <span class="msg-error">{{ 'C_F_PASSWORD_MUST_MATCH' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.floatOnly">
    <span class="msg-error">{{ 'VAL_MSG_INAVLID_AMOUNT' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.existIban">
    <span class="msg-error">{{ 'MSG_IBAN_DUPLICATED' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.isCurrentAccountIban">
    <span class="msg-error">{{ 'MSG_IBAN_ACCOUNT' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.invalidPatternEmail">
    <span class="msg-error">{{ 'VAL_MSG_INVALID_EMAIL' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.misformatIban || errors.invalidIban">
    <span class="msg-error">{{ 'MSG_IBAN_INCORRECT' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.documentExpired">
    <span class="msg-error">{{ 'HT_VAL_DOC_EXPIRED' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.documentNotIssued">
    <span class="msg-error">{{ 'HT_VAL_DOC_NOT_ISSUED' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.validatePhoneNumber && !errors.validatePhoneNumber.valid">
    <span class="msg-error">{{ 'ERROR_PHONE_INVALID' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="errors.invalid || errors.pattern">
    <span class="msg-error">
      {{ 'VAL_ERR_FORMAT_NO_VALID' | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="errors?.repeteadCharacters">
    <span class="msg-error">
      {{ 'VAL_ERR_FORMAT_REPEATED_CHARACTERS' | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="errors?.specialCharacters">
    <span class="msg-error">
      {{ 'VAL_ERR_FORMAT_SPECIAL_CHARACTERS' | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="errors?.doubleWhiteSpace">
    <span class="msg-error">
      {{ 'VAL_ERR_FORMAT_WHITE_SPACE' | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="errors.userMail">
    <span class="msg-error">
      {{ 'VAL_MSG_USER_EMAIL' | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="errors.noFounds">
    <span class="msg-error" [innerHTML]="'VAL_PAC_LIMIT_INSUFFICIENT_FUNDS_2' | translate"></span>
  </ng-container>
</div>
