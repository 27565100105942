import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphabetOnly]',
  standalone: true,
})
export class AlphabetDirective {
  // eslint-disable-next-line no-useless-escape
  onlyLettersRegex = /^[A-Za-zÀ-ÿ\s\´]+$/;

  @HostListener('keypress', ['$event']) onKeypress(event: KeyboardEvent) {
    if (!this.onlyLettersRegex.test(event.key) || event.key === 'Dead' || event.key === '´') {
      event.preventDefault();
    }
  }
}
