import { Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { CONSTANTS, FORMATS } from '@app/shared/constants/common.constants';

@Component({
  selector: 'app-base',
  template: '',
})
export class BaseComponent implements OnDestroy {
  loading: boolean;
  pending: boolean;
  subscription: Subscription;

  isSubmitted: boolean;
  form: UntypedFormGroup;

  currency$: Observable<string>;

  FORMATS = FORMATS;
  CONSTANTS = CONSTANTS;

  constructor() {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
