import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BalancePipe } from '@app/pipes/balance.pipe';
import { BeneficiaryNameOrIbanSearchPipe } from '@app/pipes/beneficiary-name-or-iban-search.pipe';
import { BeneficiaryNamePipe } from '@app/pipes/beneficiary-name.pipe';
import { BeneficiarySubtitlePipe } from '@app/pipes/beneficiary-subtitle.pipe';
import { CookiesPolicyPipe } from '@app/pipes/cookies_policy.pipe';
import { CurrencySignPipe } from '@app/pipes/currency-sign.pipe';
import { CutLongPipe } from '@app/pipes/cut-long.pipe';
import { ErrorLabelPipe } from '@app/pipes/error-label.pipe';
import { PrivacyPolicyPipe } from '@app/pipes/privacy_policy.pipe';
import { WithConfigKeysPipe } from '@app/pipes/with-config-keys.pipe';
import { BeneficiariesListComponent } from '@app/shared/components/beneficiaries-list/beneficiaries-list.component';
import { BeneficiaryBankAccountsListComponent } from '@app/shared/components/beneficiary-bank-accounts-list/beneficiary-bank-accounts-list.component';
import { BreadcrumbComponent } from '@app/shared/components/breadcrumb/breadcrumb.component';
import { MixedCardComponent } from '@app/shared/components/mixed-card/mixed-card.component';
import { ArrowButtonComponent } from '@app/shared/forms/arrow-button/arrow-button.component';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';
import { ButtonBigComponent } from '@app/shared/forms/button-big/button-big.component';
import { DateInputsComponent } from '@app/shared/forms/date-inputs/date-inputs.component';
import { DeleteButtonComponent } from '@app/shared/forms/delete-button/delete-button.component';
import { DownloadButtonComponent } from '@app/shared/forms/download-button/download-button.component';
import { CityPickerMtComponent } from '@app/shared/forms/mt-city-picker/city-picker-mt.component';
import { SearchInputMtComponent } from '@app/shared/forms/search-input-mt/search-input-mt.component';
import { SelectorComponent } from '@app/shared/forms/selector/selector.component';
import { AccountMissingDataModalComponent } from '@app/shared/modals/account-missing-data-modal/account-missing-data-modal.component';
import { BankDetailsModalComponent } from '@app/shared/modals/bank-details-modal/bank-details-modal.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TermsAndConditionsPipe } from '../pipes/terms.conditions.pipe';
import { AdditionalDocumentFormComponent } from './components/additional-document-form/additional-document-form.component';
import { BaseComponent } from './components/base/base.component';
import { CountryInformationComponent } from './components/country-information/country-information.component';
import { MenuPageComponent } from './components/menu-page/menu-page.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalProgressBarComponent } from './components/modal-progress-bar/modal-progress-bar.component';
import { NotFoundBeneficiariesComponent } from './components/not-found-beneficiaries/not-found-beneficiaries.component';
import { OpenBankingSelectorComponent } from './components/open-banking-selector/open-banking-selector.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaymentMethodInformationComponent } from './components/payment-method-info/payment-method-info.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { ResizableComponent } from './components/resizable/resizable.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { SmileAccountTransferComponent } from './components/smile-account-transfer/smile-account-transfer.component';
import { AlphabetDirective } from './directives/alphabet.directive';
import { DateDirective } from './directives/date.directive';
import { FlagIconDirective } from './directives/flag-icon.directive';
import { NumericDirective } from './directives/numeric.directive';
import { TrimDirective } from './directives/trim.directive';
import { AddressSearchInputComponent } from './forms/address-search-input/address-search-input.component';
import { AmountEntryPickerComponent } from './forms/amount-entry-picker/amount-entry-picker.component';
import { AmountInputComponent } from './forms/amount-input/amount-input.component';
import { BeneficiaryPickerComponent } from './forms/beneficiary-picker/beneficiary-picker.component';
import { ButtonComponent } from './forms/button/button.component';
import { CheckboxComponent } from './forms/checkbox/checkbox.component';
import { CityPickerComponent } from './forms/city-picker/city-picker.component';
import { CountryPickerMtModule } from './forms/country-picker-mt/country-picker-mt.module';
import { CountryPickerComponent } from './forms/country-picker/country-picker.component';
import { DatePickerComponent } from './forms/date-picker/date-picker.component';
import { ErrorsComponent } from './forms/errors/errors.component';
import { IbanInputComponent } from './forms/iban-input/iban-input.component';
import { ImageUploaderComponent } from './forms/image-uploader/image-uploader.component';
import { InputWithMaskComponent } from './forms/input-with-mask/input-with-mask.component';
import { InputComponent } from './forms/input/input.component';
import { MtPhoneInputsModule } from './forms/mt-phone-inputs/mt-phone-inputs.module';
import { OnlyLettersInputComponent } from './forms/only-letters-input/only-letters-input.component';
import { OperatorListPickerComponent } from './forms/operator-list-picker/operator-list-picker.component';
import { OperatorPickerComponent } from './forms/operator-picker/operator-picker.component';
import { PasswordInputComponent } from './forms/password-input/password-input.component';
import { PaymentMethodPickerComponent } from './forms/payment-method-picker/payment-method-picker.component';
import { RadioComponent } from './forms/radio/radio.component';
import { SelectPickUpPlaseComponent } from './forms/select-pick-up-place/select-pick-up-place.component';
import { SelectComponent } from './forms/select/select.component';
import { TextareaComponent } from './forms/textarea/textarea.component';
import { FooterComponent } from './layout/app/footer/footer.component';
import { HeaderComponent } from './layout/app/header/header.component';
import { MobileNavbarComponent } from './layout/app/mobile-navbar/mobile-navbar.component';
import { SidebarComponent } from './layout/app/sidebar/sidebar.component';
import { WrapperComponent } from './layout/app/wrapper.component';
import { HomeHeaderComponent } from './layout/home/home-header/home-header.component';
import { HomeWrapperComponent } from './layout/home/home-wrapper.component';
import { PublicHeaderComponent } from './layout/public/public-header/public-header.component';
import { PublicWrapperComponent } from './layout/public/public-wrapper.component';
import { AbsoluteLoaderComponent } from './loaders/absolute-loader/absolute-loader.component';
import { InfiniteScrollLoaderComponent } from './loaders/infinite-scroll-loader/infinite-scroll-loader.component';
import { SpinnerComponent } from './loaders/spinner/spinner.component';
import { AccountDeleteModalComponent } from './modals/account-delete-modal/account-delete-modal.component';
import { AccountModalComponent } from './modals/account-modal/account-modal.component';
import { AccountModalAdditionalDataComponent } from './modals/account-modal/components/account-modal-additional-data/account-modal-additional-data.component';
import { AccountModalAdditionalDocumentsComponent } from './modals/account-modal/components/account-modal-additional-documents/account-modal-additional-documents.component';
import { AddAdditionalDocumentFormComponent } from './modals/account-modal/components/account-modal-additional-documents/add-additional-document-form/add-additional-document-form.component';
import { AddAdditionalDocumentModalComponent } from './modals/account-modal/components/account-modal-additional-documents/add-additional-document-modal/add-additional-document-modal.component';
import { PreviewAdditionalDocumentImageComponent } from './modals/account-modal/components/account-modal-additional-documents/preview-additional-document-image/preview-additional-document-image.component';
import { AccountModalIdentityDocumentsComponent } from './modals/account-modal/components/account-modal-identity-documents/account-modal-identity-documents.component';
import { AddIdentityDocumentFormComponent } from './modals/account-modal/components/account-modal-identity-documents/add-identity-document-form/add-identity-document-form.component';
import { AddIdentityDocumentModalComponent } from './modals/account-modal/components/account-modal-identity-documents/add-identity-document-modal/add-identity-document-modal.component';
import { EditDocumentModalBodyComponent } from './modals/account-modal/components/account-modal-identity-documents/edit-document-modal-body/edit-document-modal-body.component';
import { EditDocumentModalFooterComponent } from './modals/account-modal/components/account-modal-identity-documents/edit-document-modal-footer/edit-document-modal-footer.component';
import { EditDocumentModalComponent } from './modals/account-modal/components/account-modal-identity-documents/edit-document-modal/edit-document-modal.component';
import { PreviewDocumentImageComponent } from './modals/account-modal/components/account-modal-identity-documents/preview-document-image/preview-document-image.component';
import { PreviewDocumentModalComponent } from './modals/account-modal/components/account-modal-identity-documents/preview-document/preview-document.component';
import { AccountModalMenuComponent } from './modals/account-modal/components/account-modal-menu/account-modal-menu.component';
import { AccountModalPersonalDataComponent } from './modals/account-modal/components/account-modal-personal-data/account-modal-personal-data.component';
import { AccountModalSecurityComponent } from './modals/account-modal/components/account-modal-security/account-modal-security.component';
import { SmsConfirmationModalComponent } from './modals/account-modal/components/account-modal-security/sms-confirmation-modal/sms-confirmation-modal.component';
import { AccountModalUserComponent } from './modals/account-modal/components/account-modal-user/account-modal-user.component';
import { AccountPersonalDetailsModalComponent } from './modals/account-personal-details-modal/account-personal-details-modal.component';
import { AlertAccountComponent } from './modals/alert-account/alert-account.component';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { AuthModalComponent } from './modals/auth-modal/auth-modal.component';
import { BankDetailsDisabledComponent } from './modals/bank-details-disabled/bank-details-disabled.component';
import { BeneficiaryModalComponent } from './modals/beneficiary-modal/beneficiary-modal.component';
import { BeneficiaryPickerModalComponent } from './modals/beneficiary-picker-modal/beneficiary-picker-modal.component';
import { ContactUsModalComponent } from './modals/contact-us-modal/contact-us-modal.component';
import { CountryInformationModalComponent } from './modals/country-information-modal/country-information-modal.component';
import { CreateAdditionalDocumentModalComponent } from './modals/create-additional-document-modal/create-additional-document-modal.component';
import { CustomerCodeModalComponent } from './modals/customer-code-modal/customer-code-modal.component';
import { DeleteBankAccountConfirmationModalComponent } from './modals/delete-bank-account-confirmation-modal/delete-bank-account-confirmation-modal.component';
import { EditDocumentRequestModalComponent } from './modals/edit-document-request-modal/edit-document-request-modal.component';
import { ImtPromotionModalComponent } from './modals/imt-promotion-modal/imt-promotion-modal.component';
import { MenuTransferOptionsModalComponent } from './modals/menu-transfer-options-modal/menu-transfer-options-modal.component';
import { ModifyDestinationDataModalComponent } from './modals/modify-destination-data-modal/modify-destination-data-modal.component';
import { MtBeneficiaryModalComponent } from './modals/mt-beneficiary-modal/mt-beneficiary-modal.component';
import { MtrModalSuccessComponent } from './modals/mtr-modal-success/mtr-modal-success.component';
import { OperatorsListModalComponent } from './modals/operators-list-modal/operators-list-modal.component';
import { PaymentInformationModalComponent } from './modals/payment-information-modal/payment-information-modal.component';
import { PlansInfoModalComponent } from './modals/plans-info-modal/plans-info-modal.component';
import { RequestCancellationModalComponent } from './modals/request-cancellation-modal/request-cancellation-modal.component';
import { ReservedAmountModalComponent } from './modals/reserved-amount-modal/reserved-amount-modal.component';
import { ReviewAdditionalDocumentModalComponent } from './modals/review-additional-document-modal/review-additional-document-modal.component';
import { SecurityCodeModalComponent } from './modals/security-code-modal/security-code-modal.component';
import { SepaModalComponent } from './modals/sepa-modal/sepa-modal.component';
import { SingUpModalComponent } from './modals/sing-up-modal/sing-up-modal.component';
import { TimeoutModalComponent } from './modals/timeout-modal/timeout-modal.component';
import { TopupAmountModalComponent } from './modals/topup-amount-modal/topup-amount-modal.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PaymentSuccessComponent } from './payment-status/payment-success/payment-success.component';
import { TableComponent } from './table/table.component';
import { LocalizedCurrencyPipe } from '@app/pipes/localized-currency.pipe';
import { SidebarMenuComponent } from './layout/app/sidebar-menu/sidebar-menu.component';

@NgModule({
  declarations: [
    WrapperComponent,
    HeaderComponent,
    PublicWrapperComponent,
    PublicHeaderComponent,
    HomeWrapperComponent,
    HomeHeaderComponent,
    SidebarComponent,
    MobileNavbarComponent,
    AuthModalComponent,
    AlertAccountComponent,
    AccountModalComponent,
    AccountModalUserComponent,
    AccountModalMenuComponent,
    AccountModalAdditionalDataComponent,
    AccountModalPersonalDataComponent,
    AccountModalSecurityComponent,
    AccountModalIdentityDocumentsComponent,
    AddIdentityDocumentFormComponent,
    PreviewDocumentModalComponent,
    PreviewDocumentImageComponent,
    AddIdentityDocumentModalComponent,
    EditDocumentModalComponent,
    EditDocumentModalBodyComponent,
    EditDocumentModalFooterComponent,
    AddAdditionalDocumentModalComponent,
    AddAdditionalDocumentFormComponent,
    AccountModalAdditionalDocumentsComponent,
    PreviewAdditionalDocumentImageComponent,
    SmsConfirmationModalComponent,
    BeneficiaryModalComponent,
    MtBeneficiaryModalComponent,
    ContactUsModalComponent,
    OperatorsListModalComponent,
    RequestCancellationModalComponent,
    ModifyDestinationDataModalComponent,
    SecurityCodeModalComponent,
    AlertModalComponent,
    TimeoutModalComponent,
    BankDetailsModalComponent,
    SepaModalComponent,
    BeneficiaryPickerModalComponent,
    SingUpModalComponent,
    CustomerCodeModalComponent,
    AccountMissingDataModalComponent,
    ImtPromotionModalComponent,
    MenuTransferOptionsModalComponent,
    AccountPersonalDetailsModalComponent,
    AccountDeleteModalComponent,
    EditDocumentRequestModalComponent,
    CreateAdditionalDocumentModalComponent,
    ReviewAdditionalDocumentModalComponent,
    MtrModalSuccessComponent,
    CountryInformationModalComponent,
    PaymentInformationModalComponent,
    BankDetailsDisabledComponent,
    DeleteBankAccountConfirmationModalComponent,
    PlansInfoModalComponent,
    ReservedAmountModalComponent,
    BaseInputComponent,
    ButtonComponent,
    SelectComponent,
    InputComponent,
    CheckboxComponent,
    RadioComponent,
    DatePickerComponent,
    CountryPickerComponent,
    ImageUploaderComponent,
    TextareaComponent,
    CityPickerComponent,
    AmountInputComponent,
    OperatorPickerComponent,
    OperatorListPickerComponent,
    PaymentMethodPickerComponent,
    IbanInputComponent,
    BeneficiaryPickerComponent,
    InputWithMaskComponent,
    PasswordInputComponent,
    OnlyLettersInputComponent,
    SelectPickUpPlaseComponent,
    TableComponent,
    AbsoluteLoaderComponent,
    BaseComponent,
    BeneficiaryBankAccountsListComponent,
    BeneficiariesListComponent,
    AdditionalDocumentFormComponent,
    MenuPageComponent,
    PaymentMethodComponent,
    CountryInformationComponent,
    ModalProgressBarComponent,
    PaymentMethodInformationComponent,
    SmileAccountTransferComponent,
    ResizableComponent,
    SkeletonLoaderComponent,
    NotFoundBeneficiariesComponent,
    PaymentSuccessComponent,
    PaymentStatusComponent,
    OpenBankingSelectorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    NgxIntlTelInputModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    ButtonBigComponent,
    NgSelectModule,
    SelectorComponent,
    CountryPickerMtModule,
    CityPickerMtComponent,
    SearchInputMtComponent,
    SpinnerComponent,
    BreadcrumbComponent,
    ArrowButtonComponent,
    MixedCardComponent,
    DeleteButtonComponent,
    DownloadButtonComponent,
    PaginationComponent,
    MtPhoneInputsModule,
    DateInputsComponent,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        backgroundColor: '#ff2222',
      },
    }),
    FooterComponent,
    BalancePipe,
    CurrencySignPipe,
    LocalizedCurrencyPipe,
    BeneficiaryNameOrIbanSearchPipe,
    BeneficiaryNamePipe,
    BeneficiarySubtitlePipe,
    CutLongPipe,
    AlphabetDirective,
    DateDirective,
    NumericDirective,
    TrimDirective,
    AddressSearchInputComponent,
    TermsAndConditionsPipe,
    CookiesPolicyPipe,
    PrivacyPolicyPipe,
    FlagIconDirective,
    ModalHeaderComponent,
    TooltipModule,
    ErrorLabelPipe,
    NgxMaskDirective,
    NgxMaskPipe,
    WithConfigKeysPipe,
    AmountEntryPickerComponent,
    ErrorsComponent,
    InfiniteScrollLoaderComponent,
    TopupAmountModalComponent,
    LocalizedCurrencyPipe,
    SidebarMenuComponent,
  ],
  exports: [
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    AccordionModule,
    TabsModule,
    ButtonBigComponent,
    BreadcrumbComponent,
    CountryPickerMtModule,
    DownloadButtonComponent,
    SelectorComponent,
    CityPickerMtComponent,
    AddressSearchInputComponent,
    SearchInputMtComponent,
    ArrowButtonComponent,
    MixedCardComponent,
    DeleteButtonComponent,
    PaginationComponent,
    MtPhoneInputsModule,
    DateInputsComponent,
    WrapperComponent,
    HeaderComponent,
    PublicWrapperComponent,
    PublicHeaderComponent,
    MobileNavbarComponent,
    SidebarComponent,
    AuthModalComponent,
    AccountModalComponent,
    AccountModalUserComponent,
    AccountModalMenuComponent,
    MtBeneficiaryModalComponent,
    ContactUsModalComponent,
    OperatorsListModalComponent,
    RequestCancellationModalComponent,
    ModifyDestinationDataModalComponent,
    SecurityCodeModalComponent,
    AlertModalComponent,
    TimeoutModalComponent,
    AlertAccountComponent,
    BankDetailsModalComponent,
    SepaModalComponent,
    BeneficiaryPickerModalComponent,
    AccountMissingDataModalComponent,
    AccountPersonalDetailsModalComponent,
    AccountDeleteModalComponent,
    EditDocumentRequestModalComponent,
    CreateAdditionalDocumentModalComponent,
    ReviewAdditionalDocumentModalComponent,
    MtrModalSuccessComponent,
    CountryInformationModalComponent,
    PaymentInformationModalComponent,
    BankDetailsDisabledComponent,
    DeleteBankAccountConfirmationModalComponent,
    PlansInfoModalComponent,
    ReservedAmountModalComponent,
    BaseInputComponent,
    ButtonComponent,
    SelectComponent,
    InputComponent,
    CheckboxComponent,
    RadioComponent,
    DatePickerComponent,
    CountryPickerComponent,
    ImageUploaderComponent,
    TextareaComponent,
    CityPickerComponent,
    AmountInputComponent,
    OperatorPickerComponent,
    OperatorListPickerComponent,
    PaymentMethodPickerComponent,
    IbanInputComponent,
    BeneficiaryPickerComponent,
    InputWithMaskComponent,
    PasswordInputComponent,
    OnlyLettersInputComponent,
    SelectPickUpPlaseComponent,
    TableComponent,
    AbsoluteLoaderComponent,
    SpinnerComponent,
    BaseComponent,
    BeneficiaryBankAccountsListComponent,
    BeneficiariesListComponent,
    MenuPageComponent,
    PaymentMethodComponent,
    CountryInformationComponent,
    ModalProgressBarComponent,
    SmileAccountTransferComponent,
    ResizableComponent,
    SkeletonLoaderComponent,
    NotFoundBeneficiariesComponent,
    TermsAndConditionsPipe,
    PaymentStatusComponent,
    FooterComponent,
    PrivacyPolicyPipe,
    OpenBankingSelectorComponent,
    BeneficiaryNameOrIbanSearchPipe,
    ModalHeaderComponent,
  ],
  providers: [
    NgbActiveModal,
    DatePipe,
    DecimalPipe,
    BalancePipe,
    CurrencySignPipe,
    LocalizedCurrencyPipe,
    LocalizedCurrencyPipe,
    provideEnvironmentNgxMask(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
