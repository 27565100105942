<div class="search" [class.diplay-flex]="ActiveSearchButton">
  <input
    id="inputSearch"
    type="text"
    class="search__input"
    [formControl]="control"
    [placeholder]="placeholder ? placeholder : translateService.instant('C_A_SEARCH') + '...'"
    (keypress)="onKeyPress($event)"
    [maxlength]="maxLength" />
  <ng-container *ngIf="!ActiveSearchButton">
    <span class="search__icon"><i class="fas fa-search"></i></span>
  </ng-container>
  <ng-container *ngIf="ActiveSearchButton">
    <div>
      <button
        id="buttonSearch"
        (click)="emitClickBtn()"
        [disabled]="isDisabled"
        class="search__btn">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </ng-container>
</div>
