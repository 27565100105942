<div class="form-group row ms-date-picker" [formGroup]="formGroup">
  <label
    class="col-form-label"
    *ngIf="!options?.unlabelled"
    [class.col-lg-4]="!wrapped"
    [class.col-lg-12]="fullWidth"
    [class.col-lg-3]="wrapped">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div
    [class.col-lg-12]="fullWidth"
    [class.col-lg-8]="!fullWidth && !wrapped"
    [class.col-lg-9]="!fullWidth && wrapped">
    <div class="input-group">
      <input
        class="form-control"
        appDate
        ngbDatepicker
        type="text"
        #datePicker="ngbDatepicker"
        [class.is-invalid]="isInvalid"
        [formControlName]="options.formControlNameValue"
        [placeholder]="placeholderLan$ | async | translate"
        (dateSelect)="handleSelect($event)"
        (focus)="handleFocus()"
        [minDate]="minDate"
        [maxDate]="maxDate" />
      <span class="input-group-text" [class.disabled]="isDisabled" (click)="datePicker.open()">
        <img
          alt=""
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMyIgeT0iNiIgd2lkdGg9IjE0IiBoZWlnaHQ9IjExIiByeD0iMiIgc3Ryb2tlPSIjNTY2NDZDIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxyZWN0IHg9IjYiIHk9IjkiIHdpZHRoPSIzIiBoZWlnaHQ9IjIiIHJ4PSIxIiBmaWxsPSIjNTY2NDZDIi8+CjxyZWN0IHg9IjYiIHk9IjEyIiB3aWR0aD0iMyIgaGVpZ2h0PSIyIiByeD0iMSIgZmlsbD0iIzU2NjQ2QyIvPgo8cmVjdCB4PSIxMSIgeT0iOSIgd2lkdGg9IjMiIGhlaWdodD0iMiIgcng9IjEiIGZpbGw9IiM1NjY0NkMiLz4KPHJlY3QgeD0iNiIgeT0iMyIgd2lkdGg9IjIiIGhlaWdodD0iMyIgcng9IjEiIGZpbGw9IiM1NjY0NkMiLz4KPHJlY3QgeD0iMTIiIHk9IjMiIHdpZHRoPSIyIiBoZWlnaHQ9IjMiIHJ4PSIxIiBmaWxsPSIjNTY2NDZDIi8+Cjwvc3ZnPgo=" />
      </span>
    </div>
    <ng-container *ngIf="options?.helper">
      <small class="form-text text-muted">
        {{ options.helper | translate }}
      </small>
    </ng-container>
    <app-errors
      *ngIf="!options.hideErrors"
      [isInvalid]="isInvalid"
      [formGroup]="formGroup"
      [formControlNameValue]="options.formControlNameValue"></app-errors>
  </div>
</div>
