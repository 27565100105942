<div (mousemove)="onMouseMove($event)" class="form-group row country" [formGroup]="formGroup">
  <label class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth" *ngIf="!options.unlabelled">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div class="col-lg-8" [class.col-lg-12]="fullWidth">
    <div class="select-container">
      <ng-container *ngIf="!currentCountry || !showCurrentCountryFlag">
        <div class="search-icn">
          <i class="fas fa-search"></i>
        </div>
      </ng-container>
      <ng-container *ngIf="currentCountry && showCurrentCountryFlag && isClickOnOption">
        <ng-container *ngTemplateOutlet="customize || defaultTemplate"></ng-container>
      </ng-container>
      <ng-select
        #select
        [class.disable-pointer]="pressKey"
        [class.is-invalid]="isInvalid"
        [class.padding]="true"
        [class.w-100]="fullWidth"
        [class.focus]="isFocused"
        [items]="countries"
        [bindLabel]="options.optionLabel"
        [formControlName]="options.formControlNameValue"
        [placeholder]="
          options.placeholder
            ? translateService.instant(options.placeholder) + '...'
            : translateService.instant('MSG_SELECT_TYPE_COUNTRY') + '...'
        "
        [notFoundText]="noFoundCode || 'RESULTS_NOT_FOUND' | translate"
        [clearable]="false"
        [editableSearchTerm]="true"
        (search)="onSearch($event)"
        (keydown.enter)="onOptionSelect()"
        (blur)="onBlur()"
        (focus)="onFocus()"
        (open)="onOpenList()">
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div
            class="form-group__option"
            (click)="onOptionSelect(index)"
            (mouseover)="onMouseover(index)">
            <i appFlagIcon class="flag" [ISOCode]="item.Iso2"></i>
            <span>{{ item.Name }}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <ng-container *ngIf="!options.hideErrors && isRequiredError">
      <span class="msg-error">
        {{ requiredErrorText || 'VAL_MSG_REQUIRED' | translate }}
      </span>
    </ng-container>
  </div>
</div>
<ng-template #defaultTemplate>
  <div>
    <i appFlagIcon class="flag current-flag" [ISOCode]="currentCountry.Iso2"></i>
  </div>
</ng-template>
