import feesEN from './fees/accountDetailsFees_en.json';
import feesES from './fees/accountDetailsFees_es.json';
import feesFR from './fees/accountDetailsFees_fr.json';
import feesIT from './fees/accountDetailsFees_it.json';
import feesNL from './fees/accountDetailsFees_nl.json';
import feesUK from './fees/accountDetailsFees_uk.json';
import limitsEN from './limits/accountDetailsLimits_en.json';
import limitsES from './limits/accountDetailsLimits_es.json';
import limitsFR from './limits/accountDetailsLimits_fr.json';
import limitsIT from './limits/accountDetailsLimits_it.json';
import limitsNL from './limits/accountDetailsLimits_nl.json';
import limitsUK from './limits/accountDetailsLimits_uk.json';
import operationsEN from './operations/cardOperations_en.json';
import operationsES from './operations/cardOperations_es.json';
import operationsFR from './operations/cardOperations_fr.json';
import operationsIT from './operations/cardOperations_it.json';
import operationsNL from './operations/cardOperations_nl.json';
import operationsUK from './operations/cardOperations_uk.json';

export default {
  fees: {
    en: feesEN,
    es: feesES,
    fr: feesFR,
    it: feesIT,
    nl: feesNL,
    uk: feesUK,
  },
  limits: {
    en: limitsEN,
    es: limitsES,
    fr: limitsFR,
    it: limitsIT,
    nl: limitsNL,
    uk: limitsUK,
  },
  operations: {
    en: operationsEN,
    es: operationsES,
    fr: operationsFR,
    it: operationsIT,
    nl: operationsNL,
    uk: operationsUK,
  },
};
