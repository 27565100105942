import { environment } from '@env/environment';
import { UrlRules } from '@app/shared/pipes/urls-pipes/urls.conts';

const { imtWordpressBaseUrl } = environment;

enum CookiesUrlTermsByLanguage {
  ES = 'cookies',
  FR = 'utilisation-de-cookies',
  IT = 'politica-sui-cookies',
  EN = 'cookies-policy',
  UK = 'cookies',
  NL = 'cookiebeleid',
}

/**
 * ? Belgium & France & Spain*:
 * * FR ES EN
 *
 * ? Congo RDC & United Kingdom:
 * * FR ES EN
 *
 * ? Italy:
 * * FR EN ES IT
 *
 * ? Netherlands:
 * * FR EN ES NL
 *
 *  */

export const CookiesRules: UrlRules = {
  belgium: {
    fr: `${imtWordpressBaseUrl}belgium/${CookiesUrlTermsByLanguage.FR}/`,
    es: `${imtWordpressBaseUrl}belgium/es/${CookiesUrlTermsByLanguage.ES}/`,
    en: `${imtWordpressBaseUrl}belgium/en/${CookiesUrlTermsByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}belgium/uk/${CookiesUrlTermsByLanguage.UK}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}belgium/en/${CookiesUrlTermsByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}belgium/en/${CookiesUrlTermsByLanguage.EN}/`,
  },
  'congo-rdc': {
    fr: `${imtWordpressBaseUrl}congo-rdc/${CookiesUrlTermsByLanguage.FR}/`,
    es: `${imtWordpressBaseUrl}congo-rdc/es/${CookiesUrlTermsByLanguage.ES}/`,
    en: `${imtWordpressBaseUrl}congo-rdc/en/${CookiesUrlTermsByLanguage.EN}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}congo-rdc/en/${CookiesUrlTermsByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}congo-rdc/en/${CookiesUrlTermsByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}congo-rdc/en/${CookiesUrlTermsByLanguage.EN}/`,
  },
  spain: {
    es: `${imtWordpressBaseUrl}spain/${CookiesUrlTermsByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}spain/fr/${CookiesUrlTermsByLanguage.FR}/`,
    en: `${imtWordpressBaseUrl}spain/en/${CookiesUrlTermsByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}spain/uk/${CookiesUrlTermsByLanguage.UK}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}spain/en/${CookiesUrlTermsByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}spain/en/${CookiesUrlTermsByLanguage.EN}/`,
  },
  italy: {
    it: `${imtWordpressBaseUrl}italy/${CookiesUrlTermsByLanguage.IT}/`,
    fr: `${imtWordpressBaseUrl}italy/fr/${CookiesUrlTermsByLanguage.FR}/`,
    es: `${imtWordpressBaseUrl}italy/es/${CookiesUrlTermsByLanguage.ES}/`,
    en: `${imtWordpressBaseUrl}italy/en/${CookiesUrlTermsByLanguage.EN}/`,
    // ! Missing URL default EN
    nl: `${imtWordpressBaseUrl}italy/en/${CookiesUrlTermsByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}italy/en/${CookiesUrlTermsByLanguage.EN}/`,
  },
  netherlands: {
    nl: `${imtWordpressBaseUrl}netherlands/${CookiesUrlTermsByLanguage.NL}/`,
    es: `${imtWordpressBaseUrl}netherlands/es/${CookiesUrlTermsByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}netherlands/fr/${CookiesUrlTermsByLanguage.FR}/`,
    en: `${imtWordpressBaseUrl}netherlands/en/${CookiesUrlTermsByLanguage.EN}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}netherlands/en/${CookiesUrlTermsByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}netherlands/en/${CookiesUrlTermsByLanguage.EN}/`,
  },
  'united-kingdom': {
    en: `${imtWordpressBaseUrl}united-kingdom/${CookiesUrlTermsByLanguage.EN}/`,
    es: `${imtWordpressBaseUrl}united-kingdom/es/${CookiesUrlTermsByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}united-kingdom/fr/${CookiesUrlTermsByLanguage.FR}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}united-kingdom/${CookiesUrlTermsByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}united-kingdom/${CookiesUrlTermsByLanguage.EN}/`,
    uk: `${imtWordpressBaseUrl}united-kingdom/${CookiesUrlTermsByLanguage.EN}/`,
  },
  france: {
    fr: `${imtWordpressBaseUrl}france/${CookiesUrlTermsByLanguage.FR}/`,
    en: `${imtWordpressBaseUrl}france/en/${CookiesUrlTermsByLanguage.EN}/`,
    es: `${imtWordpressBaseUrl}france/es/${CookiesUrlTermsByLanguage.ES}/`,
    uk: `${imtWordpressBaseUrl}france/uk/${CookiesUrlTermsByLanguage.UK}/`,
    // ! Missing URL default EN
    it: `${imtWordpressBaseUrl}france/en/${CookiesUrlTermsByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}france/en/${CookiesUrlTermsByLanguage.EN}/`,
  },
  portugal: {
    en: `${imtWordpressBaseUrl}portugal/${CookiesUrlTermsByLanguage.EN}/`,
    es: `${imtWordpressBaseUrl}portugal/es/${CookiesUrlTermsByLanguage.ES}/`,
    fr: `${imtWordpressBaseUrl}portugal/fr/${CookiesUrlTermsByLanguage.FR}/`,
    // ! Missing URL default EN
    uk: `${imtWordpressBaseUrl}portugal/${CookiesUrlTermsByLanguage.EN}/`,
    it: `${imtWordpressBaseUrl}portugal/${CookiesUrlTermsByLanguage.EN}/`,
    nl: `${imtWordpressBaseUrl}portugal/${CookiesUrlTermsByLanguage.EN}/`,
  },
};
