import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsService } from '@app/core/services/contact-us.service';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { IFormInputs } from '@app/interfaces/forms.interface';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
})
export class ContactUsModalComponent extends BaseComponent implements OnInit {
  inputs: IFormInputs = {
    Message: {
      formControlNameValue: 'Message',
      label: 'C_F_MESSAGE',
      type: 'textarea',
      rowsCount: 3,
    },
  };

  state: 'form' | 'success' = 'form';

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly contactUsService: ContactUsService
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  submit() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    this.pending = true;
    this.subscription.add(
      this.contactUsService.postMessage(this.form.value.Message).subscribe(() => {
        this.state = 'success';
        this.pending = false;
      })
    );
  }

  close() {
    this.activeModal.close();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Message: [null, [Validators.required]],
    });
  }
}
