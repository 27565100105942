<div class="container" *ngIf="documents$ | async as docs; else spinner">
  <div *ngIf="!docs.length; else documentsList" class="alert alert-box alert-warning">
    <i class="icon icon-info icon-20 me-2"></i>
    {{ 'HT_PROFILE_MSG_VALIDATION3' | translate }}
  </div>
  <ng-template #documentsList>
    <div class="row">
      <ul class="event-list col-md-12">
        <li *ngFor="let doc of docs">
          <app-preview-document-image
            class="image-container"
            [side]="'front'"
            [documentId]="doc.Id"></app-preview-document-image>
          <div class="info">
            <h2 class="title">{{ doc.DocumentNumber ? doc.DocumentNumber : '' }}</h2>
            <p class="desc">{{ doc.Type ? doc.Type.description : '' }}</p>
            <ul>
              <li class="action" (click)="openDocumentPreview(doc)">
                <a>
                  <span class="fa fa-list-alt"></span>
                  {{ 'C_F_DETAILS' | translate }}
                </a>
              </li>
              <li class="action" (click)="openEditDocumentModal(doc)">
                <a>
                  <span class="fa fa-edit"></span>
                  {{ 'C_A_EDIT' | translate }}
                </a>
              </li>
              <li *ngIf="doc.IsValid" class="badge-success">
                <span class="fas fa-check"></span>
                {{ 'C_F_VALID' | translate }}
              </li>
              <li *ngIf="!doc.Valid" class="badge-danger">
                <span class="fas fa-times"></span>
                {{ 'C_F_EXPIRED' | translate }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <button class="btn btn-primary btn-mt-blue btn-full-width" (click)="openAddDocumentModal()">
        {{ 'HT_PROFILE_ADD_DOCUMENT' | translate }}
      </button>
    </div>
  </ng-template>
</div>
<ng-template #spinner>
  <div class="text-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>
