import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private readonly breadcrumb$ = new Subject();
  readonly changebreadcrumbData = (breadcrum) => this.breadcrumb$.next(breadcrum);
  breadCrumbData = {};
  currentStep = 1;

  get stepsData() {
    return this.breadCrumbData;
  }

  get currentbreadcrumbData(): Observable<unknown> {
    return this.breadcrumb$.asObservable();
  }

  addData(data): void {
    Object.defineProperty(this.breadCrumbData, 'step' + this.currentStep, {
      value: data,
      writable: true,
    });
    this.changebreadcrumbData(this.breadCrumbData);
  }
}
