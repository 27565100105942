import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslationsService } from '@app/core/services/translations.service';
import { AVAILABLE_LANGUAGES } from '@app/shared/constants/available-languages.constants';
import { extractStringRegex } from '@app/shared/constants/regex.constants';
import { IAvailableLanguage } from '@app/interfaces/available-language.interface';
import { environment } from '@env/environment';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '@app/app.config';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('languageDropdown', { static: true }) languageDropdown: NgbDropdown;

  @Input() showNavbarLegacy = true;
  @Input() showNavbarNew = false;
  @Input() register = false;
  showBars = true;
  readonly links: Array<{ label: string; href: string }> = [
    { href: '#/home/public/help', label: 'P_HELP' },
    { href: AppConfig.aboutUsUrl, label: 'P_ABOUT_US' },
    { href: '#/home/public/faq', label: 'P_FAQ' },
    { href: '#/home', label: 'C_F_TERMS_CONDITIONS' },
    { href: AppConfig.contactUrl, label: 'C_CONTACT_US' },
  ];

  readonly newLinks: Array<{ label: string; href: string }> = [
    { href: '#/home/public/help', label: 'P_HELP' },
  ];
  goToRegisterPage: string;
  goToLoginPage: string;

  private destroy$ = new Subject<void>();

  readonly availableLanguages = AVAILABLE_LANGUAGES;
  currentLanguage: IAvailableLanguage;

  constructor(
    private readonly translationsService: TranslationsService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit() {
    this.translationsService.currentLanguage$.pipe(takeUntil(this.destroy$)).subscribe((code) => {
      this.currentLanguage = this.availableLanguages.find((x) => x.code === code);
    });

    const isWpEnabled = environment.wpEnabled;
    this.goToLoginPage = isWpEnabled
      ? `${environment.imtWordpressBaseUrl}${this.extractCountryFromUrl()}/login/`
      : `${environment.imtWordpressBaseUrl}`;
    this.goToRegisterPage = isWpEnabled
      ? `${environment.imtWordpressBaseUrl}${this.extractCountryFromUrl()}/register/`
      : `${environment.imtWordpressBaseUrl}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleLanguageDropdown() {
    // Library is broken - it works as it should:
    if (this.languageDropdown) {
      this.languageDropdown.isOpen() ? this.languageDropdown.open() : this.languageDropdown.close();
    }
  }

  setLanguage(language: IAvailableLanguage) {
    this.translationsService.setLanguage(language.code);
  }

  redirectToLoginPage() {
    window.location.href = this.goToLoginPage;
  }

  redirectToSingUpPage() {
    window.location.href = this.goToRegisterPage;
  }

  handleMenuIsOpen(isOpen: boolean) {
    this.showBars = !isOpen;
  }

  getUrl(): string {
    return this.document.location.href;
  }

  private extractCountryFromUrl(): string {
    const regex = extractStringRegex;
    const matches = regex.exec(this.getUrl());
    let country = matches ? matches[1] : '';
    const conditions = [
      !country,
      country.length === 0,
      !location.origin.includes(environment.imtWordpressBaseUrlNoProtocol),
    ];

    if (country.toLocaleLowerCase() === 'congo') {
      country = 'congo-rdc';
    }

    if (conditions.some((x) => !!x)) {
      country = 'belgium';
    }

    return country;
  }
}
