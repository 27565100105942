import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomersService } from '@app/services/customers.service';
import { BaseComponent } from '@app/shared/components/base/base.component';
import {
  DocumentSide,
  PROFILE_IMAGE_PLACEHOLDER,
} from '@app/shared/constants/profile-images.constants';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-preview-document-image',
  templateUrl: './preview-document-image.component.html',
  styleUrls: ['./preview-document-image.component.scss'],
})
export class PreviewDocumentImageComponent extends BaseComponent implements OnInit {
  @Input() documentId: number;
  @Input() side: DocumentSide;
  @Input() resize = false;
  @Output() readonly source = new EventEmitter<string>();
  isPDF = false;

  image;
  readonly placeholder = PROFILE_IMAGE_PLACEHOLDER;

  constructor(private readonly customerService: CustomersService) {
    super();
  }

  ngOnInit() {
    this.side === 'front'
      ? this.customerService
          .getCustomerIdDocFrontImage(this.documentId)
          .pipe(finalize(() => (this.loading = false)))
          .subscribe((res) => {
            this.image = res;
            this.isPDF = this.getPdf(res);
            this.source.emit(this.image);
          })
      : this.customerService
          .getCustomerIdDocBackImage(this.documentId)
          .pipe(finalize(() => (this.loading = false)))
          .subscribe((res) => {
            this.image = res;
            this.isPDF = this.getPdf(res);
            this.source.emit(this.image);
          });
  }

  getPdf(res): boolean {
    const code = res.substr(res.indexOf(',') + 1, res.length);

    return atob(code).includes('PDF');
  }
}
