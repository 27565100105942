import { Component, Input } from '@angular/core';
import { BaseInputComponent } from '@app/shared/forms/base-input.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
})
export class InputComponent extends BaseInputComponent {
  @Input() numberOnly = false;
  @Input() maxlength: number;
  @Input() invalid = false;
  @Input() requiredErrorText: string;
  @Input() trim = false;

  constructor() {
    super();
    this.formControlType = 'input';
  }

  accepNumberOnly(event) {
    if (this.numberOnly) {
      return event.keyCode === 8 || (event.charCode >= 48 && event.charCode <= 57);
    }

    return;
  }
}
