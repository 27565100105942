/* eslint-disable @typescript-eslint/no-empty-function */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  defer,
  firstValueFrom,
  iif,
  of,
  throwError,
} from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { SessionBasedService } from '@app/core/services/session-based.service';
import { AccountDeleteFromHelpCenterParams } from '@app/interfaces/account/account-delete-from-help-center-params.interface';
import { AccountOperation } from '@app/interfaces/account/account-operation.interface';
import { Account } from '@app/interfaces/account/account.interface';
import { ApiErrorResponse } from '@app/interfaces/api-error-response.interface';
import { ApiResponse } from '@app/interfaces/api-response.interface';
import { CodeRequest } from '@app/interfaces/code-request.interface';
import { ComplianceDoc } from '@app/interfaces/compliance-doc.interface';
import { CatalogCountry } from '@app/interfaces/country-information.interface';
import {
  CreateAdditionalDocumentRequest,
  ICreateDocumentRequest,
} from '@app/interfaces/create-document-request.interface';
import { CustomerDocument } from '@app/interfaces/customer.interface';
import { IEditDocumentRequest } from '@app/interfaces/edit-document-request.interface';
import { RegistrationRequest } from '@app/interfaces/register-request.interface';
import { ResetPasswordRequest } from '@app/interfaces/reset-password-request.interface';
import { RestoreUsernameRequest } from '@app/interfaces/restore-username-request.interface';
import { UserExistsRequest } from '@app/interfaces/user-exists-request.interface';
import { VerifyMailResponse } from '@app/interfaces/verify-mail-response.interface';
import { CompleteProfileService } from '@app/profile-account/profile.service';
import { Customer } from '@app/shared/classes/customer.class';
import { ProductType } from '@app/shared/classes/product.class';
import { RequestStatus } from '@app/shared/classes/request-status.class';
import { DocumentSide } from '@app/shared/constants/profile-images.constants';
import { ProductTypes } from '@app/shared/enums/product-type.enum';
import { dataURItoBlob } from '@app/shared/utils/blob.utils';
import { environment } from '@env/environment';
import { SkipErrorInterceptorHeaders } from '../interceptors/skip-error.interceptor';
import { AlertModalService } from '@app/shared/modals/alert-modal/alert-modal.service';
import { FrogedService } from '@app/services/froged.service';
import { VerifyAddressResponse } from '../models/dto/response';

const INACTIVE_BY_POLICY_STATUS = 409;
const TOO_MANY_REQUESTS_STATUS = 429;

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly ACCOUNT_URL = `${environment.apiBasePath}/online/account`;
  private readonly SMILE_ACCOUNT_URL = `${environment.apiBasePath}/online/account/contact`;
  private readonly REGISTER_URL = `${environment.apiBasePath}/online/customer/registration`;
  private readonly OCCUPATION_URL = `${environment.apiBasePath}/online/customer/occupation/types`;
  private readonly IDENTITY_DOCUMENT_URL = `${this.ACCOUNT_URL}/iddoc`;
  private readonly IDENTITY_ASYLUM_SEEKER_DOCUMENT_URL = `${this.ACCOUNT_URL}/iddoc/asylumseeker`;
  private readonly ADDITIONAL_DOCUMENT_URL = `${this.ACCOUNT_URL}/compliancedoc`;
  private readonly ACCOUNT_DELETE = `${this.ACCOUNT_URL}/delete`;
  private readonly ACCOUNT_DELETE_HELP = `${environment.apiBasePath}/public/account/delete`;
  private readonly CUSTOMER_CODE_URL = `${this.REGISTER_URL}/existent`;
  private readonly VERIFY_MAIL_URL = `${this.REGISTER_URL}/confirm/mail`;
  private readonly VERIFY_ADDRESS_URL = `${environment.apiBasePath}/customer/addresses/confirmation/{{hash}}`;
  private readonly CHECK_FOR_POA = `${environment.apiBasePath}/account/validationJustificationPoa`;
  private readonly CREATE_FRESHDESK_TICKET = `${environment.apiBasePath}/account/ticket`;
  private readonly COUNSENT_URL = `${environment.apiBasePath}/online/customer/consent`;
  private readonly OPERATIONS_URL = `${environment.apiBasePath}/account/operations`;
  private readonly FORGOT_PASSWORD_URL = `${environment.apiBasePath}/online/customer/registration/forgot`;
  private readonly RESET_PASSWORD_URL = `${environment.apiBasePath}/online/customer/registration/password`;
  private readonly RESEND_CONFIRMATION_URL = `${environment.apiBasePath}/online/customer/registration/resend`;
  private readonly REQUEST_CODE_URL = `${environment.apiBasePath}/online/customer/registration/requestCode`;
  private readonly RESTORE_USERNAME_URL = `${environment.apiBasePath}/online/customer/registration/username`;
  private readonly SURVEY_COMPLETE_URL = `${environment.apiBasePath}/online/customer/completesurvey`;
  private readonly AGENTPRODUCTS_URL = `${environment.apiBasePath}/public/request/agentproducts`;
  private readonly _account: BehaviorSubject<Account> = new BehaviorSubject<Account>(null);
  private readonly _accountOperations = new BehaviorSubject<AccountOperation>(null);
  private readonly _occupationTypes = new BehaviorSubject<string[]>([]);
  private readonly accountOperations$ = this._accountOperations.asObservable();
  private readonly occupationTypes$ = this._occupationTypes.asObservable();
  /* Hot OBS */
  public readonly account$ = this._account.asObservable();

  editable: boolean;
  account: Account = null;
  documentId: number;

  currentOccupationTypes(): Observable<string[]> {
    return this.occupationTypes$.pipe(
      switchMap((types) => iif(() => !!types.length, of(types), this.fetchOccupationTypes()))
    );
  }

  currentEditable(): Observable<boolean> {
    return typeof this.editable !== 'undefined' ? of(this.editable) : this.fetchEditable();
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly sessionBasedService: SessionBasedService,
    private readonly profileService: CompleteProfileService,
    private readonly frogedService: FrogedService,
    private readonly alertModalService: AlertModalService,
    private readonly translateService: TranslateService
  ) {
    this.sessionBasedService.clearData$.subscribe(() => {
      this._account.next(null);
      this._occupationTypes.next([]);
      this.editable = null;
      this._accountOperations.next(null);
    });
    this.account$.subscribe((res) => {
      this.account = res;
      if (this.account) {
        this.frogedService.set({
          email: this.account.Email,
          name: `${this.account.Name} ${this.account.LastName}`,
        });
      }
    });
    if (this.account) {
      this.validatePersonalData();
    }
  }

  createAdditionalDocument(requestData: CreateAdditionalDocumentRequest) {
    const { file1, Type } = requestData;
    const request = new FormData();
    request.append('file', dataURItoBlob(file1));

    return this.httpClient.post<ApiResponse<number>>(
      `${this.ADDITIONAL_DOCUMENT_URL}/${Type}`,
      request
    );
  }

  editDocument(request: IEditDocumentRequest): Observable<ApiResponse<ApiErrorResponse>> {
    const { Message, DocumentId } = request;
    const body = { Message };

    return this.httpClient.post<ApiResponse<ApiErrorResponse>>(
      `${this.ACCOUNT_URL}/document/${DocumentId}/RequestModification`,
      body
    );
  }

  createDocument(request: ICreateDocumentRequest) {
    const { file1 } = request;

    return this.httpClient.post<ApiResponse<number>>(this.IDENTITY_DOCUMENT_URL, request).pipe(
      switchMap(({ Data }) => {
        this.documentId = Data;

        return this.uploadDocumentImage(this.documentId, file1, 'front');
      })
    );
  }

  createAsylumSeekerDocument(request: ICreateDocumentRequest) {
    const { file1 } = request;

    return this.httpClient
      .post<ApiResponse<number>>(this.IDENTITY_ASYLUM_SEEKER_DOCUMENT_URL, request)
      .pipe(
        switchMap(({ Data }) => {
          this.documentId = Data;

          return this.uploadDocumentImage(this.documentId, file1, 'front');
        })
      );
  }

  createDocumentBack(request: ICreateDocumentRequest) {
    const { file2 } = request;

    return this.uploadDocumentImage(this.documentId, file2, 'back');
  }

  getProductsActivesByPartnerSystem(id: number): Observable<string[]> {
    return this.httpClient.get<ApiResponse<ProductType[]>>(`${this.AGENTPRODUCTS_URL}/${id}`).pipe(
      map((res) => {
        if (!res?.Data) {
          return [];
        }

        return res.Data.reduce((acc, cur) => {
          const condition =
            (cur.Id === ProductTypes.PAC ||
              cur.Id === ProductTypes.IMT ||
              cur.Id === ProductTypes.MTR) &&
            cur.Status === 1;

          if (condition) {
            acc.push(cur.Id);
          }

          return acc;
        }, []);
      })
    );
  }

  createAccount(registerRequest: RegistrationRequest): Observable<ApiResponse<RequestStatus>> {
    const _request = { ...registerRequest, Email: registerRequest.Email.toLowerCase() };
    return this.httpClient.post<ApiResponse<RequestStatus>>(`${this.REGISTER_URL}/new`, _request);
  }

  createFreshDeskTicket(): Observable<ApiResponse<number>> {
    return this.httpClient.post<ApiResponse<number>>(`${this.CREATE_FRESHDESK_TICKET}`, {});
  }

  checkCodeCustomer(data: { CodeCustomer: ''; BirthDate: '' | Date }): Observable<Customer> {
    return this.httpClient
      .post<ApiResponse<Customer>>(`${this.CUSTOMER_CODE_URL}`, data, {
        headers: new SkipErrorInterceptorHeaders(),
      })
      .pipe(map((res) => res.Data));
  }

  verifyMail(data: { code: string; login: string }): Observable<VerifyMailResponse> {
    return this.httpClient
      .post<
        ApiResponse<VerifyMailResponse>
      >(`${this.VERIFY_MAIL_URL}?code=${data.code}&login=${data.login.toLowerCase()}`, data)
      .pipe(map((res) => res.Data));
  }

  verifyAddress(hash: string): Observable<VerifyAddressResponse> {
    const url = this.VERIFY_ADDRESS_URL.replace('{{hash}}', hash);
    return this.httpClient
      .patch<ApiResponse<VerifyAddressResponse>>(url, null)
      .pipe(map((res) => res.Data));
  }

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Uploads a document image to the server.
   * @param documentId The document id
   * @param imageDataURI The image data URI
   * @param documentSide The document side, either 'front' or 'back'
   * @returns An observable of the server response
   */
  /******  79d04ffa-9d32-4b17-a52a-7c214d2bed6d  *******/
  uploadDocumentImage(
    documentId: number,
    imageDataURI: string,
    documentSide: DocumentSide
  ): Observable<ApiResponse<ApiErrorResponse>> {
    const side = documentSide === 'front' ? 'picturefront' : 'pictureback';
    const url = `${this.IDENTITY_DOCUMENT_URL}/${documentId}/${side}`;
    const request = new FormData();
    request.append('file', dataURItoBlob(imageDataURI));

    return this.httpClient.post<ApiResponse<ApiErrorResponse>>(url, request);
  }

  fetchAccount(forceRefresh = false): Observable<Account> {
    if (this._account.getValue() && !forceRefresh) {
      return this.account$.pipe(take(1));
    }
    return this.httpClient.get<ApiResponse<Account>>(`${this.ACCOUNT_URL}`).pipe(
      map((res) => res.Data),
      tap((res) => this._account.next(res)),
      catchError((err) => throwError(() => err))
    );
  }

  refreshAccount(): Promise<Account> {
    return firstValueFrom(this.fetchAccount(true));
  }

  fetchAccountAsync(): Observable<Account> {
    if (this.account) {
      return of(this.account);
    }
    return this.fetchAccount();
  }

  completeSurvey(): Observable<boolean> {
    return this.httpClient
      .get<ApiResponse<boolean>>(`${this.SURVEY_COMPLETE_URL}`)
      .pipe(map((res) => res.Data));
  }

  fetchOccupationTypes(): Observable<string[]> {
    return this.httpClient.get<ApiResponse<string[]>>(`${this.OCCUPATION_URL}`).pipe(
      map((res) => res.Data),
      tap((res) => this._occupationTypes.next(res)),
      catchError((err) => throwError(() => err))
    );
  }

  fetchEditable(): Observable<boolean> {
    return this.httpClient.get<ApiResponse<boolean>>(`${this.ACCOUNT_URL}/editable`).pipe(
      map((res) => res.Data),
      tap((res) => (this.editable = res))
    );
  }

  // If DateImageInsertion is null, it means that the process with idNow was not completed
  fetchIdentityDocuments(): Observable<CustomerDocument[]> {
    return this.httpClient
      .get<ApiResponse<CustomerDocument[]>>(`${this.IDENTITY_DOCUMENT_URL}`)
      .pipe(map((res) => res.Data.filter((d) => d.DateImageInsertion !== null)));
  }

  identityDocumentStatus(): Observable<{
    valid: boolean;
    pending: boolean;
    validOrPending: boolean;
  }> {
    return this.fetchIdentityDocuments().pipe(
      map((docs) => ({
        valid: docs && docs.some((doc) => doc.Valid),
        pending: docs && docs.some((doc) => doc.DocumentNumber.includes('DOC PENDING')),
        validOrPending:
          docs && docs.some((doc) => doc.Valid || doc.DocumentNumber.includes('DOC PENDING')),
      }))
    );
  }

  hasIdentityDocumentsByCountry(partnerSystem: CatalogCountry): Observable<boolean> {
    return this.fetchIdentityDocuments().pipe(
      map(
        (docs) => docs && docs.some((d) => d.Valid && this._documentTypeExclude(d, partnerSystem))
      )
    );
  }

  hasIdentityOrPendingDocumentsByCountry(partnerSystem: CatalogCountry): Observable<boolean> {
    return this.fetchIdentityDocuments().pipe(
      map(
        (docs) =>
          docs &&
          docs.some(
            (d) =>
              (d.Valid || d.DocumentNumber.includes('DOC PENDING')) &&
              this._documentTypeExclude(d, partnerSystem)
          )
      )
    );
  }

  hasValidProofOfAddressDocuments(): Observable<boolean> {
    return this.httpClient
      .get<ApiResponse<boolean>>(`${this.CHECK_FOR_POA}`)
      .pipe(map((res) => res.Data));
  }

  fetchAdditionalDocuments(): Observable<ComplianceDoc[]> {
    return this.httpClient
      .get<ApiResponse<ComplianceDoc[]>>(`${this.ADDITIONAL_DOCUMENT_URL}`)
      .pipe(map((res) => res.Data));
  }

  userExists(request: UserExistsRequest): Observable<boolean> {
    const safetyTime = localStorage.getItem('safetyTime');
    const remainTime = new Date(Number(safetyTime)).getTime() - new Date().getTime();
    const minutes = Math.floor(remainTime / (1000 * 60));

    if (minutes > 1) {
      this.showForgotWarningAlert(minutes);
      return of(false);
    }

    localStorage.removeItem('safetyTime');

    const _request = { ...request, Email: request.Email?.toLowerCase() };
    return this.httpClient.post<ApiResponse<string>>(this.FORGOT_PASSWORD_URL, _request).pipe(
      map(() => true),
      catchError((error) => {
        const now = new Date();
        switch (error.status) {
          case INACTIVE_BY_POLICY_STATUS:
            this.alertModalService.danger(
              [this.translateService.instant('COM_ACCOUNT_INACTIVE_BY_POLICY')],
              '',
              'C_OK'
            );
            break;

          case TOO_MANY_REQUESTS_STATUS:
            localStorage.setItem(
              'safetyTime',
              `${now.setMinutes(now.getMinutes() + environment.safetyTimeMinutes)}`
            );
            this.showForgotWarningAlert(environment.safetyTimeMinutes);
            break;

          default:
            return throwError(() => error);
        }
        return of(false);
      })
    );
  }

  resendConfirmation(request: UserExistsRequest): Observable<boolean> {
    const _request = { ...request, Email: request.Email.toLowerCase() };
    return this.httpClient.post<ApiResponse<string>>(this.RESEND_CONFIRMATION_URL, _request).pipe(
      map(() => true),
      catchError((error) => {
        switch (error.status) {
          case INACTIVE_BY_POLICY_STATUS:
            this.alertModalService.danger(
              [this.translateService.instant('COM_ACCOUNT_INACTIVE_BY_POLICY')],
              '',
              'C_OK'
            );
            break;
          default:
            return throwError(() => error);
        }
        return of(false);
      })
    );
  }

  requestCode(request: CodeRequest): Observable<boolean> {
    const _request = { ...request, email: request.email.toLowerCase() };
    return this.httpClient.post<ApiResponse<string>>(this.REQUEST_CODE_URL, _request).pipe(
      map(({ Data }) => !!Data),
      catchError((error) => {
        switch (error.status) {
          case INACTIVE_BY_POLICY_STATUS:
            this.alertModalService.danger(
              [this.translateService.instant('COM_ACCOUNT_INACTIVE_BY_POLICY')],
              '',
              'C_OK'
            );
            break;
          default:
            return throwError(() => error);
        }
        return of(false);
      })
    );
  }

  restoreUsername(request: RestoreUsernameRequest): Observable<string> {
    return this.httpClient
      .post<ApiResponse<string>>(this.RESTORE_USERNAME_URL, request)
      .pipe(map((res) => res.Data));
  }

  resetPassword(request: ResetPasswordRequest): Observable<unknown> {
    const _request = { ...request, login: request.login.toLowerCase() };
    return this.httpClient
      .put<ApiResponse<unknown>>(this.RESET_PASSWORD_URL, _request, {
        headers: new SkipErrorInterceptorHeaders(),
      })
      .pipe(
        catchError((error) => {
          switch (error.status) {
            case INACTIVE_BY_POLICY_STATUS:
              this.alertModalService.danger(
                [this.translateService.instant('COM_ACCOUNT_INACTIVE_BY_POLICY')],
                '',
                'C_OK'
              );
              break;
            default:
              return throwError(() => error);
          }
          return of(false);
        })
      );
  }

  putAccount(newAccount: Partial<Account>): Observable<Account> {
    return this.httpClient.put<ApiResponse<boolean>>(`${this.ACCOUNT_URL}`, newAccount).pipe(
      catchError((err) => throwError(() => err)),
      switchMap(() => this.httpClient.get<ApiResponse<Account>>(`${this.ACCOUNT_URL}`)),
      catchError((err) => throwError(() => err)),
      map((res) => res.Data),
      tap((res) => this._account.next(res))
    );
  }

  deleteAccount(reason: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponse<boolean>>(`${this.ACCOUNT_DELETE}`, { Reason: reason })
      .pipe(
        switchMap((res) =>
          iif(
            () => !!res.Data,
            defer(() => of(res.Data)),
            defer(() => throwError(() => res.Data))
          )
        )
      );
  }

  deleteAccountFromHelpCenter(
    params: AccountDeleteFromHelpCenterParams,
    countryId: string
  ): Observable<boolean> {
    return this.httpClient
      .post<ApiResponse<boolean>>(`${this.ACCOUNT_DELETE_HELP}/${countryId}`, {
        ...params,
      })
      .pipe(map(({ Data }) => Data));
  }

  putSmileAccount(newAccount: Partial<Account>): Observable<Account> {
    return this.httpClient.put(`${this.SMILE_ACCOUNT_URL}`, newAccount).pipe(
      catchError(() => EMPTY),
      switchMap(() => this.fetchAccount())
    );
  }

  putConsent(consent: boolean) {
    return this.httpClient.put(`${this.COUNSENT_URL}/${consent}`, {});
  }

  fieldsForValidation(): string[] {
    return [
      'Name',
      'LastName',
      'Address',
      'IdNationality',
      'UserMail',
      'BirthDate',
      'Telephone',
      'OccupationType',
      'Gender',
      'NationalCitizenNumber',
      'City',
      'PostalCode',
      'Street',
      'CountryId',
    ];
  }

  validatePersonalData(): boolean | [boolean, string[]] {
    if (
      !this.account.LastName ||
      !this.account.Name ||
      !this.account.IdNationality ||
      this.account.IdNationality < 1 ||
      !this.account?.Address.Street ||
      !this.account.Address.PostalCode ||
      !this.account.Address.CountryId ||
      this.account.Address.CountryId < 1 ||
      !this.account.Address?.City?.Id ||
      this.account.Address.City.Id < 1 ||
      !this.account.UserMail ||
      !this.account.BirthDate ||
      !this.account.Telephone ||
      !this.account.OccupationType
    ) {
      this.profileService.IncompleteInformation = true;

      return false;
    }

    return true;
  }

  getMissingData(): string[] {
    const missingData: string[] = [];
    const objectKeys = Object.keys(this.account ?? {});
    objectKeys.forEach((key) => {
      const field = this.fieldsForValidation().find((miss) => miss === key);
      if (!field) {
        return;
      }
      if (!this.account[key]) {
        this.searchGeneralMissingKeys(key, field, missingData);
      } else if (key === 'Address') {
        this.searchMissingAddressKeys(missingData);
      }
    });
    this.setIfHasIncompleteInfo(missingData);
    return missingData;
  }

  private searchGeneralMissingKeys(key: string, field: string, missingData: string[]): void {
    if (
      (key === 'NationalCitizenNumber' && this.account.RequiresNationalCitizenNumber) ||
      (key !== 'Address' && key !== 'NationalCitizenNumber')
    ) {
      missingData.push(field);
    }
  }

  private searchMissingAddressKeys(missingData: string[]): void {
    const addressKeys = Object.keys(this.account.Address);
    addressKeys.forEach((addKey) => {
      if (!this.account.Address[addKey] && addKey !== 'StreetLetter' && addKey !== 'StreetNumber') {
        missingData.push(addKey);
      }
    });
  }

  setIfHasIncompleteInfo(missingData) {
    if (missingData.length === 0) {
      this.profileService.IncompleteInformation = false;
    }
  }

  getAccountOperations(): Observable<AccountOperation> {
    if (this._accountOperations.getValue()) {
      return this.accountOperations$.pipe(take(1));
    }
    return this.httpClient.get<ApiResponse<AccountOperation>>(`${this.OPERATIONS_URL}`).pipe(
      map((res) => res.Data),
      tap((value) => this._accountOperations.next(value))
    );
  }

  private _documentTypeExclude(document: CustomerDocument, partnerSystem: CatalogCountry): boolean {
    const partnerCountryId = partnerSystem ? partnerSystem.Id : 0;
    const partnerCountryIsoCode = partnerSystem ? partnerSystem.Iso3 : '';
    const documentsTypesCodeForCheck = ['PASS', 'NICD', 'STID', 'LRPT', 'RECD'];
    const countryWithExternalPassport = ['ESP'];
    if (countryWithExternalPassport.includes(partnerCountryIsoCode)) {
      return documentsTypesCodeForCheck.includes(document.Type?.code);
    } else {
      return document.IdDeliveryCountry === partnerCountryId;
    }
  }

  private showForgotWarningAlert(minutes: number) {
    this.alertModalService.warning(
      [`${this.translateService.instant('HELP_FORGOT_WARNING_MSG', { minutes })}`],
      '',
      'C_A_CLOSE',
      () => {},
      [],
      () => {},
      'warning-alert'
    );
  }
}
