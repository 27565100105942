import * as html2pdf from 'html2pdf.js';

export function dataURItoBlob(dataURI: string): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const buffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(buffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([buffer], { type: mimeString });
}

export function downloadHtmlAsPdfFile(htmlId: string, fileName: string, showLogo?: boolean) {
  window.scrollTo(0, 0);
  const element = document.getElementById(htmlId);

  const opt = {
    margin: showLogo ? [25, 0.1] : 0.1,
    filename: `${fileName}.pdf`,
    image: { type: 'jpeg', quality: 0.95 },
    html2canvas: { dpi: 192, letterRendering: true },
    jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
  };

  html2pdf()
    .from(element)
    .set(opt)
    .toPdf()
    .get('pdf')
    .then((pdf) => {
      if (showLogo) {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.addImage('assets/scss/theme/images/logo.png', 'PNG', 10, 10, 80, 15);
        }
      }
    })
    .save();
}

export function downloadPdfFile(base64Data: string, fileName: string) {
  // Decode base64 string
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link to download the file
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  // Clear the DOM
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}
