export enum FormStatus {
  PENDING = 'PENDING',
  VALID = 'VALID',
  INVALID = 'INVALID',
  DISABLED = 'DISABLED',
}

export const ERRORS_CATALOG = [
  'required',
  'sendEmail',
  'email',
  'minlength',
  'maxlength',
  'maxDate',
  'minDate',
  'underLegalAge',
  'alphaNumeric',
  'mustMatch',
  'mustPasswordMatch',
  'floatOnly',
  'existIban',
  'isCurrentAccountIban',
  'maxLength',
  'invalidPatternEmail',
  'misformatIban',
  'documentExpired',
  'documentNotIssued',
  'validatePhoneNumber',
  'pattern',
  'invalidIban',
  'userMail',
  'invalid',
  'repeteadCharacters',
  'specialCharacters',
  'doubleWhiteSpace',
  'noFounds',
];
