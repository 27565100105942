import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompleteProfileService {
  menuMobile$ = new BehaviorSubject([]);
  incompleteInformation: boolean;

  set IncompleteInformation(complete: boolean) {
    this.incompleteInformation = complete;
  }

  get IncompleteInformation(): boolean {
    return this.incompleteInformation;
  }
}
