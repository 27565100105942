<div class="modal-header">
  <div class="modal-header__icon"><i class="fas fa-comments"></i></div>
  <div class="modal-header__title">{{ 'M_CONTACT_WITH_US' | translate }}!</div>
</div>
<div class="modal-body">
  <div class="contact-us-modal">
    <ng-container [ngSwitch]="state">
      <ng-container *ngSwitchCase="'form'">
        <div class="contact-us-modal__title">{{ 'M_WRITE_MESSAGE' | translate }}:</div>
        <form class="contact-us-modal__form" [formGroup]="form" [class.disabled]="pending">
          <app-textarea
            [fullWidth]="true"
            [formGroup]="form"
            [isSubmitted]="isSubmitted"
            [options]="inputs.Message"></app-textarea>
        </form>
      </ng-container>
      <ng-container *ngSwitchCase="'success'">
        <div class="contact-us-modal__success">
          <div class="contact-us-modal__success__icon">
            <i class="icon icon-assistance-fill icon-64"></i>
          </div>
          <div class="contact-us-modal__success__title">
            {{ 'M_SEND_MESSAGE_SUCCESS' | translate }}!
          </div>
          <div class="contact-us-modal__success__sub-title">
            {{ 'M_WE_WILL_CONTACT_YOU_SHORTLY' | translate }}.
            <br />
            {{ 'C_THANKS' | translate }}.
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="'form'">
      <app-button (click)="submit()" [pending]="pending">
        <i class="far fa-comment"></i>
        {{ 'C_A_SEND_MESSAGE' | translate }}
      </app-button>
      <app-button color="secondary" (click)="close()">
        <i class="fas fa-times-circle"></i>
        {{ 'C_A_CLOSE' | translate }}
      </app-button>
    </ng-container>
    <ng-container *ngSwitchCase="'success'">
      <app-button color="success" (click)="close()">
        <i class="fas fa-check"></i>
        {{ 'C_OK' | translate }}!
      </app-button>
    </ng-container>
  </ng-container>
</div>
