import { Pipe, PipeTransform } from '@angular/core';

interface CurrencySignMap {
  code: string;
  sign: string;
}

@Pipe({
  name: 'currencySign',
  standalone: true,
})
export class CurrencySignPipe implements PipeTransform {
  private readonly currency: CurrencySignMap = { code: 'EUR', sign: '€' };
  transform(value: string): string {
    return value ? value.replace(this.currency.code, this.currency.sign) : '';
  }
}
