<button
  #button
  [type]="type"
  [disabled]="disabled || pending"
  class="btn {{ colorClass }}"
  [ngClass]="{ 'full-width': fullWidth }">
  <ng-container [ngSwitch]="pending">
    <ng-container *ngSwitchCase="true">
      <div class="btn__spinner" @fadeIn [@.disabled]="disableAnimation">
        <i class="fas fa-spinner fa-pulse spinner"></i>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="btn__content" @fadeIn [@.disabled]="disableAnimation">
        <ng-content></ng-content>
      </div>
    </ng-container>
  </ng-container>
</button>
