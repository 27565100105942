import { DEFAULT_QUERY_PARAMS } from '../constants/api-params.constants';

export class PaginatedRequest {
  [key: string]: unknown;
  StartRow: number = DEFAULT_QUERY_PARAMS.StartRow;
  RowQty = DEFAULT_QUERY_PARAMS.RowQty;
  IsPaginated = DEFAULT_QUERY_PARAMS.IsPaginated;
  RequestStatusCode = DEFAULT_QUERY_PARAMS.RequestStatusCode;

  constructor(filters = {}, page?: number) {
    Object.entries(filters).forEach(([key, value]) => (this[key] = value));
    if (page) {
      this.StartRow = (page - 1) * this.RowQty;
    }
  }
}
