import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, map } from 'rxjs';
import moment from 'moment';
import { AccountService } from '@app/core/services/account.service';
import { CommonService } from '@app/core/services/common.service';
import { fadeIn, fadeOut } from '@app/shared/animations/fade.animation';
import { Account } from '@app/interfaces/account/account.interface';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { emailRegex, onlyLettersRegex } from '@app/shared/constants/regex.constants';
import { IFormInputs } from '@app/interfaces/forms.interface';
import { DateValidators } from '@app/shared/validators/date.validator';

@Component({
  selector: 'app-account-modal-personal-data',
  templateUrl: './account-modal-personal-data.component.html',
  animations: [fadeIn, fadeOut],
})
export class AccountModalPersonalDataComponent extends BaseComponent implements OnInit {
  account: Account;

  inputs: IFormInputs = {
    Name: {
      formControlNameValue: 'Name',
      label: 'C_F_NAME',
      type: 'text',
    },
    LastName: {
      formControlNameValue: 'LastName',
      label: 'C_F_LASTNAME',
      type: 'text',
    },
    BirthDate: {
      formControlNameValue: 'BirthDate',
      label: 'C_F_DOB',
    },
    Email: {
      formControlNameValue: 'Email',
      label: 'C_F_EMAIL',
      type: 'email',
    },
    Telephone: {
      formControlNameValue: 'Telephone',
      label: 'C_F_PHONE',
      type: 'text',
    },
    Gender: {
      formControlNameValue: 'Gender',
      label: 'C_F_GENDER',
      optionLabel: 'label',
      optionValue: 'value',
      options: [
        {
          value: 1,
          label: 'C_F_MALE',
        },
        {
          value: 2,
          label: 'C_F_FEMALE',
        },
      ],
    },
    Occupation: {
      formControlNameValue: 'Occupation',
      label: 'C_F_OCCUPATION',
      options: [],
    },
  };

  contactPhone$ = this.commonService.currentContactPhone;
  editable: boolean;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly accountService: AccountService,
    private readonly commonService: CommonService,
    private readonly toastr: ToastrService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.initForm();
    combineLatest([
      this.accountService.currentOccupationTypes(),
      this.accountService.currentEditable(),
    ])
      .pipe(
        map(([options, editable]) => ({
          options,
          editable,
        }))
      )
      .subscribe(({ options, editable }) => {
        this.inputs.Occupation.options = options;
        this.editable = editable;
        this.observeAccount();
        this.loading = false;
      });
  }

  submit() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    this.pending = true;
    const value = this.form.getRawValue();
    const parsedValues: Partial<Account> = {
      BirthDate: moment(value.BirthDate).format(),
      Code: this.account.Code,
      Email: value.Email,
      FiscalCode: this.account.FiscalCode,
      Gender: value.Gender,
      IdNationality: this.account.IdNationality,
      LastName: value.LastName,
      Name: value.Name,
      Occupation: value.Occupation,
      OccupationType: value.Occupation,
      Telephone: value.Telephone,
      Address: {
        City: this.account.Address.City,
        CountryId: this.account.Address.CountryId,
        PostalCode: this.account.Address.PostalCode,
        Street: this.account.Address.Street,
        StreetLetter: this.account.Address.StreetLetter,
        StreetNumber: this.account.Address.StreetNumber,
      },
    };

    this.accountService.putAccount(parsedValues).subscribe({
      next: () => this.toastr.success(this.translateService.instant('MSG_ACCOUNT_SAVED')),
      complete: () => (this.pending = false),
    });
  }

  private initForm() {
    const regex = new RegExp(onlyLettersRegex);
    this.form = this.formBuilder.group({
      Name: [null, [Validators.required, Validators.pattern(regex)]],
      LastName: [null, [Validators.required, Validators.pattern(regex)]],
      BirthDate: [
        null,
        [Validators.required, DateValidators.max(new Date()), DateValidators.isLegalAge()],
      ],
      Email: [null, [Validators.required, Validators.pattern(emailRegex), Validators.minLength(3)]],
      Telephone: [null, [Validators.required]],
      Gender: [null, [Validators.required]],
      Occupation: [null, []],
    });
  }

  private observeAccount() {
    setTimeout(() => {
      this.subscription.add(
        this.accountService.fetchAccount().subscribe((res) => {
          this.account = res;
          this.form.controls.Name.patchValue(res.Name);
          this.form.controls.LastName.patchValue(res.LastName);
          this.form.controls.BirthDate.patchValue(res.BirthDate);
          this.form.controls.Email.patchValue(res.UserMail);
          this.form.controls.Telephone.patchValue(res.Telephone);
          this.form.controls.Gender.patchValue(res.Gender);
          this.form.controls.Occupation.patchValue(res.OccupationType);
          Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.controls[key];
            const value = control.value;
            const validator = control.validator ? control.validator({} as AbstractControl) : null;
            if (value && validator && validator.required) {
              control.disable();
            }
          });
        })
      );
    });
  }
}
