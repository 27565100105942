<div class="group row date" [formGroup]="dateFormGroup">
  <label *ngIf="!options?.unlabelled" class="col-lg-4 date__title" [class.col-lg-12]="fullWidth">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div class="col-lg" [class.col-lg-12]="fullWidth">
    <div
      class="input-group date__container"
      [class.is-invalid-amount]="isInvalid"
      [class.input-group__disabled]="isDisabled">
      <ng-container *ngFor="let item of inputsConfig; let i = index">
        <div>
          <label class="date__label" for="">{{ item.translateCode | translate }}</label>
          <input
            inputmode="numeric"
            class="date__input"
            type="text"
            [class.invalid-value]="
              isInvalidValue ||
              isInvalidDate ||
              isInvalidBirthdate ||
              _requiredError ||
              isRequiredError
            "
            [class.focus]="i === currentIndexInputFocus"
            [name]="item.name"
            [maxlength]="item.todalDigits"
            [placeholder]="item.placeholder | translate"
            [formControlName]="item.name"
            (focus)="onFocus(i)"
            (keypress)="keyPressNumbers($event)"
            (focusout)="onFocusOut(item.name)"
            autocomplete="off"
            [readonly]="isDisabled" />
        </div>
      </ng-container>
    </div>
    <div class="invalid-feedback" *ngIf="!options.hideErrors && !isInvalidBirthdate && !isInvalidDate && !isInvalidValue && isRequiredError">
      <span class="msg-error">
        {{ requiredErrorText || 'VAL_MSG_REQUIRED' | translate }}
      </span>
    </div>
    <div class="invalid-feedback" *ngIf="isInvalidValue || isInvalidDate">
      <span class="msg-error">
        {{ 'ERROR_DATE_INVALID' | translate }}
      </span>
    </div>
    <div
      class="invalid-feedback"
      *ngIf="!isInvalidBirthdate && !isInvalidDate && !isInvalidValue && _requiredError">
      <span class="msg-error">
        {{ 'VAL_MSG_REQUIRED' | translate }}
      </span>
    </div>
    <div class="invalid-feedback" *ngIf="isInvalidBirthdate && !isExpiryDate && !isIssuingDate">
      <span class="msg-error">
        {{ 'HT_VAL_GREATER_THAN_18' | translate }}
      </span>
    </div>
    <div class="invalid-feedback" *ngIf="options?.helper">
      <span class="form-text text-muted">
        {{ options.helper | translate }}
      </span>
    </div>
  </div>
</div>
