export const AppConfig = {
  appName: 'Moneytrans',
  appFullName: 'Moneytrans Payment Services S.A.',
  mainUrlSimplified: 'www.moneytrans.eu',
  mainUrl: 'https://www.moneytrans.eu',
  contactUrl: 'https://www.moneytrans.eu/belgium/en/contact/',
  easyCardUrl: 'https://www.moneytrans.eu/belgium/en/moneytrans-easycard/',
  aboutUsUrl: 'https://www.moneytrans.eu/belgium/es/about-us/',
  blogUrl: 'https://www.moneytrans.eu/moneytransblog/en/',
  paymentSupportEmail: 'onlinerdc@moneytrans.cd',
  supportEmail: 'onlinesupport@moneytrans.eu',
  supportPhone: '+32 2 227 18 20',
  supportPhoneAlt: '+34 95 101 5501',
  supportPhoneIT: '+39 02 287 0725',
  supportPhoneNL: '+31 10 205 2453',
  footerDisclaimer: 'Made with ❤️ by Moneytrans Team ©',
  contactPageEnabled: true,
};
