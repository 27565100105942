import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from '@app/core/services/session.service';
import { Language } from '@app/shared/enums/language-enum';
import { Calendar } from '@app/interfaces/calendar.interface';
import { CatalogCountry } from '@app/interfaces/country-information.interface';
import { ICountry } from '@app/interfaces/country.interface';
import { FrequentlyAskedQuestion } from '@app/interfaces/frequently-asked-questions.interface';
import { PpcQuestion } from '@app/interfaces/ppc-question.interface';
import { CardReplacementCause } from '@app/interfaces/smile-account/card-replacement-cause.interface';
import { SmileAccountFees } from '@app/interfaces/smile-account/smile-account-fees.interface';
import { SmileAccountLimit } from '@app/interfaces/smile-account/smile-account-limits.interface';
import { environment } from '@env/environment';
import calendarNames from '@translations/calendar';
import countriesEn from '@translations/countries/countries_en.json';
import countriesEs from '@translations/countries/countries_es.json';
import countriesFr from '@translations/countries/countries_fr.json';
import countriesIt from '@translations/countries/countries_it.json';
import countriesNl from '@translations/countries/countries_nl.json';
import countriesUK from '@translations/countries/countries_uk.json';
import faqs from '@translations/faqs';
import smileAccountDetails from '@translations/smile-account-details';
import { CookiesService } from '@app/services/cookies.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  currentLanguage$: BehaviorSubject<Language>;
  readonly localStorageLanguageName = 'mt-language';
  defaultLanguage: Language = 'en';
  defaultPartnerSystem = 'BEL';
  partnerSystem: CatalogCountry;
  constructor(
    private readonly translateService: TranslateService,
    private readonly sessionService: SessionService,
    private readonly cookiesService: CookiesService
  ) {
    const wpLanguage = this.cookiesService.getCookie(environment.wpSiteLanguageCookie) as Language;
    const loggedByPWA = this.sessionService.loggedByPWA;
    if ((environment.wpEnabled || loggedByPWA) && wpLanguage) {
      this.defaultLanguage = wpLanguage;
    }
    this.currentLanguage$ = new BehaviorSubject<Language>(this.defaultLanguage);
  }

  setPartnerSystem(country: CatalogCountry) {
    this.partnerSystem = country;
  }

  get countries(): ICountry[] {
    switch (this.currentLanguage$.getValue()) {
      case 'en':
        return countriesEn;
      case 'es':
        return countriesEs;
      case 'fr':
        return countriesFr;
      case 'it':
        return countriesIt;
      case 'nl':
        return countriesNl;
      case 'uk':
        return countriesUK;
      default:
        return countriesEn;
    }
  }

  get imtFrequentlyAskedQuestions(): FrequentlyAskedQuestion[] {
    const lang = this.currentLanguage$.getValue();

    return faqs.products.IMT[lang] || faqs.products.IMT.en;
  }

  get generalFrequentlyAskedQuestions(): FrequentlyAskedQuestion[] {
    const lang = this.currentLanguage$.getValue();

    return faqs.general[lang] || faqs.general.en;
  }
  get ppcFrequentlyAskedQuestions(): PpcQuestion {
    const lang = this.currentLanguage$.getValue();

    return faqs.ppc.PPC[lang] || faqs.ppc.PPC.en;
  }

  get smileAccountLimits(): SmileAccountLimit[] {
    const lang = this.currentLanguage$.getValue();

    return (
      smileAccountDetails.limits[lang][this.partnerSystem?.Iso3] ||
      smileAccountDetails.limits[this.defaultLanguage][this.defaultPartnerSystem]
    );
  }

  get smileAccountFees(): SmileAccountFees {
    const lang = this.currentLanguage$.getValue();

    return (
      smileAccountDetails.fees[lang][this.partnerSystem?.Iso3] ||
      smileAccountDetails.fees[this.defaultLanguage][this.defaultPartnerSystem]
    );
  }

  get cardReplacementCauses(): CardReplacementCause[] {
    const lang = this.currentLanguage$.getValue();

    return (
      smileAccountDetails.operations[lang] || smileAccountDetails.operations[this.defaultLanguage]
    );
  }

  get calendarNames(): Calendar {
    const lang = this.currentLanguage$.getValue();

    return calendarNames[lang] || calendarNames[this.defaultLanguage];
  }

  /**
   *
   * @returns HOT OBSERVABLE
   */
  translateCountries(countries: CatalogCountry[]): Observable<CatalogCountry[]> {
    return this.currentLanguage$.pipe(
      map(() => {
        const translations = this.countries;

        return countries.map((country) => {
          const translatedCountry = translations.find((x) => x.id === country.Id);
          if (translatedCountry) {
            country.Name = translatedCountry.name;
          }

          return country;
        });
      })
    );
  }

  initTranslations() {
    this.translateService.setDefaultLang(this.defaultLanguage);
    let language = this.defaultLanguage;
    const savedLanguage = localStorage.getItem(this.localStorageLanguageName);
    if (savedLanguage) {
      language = JSON.parse(savedLanguage);
    }
    this.currentLanguage$.next(language);
    this.translateService.use(language);
  }

  setLanguage(language: string) {
    this.currentLanguage$.next(language as Language);
    localStorage.setItem(this.localStorageLanguageName, JSON.stringify(language));
    this.translateService.use(language);
  }
}
