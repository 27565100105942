import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from '@env/environment';
import * as moment from 'moment';

export class DateValidators {
  static min(minDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && control.value < minDate) {
        return { minDate: true };
      }

      return null;
    };
  }

  static max(maxDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && control.value > maxDate) {
        return { maxDate: true };
      }

      return null;
    };
  }

  static isLegalAge(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && control.value instanceof Date) {
        const diff = moment().diff(control.value, 'years');
        if (diff < environment.legal_age) {
          return { underLegalAge: true };
        }
      }

      return null;
    };
  }

  static documentExpired(minDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && control.value < minDate) {
        return { documentExpired: true };
      }

      return null;
    };
  }

  static documentNotIssued(maxDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && control.value > maxDate) {
        return { documentNotIssued: true };
      }

      return null;
    };
  }

  static dateRangeValidator(
    fromControlName: string,
    toControlName: string,
    hasOneYear?: boolean
  ): ValidatorFn | null {
    return (formGroup: UntypedFormGroup): ValidationErrors => {
      const dateFrom = formGroup.get(fromControlName);
      const dateTo = formGroup.get(toControlName);

      if (!dateFrom.value || !dateTo.value) {
        return;
      }

      dateFrom.setErrors(dateFrom.errors?.ngbDate);
      dateTo.setErrors(dateTo.errors?.ngbDate);

      if (dateFrom.value < dateTo.value) {
        if (hasOneYear && !moment(dateFrom.value).add(1, 'y').isAfter(moment(dateTo.value))) {
          const oneYearError = { dateDifference: true };
          dateFrom.setErrors(oneYearError);
          dateTo.setErrors(oneYearError);
          formGroup.markAllAsTouched();
          return oneYearError;
        }
        dateFrom.setErrors(dateFrom.errors?.ngbDate);
        dateTo.setErrors(dateTo.errors?.ngbDate);
        return null;
      }
      if (
        new Date(moment(dateFrom.value).toDate().setHours(0, 0, 0)) >
        new Date(moment(dateTo.value).toDate().setHours(23, 59, 59))
      ) {
        const rangeError = { dateRange: true };
        dateFrom.setErrors(rangeError);
        dateTo.setErrors(rangeError);
        formGroup.markAllAsTouched();
        return rangeError;
      }
    };
  }
}
