/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { BaseInputComponent } from '../base-input.component';
import { CatalogCountry } from '@app/interfaces/country-information.interface';

@Component({
  selector: 'app-country-picker-mt',
  templateUrl: './country-picker-mt.component.html',
  styleUrls: ['./country-picker-mt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryPickerMtComponent
  extends BaseInputComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ContentChild('customize') customize!: TemplateRef<HTMLElement>;

  @ViewChild('select', { static: true }) select: NgSelectComponent;
  @Input() customCountries: CatalogCountry[];
  @Input() noFoundCode: string;
  @Output() onCountrySelected: EventEmitter<CatalogCountry> = new EventEmitter();

  countries: CatalogCountry[];
  countriesTest: CatalogCountry[];
  currentCountry: CatalogCountry;
  showCurrentCountryFlag = true;
  isFocused = false;
  isClickOnOption = false;
  indexList = 1;
  pressKey = false;
  currentListElement: CatalogCountry[];
  mouseMoveEvent: Event;
  isMouseMove = true;
  totalElements = 0;

  @Input() set currentTranslatedCountries(value: CatalogCountry[]) {
    this.countriesTest = value;
  }
  get currentTranslatedCountries(): CatalogCountry[] {
    return this.countriesTest;
  }

  constructor(
    public readonly translateService: TranslateService,
    private render: Renderer2
  ) {
    super();
    this.formControlType = 'searchablePicker';
  }

  ngOnInit() {
    this.observeCountry();
    this.keyArrowsListener();
  }

  ngAfterViewInit() {
    if (this.value) {
      this.displayCurrentCountryName();
    }
  }

  ngOnChanges() {
    this.load();
  }

  private load() {
    if (this.customCountries && this.customCountries.length > 0) {
      this.translateCountries();
    } else {
      this.countries = this.currentTranslatedCountries;
    }
  }

  translateCountries() {
    this.currentCountry = this.value;
    if (this.customCountries && this.customCountries.length > 0) {
      this.countries = this.customCountries.map((country) => {
        const translatedCountry = this.currentTranslatedCountries.find((x) => x.Id === country.Id);
        if (translatedCountry) {
          country.Name = translatedCountry.Name;
        }

        return country;
      });
    } else {
      this.countries = this.currentTranslatedCountries;
    }
    this.countries.sort((a, b) => {
      const stringA = a.Name.toLocaleLowerCase();
      const stringB = b.Name.toLowerCase();
      return stringA.localeCompare(stringB);
    });

    if (this.currentCountry) {
      this.currentCountry = this.countries.find((x) => x.Id === this.value.Id);
      this.displayCurrentCountryName();
    }
  }

  observeCountry() {
    this.subscription.add(
      this.control.valueChanges.pipe(distinctUntilChanged()).subscribe((res) => {
        this.currentCountry = res;
        this.showCurrentCountryFlag = true;
        this.displayCurrentCountryName();
        if (this.value) {
          this.indexList = this.countries.findIndex((country) => country.Id === this.value.Id) + 1;
          if (this.indexList === this.countries.length) {
            this.indexList--;
          }
        } else {
          this.indexList = 1;
        }
      })
    );
  }

  displayCurrentCountryName(index?: number) {
    this.indexList = index + 1;
    if (this.currentCountry) {
      this.isClickOnOption = true;
      const inputSearchRef = this.select.element.getElementsByTagName('input');
      inputSearchRef[0].value = this.currentCountry.Name;
    }
  }

  onSearch(e) {
    this.totalElements = e.items.length;
    this.showCurrentCountryFlag = false;
    this.control.patchValue(null);
    this.currentCountry = null;
    if (e.term === '') {
      this.countries = this.customCountries;
      this.select.handleClearClick();
    }
  }

  onBlur() {
    if (this.currentCountry) {
      this.showCurrentCountryFlag = true;
      const inputSearchRef = this.select.element.getElementsByTagName('input');
      inputSearchRef[0].value = this.currentCountry.Name;
    }
    this.onFocus();
  }

  onFocus() {
    this.isFocused = !this.isFocused;
  }

  onOptionSelect(index?: number) {
    this.indexList = index + 1;
    this.isClickOnOption = true;
    this.showCurrentCountryFlag = true;
    this.onCountrySelected.emit(this.currentCountry);
    if (index >= 0 && this.currentCountry) {
      this.displayCurrentCountryName(index);
    }
  }

  onOpenList() {
    const inputSearchRef = this.select.element.getElementsByTagName('input');
    inputSearchRef[0].value = '';
    this.showCurrentCountryFlag = false;
    if (!this.value) {
      this.indexList = 1;
    } else {
      this.indexList = this.countries.findIndex((country) => country.Id === this.value.Id) + 1;
    }
    this.totalElements = this.countries.length;
    this.select.focus();
  }

  private keyArrowsListener() {
    const containerRef = this.select.element.getElementsByClassName('ng-select-container')[0];
    this.render.listen(containerRef, 'keydown.arrowdown', (t) => {
      this.pressKey = true;
      this.mouseMoveEvent.preventDefault();
      this.mouseMoveEvent.stopImmediatePropagation();
      if (this.indexList >= this.totalElements) {
        this.stopPropagation(t);
      }
      if (this.indexList < this.totalElements) {
        this.indexList++;
        if (!this.select.isOpen) {
          this.indexList--;
        }
      }
    });
    this.render.listen(containerRef, 'keydown.arrowup', (t) => {
      this.mouseMoveEvent.preventDefault();
      this.mouseMoveEvent.stopImmediatePropagation();
      this.pressKey = true;
      if (this.indexList <= 1) {
        this.stopPropagation(t);
      }
      this.indexList--;
      if (this.indexList === 0) {
        this.indexList = 1;
      }
    });
  }

  private stopPropagation(e: Event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
  }

  onMouseover(index: number) {
    this.indexList = index + 1;
    this.pressKey = false;
  }

  onMouseMove(e: Event) {
    this.mouseMoveEvent = e;
    this.pressKey = false;
  }
}
