import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/core/services/account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AddIdentityDocumentModalComponent } from './add-identity-document-modal/add-identity-document-modal.component';
import { EditDocumentModalComponent } from './edit-document-modal/edit-document-modal.component';
import { PreviewDocumentModalComponent } from './preview-document/preview-document.component';
import { CustomerDocument } from '@app/interfaces/customer.interface';

@Component({
  selector: 'app-account-modal-identity-documents',
  templateUrl: './account-modal-identity-documents.component.html',
  styleUrls: ['./account-modal-identity-documents.component.scss'],
})
export class AccountModalIdentityDocumentsComponent implements OnInit {
  documents$: Observable<CustomerDocument[]>;
  constructor(
    private readonly accountService: AccountService,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit() {
    this.fetchDocuments();
  }

  openDocumentPreview(document: CustomerDocument): void {
    const modal = this.modalService.open(PreviewDocumentModalComponent, { size: 'xl' });
    modal.componentInstance.document = document;
  }

  openAddDocumentModal(): void {
    this.modalService
      .open(AddIdentityDocumentModalComponent, { size: 'lg' })
      .result.then(() => this.fetchDocuments());
  }

  openEditDocumentModal(document: CustomerDocument): void {
    const modal = this.modalService.open(EditDocumentModalComponent, { size: 'lg' });
    modal.componentInstance.document = document;
  }

  private fetchDocuments() {
    this.documents$ = this.accountService.fetchIdentityDocuments();
  }
}
