import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SessionService } from '@app/core/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private readonly sessionService: SessionService) {}

  canActivate() {
    if (this.sessionService.isUserLoggedIn) {
      return true;
    }
    this.sessionService.logout();
    return false;
  }
}
