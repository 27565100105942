import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountResponse } from '@app/interfaces/account-response.interface';
import {
  IdDocComplianceTypeResponse,
  IdDocTypeResponse,
} from '@app/interfaces/id-doc-response.interface';
import { environment } from '@env/environment';
import { ApiResponse } from '@app/interfaces/api-response.interface';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  private readonly ACCOUNTS_URL = `${environment.apiBasePath}/corp/accounts`;
  private readonly IS_ACCOUNT_VALID_URL = `${environment.apiBasePath}/corp/accounts/valid`;
  private readonly ACCOUNT_EXISTS_URL = `${environment.apiBasePath}/corp/accounts/exists`;
  private readonly ID_DOCUMENT_TYPES_URL = `${environment.apiBasePath}/account/iddoc/types`;
  private readonly ADDITIONAL_DOCUMENT_TYPES_URL = `${environment.apiBasePath}/account/compliancedoc/types`;

  constructor(private readonly httpClient: HttpClient) {}

  public getAccountByNumber(number: string): Observable<AccountResponse> {
    return this.httpClient.get<AccountResponse>(`${this.ACCOUNTS_URL}?searchTerm=${number}`);
  }

  public checkAccountValid(number: string): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(
      `${this.IS_ACCOUNT_VALID_URL}?accNumber=${number}`
    );
  }

  public checkAccountExists(number: string): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(
      `${this.ACCOUNT_EXISTS_URL}?accNumber=${number}`
    );
  }

  // TODO move to OptionsService
  public getGetIdDocTypes(): Observable<IdDocTypeResponse> {
    return this.httpClient.get<IdDocTypeResponse>(`${this.ID_DOCUMENT_TYPES_URL}`);
  }

  public getIdDocTypesByProduct(product: string): Observable<IdDocTypeResponse> {
    return this.httpClient.get<IdDocTypeResponse>(`${this.ID_DOCUMENT_TYPES_URL}/${product}`);
  }

  // TODO move to OptionsService
  public getGetAdditionalDocTypes(): Observable<IdDocComplianceTypeResponse> {
    return this.httpClient.get<IdDocComplianceTypeResponse>(
      `${this.ADDITIONAL_DOCUMENT_TYPES_URL}`
    );
  }
}
