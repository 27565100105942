<button
  class="arrow arrow__size"
  [ngClass]="color"
  [disabled]="disabled"
  (click)="handleClick($event)"
  [type]="type">
  <div class="arrow__container">
    <span class="arrow__text">
      {{ callToAction }}
    </span>
    <i class="arrow__icon__normal icon icon-chevron-bg-right-default icon-20">
      <span class="path1" [ngClass]="color"></span>
      <span class="path2" [ngClass]="color"></span>
    </i>
    <i class="arrow__icon__active icon icon-chevron-bg-right-active icon-20">
      <span class="path1" [ngClass]="color"></span>
      <span class="path2" [ngClass]="color"></span>
    </i>
  </div>
</button>
