import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencySignPipe } from '@app/pipes/currency-sign.pipe';

@Pipe({
  name: 'balance',
  standalone: true,
})
export class BalancePipe implements PipeTransform {
  constructor(
    private readonly currencySignPipe: CurrencySignPipe,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {}

  transform(value: number, currencyISOCode: string, withSing = true, withCurrency = true): string {
    let currencySign = this.currencySignPipe.transform(currencyISOCode);
    const decimalValue = formatNumber(value, this.locale, '1.2-3');
    let sign = '';

    sign = value > 0 && withSing ? '+' : '';

    currencySign = withCurrency ? currencySign : '';

    return `${sign}${decimalValue} ${currencySign}`;
  }
}
