<div class="mixed__card" (click)="handleClick($event)">
  <div class="mixed__card__header" [class.mixed__active]="active">
    <span class="mixed__card__title">{{ title }}</span>
    <span class="mixed_card__subtitle">{{ subTitle }}</span>
  </div>
  <div class="mixed__card__options" (click)="handleAction($event)">
    <span class="mixed__card__action">{{ action }}</span>
    <i class="icon {{ icon }}">
      <span class="path1"></span>
      <span class="path2"></span>
    </i>
  </div>
</div>
