<app-modal-header [title]="operator.DisplayName"></app-modal-header>
<div class="container amount">
  <div class="row">
    <div class="col-12 amount__container">
      <app-amount-entry-picker
        [entries]="amountEntries$ | async"
        [formGroup]="form"
        fullWidth="true"
        [options]="inputs.AmountEntry"
        [loading]="loading"></app-amount-entry-picker>
    </div>

    <div class="col-12 amount__container__btn">
      <app-button-big
        onkeyup="null"
        (Click)="goToSendTopUP()"
        [callToAction]="'M_IMT_SEND_TOP_UP_LATERAL_SUB_MENU' | translate"
        [disabled]="loading"
        [color]="'primary'"></app-button-big>
    </div>
  </div>
</div>
