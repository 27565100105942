import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appFlagIcon]',
  standalone: true,
})
export class FlagIconDirective implements OnChanges {
  @Input() ISOCode: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { ISOCode } = changes;
    if (ISOCode.previousValue) {
      this.renderer.removeClass(this.el.nativeElement, 'fi');
      this.renderer.removeClass(this.el.nativeElement, `fi-${ISOCode.previousValue.toLowerCase()}`);
    }
    this.setFlag(ISOCode.currentValue);
  }

  private setFlag(ISOCode: string): void {
    if (!ISOCode) {
      return;
    }
    this.renderer.addClass(this.el.nativeElement, 'fi');
    this.renderer.addClass(this.el.nativeElement, `fi-${ISOCode.toLowerCase()}`);
  }
}
