<div class="form-group row" [formGroup]="formGroup">
  <label class="col-lg-4 col-form-label" [class.col-lg-12]="fullWidth">
    <span *ngIf="showRequiredSymbol && isRequired" class="text-danger me-1">*</span>
    {{ options.label | translate }}
  </label>
  <div class="col-lg-8" [class.col-lg-12]="fullWidth">
    <ng-template #rt let-r="result" let-t="term">
      <ngb-highlight [result]="r.Name" [term]="t"></ngb-highlight>
    </ng-template>
    <input
      type="text"
      class="form-control"
      [class.is-invalid]="isInvalid"
      [class.disabled]="!countryId"
      [formControlName]="options.formControlNameValue"
      [ngbTypeahead]="search"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
      [editable]="false"
      (focus)="focus$.next($any($event).target.value)"
      (click)="click$.next($any($event).target.value)"
      [placeholder]="'C_F_ENTER_3_OR_MORE' | translate"
      autocomplete="chrome-off"
      #instance="ngbTypeahead"
      [appTrimInput]="true" />
    <ng-container *ngIf="searchFailed">
      <small class="form-text text-muted">No matches found</small>
    </ng-container>
    <ng-container *ngIf="!countryId && !isDisabled">
      <small class="form-text text-muted">
        {{ 'C_F_SELECT_COUNTRY_FIRST' | translate }}
      </small>
    </ng-container>
    <ng-container *ngIf="options?.helper">
      <small class="form-text text-muted">
        {{ options.helper | translate }}
      </small>
    </ng-container>
    <app-errors
      [isInvalid]="isInvalid"
      [formGroup]="formGroup"
      [formControlNameValue]="options.formControlNameValue"></app-errors>
  </div>
</div>
