import {
  ApplicationRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AccountService } from '@app/core/services/account.service';
import { CountryService } from '@app/core/services/country.service';
import { SessionService } from '@app/core/services/session.service';
import { Account } from '@app/interfaces/account/account.interface';
import { CatalogCountry } from '@app/interfaces/country-information.interface';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { AlertModalService } from '@app/shared/modals/alert-modal/alert-modal.service';
import { TimeoutModalComponent } from '@app/shared/modals/timeout-modal/timeout-modal.component';
import { environment } from '@env/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { HeaderComponent } from './header/header.component';
import { FreshChatService } from '@app/services/freshchat.service';
import { MenuOption } from '@app/interfaces/menu-options.interface';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(HeaderComponent, { static: true }) header: HeaderComponent;

  showSidebar = false;
  showSidebarMenu = false;
  showNavbarMobileVersion = true;
  showReturnToHomeButton = true;
  title = 'P_HOME';
  route = 'app';
  showBackButtonInSmall = false;
  scriptElementFC: HTMLScriptElement;
  fsChatSubject$ = new BehaviorSubject<boolean>(false);
  fsChatEnabled = environment.services.freshChat.enabled;
  initDataSubject$ = new BehaviorSubject<{ country: CatalogCountry; account: Account }>(null);
  initData$: Observable<{ country: CatalogCountry; account: Account }> = this.accountService
    .fetchAccountAsync()
    .pipe(
      switchMap((account) => {
        return this.countryService
          .fetchSourceCountry(account.IdCustomer)
          .pipe(map((country) => ({ country, account })));
      })
    );

  private modalRef: NgbModalRef;
  private readonly destroy$: Subject<void> = new Subject<void>();

  options: MenuOption[] = [
    {
      label: 'P_PROFILE_DETAIL',
      icon: 'icon-user',
      active: false,
      route: 'profile-account/detail',
    },
    {
      label: 'C_F_IDENTITY_DOCUMENTS',
      icon: 'icon-id-document-charge',
      active: false,
      route: 'profile-account/identity-documents',
    },
    {
      label: 'C_A_COMPLIANCE_FORM',
      icon: 'icon-location-address-document',
      active: false,
      route: 'profile-account/additional-documents',
    },
    {
      label: 'C_SECURITY',
      icon: 'icon-code-sms',
      active: false,
      route: 'profile-account/security',
    },
    {
      label: 'PAYMENT_METHOD_SAVED',
      icon: 'icon-wallet-euro',
      active: false,
      route: 'profile-account/payment-methods',
    },
  ];

  constructor(
    private readonly idle: Idle,
    private readonly sessionService: SessionService,
    private readonly alertModalService: AlertModalService,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly accountService: AccountService,
    private readonly renderer: Renderer2,
    private readonly countryService: CountryService,
    private readonly freshChatService: FreshChatService,
    private appRef: ApplicationRef
  ) {
    super();
    const loginSklElment = this.renderer.selectRootElement('#login-skl-loader');
    this.renderer.addClass(loginSklElment, 'd-none');
    this.routeListener();

    // Idle config
    this.idle.setIdle(environment.idle.seconds);
    this.idle.setTimeout(environment.idle.timeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeoutWarning.pipe(takeUntil(this.destroy$)).subscribe({
      next: (seconds) => {
        if (!this.modalRef) {
          this.modalRef = this.openTimeoutModal(seconds);
        }
      },
      error: (error) => console.log(error),
    });

    this.idle.onTimeout.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.openAlertModal();
      },
      error: (error) => console.log(error),
    });

    this.idle.onIdleEnd.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        if (this.modalRef) {
          this.modalRef.dismiss();
          this.modalRef = null;
        }
        this.appRef.tick();
      },
      error: (error) => console.log(error),
    });
    this.idle.watch();
  }

  onActivate(componentRef) {
    if (componentRef.setWrapperComponent) {
      componentRef.setWrapperComponent(this.header);
    }
  }

  ngOnInit() {
    this.initData$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => this.initDataSubject$.next(res),
      error: (err) => console.log(err),
      complete: async () => {
        const { country, account } = this.initDataSubject$.getValue() || {};
        if (country && account) {
          await this.freshChatService.init(country, account);
          this.fsChatSubject$.next(window.innerWidth < 766);
        }
      },
    });

    this.onResize();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.idle.stop();
  }

  eventListener(e: { sidebar: boolean; sidebarMenu: boolean }) {
    this.showSidebar = e?.sidebar;
    this.showSidebarMenu = e?.sidebarMenu;
  }

  @HostListener('window:resize')
  onResize() {
    this.freshChatService.update();
    this.fsChatSubject$.next(window.innerWidth < 766);
    if (window.innerWidth > 766 && this.route.includes('contact')) {
      this.router.navigateByUrl('contact');
    }
  }

  onChatWidget() {
    this.freshChatService.open();
  }

  private openTimeoutModal(countdown: number): NgbModalRef {
    const modalRef = this.modalService.open(TimeoutModalComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: 'alert-modal-width',
      keyboard: false,
    });
    modalRef.componentInstance.countdown = countdown;
    modalRef.result.finally(() => {
      this.modalRef = null;
    });

    return modalRef;
  }

  private openAlertModal(): void {
    this.modalService.dismissAll();
    this.alertModalService.danger(
      ['MSG_TIMEOUT'],
      '',
      '',
      () => {
        this.sessionService.setIsSessionTimeOut(true);
        this.sessionService.logout();
      },
      [],
      () => {
        this.sessionService.setIsSessionTimeOut(true);
        this.sessionService.logout();
      },
      'server-error-modal'
    );
  }

  private routeListener() {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          distinctUntilChanged()
        )
        .subscribe((route: NavigationEnd) => {
          this.route = route.url;
          this.showBackButtonInSmall = false;
          if (route.url === '/') {
            this.showNavbarMobileVersion = true;
            this.showReturnToHomeButton = false;
          } else if (
            (route.url.includes('/enrollment') &&
              route.url !== '/enrollment/documents' &&
              route.url !== '/enrollment/information' &&
              route.url !== '/enrollment/address' &&
              route.url !== '/enrollment/additional-information' &&
              route.url !== '/enrollment/initial-deposit' &&
              route.url !== '/enrollment/summary' &&
              route.url !== '/enrollment/re-open/summary-payment') ||
            route.url.includes('/pac/credit-my-account') ||
            route.url.includes('/pac/transfer-funds')
          ) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'PAC_M_SMILE_ACCOUNT';
            this.route = 'pac';
          } else if (route.url.includes('/pac/history/detail')) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'PAC_M_HISTORY';
            this.route = 'pac/history';
          } else if (route.url.includes('/pac/beneficiaries')) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'M_IMT_RECIPIENTS';
            this.route = 'pac/recipients';
          } else if (route.url.includes('/pac/fees') || route.url.includes('/pac/limits')) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'C_ACCOUNT';
            this.route = 'pac/account';
          } else if (route.url.includes('/imt/beneficiaries')) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'M_IMT_RECIPIENTS';
            this.route = 'imt/recipients';
          } else if (route.url.includes('/imt/beneficiaryForm')) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'M_IMT_SEND_TOP_UP_BUTTON';
            this.route = '/imt/send/beneficiary';
          } else if (route.url.includes('/imt/send')) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'C_SEND_TOP_UP';
            this.route = 'imt';
          } else if (this.route.includes('imt/transaction')) {
            this.showNavbarMobileVersion = false;
            this.showReturnToHomeButton = true;
            this.showBackButtonInSmall = true;
            this.title = 'PAC_M_HISTORY';
            this.route = 'imt/history';
          } else if (route.url.includes('mtr/send-money')) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'PAC_M_DASHBOARD_ALT';
            this.route = 'mtr';
          } else if (route.url.includes('mtr/transactions')) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'PAC_M_HISTORY';
            this.route = 'mtr/history';
          } else if (route.url.includes('mtr/beneficiaries')) {
            this.showNavbarMobileVersion = false;
            this.showBackButtonInSmall = true;
            this.showReturnToHomeButton = true;
            this.title = 'M_IMT_RECIPIENTS';
            this.route = 'mtr/recipients';
          } else if (
            this.route.includes('telephone') ||
            this.route.includes('whatsapp') ||
            this.route.includes('email')
          ) {
            this.title = 'P_HELP';
            this.showNavbarMobileVersion = false;
            this.showReturnToHomeButton = true;
            this.showBackButtonInSmall = true;
            this.route = 'contact';
          } else {
            this.showNavbarMobileVersion = false;
            this.showReturnToHomeButton = true;
            this.showBackButtonInSmall = true;
            this.title = 'P_HOME';
            this.route = '';
          }

          this.checkContact(route);
        })
    );
  }

  private checkContact(route: NavigationEnd) {
    if (route.url === '/contact') {
      this.showNavbarMobileVersion = true;
      this.showBackButtonInSmall = false;
      this.showReturnToHomeButton = true;
      this.title = 'P_HOME';
      this.route = '';
    }
  }
}
