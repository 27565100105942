import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

/* eslint-disable */
declare let hj: Function;

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  private enabled: boolean;
  private hjid: number | null;
  private hjsv: number | null;

  constructor() {
    const { hotjar } = environment.services;
    this.enabled = hotjar.enabled;
    this.hjid = hotjar.hjid;
    this.hjsv = hotjar.hjsv;
  }

  init(): void {
    if (this.enabled) {
      const hjid = this.hjid;
      const hjsv = this.hjsv;

      (function (h: any, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function (...args: any[]) {
            (h.hj.q = h.hj.q || []).push(args);
          };
        h._hjSettings = { hjid, hjsv };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = true;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    } else {
      console.log('Hotjar is disabled in this environment.');
    }
  }

  emitEvent(action: string) {
    if (this.enabled) {
      hj('event', action);
    }
  }
}
