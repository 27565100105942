<ul class="breadcrumb-small d-md-none d-lg-none d-xl-none">
  <ng-container *ngFor="let option of options; let i = index">
    <li
      class="breadcrumb-small__option"
      [class.disabled]="option.disabled"
      [class.active__small]="option.active"
      [class.bg-base]="option.completedStep"
      [routerLink]="option.router"
      [class.center]="!option.active">
      <ng-container *ngIf="option.completedStep">
        <span class="breadcrumb-small__completed completed-step">
          <i class="fas fa-check"></i>
        </span>
      </ng-container>
      <ng-container *ngIf="!option.completedStep">
        <div class="breadcrumb-small__option__number">
          <span class="breadcrumb-small__option__number__label">
            {{ i + 1 }}
            <span *ngIf="option.active">.</span>
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="option.active">
        <span class="breadcrumb-small__option__label">
          {{ option.title | translate }}
        </span>
      </ng-container>
    </li>
  </ng-container>
</ul>

<ul class="breadcrumb-content d-none d-md-block d-lg-block d-xl-block">
  <ng-container *ngFor="let option of options; let i = index">
    <li
      class="breadcrumb-content__option"
      [class.disabled]="option.disabled"
      [class.active]="option.active"
      [class.bg-base]="option.completedStep"
      [routerLink]="option.router">
      <ng-container *ngIf="option.completedStep; else step">
        <span class="completed-step">
          <i class="fas fa-check"></i>
        </span>
      </ng-container>
      <ng-template #step>
        <div class="breadcrumb-content__option__number">
          <div>{{ i + 1 }}.</div>
        </div>
      </ng-template>
      <ng-container *ngIf="!option.completedStep; else stepInfo">
        <span class="breadcrumb-content__option__label">
          {{ option.title | translate }}
        </span>
      </ng-container>
      <ng-template #stepInfo>
        <span
          class="breadcrumb-content__option__label"
          [innerHTML]="option.stepInfoText | translate"></span>
      </ng-template>
    </li>
  </ng-container>
</ul>
