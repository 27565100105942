export enum CatalogNumber {
  FAMILY_STATUS = 1,
  PROPERTY_STATUS = 2,
  PROFESSIONAL_STATUS = 3,
  FINANCIAL_STATUS = 4,
  SMILE_ACCOUNT_USE = 5,
}

export enum AcceptanceDataForm {
  INCOME_RANGE = 'IncomeRange',
  TAX_RESIDENT = 'TaxResident',
  TAX_COUNTRY = 'TaxCountry',
  GENDER = 'Gender',
  FAMILY_STATUS = 'FamilyStatus',
  OCCUPATION = 'Occupation',
  IS_PRINCIPAL_ACCOUNT = 'IsPrincipalAccount',
  PROPERTY_STATUS = 'PropertyStatus',
  NAME_EMPLOYER = 'NameOfEmployer',
}

/**
 * MovementEndToEndId enum used to track the type of movement.
 * @enum {string}
 */
export enum MovementEndToEndId {
  withdrawType = 'withdraw',
  depositType = 'deposit',
  receivedType = 'received',
  incomingTransfer = 'TRANSFER_IN',
  outgoingTransfer = 'TRANSFER_OUT',
  enrolmentTransfer = 'ENROLMENT-PRINCIPAL',
  principalTransfer = 'TRANSFER-PRINCIPAL',
  feeTransfer = 'TRANSFER_FEES',
  purchaseTransfer = 'PURCHASE',
  moneyTransferPayout = 'MONEY_TRANSFER_PAYOUT',
  manteTransfer = 'MAINTENANCE',
  notProvided = 'NOT_PROVIDED',
  refundTransfer = 'MANOPR',
  OTHER = 'OTHER',
  mtProductPay = 'MT_PRODUCT_PAY',
  otherFees = 'OTHER_FEES',
}

/**
 * BankOperationsType enum representing various bank operation types.
 * @enum {string}
 */
export enum BankOperationsType {
  /** CTIN - Transfer in */
  CTIN = 'CTIN',
  /** CTINIT - Internal transfer in */
  CTINIT = 'CTINIT',
  /** CTINRE - Transfer in rehabilitated */
  CTINRE = 'CTINRE',
  /** CTINSU - Pending transfer */
  CTINSU = 'CTINSU',
  /** CTOU - Transfer out */
  CTOU = 'CTOU',
  /** CTOUIT - Internal transfer out */
  CTOUIT = 'CTOUIT',
  /** GRTO - Global. unpaid transfer */
  GRTO = 'GRTO',
  /** GTI - Global. transfer in */
  GTI = 'GTI',
  /** GTO - Global. transfer out */
  GTO = 'GTO',
  /** MANOPR - Manual operation */
  MANOPR = 'MANOPR',
  /** RVCTIN - Unpaid transfer out */
  RVCTIN = 'RVCTIN',
  /** RVCTO - Unpaid transfer in */
  RVCTO = 'RVCTOU',
  /** BCTROP - unknown */
  BCTROP = 'BCTROP',
  /** BCTRFE - unknown */
  BCTRFE = 'BCTRFE',
}
