<div class="user">
  <div class="user__image">
    <img src="assets/images/common/no-img-profile.jpg" alt="user-avatar" />
  </div>
  <div class="user__details">
    <ng-container *ngIf="!loading">
      <div class="user__details__item" @fadeIn>
        <div class="user__details__item__icon"><i class="far fa-address-card"></i></div>
        <div class="user__details__item__wrapper">
          <div class="user__details__item__wrapper__label">{{ 'C_NAME' | translate }}</div>
          <div class="user__details__item__wrapper__value">
            {{ account.Name }} {{ account.LastName }}
          </div>
        </div>
      </div>
      <div class="user__details__item" @fadeIn>
        <div class="user__details__item__icon"><i class="fas fa-phone"></i></div>
        <div class="user__details__item__wrapper">
          <div class="user__details__item__wrapper__label">{{ 'C_F_PHONE' | translate }}</div>
          <div class="user__details__item__wrapper__value">
            {{ account.Telephone ? account.Telephone : '-' }}
          </div>
        </div>
      </div>
      <div class="user__details__item" @fadeIn>
        <div class="user__details__item__icon"><i class="fas fa-envelope"></i></div>
        <div class="user__details__item__wrapper">
          <div class="user__details__item__wrapper__label">{{ 'C_F_EMAIL' | translate }}</div>
          <div class="user__details__item__wrapper__value">
            {{ account.UserMail ? account.UserMail : '-' }}
          </div>
        </div>
      </div>
      <div class="user__details__item" @fadeIn>
        <div class="user__details__item__icon"><i class="fas fa-map-marker-alt"></i></div>
        <div class="user__details__item__wrapper">
          <div class="user__details__item__wrapper__label">{{ 'C_F_ADDRESS' | translate }}</div>
          <div class="user__details__item__wrapper__value">
            {{ address }}
          </div>
        </div>
      </div>
    </ng-container>
    <app-infinite-scroll-loader [show]="loading"></app-infinite-scroll-loader>
  </div>
</div>
