import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuOption } from '@app/interfaces/menu-options.interface';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
})
export class SidebarMenuComponent {
  @Input() showSidebarMenu: boolean;
  @Input() options: MenuOption[];
  @Output() readonly clickOptionEvent = new EventEmitter<{ sidebarMenu: boolean }>();

  constructor(public readonly translate: TranslateService) {}

  close(): void {
    this.clickOptionEvent.emit({ sidebarMenu: false });
  }
}
